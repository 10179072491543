import { CardContainer } from '../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../styled/Table'
import PropTypes from 'prop-types'
import React from 'react'

const COLUMNS_LENGTH = 8
const PREFIX_KEY = 'lateAppointments'

const LateAppointments = ({ data, isLoading, errorMessage }) => (
  <CardContainer>
    <StyledTable>
      <thead>
        <TableRow>
          <TableColumnHeader />
          <TableColumnHeader>DATE</TableColumnHeader>
          <TableColumnHeader>START TIME</TableColumnHeader>
          <TableColumnHeader>BUILDING</TableColumnHeader>
          <TableColumnHeader>DOOR</TableColumnHeader>
          <TableColumnHeader nowrap>APPOINTMENT #</TableColumnHeader>
          <TableColumnHeader>CARRIER</TableColumnHeader>
          <TableColumnHeader>SHIP TO</TableColumnHeader>
        </TableRow>
      </thead>
      <tbody>
        {(!isLoading && data) && data.map((s, key) => (
          <TableRow key={PREFIX_KEY + key + s.get('date') + s.get('startTime')}>
            <TableCol>{key + 1}</TableCol>
            <TableCol>{s.get('date')}</TableCol>
            <TableCol>{s.get('startTime')}</TableCol>
            <TableCol>{s.get('building')}</TableCol>
            <TableCol>{s.get('door')}</TableCol>
            <TableCol>{s.get('appointmentNumber')}</TableCol>
            <TableCol>{s.get('carrier')}</TableCol>
            <TableCol>{s.get('shipTo')}</TableCol>
          </TableRow>
        ))}
        {isLoading && (
          <TableRow key={`${PREFIX_KEY}Loading`}>
            <TableCol centerText colSpan={COLUMNS_LENGTH}> LOADING REPORT </TableCol>
          </TableRow>
        )}
        {(!isLoading && !errorMessage && (!data || data.isEmpty())) && (
          <TableRow key={`${PREFIX_KEY}NoData`}>
            <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
          </TableRow>
        )}
        {(errorMessage && !isLoading) && (
          <TableRow key={`${PREFIX_KEY}Error`}>
            <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
          </TableRow>
        )}
      </tbody>
    </StyledTable>
  </CardContainer>
)

LateAppointments.propTypes = {
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool
}

export default LateAppointments
