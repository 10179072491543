import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import {
  BackgroundPageContainer,
  SecondaryContainer
} from '../../../styled/Containers'
import { ButtonSecondary } from '../../../styled/Buttons'
import { ContrastHeaderTitle } from '../../../styled/Texts'
import { Form } from '../../../styled/Inputs'
import { Header } from '../../../styled/Headers'
import { Panel } from '../../../styled/Boxes'
import TextInput from '../../../components/TextInput'
import UsersActions from '../../../modules/users/actions'
import backgroundImage from '../../../assets/images/login-background.jpg'
import logoImage from '../../../assets/images/taylor-farms-logo.svg'
import { isLoggedIn } from '../../../modules/users/selectors'

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      resetError: false
    }
  }

  onUsernameChange = e => {
    const { value: username } = e.target
    this.setState(state => ({
      ...state,
      username,
      resetError: true
    }))
  }

  onPasswordChange = e => {
    const { value: password } = e.target
    this.setState(state => ({
      ...state,
      password,
      resetError: true
    }))
  }

  onSubmit = e => {
    e.preventDefault()
    const { username = '', password } = this.state
    const { login, isLoading } = this.props

    if (isLoading) {
      return
    }

    this.setState({ resetError: false })
    login({ username: username.trim().toLowerCase(), password })
  }

  render () {
    const { username, password, resetError } = this.state
    const { isLoggedIn, isLoading, loginErrorMessage } = this.props

    if (isLoggedIn) {
      return <Navigate to="/scheduler" replace/>
    }

    return (
      <BackgroundPageContainer centered imageSrc={backgroundImage}>
        <Panel>
          <SecondaryContainer>
            <Header flex padded>
              <ContrastHeaderTitle centered>SmartDock</ContrastHeaderTitle>
              <img src={logoImage} alt="Company X" width="90" height="75"/>
            </Header>
          </SecondaryContainer>
          <Form onSubmit={this.onSubmit}>
            <TextInput
              fullwidth="true"
              label="Username"
              type="text"
              placeholder="user@companyx.com"
              value={username}
              autoComplete="email"
              onChange={this.onUsernameChange}
            />
            <TextInput
              fullwidth="true"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={this.onPasswordChange}
            />
            {!!loginErrorMessage && !resetError ? (
              <ButtonSecondary type="submit" primary error disabled={isLoading}>
                Invalid Credentials
              </ButtonSecondary>
            ) : (
              <ButtonSecondary type="submit" primary disabled={isLoading}>
                Login
              </ButtonSecondary>
            )}
          </Form>
        </Panel>
      </BackgroundPageContainer>
    )
  }
}

Login.propTypes = {
  isLoggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  loginErrorMessage: PropTypes.string,
  login: PropTypes.func
}

const mapStateToProps = state => ({
  loginErrorMessage: state.users.get('loginErrorMessage'),
  isLoggedIn: isLoggedIn(state),
  isLoading: !!state.users.get('loginIsLoading')
})

const mapDispatchToProps = dispatch => ({
  login: payload => dispatch(UsersActions.login(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
