import { createReducer } from 'reduxsauce'
import { fromJS, Map, List } from 'immutable'
import uniqWith from 'lodash.uniqwith'

import { AppointmentsTypes } from './actions'
import { INITIAL_STATE } from './initialState'
import { ignore } from '../../utils/common'

// getAppointments
const getAppointmentsLoading = state =>
  state.merge({
    getAppointmentsIsLoading: true,
    getAppointmentsErrorMessage: ''
  })

const getAppointmentsSuccess = (state, { appointments }) =>
  state.merge({
    appointments: appointments.data ? appointments.data : appointments,
    total: appointments.total >= 0 ? appointments.total : appointments.length,
    pages: appointments.pages >= 0 ? appointments.pages : 0,
    getAppointmentsIsLoading: false,
    getAppointmentsErrorMessage: null
  })

const getAppointmentsFailure = (state, { errorMessage }) =>
  state.merge({
    appointments: null,
    getAppointmentsIsLoading: false,
    getAppointmentsErrorMessage: errorMessage
  })

// getAppointment
const getAppointmentLoading = state =>
  state.merge({
    getAppointmentIsLoading: true,
    getAppointmentErrorMessage: ''
  })

const getAppointmentSuccess = (state, { appointment }) =>
  state.merge({
    appointment: ignore(appointment, ['meta']),
    getAppointmentIsLoading: false,
    getAppointmentErrorMessage: null
  })

const getAppointmentFailure = (state, { errorMessage }) =>
  state.merge({
    appointment: null,
    getAppointmentIsLoading: false,
    getAppointmentErrorMessage: errorMessage
  })

// createAppointment
const createAppointmentLoading = state =>
  state.merge({
    createAppointmentIsLoading: true,
    createAppointmentErrorMessage: null
  })

const createAppointmentSuccess = (state, { appointment }) =>
  state.merge({
    createAppointmentIsLoading: false,
    createAppointmentErrorMessage: null,
    editingAppointment: state.get('isUpsertAppointmentVisible')
      ? state.get('editingAppointment', new Map())
        .merge(ignore(appointment, ['meta']))
      : null,
    editingAppointmentIssues: state.get('isUpsertAppointmentVisible')
      ? appointment.meta.appointmentIssues
      : new Map({
        hasPastDate: false,
        hasLowInventory: false,
        hasConflictingInventory: false
      }),
    editingAppointmentSuggestionsTimes: state.get('isUpsertAppointmentVisible')
      ? appointment.meta.suggestions
      : new List()
  })

const createAppointmentFailure = (state, { errorMessage }) =>
  state.merge({
    createAppointmentIsLoading: false,
    createAppointmentErrorMessage: errorMessage
  })

// deleteAppointment
const deleteAppointmentLoading = state =>
  state.merge({
    deleteAppointmentIsLoading: true,
    deleteAppointmentErrorMesage: null
  })

const deleteAppointmentSuccess = state =>
  state.merge({
    deleteAppointmentIsLoading: false,
    deleteAppointmentErrorMesage: null
  })

const deleteAppointmentFailure = (state, { errorMessage }) =>
  state.merge({
    deleteAppointmentIsLoading: false,
    deleteAppointmentErrorMesage: errorMessage
  })

// updateAppointment
const updateAppointmentLoading = (state, { appointmentId }) =>
  state.merge({
    updateAppointmentIsLoading: appointmentId,
    updateAppointmentErrorMessage: null
  })

const updateAppointmentSuccess = (state, { appointment }) => {
  const updatedAppointment = ignore(appointment, ['meta'])
  const updatedAppointmentIndex = state
    .get('appointments')
    .findIndex(
      appointment => appointment.get('id') === updatedAppointment.get('id')
    )
  const outdatedAppointment = state
    .get('appointments')
    .get(updatedAppointmentIndex)

  return state.merge({
    appointments:
      updatedAppointmentIndex === -1
        ? state.get('appointments').push(updatedAppointment)
        : state
          .get('appointments')
          .set(
            updatedAppointmentIndex,
            outdatedAppointment.merge(updatedAppointment)
          ),
    updateAppointmentIsLoading: false,
    updateAppointmentErrorMessage: null,
    editingAppointment:
      state.get('isUpsertAppointmentVisible') && state.get('editingAppointment')
        ? state.get('editingAppointment').merge(updatedAppointment)
        : null,
    editingAppointmentIssues: state.get('isUpsertAppointmentVisible')
      ? appointment.meta.appointmentIssues
      : new Map({
        hasPastDate: false,
        hasLowInventory: false,
        hasConflictingInventory: false
      }),
    editingAppointmentSuggestionsTimes: state.get('isUpsertAppointmentVisible')
      ? appointment.meta.suggestions
      : new List()
  })
}

const updateAppointmentFailure = (state, { errorMessage }) =>
  state.merge({
    updateAppointmentIsLoading: false,
    updateAppointmentErrorMessage: errorMessage
  })

const updateAppointments = (state, { appointments }) => {
  const uniqueAppointments = uniqWith(
    [...state.get('appointments'), ...fromJS(appointments)],
    (a, b) => a.get('id') === b.get('id')
  )
  return state.merge({
    appointments: uniqueAppointments,
    getAppointmentsIsLoading: false,
    getAppointmentsErrorMessage: null
  })
}

// getAllAppointmentStatuses
const getAllAppointmentStatusesLoading = state =>
  state.merge({
    getAllAppointmentStatusesIsLoading: true,
    getAllAppointmentStatusesErrorMessage: ''
  })

const getAllAppointmentStatusesSuccess = (state, { appointmentStatuses }) =>
  state.merge({
    appointmentStatuses,
    getAllAppointmentStatusesIsLoading: false,
    getAllAppointmentStatusesErrorMessage: null
  })

const getAllAppointmentStatusesFailure = (state, { errorMessage }) =>
  state.merge({
    appointmentStatuses: null,
    getAllAppointmentStatusesIsLoading: false,
    getAllAppointmentStatusesErrorMessage: errorMessage
  })

const openCreateAppointment = state =>
  state.merge({
    isUpsertAppointmentVisible: true,
    editingAppointmentTab: 0,
    editingAppointment: null
  })

const openEditAppointmentIsLoading = (state, { appointmentId }) =>
  state.merge({
    openEditAppointmentIsLoading: appointmentId
  })

const moveAppointmentSuccess = (state) =>
  state.merge({
    openEditAppointmentIsLoading: false
  })

const openEditAppointmentSuccess = (state, { appointment }) =>
  state.merge({
    isUpsertAppointmentVisible: true,
    openEditAppointmentIsLoading: false,
    editingAppointmentTab: 0,
    editingAppointment: appointment
  })

const changeEditAppointmentTab = (state, { tab }) =>
  state.merge({
    isUpsertAppointmentVisible: true,
    editingAppointmentTab: tab
  })

const closeUpsertAppointment = state =>
  state.merge({
    isUpsertAppointmentVisible: false,
    openEditAppointmentIsLoading: false,
    editingAppointmentTab: 0,
    editingAppointment: null
  })

// getAppointmentsForWarehouse
const getAppointmentsForWarehouseLoading = state =>
  state.merge({
    getAppointmentsForWarehouseIsLoading: true,
    getAppointmentsForWarehouseErrorMessage: ''
  })

const getAppointmentsForWarehouseSuccess = state =>
  state.merge({
    getAppointmentsForWarehouseIsLoading: false,
    getAppointmentsForWarehouseErrorMessage: null
  })

const getAppointmentsForWarehouseFailure = (state, { errorMessage }) =>
  state.merge({
    getAppointmentsForWarehouseIsLoading: false,
    getAppointmentsForWarehouseErrorMessage: errorMessage
  })

// clearRequest
const clearRequestLoading = state =>
  state.merge({
    clearRequestIsLoading: true,
    clearRequestErrorMessage: null
  })

const clearRequestSuccess = (state, { appointment }) =>
  state.merge({
    clearRequestIsLoading: false,
    clearRequestErrorMessage: null,
    editingAppointment: ignore(appointment, ['meta'])
  })

const clearRequestFailure = (state, { errorMessage }) =>
  state.merge({
    clearRequestIsLoading: false,
    clearRequestErrorMessage: errorMessage
  })

const mergeAppointmentCounts = (state, { payload }) =>
  state.merge({
    appointmentCounts: {
      appointmentYesterdayCount: payload.appointmentYesterdayCount,
      appointmentTodayCount: payload.appointmentTodayCount,
      appointmentNext1Count: payload.appointmentNext1Count,
      appointmentNext2Count: payload.appointmentNext2Count,
      appointmentNext3Count: payload.appointmentNext3Count
    }
  })

const setRecalculateDurationFlag = (state, { flag }) =>
  state.merge({
    recalculateDurationFlag: flag
  })

const openDeleteAppointment = (state, { appointment }) =>
  state.merge({
    removingAppointment: appointment
  })

const closeDeleteAppointment = state =>
  state.merge({
    removingAppointment: null
  })

const editingAppointmentIssuesReset = (state, { payload }) =>
  state.merge({
    editingAppointmentIssues: {
      hasPastDate: false,
      hasLowInventory: false,
      hasConflictingInventory: false
    }
  })

// editingAppointmentSuggestions
const editingAppointmentSuggestionsReset = state =>
  state.merge({
    editingAppointmentSuggestionsTimes: new List()
  })

// Appointments search
const onSearchChange = (state, { payload }) => {
  return state.merge({
    ...payload
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [AppointmentsTypes.GET_APPOINTMENTS_LOADING]: getAppointmentsLoading,
  [AppointmentsTypes.GET_APPOINTMENTS_SUCCESS]: getAppointmentsSuccess,
  [AppointmentsTypes.GET_APPOINTMENTS_FAILURE]: getAppointmentsFailure,
  [AppointmentsTypes.GET_APPOINTMENT_LOADING]: getAppointmentLoading,
  [AppointmentsTypes.GET_APPOINTMENT_SUCCESS]: getAppointmentSuccess,
  [AppointmentsTypes.GET_APPOINTMENT_FAILURE]: getAppointmentFailure,

  [AppointmentsTypes.GET_ALL_APPOINTMENT_STATUSES_LOADING]: getAllAppointmentStatusesLoading,
  [AppointmentsTypes.GET_ALL_APPOINTMENT_STATUSES_SUCCESS]: getAllAppointmentStatusesSuccess,
  [AppointmentsTypes.GET_ALL_APPOINTMENT_STATUSES_FAILURE]: getAllAppointmentStatusesFailure,

  [AppointmentsTypes.CREATE_APPOINTMENT_LOADING]: createAppointmentLoading,
  [AppointmentsTypes.CREATE_APPOINTMENT_SUCCESS]: createAppointmentSuccess,
  [AppointmentsTypes.CREATE_APPOINTMENT_FAILURE]: createAppointmentFailure,

  [AppointmentsTypes.UPDATE_APPOINTMENT_LOADING]: updateAppointmentLoading,
  [AppointmentsTypes.UPDATE_APPOINTMENT_SUCCESS]: updateAppointmentSuccess,
  [AppointmentsTypes.UPDATE_APPOINTMENT_FAILURE]: updateAppointmentFailure,
  [AppointmentsTypes.UPDATE_APPOINTMENTS]: updateAppointments,

  [AppointmentsTypes.DELETE_APPOINTMENT_LOADING]: deleteAppointmentLoading,
  [AppointmentsTypes.DELETE_APPOINTMENT_SUCCESS]: deleteAppointmentSuccess,
  [AppointmentsTypes.DELETE_APPOINTMENT_FAILURE]: deleteAppointmentFailure,
  [AppointmentsTypes.OPEN_CREATE_APPOINTMENT]: openCreateAppointment,
  [AppointmentsTypes.OPEN_EDIT_APPOINTMENT_IS_LOADING]: openEditAppointmentIsLoading,
  [AppointmentsTypes.OPEN_EDIT_APPOINTMENT_SUCCESS]: openEditAppointmentSuccess,
  [AppointmentsTypes.MOVE_APPOINTMENT_SUCCESS]: moveAppointmentSuccess,
  [AppointmentsTypes.MOVE_APPOINTMENT_FAILURE]: moveAppointmentSuccess,
  [AppointmentsTypes.CLOSE_UPSERT_APPOINTMENT]: closeUpsertAppointment,
  [AppointmentsTypes.CHANGE_EDIT_APPOINTMENT_TAB]: changeEditAppointmentTab,
  [AppointmentsTypes.OPEN_DELETE_APPOINTMENT]: openDeleteAppointment,
  [AppointmentsTypes.CLOSE_DELETE_APPOINTMENT]: closeDeleteAppointment,

  [AppointmentsTypes.GET_APPOINTMENTS_FOR_WAREHOUSE_LOADING]: getAppointmentsForWarehouseLoading,
  [AppointmentsTypes.GET_APPOINTMENTS_FOR_WAREHOUSE_SUCCESS]: getAppointmentsForWarehouseSuccess,
  [AppointmentsTypes.GET_APPOINTMENTS_FOR_WAREHOUSE_FAILURE]: getAppointmentsForWarehouseFailure,

  [AppointmentsTypes.CLEAR_REQUEST_LOADING]: clearRequestLoading,
  [AppointmentsTypes.CLEAR_REQUEST_SUCCESS]: clearRequestSuccess,
  [AppointmentsTypes.CLEAR_REQUEST_FAILURE]: clearRequestFailure,

  [AppointmentsTypes.MERGE_APPOINTMENT_COUNTS]: mergeAppointmentCounts,

  [AppointmentsTypes.ON_APPOINTMENT_SEARCH_CHANGE]: onSearchChange,

  [AppointmentsTypes.SET_RECALCULATE_DURATION_FLAG]: setRecalculateDurationFlag,
  [AppointmentsTypes.EDITING_APPOINTMENT_ISSUES_RESET]: editingAppointmentIssuesReset,
  [AppointmentsTypes.EDITING_APPOINTMENT_SUGGESTIONS_RESET]: editingAppointmentSuggestionsReset
})
