import { connect } from 'react-redux'
import React, { Component } from 'react'

import { TabContainer, TabContent } from '../../styled/Tabs'
import BuildingsTab from './BuildingsTab'
import UsersTab from './UsersTab'
import PropTypes from 'prop-types'

class SystemSettingsTabs extends Component {
  onClickActivationButon = (id, active) => {}

  render () {
    const { tab, tabMap } = this.props

    return (
      <TabContainer>
        <TabContent keepHeight visible={tab === tabMap.USERS_TAB}>
          <UsersTab />
        </TabContent>
        <TabContent visible={tab === tabMap.BUILDINGS_TAB}>
          <BuildingsTab />
        </TabContent>
      </TabContainer>
    )
  }
}
SystemSettingsTabs.propTypes = {
  tabMap: PropTypes.any,
  tab: PropTypes.any
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemSettingsTabs)
