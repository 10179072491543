import { createSelector } from 'reselect'
import { getStateSms } from '../common/selectors'

export const getSendSMSIsLoading = createSelector(
  getStateSms,
  sms => sms.get('sendSMSIsLoading')
)

export const getSendSMSErrorMessage = createSelector(
  getStateSms,
  sms => sms.get('sendSMSErrorMessage')
)
