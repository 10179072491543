import React from 'react'
import PropTypes from 'prop-types'

const ErrorDisplay = ({ error, onClose }) => (
  <div style={{
    background: '#fff',
    maxHeight: '100%'
  }}>
    <h2 style={{
      color: '#dd0000',
      paddingLeft: 16,
      paddingRight: 16,
      fontSize: 14
    }}>
      There was an error, please send this to technical support:

      {
        onClose ? (
          <a href="#" onClick={onClose} style={{ textDecoration: 'none', color: '#000', float: 'right' }}>
            Close
          </a>
        ) : null
      }
    </h2>

    <div contentEditable="true" style={{
      width: '100%',
      fontSize: 10,
      padding: 16,
      color: '#333',
      background: 'rgba(0,0,0,0.05)',
      outline: 0,
      fontFamily: 'monospace',
      whiteSpace: 'pre',
      overflow: 'auto'
    }}>
      {error.message}<br /><br />
      {error.stack}
    </div>
  </div>
)

ErrorDisplay.propTypes = {
  error: PropTypes.object,
  onClose: PropTypes.func
}

export default ErrorDisplay
