import { createSelector } from 'reselect'
import { fromJS, List, Map } from 'immutable'
import moment from 'moment-timezone'

import { getAllAppointments } from '../appointments/selectors'
import { getStateOrders } from '../common/selectors'
import { isNotNullOrUndef, pick } from '../../utils/common'
import { sortInventoryItems } from '../../utils/sortInventoryItems'
import { getTotalWeight, getUniqueItems } from '../../utils/getOrderSummary'

export const injectPO = orders => {
  if (orders) {
    orders = orders.map(o => {
      const data = o.get('data')
      let PO = data ? data.get('otherRefs', []).find(ref => ref.get('type') === 'PO') : null
      if (!PO) {
        PO = data ? data.get('otherRefs', []).first() : null
      }
      return PO ? o.set('PO', PO.get('val')) : o
    })
  }
  return orders
}

export const injectIsOutbound = orders => {
  if (orders) {
    return orders.map(o => o.set('isOutbound', o.get('primaryRefName') !== 'Purchase Order'))
  }

  return orders
}

const createGenericOrdersSelector = (field, defaultValue) =>
  createSelector(getStateOrders, orders => orders.get(field, defaultValue))

export const getPages = createGenericOrdersSelector('pages')
export const getRawSelectedOrders = createGenericOrdersSelector('selectedOrders', new List())
export const getSelectedOrders = createSelector(getRawSelectedOrders, selectedOrders =>
  injectIsOutbound(selectedOrders)
)

export const getCheckedOrders = createGenericOrdersSelector('checkedOrders', new List())
export const getIsOrderDetailsModalVisible = createGenericOrdersSelector(
  'isOrderDetailsModalVisible'
)
export const getOrderDetails = createGenericOrdersSelector('orderDetails')
export const getOrdersIsLoading = createGenericOrdersSelector('getOrdersIsLoading')

export const getOrderDetailsInJS = createSelector(getOrderDetails, orderDetails =>
  orderDetails?.toJS()
)

export const getSearchAttributes = createSelector(getStateOrders, orders => ({
  searchText: orders.get('searchText'),
  customerPurchaseOrder: orders.get('customerPurchaseOrder'),
  customerSelect: orders.get('customerSelect'),
  isScheduledSelect: orders.get('isScheduledSelect'),
  ordersStatusSelect: orders.get('ordersStatusSelect'),
  requiredShipDateSelect: orders.get('requiredShipDateSelect'),
  deliveryDateSelect: orders.get('deliveryDateSelect'),
  destinationSelect: orders.get('destinationSelect'),
  currentPage: orders.get('currentPage') || 1
}))

export const getSearchAttributesCount = createSelector(getSearchAttributes, searchAttributes => {
  const keys = [
    'searchText',
    'customerPurchaseOrder',
    'customerSelect',
    'isScheduledSelect',
    'ordersStatusSelect',
    'requiredShipDateSelect',
    'deliveryDateSelect',
    'destinationSelect'
  ]

  return pick(searchAttributes, keys)
    .filter(attribute => attribute)
    .count()
})

export const getSelectedQuantitiesBySku = createSelector(getSelectedOrders, selectedOrders => {
  if (!selectedOrders) return new Map()

  return fromJS(
    selectedOrders.reduce((quantitiesBySku, order) => {
      let orderQuantityBySku = {}

      order.get('items', new List()).forEach(item => {
        const sku = item.get('sku')

        orderQuantityBySku = {
          ...orderQuantityBySku,
          [sku]:
            sku in quantitiesBySku
              ? item.get('orderItem', new Map()).get('remainderQty', 0) + quantitiesBySku[sku]
              : item.get('orderItem', new Map()).get('remainderQty', 0)
        }
      })

      return Object.assign({}, quantitiesBySku, orderQuantityBySku)
    }, {})
  )
})

export const createGetAggregatedOrderDetails = createSelector(
  getSelectedOrders,
  selectedOrders => appointment => {
    const inventoryIssuesList =
      (fromJS(appointment) || new Map()).get('inventoryIssues') || new List()
    const totalPallets = selectedOrders
      ? selectedOrders.reduce((acc, order) => {
        const pallets = order.get('pallets') || null
        return pallets ? acc + pallets : acc
      }, 0)
      : null
    const selectedItems = selectedOrders
      .map(order => order.get('items', List([])))
      .flatten(1)
      .map(item => {
        const inventoryIssues = fromJS(inventoryIssuesList.get(item.get('sku')))

        return item.merge({
          inventoryIssues: inventoryIssues || new Map(),
          issue:
            inventoryIssues &&
            !inventoryIssues.isEmpty() &&
            inventoryIssues.get('projectedStock') < 0 &&
            item.get('orderItem').get('remainderQty') > 0
        })
      })

    // this is plain JS method
    const uniqueItems = fromJS(getUniqueItems(selectedItems.toJS())).sort(sortInventoryItems)

    const totalWeight = getTotalWeight(uniqueItems.toJS())

    return fromJS({
      uniqueItems,
      totalPallets,
      totalWeight
    })
  }
)

export const createGetAppointmentForOrder = order =>
  createSelector(getAllAppointments, appointments => {
    if (!order) return null

    return order.get('appointments', new List()).first()
  })

export const getOrders = createSelector(
  getStateOrders,
  getSearchAttributes,
  getCheckedOrders,
  getSelectedOrders,
  (stateOrders, filters, checkedOrders, selectedOrders) => {
    // get the ids
    const checkedOrdersIds = checkedOrders.map(o => o.get('id'))

    // same sort as the backend
    const orders = stateOrders.get('orders', new List())
    let sortedOrders = orders.sort((a, b) => {
      const isOrderSelected = selectedOrders.some(
        o => o.get('id') === a.get('id') || o.get('id') === b.get('id')
      )
      if (isOrderSelected) {
        return 0
      }
      return moment(a.get('updatedAt')) >= moment(b.get('updatedAt')) ? 1 : -1
    })

    // let's do some filters that we know backend will return to us
    if (isNotNullOrUndef(filters.ordersStatusSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          order.get('orderStatusId') === filters.ordersStatusSelect ||
          checkedOrdersIds.indexOf(order.get('id')) > -1
      )
    }
    if (isNotNullOrUndef(filters.customerSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          order.get('customerId') === filters.customerSelect ||
          checkedOrdersIds.indexOf(order.get('id')) > -1
      )
    }
    if (isNotNullOrUndef(filters.destinationSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          order.get('destinationId') === filters.destinationSelect ||
          checkedOrdersIds.indexOf(order.get('id')) > -1
      )
    }
    if (isNotNullOrUndef(filters.deliveryDateSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          moment(order.get('deliveryDate')).format('YYYY-MM-DD') ===
            moment(filters.deliveryDateSelect).format('YYYY-MM-DD') ||
          checkedOrdersIds.indexOf(order.get('id')) > -1
      )
    }
    if (isNotNullOrUndef(filters.requiredShipDateSelect)) {
      sortedOrders = sortedOrders.filter(
        order =>
          moment.utc(order.get('requiredShipDate')).format('YYYY-MM-DD') ===
            moment.utc(filters.requiredShipDateSelect).format('YYYY-MM-DD') ||
          checkedOrdersIds.indexOf(order.get('id')) > -1
      )
    }

    const ordersWithPo = injectPO(sortedOrders)
    const ordersWithIsOutbound = injectIsOutbound(ordersWithPo)

    return ordersWithIsOutbound
  }
)
