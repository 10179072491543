import { Map } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  carrierRequests: null,
  carrierRequest: null,
  creatingCarrierRequest: null,
  numberOfCarrierRequests: null,
  carrierRequestsPickUpTimes: null,
  getAllCarrierRequestsIsLoading: false,
  getAllCarrierRequestsErrorMessage: null,
  getNumberOfRequestsIsLoading: false,
  getNumberOfRequestsErrorMessage: null,
  getCarrierRequestPickUpTimesIsLoading: false,
  getCarrierRequestPickUpTimesErrorMessage: null,
  createCarrierRequestIsLoading: false,
  createCarrierRequestErrorMessage: null,
  editingCarrierRequest: null,
  editingCarrierRequestTab: 0,
  updateCarrierRequestIsLoading: false,
  updateCarrierRequestErrorMessage: null,
  addPoIsLoading: false,
  addPoFailure: null,
  addPoSuccess: null,
  removingRequest: null,
  deleteCarrierRequestIsLoading: false,
  deleteCarrierRequestErrorMesage: null,
  isUpsertCarrierRequestVisible: false,
  openEditCarrierRequestIsLoading: false
})
