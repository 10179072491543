import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getSettingsForAllBuildings
  getSettingsForAllBuildings: ['payload'],
  getSettingsForAllBuildingsLoading: null,
  getSettingsForAllBuildingsSuccess: ['settings'],
  getSettingsForAllBuildingsFailure: ['errorMessage'],

  // updateSettingsForBuilding
  updateSettingsForBuilding: ['payload'],
  updateSettingsForBuildingLoading: null,
  updateSettingsForBuildingSuccess: ['settings'],
  updateSettingsForBuildingFailure: ['errorMessage']
})

export const BuildingsTypes = Types
export default Creators
