import DatePicker from 'react-datepicker'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { StyledCalendarIcon } from '../styled/Icons'
import { Container } from '../styled/Containers'
import {
  DatePickerContainer,
  StyledInvisibleDatePicker,
  StyledDatePicker
} from '../styled/DatePicker'

class DatePickerComponent extends Component {
  constructor (props) {
    super(props)
    this.picker = null
  }

  handleChange = date => {
    const { onChange } = this.props
    onChange(date)
    if (this.picker) {
      this.picker.cancelFocusInput()
      this.picker.setOpen(false)
    }
  }

  render () {
    const {
      HeaderGridDatePicker = false,
      SearchOrderDatePicker = false,
      SearchAppointmentDatePicker = false,
      disabled = false,
      dateFormat,
      onChangeStartDate,
      startDate,
      placeholderText,
      selected,
      onChange,
      showIcon
    } = this.props

    if (HeaderGridDatePicker) {
      return (
        <Container flex centered>
          {showIcon && (
            <StyledCalendarIcon
              onClick={e => {
                this.picker.setOpen(true)
              }}
            />
          )}
          <StyledInvisibleDatePicker
            selected={startDate}
            dateFormat={dateFormat}
            onChange={onChangeStartDate}
          />
        </Container>
      )
    }
    if (SearchOrderDatePicker) {
      return (
        <DatePickerContainer>
          {showIcon && (
            <StyledCalendarIcon
              onClick={e => {
                this.picker.setOpen(true)
              }}
            />
          )}
          <StyledDatePicker
            ref={node => {
              this.picker = node
            }}
            placeholderText={placeholderText}
            dateFormat={dateFormat}
            selected={selected}
            onChange={this.handleChange}
            onClickOutside={() => {
              this.picker.cancelFocusInput()
              this.picker.setOpen(false)
            }}
          />
        </DatePickerContainer>
      )
    }
    if (SearchAppointmentDatePicker) {
      return (
        <DatePickerContainer>
          <StyledDatePicker
            disabled={disabled}
            placeholderText={placeholderText}
            dateFormat={dateFormat}
            selected={selected}
            onChange={this.handleChange}
          />
          {showIcon && (
            <StyledCalendarIcon
              onClick={e => {
                this.picker.setOpen(true)
              }}
            />
          )}
        </DatePickerContainer>
      )
    }
    return (
      <DatePicker
        dropdownMode={'scroll'}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        selected={selected}
        onChange={onChange}
      />
    )
  }
}

DatePickerComponent.propTypes = {
  HeaderGridDatePicker: PropTypes.bool,
  SearchOrderDatePicker: PropTypes.bool,
  SearchAppointmentDatePicker: PropTypes.bool,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  placeholderText: PropTypes.string,
  selected: PropTypes.any,
  onChangeStartDate: PropTypes.func,
  startDate: PropTypes.any,
  onChange: PropTypes.func,
  showIcon: PropTypes.bool
}

export default DatePickerComponent
