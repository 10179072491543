import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Plus extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 20 20"
        className={className}
        height={height}
        width={width}>
        <rect
          transform="rotate(-90 10 10)"
          rx="2"
          stroke="#898989"
          fillRule="evenodd"
          fill="none"
          y=".5"
          x=".5"
          height="19"
          width="19" />
        <rect
          y="9.4499998"
          x="6"
          height="1.1"
          width="8"
          style={{
            fill: color,
            fillOpacity: 1,
            fillRule: 'evenodd',
            strokeWidth: 0.42777523
          }} />
        <rect
          transform="rotate(-90)"
          y="9.4499998"
          x="-14"
          height="1.1"
          width="8"
          style={{
            fill: color,
            fillOpacity: 1,
            fillRule: 'evenodd',
            strokeWidth: 0.42777523
          }} />
      </svg>
    )
  }
}

Plus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

Plus.defaultProps = {
  width: 20,
  height: 20,
  color: '#aab0c0'
}
