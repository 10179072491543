import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import { components } from 'react-select'

const Container = styled.div`
    position: absolute;
    left: 5px;
`
/**
 * the code {React.cloneElement(children[1])} is a hack to close select dropdown even if no option is selected,
 * without this piece of code, you hack do explicit click in dropdown to close it
 */
const SelectValueContainer = ({
  children,
  isDisabled,
  getValue,
  selectProps,
  ...props
}) => {
  const length = getValue().length

  return !isDisabled ? (
    <components.ValueContainer {...props} selectProps={selectProps}>
      <Container>{`${selectProps.placeholder} (${length})`}</Container>
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  ) : null
}

SelectValueContainer.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.any,
  getValue: PropTypes.any,
  isDisabled: PropTypes.bool
}

export default SelectValueContainer
