import {
  createAction,
  createSelector,
  createSlice,
  Dispatch,
  SerializedError
} from '@reduxjs/toolkit'
import { RequestStatus } from '../common-types'
import FeedsActions from '../modules/feeds/actions'
import { RootState } from '../root-types'
import { selectUserEntities, UpsertUserSuccessResponse } from '../users/users-slice'
import axios from '../utils/axios'

export interface CarrierAppState {
  token: string | null
  userId: number | null
  loginLoading: RequestStatus
  loginError: SerializedError | null
  isLoginOpen: boolean
}

// TODO: it should be in shared
export interface LoginSuccessResult {
  token: string
  user: UpsertUserSuccessResponse
}

export interface Credentials {
  password: string
  username: string
}

export const carrierLoginPending = createAction('carrierApp/login/pending')
export const carrierLoginFullfiled = createAction<LoginSuccessResult>('carrierApp/login/fullfiled')
export const carrierLoginRejected = createAction<SerializedError>('carrierApp/login/rejected')

export const carrierLogin = (credentials: Credentials) => async (dispatch: Dispatch) => {
  dispatch(carrierLoginPending)
  try {
    const { data } = await axios.post('/users/login', {
      email: credentials?.username,
      password: credentials?.password
    })

    dispatch(carrierLoginFullfiled(data))
  } catch (err: any) {
    dispatch(carrierLoginRejected({
      name: err.name,
      code: err.code,
      stack: err.stack,
      message: err.message
    }))
  }

  dispatch(
    FeedsActions.getBootstrapData({
      carrierPortal: true,
      sortByDate: 'desc',
      guid: undefined,
      isLoggedIn: true
    })
  )
}

export const carrierLogoutFulfilled = createAction('carrierApp/logout/fullfiled')

const initialState: CarrierAppState = {
  token: null,
  userId: null,
  loginLoading: RequestStatus.Idle,
  loginError: null,
  isLoginOpen: false
}

const slice = createSlice({
  name: 'carrierApp',
  initialState,
  reducers: {
    carrierLoginOpened (state) {
      state.isLoginOpen = true
    },
    carrierLoginClosed (state) {
      state.isLoginOpen = false
    }
  },
  extraReducers: builder => {
    builder.addCase(carrierLoginFullfiled, (state, { payload }) => {
      state.token = payload.token
      state.userId = payload.user.id
      state.loginLoading = RequestStatus.Succeded
    })
    builder.addCase(carrierLoginPending, state => {
      if (state.loginLoading === RequestStatus.Idle) {
        state.loginLoading = RequestStatus.Pending
      }
    })
    builder.addCase(carrierLoginRejected, (state, { payload }) => {
      if (state.loginLoading === RequestStatus.Idle) {
        state.loginLoading = RequestStatus.Failed
        state.loginError = payload
      }
    })
    builder.addCase(carrierLogoutFulfilled, state => {
      state.loginLoading = RequestStatus.Idle
      state.loginError = null
      state.token = null
      state.userId = null
    })
  }
})

export default slice.reducer
export const { carrierLoginOpened, carrierLoginClosed } = slice.actions

const selectCarrierApp = (state: RootState) => state.carrierApp
const selectUserId = createSelector(selectCarrierApp, carrierApp => carrierApp.userId)
export const selectCarrierToken = createSelector(selectCarrierApp, carrierApp => carrierApp.token)
export const selectIsCarrierUserLoggedIn = createSelector(selectCarrierToken, token => !!token)
export const selectCarrierLoginError = createSelector(
  selectCarrierApp,
  carrierApp => carrierApp.loginError
)
export const selectIsCarrierLoginOpen = createSelector(
  selectCarrierApp,
  carrierApp => carrierApp.isLoginOpen
)
export const selectCarrierUser = createSelector(
  selectUserId,
  selectUserEntities,
  (userId, userEntities) => {
    if (!userId) return null

    return userEntities[userId]
  }
)
