import React, { Component } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GridCell, GridRow } from '../../../styled/Grids'
import Orders from '../pages/Orders'
import Appointments from '../pages/Appointments'
import Requests from '../pages/Requests'
import Reports from '../pages/Reports'
import TimeTable from '../../../components/TimeTable'
import CustomDragLayer from '../../../components/CustomDragLayer'
import YardsTable from '../../../yards/yards-table'
import { connect } from 'react-redux'
import { isAppointmentTypeOutbound, selectAppointmentType } from '../../../app/app-slice'
import { isLoggedIn } from '../../../modules/users/selectors'

export const YardsGridCell = styled(GridCell)`
  padding-left: 8px;
  padding-top: 71px;
  overflow-y: hidden;
  overflow-x: scroll;
  justify-content: space-between;
`

class Content extends Component {
  render () {
    const { isLoggedIn, isYardsCellVisible } = this.props

    if (!isLoggedIn) {
      return <Navigate to="/login" replace/>
    }

    return (
      <GridRow page padded noScroll width={'100%'}>
        <GridCell padded="top" flex={1}>
          <Routes>
            <Route path="/orders" element={<Orders/>}/>
            <Route path="/appointments" element={<Appointments/>}/>
            <Route path="/requests" element={<Requests/>}/>
            <Route path="/reports" element={<Reports/>}/>
            <Route path="/reports/:index" element={<Reports/>}/>
          </Routes>
          <CustomDragLayer/>
        </GridCell>
        <Routes>
          <Route path="/orders" element={<TimeTable/>}/>
          <Route path="/appointments" element={<TimeTable/>}/>
          <Route path="/requests" element={<TimeTable/>}/>
        </Routes>
        <Routes>
          <Route path="/orders" element={isYardsCellVisible && <YardsGridCell><YardsTable/></YardsGridCell>}/>
          <Route path="/appointments" element={isYardsCellVisible && <YardsGridCell><YardsTable/></YardsGridCell>}/>
        </Routes>
      </GridRow>
    )
  }
}

Content.propTypes = {
  isYardsCellVisible: PropTypes.bool,
  isLoggedIn: PropTypes.bool
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
  isYardsCellVisible: !isAppointmentTypeOutbound(selectAppointmentType(state))
})

export default connect(
  mapStateToProps
)(Content)
