import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import CarrierButton from '../../components/CarrierButton'
import { selectCarrierUser } from '../../carrier-app/carrier-app-slice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  div {
    justify-content: center;
  }
`

const StyledButton = styled(CarrierButton)`
  align-self: center;
  height: 36px;
  width: 60%;
  padding: 5px 10px 5px 10px;
  margin-top: 20px;
  margin-bottom: 24px;
  color: ${props => props.disabled ? props.theme.colors.default : '#fff'};
  border-color: ${props => props.disabled ? props.theme.colors.default : props.theme.colors.primaryAccent};
  background-color: ${props => props.disabled ? '#fff' : props.theme.colors.primaryAccent};
  font-size: 12px;
`

const BoldLabel = styled.div`
  display: flex;
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  text-transform: uppercase;
`

const Label = styled.div`
  display: flex;
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 12px;
  margin-top: 5px;
`

const GrayText = styled.div`
  display: flex;
  color: #898989;
  font-size: 12px;
  margin-top: 5px;
`

const HorizontalLine = styled.div`
  align-self: center;
  border-bottom: 1px solid ${props => props.theme.colors.default};
  margin: 20px 0;
  width: 150%;
`

const CreateAccount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

class Success extends Component {
  render () {
    const { onRestartSteps, user, onCreateAccount } = this.props

    return (
      <Container>
        <BoldLabel>Success</BoldLabel>
        <GrayText>We will send you a confirmation message with your order details</GrayText>
        <GrayText>Once your pickup time is set, will send a confirmation message with exact pickup time</GrayText>
        <StyledButton type="button" onClick={onRestartSteps} >Request another appointment</StyledButton>
        {!user &&
          <CreateAccount>
            <HorizontalLine />
            <Label>Want to save time next time?</Label>
            <StyledButton type="button" onClick={onCreateAccount} >Create An Account</StyledButton>
          </CreateAccount>
        }
      </Container>
    )
  }
}

Success.propTypes = {
  onRestartSteps: PropTypes.func,
  onCreateAccount: PropTypes.func,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: selectCarrierUser(state)
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Success)
