import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InfoIcon extends Component {
  render () {
    const { width, height, color, className, warning, warningColor, issue, issueColor } = this.props
    let viewBox = '0 0 14 14'
    if (warning || issue) {
      viewBox = '0 0 22 21'
    }

    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <path fill={color} fillRule="evenodd" d="M7 0C3.15 0 0 3.15 0 7s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm-.7 10.5h1.4V6.3H6.3v4.2zm0-5.6h1.4V3.5H6.3v1.4z"/>
        {
          warning && !issue ? (<path fill={warningColor} d="M21.143 8.864L16.327.403A.804.804 0 0 0 15.625 0a.803.803 0 0 0-.703.403l-4.815 8.461a.692.692 0 0 0 .012.757.809.809 0 0 0 .69.379h9.632a.809.809 0 0 0 .69-.379.692.692 0 0 0 .012-.757zm-4.893-.275a.156.156 0 0 1-.047.115.15.15 0 0 1-.11.046h-.937a.149.149 0 0 1-.11-.046.156.156 0 0 1-.046-.115v-.928c0-.045.016-.083.047-.114a.15.15 0 0 1 .11-.047h.937a.15.15 0 0 1 .11.047c.03.03.046.069.046.114v.928zm-.078-3.701a.111.111 0 0 1-.046.08.163.163 0 0 1-.102.032h-.803a.172.172 0 0 1-.104-.032.096.096 0 0 1-.043-.08L15 2.656c0-.045.015-.08.043-.102.038-.036.073-.054.105-.054h.954c.032 0 .067.018.105.054a.111.111 0 0 1 .043.093l-.078 2.24z"/>) : ''
        }
        {
          issue && !warning ? <path fill={issueColor} d="M15.5 0A10.67 10.67 0 0 0 9 2.2l6.5 8.8L22 2.2A10.67 10.67 0 0 0 15.5 0zm0 5.604c-.593 0-1.187.148-1.73.346L11 2.238A8.776 8.776 0 0 1 15.5 1c1.632 0 3.165.446 4.5 1.238L17.181 6a3.821 3.821 0 0 0-1.681-.396z"/> : ''
        }
      </svg>
    )
  }
}

InfoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  warning: PropTypes.bool,
  warningColor: PropTypes.string,
  issue: PropTypes.bool,
  issueColor: PropTypes.string
}

InfoIcon.defaultProps = {
  width: 14,
  height: 14,
  color: '#ffffff',
  warning: false,
  warningColor: '#ffffff', // FF8100
  issue: false,
  issueColor: '#ffffff' // FF1E3A
}
