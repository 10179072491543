import React from 'react'
import PropTypes from 'prop-types'

import { ButtonToggle, ToggleActivate, ToggleDeactivate } from '../styled/Buttons'

const Toggle = ({
  isActive,
  onActivate,
  onActivateText = 'On',
  onDeactivate,
  onDeactivateText = 'Off'
}) => (
  <ButtonToggle
    onClick={isActive ? onDeactivate : onActivate}
  >
    <ToggleActivate as="span" isActive={isActive}>
      {onActivateText}
    </ToggleActivate>
    <ToggleDeactivate as="span" isActive={!isActive}>
      {onDeactivateText}
    </ToggleDeactivate>
  </ButtonToggle>
)

Toggle.propTypes = {
  isActive: PropTypes.bool,
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
  onActivateText: PropTypes.string,
  onDeactivateText: PropTypes.string
}

export default Toggle
