import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppointmentModal from '../../../components/modals/AppointmentModal'
import OrderDetailsModal from '../../../components/modals/OrderDetailsModal'
import SystemSettingsModal from '../../../components/modals/SystemSettingsModal'
import DeleteAppointmentModal from '../../../components/modals/DeleteAppointmentModal'
import DeleteRequestModal from '../../../components/modals/DeleteRequestModal'
import { getEditingAppointment, getIsAppointmentModalVisible } from '../../../modules/appointments/selectors'
import { isAdmin } from '../../../modules/users/selectors'
import DestinationDetailsModal from '../../../components/modals/DestinationDetailsModal'

class Modals extends Component {
  render () {
    const {
      isAppointmentModalVisible,
      isAdmin,
      editingAppointment
    } = this.props

    return (
      <Fragment>
        { isAppointmentModalVisible && editingAppointment ? <AppointmentModal /> : null }
        <OrderDetailsModal />
        { isAdmin ? <SystemSettingsModal /> : null }
        <DeleteAppointmentModal />
        <DeleteRequestModal />
        <DestinationDetailsModal />
      </Fragment>
    )
  }
}

Modals.propTypes = {
  editingAppointment: PropTypes.object,
  isAppointmentModalVisible: PropTypes.bool,
  isAdmin: PropTypes.bool
}

const mapStateToProps = state => ({
  editingAppointment: getEditingAppointment(state),
  isAppointmentModalVisible: getIsAppointmentModalVisible(state),
  isAdmin: isAdmin(state)
})

export default connect(mapStateToProps)(Modals)
