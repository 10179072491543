import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import axios from '../../utils/axios'
import CustomersActions, { CustomersTypes } from './actions'
import { token as getToken } from '../users/selectors'

// Sagas
function * getAllCustomers ({ payload }) {
  yield put(CustomersActions.getAllCustomersLoading())
  try {
    let params = ''

    const {
      name
    } = payload

    if (name) params = params + `name=${name}`

    const token = yield select(getToken)
    const { data } = yield call(axios.get, `/customers?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(CustomersActions.getAllCustomersSuccess(data))
  } catch (e) {
    yield put(CustomersActions.getAllCustomersFailure(e))
  }
}

// Watchers
function * getAllCustomersWatcher () {
  yield takeLatest(CustomersTypes.GET_ALL_CUSTOMERS, getAllCustomers)
}

export default function * root () {
  yield fork(getAllCustomersWatcher)
}
