import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { OrderItemsTypes } from './actions'

// getOrderItems
export const getOrderItemsLoading = state =>
  state.merge({
    getOrderItemsIsLoading: true,
    getOrderItemsErrorMessage: ''
  })

export const getOrderItemsSuccess = (state, { orderItems }) =>
  state.merge({
    orderItems,
    getOrderItemsIsLoading: false,
    getOrderItemsErrorMessage: null
  })

export const getOrderItemsFailure = (state, { errorMessage }) =>
  state.merge({
    orderItems: null,
    getOrderItemsIsLoading: false,
    getOrderItemsErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OrderItemsTypes.GET_ORDER_ITEMS_LOADING]: getOrderItemsLoading,
  [OrderItemsTypes.GET_ORDER_ITEMS_SUCCESS]: getOrderItemsSuccess,
  [OrderItemsTypes.GET_ORDER_ITEMS_FAILURE]: getOrderItemsFailure
})
