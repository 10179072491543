import { delay, fork, put, takeLatest } from 'redux-saga/effects'
import { updateTimestamp } from './time-slice'

const TIME_REFRESH_INTERVAL: number = 10000

export function * updateTimestampCyclic () {
  yield delay(TIME_REFRESH_INTERVAL)

  yield put(updateTimestamp(Date.now()))
}

function * actionWatcher () {
  yield takeLatest(updateTimestamp.type, updateTimestampCyclic)
}

export default function * root () {
  yield fork(actionWatcher)
}
