import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import isEqual from 'lodash.isequal'

import { EMAIL_HIDDEN_TAB, INVENTORY_TAB } from '../../modals/AppointmentModal'
import { GridRow } from '../../../styled/Grids'
import {
  createGetAppointmentIssues,
  getClearRequestIsLoading,
  getEditingAppointment,
  getEditingAppointmentIssues,
  getEditingAppointmentSuggestions,
  getTopEditingAppointmentSuggestions
} from '../../../modules/appointments/selectors'
import AllowIssuesModal from '../../modals/AllowIssuesModal'
import AppointmentForm from '../../forms/AppointmentForm'
import AppointmentsActions from '../../../modules/appointments/actions'
import CarrierActions from '../../../modules/carrierRequests/actions'
import Notifications from './Notifications'
import OrdersActions from '../../../modules/orders/actions'
import OrdersSummary from './OrdersSummary'
import TopSuggestions from './TopSuggestions'
import { selectCurrentBuildingId } from '../../../app/app-slice'

class AppointmentTab extends Component {
  componentDidUpdate (prevProps, prevState, snapshot) {
    /**
     * This could go to handleFormChange
     * But we also need to check against
     * added orders, so, better to keep
     * here and strengthen the checks
     */
    const {
      appointmentData,
      createAppointment,
      updateAppointment,
      editingAppointment,
      editingAppointmentSuggestionsReset,
      selectedOrders,
      isSaving
    } = this.props

    if (
      appointmentData && // only run this if this key fields have been changed
      (appointmentData.date !== prevProps.appointmentData.date ||
        appointmentData.time !== prevProps.appointmentData.time ||
        appointmentData.duration !== prevProps.appointmentData.duration ||
        appointmentData.doorId !== prevProps.appointmentData.doorId ||
        (selectedOrders.length > 0 && !isEqual(selectedOrders, prevProps.selectedOrders))) &&
      (!editingAppointment.carrierRequests || !editingAppointment.carrierRequests.length)
    ) {
      editingAppointmentSuggestionsReset()
      if (
        appointmentData.buildingId &&
        appointmentData.siteId &&
        appointmentData.doorId &&
        appointmentData.duration &&
        !isSaving
      ) {
        if (!editingAppointment.id) {
          createAppointment({
            ...appointmentData,
            orderIds: selectedOrders.map(order => order.id),
            inProgress: true
          })
        } else {
          updateAppointment({
            id: editingAppointment.id,
            ...appointmentData,
            orderIds: selectedOrders.map(order => order.id),
            onlyCheckIssues: true
          })
        }
      }
    }
  }

  componentDidMount () {
    const { createAppointment, updateAppointment, editingAppointment, appointmentData } = this.props

    // appointmentData is data from the form
    // editingAppointment is what we have on the store
    // ID should only be reliable from the store
    if (appointmentData && editingAppointment) {
      const { appointmentStatusId, ...newAppointmentData } = appointmentData

      if (
        newAppointmentData.buildingId &&
        newAppointmentData.siteId &&
        newAppointmentData.doorId &&
        newAppointmentData.duration &&
        (!editingAppointment.carrierRequests || !editingAppointment.carrierRequests.length)
      ) {
        if (!editingAppointment.id) {
          createAppointment({
            ...newAppointmentData,
            orderIds: newAppointmentData.orders.map(order => order.id),
            inProgress: true
          })
        } else {
          updateAppointment({
            id: editingAppointment.id,
            appointmentStatusId,
            ...newAppointmentData,
            orderIds: newAppointmentData.orders.map(order => order.id),
            orderSequence: newAppointmentData.orders.reduce(
              (acc, order) => ({
                ...acc,
                [order.id]: order.orderSequence
              }),
              {}
            ),
            onlyCheckIssues: true
          })
        }
      }
    }
  }

  componentWillUnmount () {
    const { editingAppointmentSuggestionsReset, editingAppointmentIssuesReset } = this.props
    editingAppointmentSuggestionsReset()
    editingAppointmentIssuesReset()
  }

  onRemoveOrderFromAppointment = order => {
    const { deselectOrder } = this.props
    deselectOrder && deselectOrder(order)
  }

  onDeleteAppointment = () => {
    const { openDeleteAppointment, appointmentData, editingAppointment } = this.props
    openDeleteAppointment({
      ...appointmentData,
      id: editingAppointment.id,
      rwConnect: true
    })
  }

  onDeleteCarrierRequest = () => {
    const { deleteCarrierRequest, appointmentData } = this.props
    deleteCarrierRequest(appointmentData.carrierRequestId)
  }

  onSaveIssues = () => {
    const { onSubmitAppointment, editingAppointmentIssuesReset, setIsSubmitting } = this.props
    editingAppointmentIssuesReset()
    setIsSubmitting(false)
    onSubmitAppointment(true, true)
  }

  onCancelIssues = () => {
    const { editingAppointmentIssues, switchToTab, setIsSubmitting } = this.props
    if (editingAppointmentIssues.hasLowInventory) {
      switchToTab(INVENTORY_TAB)()
    }
    setIsSubmitting(false)
  }

  render () {
    const {
      appointmentData,
      editingAppointment,
      selectedOrders,
      initialValues,
      switchToTab,
      onChange,
      isSaving,
      isSubmitting,
      onSubmitAppointment,
      isInventoryCalculationEnabled,
      editingAppointmentIssues,
      topEditingAppointmentSuggestions,
      editingAppointmentSuggestions,
      displayAppointmentTimeSuggestions,
      clearRequest,
      clearRequestIsLoading,
      onApplySuggestions,
      setSelectedOrders
    } = this.props

    const isSuggestionEnabled =
      isInventoryCalculationEnabled && editingAppointmentIssues.hasLowInventory

    return (
      <Fragment>
        <Notifications
          appointmentData={appointmentData}
          editingAppointment={editingAppointment}
          isSaving={isSaving}
          clearRequestIsLoading={clearRequestIsLoading}
          clearRequest={clearRequest}
          isSuggestionEnabled={isSuggestionEnabled}
          editingAppointmentIssues={editingAppointmentIssues}
          topEditingAppointmentSuggestions={topEditingAppointmentSuggestions}
          editingAppointmentSuggestions={editingAppointmentSuggestions}
          isInventoryCalculationEnabled={isInventoryCalculationEnabled}
          displayAppointmentTimeSuggestions={displayAppointmentTimeSuggestions}
        />
        <GridRow opaque>
          <OrdersSummary
            onRemoveOrder={this.onRemoveOrderFromAppointment}
            setSelectedOrders={setSelectedOrders}
            selectedOrders={selectedOrders}
            appointmentDate={appointmentData.date}
            isOutbound={editingAppointment.isOutbound}
          />
          <AppointmentForm
            onChange={onChange}
            onSubmit={onSubmitAppointment}
            onDelete={this.onDeleteAppointment}
            submitButtonText={isSaving ? 'Saving...' : 'Save'}
            isSubmitDisabled={isSaving || !selectedOrders.length}
            isEmailDisabled={
              isSaving ||
              !appointmentData.id ||
              !selectedOrders.length ||
              !editingAppointment.isOutbound
            }
            onEmailClick={switchToTab(EMAIL_HIDDEN_TAB)}
            appointmentData={appointmentData}
            initialValues={initialValues}
            canDelete={editingAppointment && editingAppointment.id !== undefined}
            onDeleteCarrierRequest={this.onDeleteCarrierRequest}
            isTimeDisabled={appointmentData.door?.isYard}
            isDateDisabled={appointmentData.door?.isYard}
            isStatusDisabled={appointmentData.door?.isYard}
          />
          {isSuggestionEnabled ? (
            <TopSuggestions
              topEditingAppointmentSuggestions={topEditingAppointmentSuggestions}
              onApplySuggestions={onApplySuggestions}
              isSaving={isSaving}
            />
          ) : null}
        </GridRow>

        <AllowIssuesModal
          isSubmitting={isSubmitting}
          isInventoryCalculationEnabled={isInventoryCalculationEnabled}
          hasLowInventory={editingAppointmentIssues.hasLowInventory}
          hasConflictingInventory={editingAppointmentIssues.hasConflictingInventory}
          hasPastDate={editingAppointmentIssues.hasPastDate}
          hasLateShippingOrders={editingAppointmentIssues.hasLateShippingOrders}
          onSave={this.onSaveIssues}
          onCancel={this.onCancelIssues}
        />
      </Fragment>
    )
  }
}

AppointmentTab.propTypes = {
  // comes from store
  clearRequestIsLoading: PropTypes.bool,
  clearRequest: PropTypes.func,
  createAppointment: PropTypes.func,
  updateAppointment: PropTypes.func,
  editingAppointment: PropTypes.object,
  editingAppointmentSuggestions: PropTypes.object,
  topEditingAppointmentSuggestions: PropTypes.object,
  editingAppointmentSuggestionsReset: PropTypes.func,
  editingAppointmentIssuesReset: PropTypes.func,
  deselectOrder: PropTypes.func.isRequired,
  setSelectedOrders: PropTypes.func.isRequired,
  openDeleteAppointment: PropTypes.func,
  deleteCarrierRequest: PropTypes.func,
  // comes from parent
  editingAppointmentIssues: PropTypes.object,
  appointmentData: PropTypes.object,
  selectedOrders: PropTypes.array,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isSaving: PropTypes.bool,
  isInventoryCalculationEnabled: PropTypes.bool,
  onApplySuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
  onSubmitAppointment: PropTypes.func.isRequired,
  displayAppointmentTimeSuggestions: PropTypes.func.isRequired,
  switchToTab: PropTypes.func.isRequired
}

AppointmentTab.defaultProps = {
  appointmentData: null,
  selectedOrders: []
}

const mapStateToProps = state => {
  const editingAppointment = getEditingAppointment(state)
  const editingAppointmentIssues = getEditingAppointmentIssues(state)
  const suggestions = getEditingAppointmentSuggestions(state)

  return {
    selectedBuildingId: selectCurrentBuildingId(state),
    editingAppointment: editingAppointment ? editingAppointment.toJS() : null,
    topEditingAppointmentSuggestions: getTopEditingAppointmentSuggestions(state).toJS(),
    editingAppointmentIssues: createGetAppointmentIssues(state)(editingAppointmentIssues).toJS(),
    editingAppointmentSuggestions:
      suggestions && suggestions.get('suggestions', false)
        ? suggestions.toJS()
        : {
            suggestions: [],
            hasMore: false
          },
    clearRequestIsLoading: getClearRequestIsLoading(state)
  }
}

const mapActionToDispatch = dispatch => ({
  createAppointment: payload => dispatch(AppointmentsActions.createAppointment(payload)),
  updateAppointment: payload => dispatch(AppointmentsActions.updateAppointment(payload)),
  editingAppointmentSuggestionsReset: () =>
    dispatch(AppointmentsActions.editingAppointmentSuggestionsReset()),
  editingAppointmentIssuesReset: () =>
    dispatch(AppointmentsActions.editingAppointmentIssuesReset()),
  deselectOrder: order => dispatch(OrdersActions.deselectOrder(fromJS(order))),
  setSelectedOrders: orders => dispatch(OrdersActions.setSelectedOrders(orders)),
  closeUpsertAppointment: () => dispatch(AppointmentsActions.closeUpsertAppointment()),
  openDeleteAppointment: payload =>
    dispatch(AppointmentsActions.openDeleteAppointment(fromJS(payload))),
  deleteCarrierRequest: carrierRequestId => {
    dispatch(CarrierActions.deleteCarrierRequest(carrierRequestId))
    dispatch(CarrierActions.closeDeleteCarrierRequest())
    dispatch(CarrierActions.closeUpsertCarrierRequest())
    dispatch(AppointmentsActions.closeUpsertAppointment())
  },
  clearRequest: id => dispatch(AppointmentsActions.clearRequest(id)),
  showAppointment: payload => dispatch(AppointmentsActions.showAppointment(payload))
})

export default connect(mapStateToProps, mapActionToDispatch)(AppointmentTab)
