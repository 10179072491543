import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  sendSMS: ['to', 'template', 'content'],
  sendSMSLoading: null,
  sendSMSSuccess: ['result'],
  sendSMSFailure: ['errorMessage']
})

export const SMSTypes = Types
export default Creators
