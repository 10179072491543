import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ArrowIcon extends Component {
  render () {
    const { width, height, color, direction, className } = this.props
    let rotate = null
    switch (direction) {
      case 'up':
        rotate = 180
        break
      case 'left':
        rotate = 90
        break
      case 'right':
        rotate = 270
        break
      case 'down':
      default:
        // do nothing
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 8 6"
        className={className}
        transform={`rotate(${rotate || 0})`}
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={color}
          d="M3.97 4.671l3.08-3.08a.184.184 0 0 0 0-.26.184.184 0 0 0-.262 0L3.84 4.277.892 1.33a.186.186 0 1 0-.316.13.18.18 0 0 0 .054.13l3.08 3.08a.184.184 0 0 0 .26.001z"
        />
      </svg>
    )
  }
}

ArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right'])
}

ArrowIcon.defaultProps = {
  width: 8,
  height: 6,
  color: '#ffffff'
}
