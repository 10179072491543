import Immutable, { Map } from 'immutable'
import { immutable } from '@redux-devtools/serialize'
import config from '../config'

const { stringify, parse } = immutable(Immutable)

const PREFIX = `smardock-${config.VERSION}`
const clearStorage = (prefix = PREFIX) => {
  window.localStorage.removeItem(`${prefix}:root`)
}

export const loadState = (prefix = PREFIX) => {
  try {
    const unserializedState = window.localStorage.getItem(`${prefix}:root`)
    if (!unserializedState) {
      return undefined
    }

    const rawState = parse(unserializedState)

    const state = {}
    Object.keys(rawState).forEach(key => {
      state[key] = parse(rawState[key])
    })

    if (state.app?.version !== config.VERSION) {
      clearStorage(prefix)
      return undefined
    }

    return state
  } catch (e) {
    return undefined
  }
}

export const saveState = (state, { blacklisted, prefix = PREFIX }) => {
  try {
    const serializedState = {}
    Object.keys(state).forEach(key => {
      if (blacklisted.indexOf(key) < 0) {
        serializedState[key] = stringify(state[key])
      }
    })

    // if we blacklist users, lets make sure we keep needed keys
    if (blacklisted.indexOf('users') > -1) {
      serializedState.users = stringify(
        new Map({
          user: state.users.get('user'),
          token: state.users.get('token')
        })
      )
    }

    window.localStorage.setItem(`${prefix}:root`, stringify(serializedState))
  } catch (e) {
    console.error(`Failed to persist state: ${e.message}`)
  }
}
