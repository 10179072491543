import { css } from 'styled-components'

export const _flexAlignCenter = css`
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const _flex = css`
  display: flex;
`

export const flex = css`
  ${props => props.flex ? _flex : ''}
`

export const flexAlignCenter = css`
  ${props => props.centered ? _flexAlignCenter : ''}
`

export const flexGrow = css`
  ${props => props.grow ? css`flex: 1 1 auto;` : ''}
`

export const _fill = css`
  ${props => props.fillHeight ? 'height: 100%;' : ''}
  width: 100%;
`

export const fill = css`
  ${props => props.fill ? _fill : ''}
`

export const input = css`
  height: 2.4rem;
  width: ${props => props.inputWidth ? `${props.inputWidth}rem;` : 'auto'};
  white-space: pre;
  border-radius: 2px;
  padding: 2px 8px;
  border: 1px solid ${props => props.theme.darkGray};
  ${props => props.notSpaced ? '' : 'margin-bottom: ' + props.theme.spacing(1) + ';'}
  font-size: 0.75rem;
  outline: 0;
`
