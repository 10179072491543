import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FilterIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 12"
      >
        <path fill={color} fillRule="evenodd" d="M7.538 12h4.308v-2H7.538v2zM0 2h19.385V0H0v2zm3.23 5h12.924V5H3.23v2z"/>
      </svg>
    )
  }
}

FilterIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

FilterIcon.defaultProps = {
  width: 20,
  height: 12,
  color: '#ffffff'
}
