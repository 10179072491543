import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { getCreatingCarrierRequest } from '../../modules/carrierRequests/selectors'
import CarrierButton from '../../components/CarrierButton'
import CarrierRequestsActions from '../../modules/carrierRequests/actions'
import UnstyledTextInput from '../../components/CarrierTextInput'
import radioOff from '../../assets/images/radio-button-off.svg'
import radioOn from '../../assets/images/radio-button-on.svg'
import { selectCarrierUser } from '../../carrier-app/carrier-app-slice'
import { selectDriverById } from '../../drivers/drivers-slice'
import { selectCarrierById } from '../../carriers/carriers-slice'

const Container = styled.div`
  margin-top: 100px;
`

const Row = styled.div`
  display: flex;
  margin: 0px -10px 10px 0px;
`

const Column = styled.div`
  width: 50%;
  padding: 0px 10px;
`

const ContactMethod = styled.div`
  margin-top: 12px;
  color: #898989;
  font-weight: bold;
  font-size: 12px;
`

const TextInput = styled(UnstyledTextInput)`
  background-color: ${props => props.theme.background};
  color: hsl(0,0%,20%);
`

const RadioOptions = styled.div`
  display: flex;
  padding: 10px 0px;
`

const Radio = styled.div`
  background-image: url(${props => props.selected ? radioOn : radioOff});
  background-repeat: no-repeat;
  margin-right: 40px;
  padding-left: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #898989;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'inherit'};
  cursor: pointer;
`

const ConfirmButton = styled(CarrierButton)`
  height: 36px;
  font-size: 12px;
  width: 100%;
  color: ${props => props.disabled ? props.theme.colors.primaryAccent : '#fff'};
  background-color: ${props => props.disabled ? '#fff' : props.theme.colors.primaryAccent};
`

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Back = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.primaryAccent};
  background-color: #fff;
  margin-right: 10px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${props => props.theme.colors.primaryAccent};
`

const BackText = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 12px;
`

class ContactInfo extends Component {
  constructor (props) {
    super(props)

    const { user } = props

    this.state = {
      carrierId: user?.carrierId ?? '',
      carrierName: '',
      driverId: user?.driverId ?? '',
      driverName: '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      tractorNumber: '',
      trailerLicense: '',
      prefContactMethod: user?.prefContactMethod ?? 'email'
    }
  }

  componentDidMount () {
    const { creatingCarrierRequest } = this.props

    if (creatingCarrierRequest) {
      this.updateValues(creatingCarrierRequest)
    }
  }

  componentDidUpdate (prevProps) {
    const { creatingCarrierRequest } = this.props
    if (creatingCarrierRequest !== prevProps.creatingCarrierRequest) {
      this.updateValues(creatingCarrierRequest)
    }
  }

  updateValues (creatingCarrierRequest) {
    const { user, getDriverById, getCarrierById } = this.props
    const carrierName = (user && user.carrierId) ? getCarrierById(user.carrierId)?.name : ''
    const driver = (user && user.driverId) ? getDriverById(user.driverId) : null
    const driverName = (driver) ? `${driver.firstName ?? ''} ${driver.lastName ?? ''}` : ''

    this.setState({
      carrierId: creatingCarrierRequest.get('carrierId') || (user ? user.carrierId : ''),
      carrierName: creatingCarrierRequest.get('carrierName') || carrierName,
      driverId: creatingCarrierRequest.get('driverId') || (user ? user.driverId : ''),
      driverName: creatingCarrierRequest.get('driverName') || driverName,
      email: creatingCarrierRequest.get('email') || (user ? user.email : ''),
      phone: creatingCarrierRequest.get('phone') || (user ? user.phone : ''),
      tractorNumber: creatingCarrierRequest.get('tractorNumber') ?? '',
      trailerLicense: creatingCarrierRequest.get('trailerLicense') ?? '',
      prefContactMethod: creatingCarrierRequest.get('prefContactMethod') ||
        (user ? user.prefContactMethod : '') ||
        'email'
    })
  }

  onPhoneChange = e => {
    const { value } = e.target
    const { prefContactMethod } = this.state

    this.setState({
      phone: value,
      prefContactMethod: value ? prefContactMethod : 'email'
    })
  }

  onBack = () => {
    const { updateCreatingCarrierRequest, stepBack } = this.props
    updateCreatingCarrierRequest({ ...this.state })
    stepBack()
  }

  onConfirm = () => {
    const { updateCreatingCarrierRequest, stepForward } = this.props
    updateCreatingCarrierRequest({ ...this.state })
    stepForward()
  }

  render () {
    const {
      email,
      phone,
      tractorNumber,
      trailerLicense,
      prefContactMethod,
      carrierId,
      carrierName,
      driverName
    } = this.state

    const emailValid = email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    return (
      <Container>
        <Row>
          <Column>
            <TextInput
              fixedLabel={true}
              fullwidth="true"
              name="carrier"
              label='Carrier'
              type='text'
              value={carrierName}
              onChange={e => this.setState({ carrierName: e.target.value })}
            />
          </Column>
          <Column>
            <TextInput
              name="email"
              fixedLabel={true}
              label="Email*"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              name="driver"
              fixedLabel={true}
              fullwidth="true"
              label='Driver'
              type='text'
              value={driverName}
              onChange={e => this.setState({ driverName: e.target.value })}
            />
          </Column>
          <Column>
            <TextInput
              name="mobile_number"
              fixedLabel={true}
              label="Mobile Number"
              value={phone}
              onChange={this.onPhoneChange}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <TextInput
              name="tractor_number"
              fixedLabel={true}
              label="Tractor Number"
              value={tractorNumber}
              onChange={e => this.setState({ tractorNumber: e.target.value })}
            />
          </Column>
          <Column>
            <TextInput
              name="trailer_license"
              fixedLabel={true}
              label="Trailer License"
              value={trailerLicense}
              onChange={e => this.setState({ trailerLicense: e.target.value })}
            />
          </Column>
        </Row>
        <Row>
          <Column> </Column>
          <Column>
            <ContactMethod>Prefered method of contact</ContactMethod>
            <RadioOptions>
              <Radio
                selected={prefContactMethod === 'email'}
                onClick={() => this.setState({ prefContactMethod: 'email' })}
              >
                Email
              </Radio>
              <Radio
                selected={prefContactMethod === 'sms'}
                onClick={() => this.setState({ prefContactMethod: 'sms' })}
                disabled={!phone}
              >
                Text
              </Radio>
            </RadioOptions>
          </Column>
        </Row>
        <Row>
          <Column>
            <BackContainer onClick={this.onBack}>
              <Back>&#8810;</Back>
              <BackText>Back</BackText>
            </BackContainer>
          </Column>
          <Column>
            <ConfirmButton
              onClick={this.onConfirm}
              disabled={(!email || !emailValid) || (!carrierName && !carrierId)}
            >
              Confirm Request
            </ConfirmButton>
          </Column>
        </Row>
      </Container>
    )
  }
}

ContactInfo.propTypes = {
  updateCreatingCarrierRequest: PropTypes.func,
  creatingCarrierRequest: PropTypes.object,
  stepBack: PropTypes.func,
  stepForward: PropTypes.func,
  user: PropTypes.object,
  getDriverById: PropTypes.func,
  getCarrierById: PropTypes.func
}

const mapStateToProps = state => ({
  creatingCarrierRequest: getCreatingCarrierRequest(state),
  user: selectCarrierUser(state),
  getDriverById: (id) => selectDriverById(state, id),
  getCarrierById: (id) => selectCarrierById(state, id)
})

const mapDispatchToProps = dispatch => ({
  updateCreatingCarrierRequest: payload => dispatch(CarrierRequestsActions.updateCreatingCarrierRequest(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo)
