import React, { Component } from 'react'
import ReactModal from 'react-modal'

import PropTypes from 'prop-types'

function ReactModalAdapter ({ className, ...props }) {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

ReactModalAdapter.propTypes = {
  className: PropTypes.string
}

class Modal extends Component {
  render () {
    const {
      children,
      contentLabel,
      isOpen = false,
      styles,
      size
    } = this.props
    return (
      <ReactModalAdapter
        ariaHideApp={false}
        isOpen={isOpen}
        style={{ ...styles }}
        size={size}
        contentLabel={contentLabel}
        {...this.props}
      >
        {children}
      </ReactModalAdapter>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  big: PropTypes.bool,
  small: PropTypes.bool,
  onRequestClose: PropTypes.func,
  styles: PropTypes.object,
  contentLabel: PropTypes.any,
  size: PropTypes.any
}

export default Modal
