import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import axios from '../../utils/axios'
import OrderItemsActions, { OrderItemsTypes } from './actions'
import { token as getToken } from '../users/selectors'

const baseUrl = '/orderItems'

// Sagas
function * getOrderItems ({ payload }) {
  yield put(OrderItemsActions.getOrderItemsLoading())
  try {
    const token = yield select(getToken)
    const { orderId } = payload
    let url = baseUrl

    if (orderId) {
      url += `&orderId=${orderId}`
    }

    const { data } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(OrderItemsActions.getOrderItemsSuccess(data))
  } catch (e) {
    yield put(OrderItemsActions.getOrderItemsFailure(e))
  }
}

// Watchers
function * getOrderItemsWatcher () {
  yield takeLatest(OrderItemsTypes.GET_ORDER_ITEMS, getOrderItems)
}

export default function * root () {
  yield fork(getOrderItemsWatcher)
}
