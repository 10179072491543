import axios from 'axios'
import config from '../config'

axios.defaults.baseURL = config.API_BASE
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

// Not sure why POST, PUT and DELETE don't work setting common
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.headers.delete['Content-Type'] = 'application/json'

export const handleError = (action, e) => {
  return action(e)
}

export default axios
