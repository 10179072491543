import { fromJS, List, Map } from 'immutable'
import { NotificationManager } from 'react-notifications'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'
import moment from 'moment'
import merge from 'lodash.merge'

import { CarrierSchema } from '../carriers/sagas'
import { OrderSchema } from '../orders/sagas'
import { getAppointmentsForDoors } from '../doors/selectors'
import { createGetDoorById, selectAllDoors } from '../../doors/doors-slice'
import { createGetDriverEntityById } from '../../drivers/drivers-slice'
import { selectAllBuildings } from '../../buildings/buildings-slice'
import { selectAllAreas } from '../../areas/areas-slice'
import { getUserToken, token as getToken } from '../users/selectors'
import AppointmentsActions, { AppointmentsTypes } from './actions'
import CarrierRequestsActions from '../carrierRequests/actions'
import DoorsActions from '../doors/actions'
import OrdersActions from '../orders/actions'
import appointmentSelectors, {
  getAllAppointments,
  getSearchAttributes,
  getSearchAttributesCount
} from './selectors'
import axios from '../../utils/axios'
import {
  selectAppointmentType,
  selectCurrentBuildingId,
  setBuildingId,
  setSiteId
} from '../../app/app-slice'
import {
  createGetAppointmentById,
  createGetAppointmentsById,
  getAppointmentsFulfilled,
  setManyAppointments
} from '../../appointments/appointments-slice'
import { createGetCarrierById, getAllCarriersFulfilled } from '../../carriers/carriers-slice'
import { setManyOrders } from '../../orders/orders-slice'

const baseUrl = '/appointments'

export const AppointmentSchema = new schema.Entity('appointments')

const updateAppointmentOnList = (newAppt, apptList, upsert = false) => {
  // check if appt already exist in the list
  const appt = apptList.find(ap => ap.get('id') === newAppt.get('id'))

  // if it was deleted remove from grid
  if (newAppt.get('deletedAt')) {
    const index = apptList.findIndex(ap => ap.get('id') === newAppt.get('id'))
    if (index >= 0) apptList = apptList.delete(index)
  } else {
    // if exist just update
    if (appt) {
      apptList = apptList.map(ap => {
        if (ap.get('id') === newAppt.get('id')) {
          return newAppt
        }
        return ap
      })
    } else {
      // if doesnt push to the list of appointments for doors
      if (upsert) apptList = apptList.push(newAppt)
    }
  }
  return apptList
}

/* Sagas */

function * getAppointments ({ payload }) {
  yield put(AppointmentsActions.getAppointmentsLoading())
  try {
    const token = yield select(getToken)
    const appointmentTypes = yield select(selectAppointmentType)
    const {
      id,
      searchText,
      customerPurchaseOrder,
      dateFrom,
      dateTo,
      customerId,
      appointmentStatusId,
      destinationId,
      buildingId,
      currentPage,
      doors
    } = payload
    let url = searchText ? `${baseUrl}?primaryRefValue=${searchText}` : `${baseUrl}?`

    if (id) {
      url += `&id=${id}`
    }

    if (appointmentTypes && appointmentTypes === 1) {
      url += '&type=Purchase Order'
    }

    if (appointmentTypes && appointmentTypes === 2) {
      url += '&type=Sales Order'
    }

    if (customerPurchaseOrder) {
      url += `&otherRefs=${customerPurchaseOrder}`
    }

    if (dateFrom) {
      url += `&dateFrom=${dateFrom}&dateTo=${dateTo || dateFrom}`
    }
    if (customerId) {
      url += `&customerId=${customerId}`
    }
    if (appointmentStatusId) {
      url += `&appointmentStatusId=${appointmentStatusId}`
    }
    if (destinationId) {
      url += `&destinationId=${destinationId}`
    }
    if (buildingId) {
      url += `&buildingId=${buildingId}`
    }
    if (currentPage) {
      url += `&page=${currentPage}`
    }
    if (doors) {
      doors.forEach(door => {
        url += `&doorId=${door}`
      })
    }

    const {
      data: { appointments: data, pages, total }
    } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const normalizedData = normalize(data, [AppointmentSchema])
    const appointmentEntities = normalizedData.entities?.appointments
    if (appointmentEntities) {
      yield put(getAppointmentsFulfilled(Object.values(appointmentEntities)))
    }

    const orders = {}

    for (const appt of data) {
      if (appt.orders) {
        const normalizedOrdersData = normalize(appt.orders, [OrderSchema])
        merge(orders, normalizedOrdersData.entities.orders)
      }
    }
    yield put(setManyOrders(Object.values(orders)))

    if (currentPage && currentPage > 1) {
      const currentAppointments = yield select(getAllAppointments)
      if (currentAppointments) {
        return yield put(
          AppointmentsActions.getAppointmentsSuccess({
            data: [...currentAppointments.toArray(), ...data],
            pages,
            total
          })
        )
      }
    }
    yield put(
      AppointmentsActions.getAppointmentsSuccess({
        data,
        pages,
        total
      })
    )
  } catch (e) {
    console.error(e)
    yield put(AppointmentsActions.getAppointmentsFailure(e))
  }
}

function * getAppointment ({ id }) {
  yield put(AppointmentsActions.getAppointmentLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.get, `${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.getAppointmentSuccess(data))
  } catch (e) {
    yield put(AppointmentsActions.getAppointmentFailure(e))
  }
}

function * getAppointmentWithOrders ({ payload }) {
  yield put(AppointmentsActions.getAppointmentWithOrdersLoading())
  try {
    const { id } = payload
    const token = yield select(getToken)
    const { data } = yield call(axios.get, `${baseUrl}/${id}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.getAppointmentWithOrdersSuccess(data))
  } catch (e) {
    yield put(AppointmentsActions.getAppointmentWithOrdersFailure(e))
  }
}

function * createAppointment ({ payload }) {
  yield put(AppointmentsActions.createAppointmentLoading())
  try {
    // FIXME: We should sanitize requests for smaller payloads
    const sendingData = { ...payload }
    delete sendingData.items
    delete sendingData.appointmentStatus
    delete sendingData.building
    delete sendingData.door
    delete sendingData.site
    delete sendingData.orders

    const token = yield select(getToken)
    const { data } = yield call(axios.post, baseUrl, sendingData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    // TODO: why is it necessary ???
    if (data.carrier) {
      const normalizedData = normalize([data.carrier], [CarrierSchema])
      yield put(getAllCarriersFulfilled(Object.values(normalizedData?.entities?.carriers)))
    }

    yield put(AppointmentsActions.createAppointmentSuccess(data))
  } catch (e) {
    if (e.response) {
      if (e.response.status === 409) {
        NotificationManager.error(e.response.data.error)
      } else if (e.response.status === 400) {
        NotificationManager.error(`Appointment request failed: ${e.response.data}`)
      } else if (e.response.status === 422) {
        NotificationManager.error('Appointment missing required fields.')
      } else if (e.response.status === 412) {
        NotificationManager.error(
          e.response.data ? e.response.data.error : 'One or more orders are already scheduled.'
        )
      } else {
        NotificationManager.error('Appointment could not be created.')
      }
    } else {
      NotificationManager.error('Appointment could not be created.')
    }

    yield put(AppointmentsActions.createAppointmentFailure(e))
  }
}

function * updateAppointment ({ payload }) {
  // TODO: id is null after closing appt modal afatr opening on clicking on appt request
  if (payload.id == null) return
  yield put(AppointmentsActions.updateAppointmentLoading(payload.id))
  try {
    // FIXME: We should sanitize requests for smaller payloads
    const sendingData = { ...payload }
    delete sendingData.items
    delete sendingData.appointmentStatus
    delete sendingData.building
    delete sendingData.door
    delete sendingData.site
    delete sendingData.orders
    const preserveUndefinedPayload = JSON.stringify(sendingData, (k, v) =>
      v === undefined ? null : v
    )
    const token = yield select(getToken)

    const { data } = yield call(axios.put, `${baseUrl}/${payload.id}`, preserveUndefinedPayload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    // TODO: why it's necessary?
    if (data.carrier) {
      const normalizedData = normalize([data.carrier], [CarrierSchema])
      yield put(getAllCarriersFulfilled(Object.values(normalizedData?.entities?.carriers)))
    }

    yield put(AppointmentsActions.updateAppointmentSuccess(data))
    yield put(OrdersActions.updateOrdersFromAppointment(data))
  } catch (e) {
    if (e.response) {
      if (e.response.status === 409) {
        NotificationManager.error(e.response.data.error)
      } else if (e.response.status === 400) {
        NotificationManager.error(`Appointment request failed: ${e.response.data}`)
      } else if (e.response.status === 422) {
        NotificationManager.error('Appointment missing required fields.')
      } else if (e.response.status === 412) {
        NotificationManager.error(e.response.data.error)
      } else {
        NotificationManager.error(e.response?.data?.message || 'Appointment could not be modified.')
      }
    } else {
      NotificationManager.error('Appointment could not be modified.')
    }

    yield put(AppointmentsActions.updateAppointmentFailure(e))
  }
}

function * removeOrderFromAppointment ({ appointmentId, orderId }) {
  yield put(AppointmentsActions.removeOrderFromAppointmentLoading())
  try {
    const token = yield select(getToken)
    yield call(axios.delete, `${baseUrl}/${appointmentId}/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.removeOrderFromAppointmentSuccess())
  } catch (e) {
    yield put(AppointmentsActions.removeOrderFromAppointmentFailure(e))
  }
}

function * deleteAppointment ({ id }) {
  yield put(AppointmentsActions.deleteAppointmentLoading())
  try {
    const token = yield select(getToken)
    yield call(axios.delete, `${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.deleteAppointmentSuccess())
  } catch (e) {
    yield put(AppointmentsActions.deleteAppointmentFailure(e))
  }
}

function * getAllAppointmentStatuses () {
  yield put(AppointmentsActions.getAllAppointmentStatusesLoading())
  try {
    const token = yield select(getUserToken)
    const { data } = yield call(axios.get, '/appointment_statuses', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.getAllAppointmentStatusesSuccess(data))
  } catch (e) {
    yield put(AppointmentsActions.getAllAppointmentStatusesFailure(e))
  }
}

function * moveAppointment ({ payload }) {
  const getAppointmentById = yield select(state => createGetAppointmentById(state))
  const oldAppointment = getAppointmentById(payload.id)
  yield put(AppointmentsActions.openEditAppointmentIsLoading(payload.id))
  yield put(AppointmentsActions.moveAppointmentLoading(payload.id))
  try {
    const token = yield select(getToken)

    yield put(DoorsActions.editAppointment(payload))
    const { data } = yield call(axios.put, `${baseUrl}/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.moveAppointmentSuccess(data))
  } catch (e) {
    if (e.response.status === 400) {
      NotificationManager.error(
        "Appointment couldn't be created because it was overlapping another one."
      )
    } else if (e.response.status === 422) {
      NotificationManager.error('Appointment missing required fields.')
    } else {
      NotificationManager.error('Appointment could not be created.')
    }

    yield put(DoorsActions.editAppointment(oldAppointment))
    yield put(AppointmentsActions.moveAppointmentFailure(e))
  }
}

function * getAppointmentsForWarehouse () {
  yield put(AppointmentsActions.getAppointmentsLoading())
  try {
    const buildingId = yield select(selectCurrentBuildingId)
    const token = yield select(getToken)
    const doors = yield select(selectAllDoors)
    const areas = yield select(selectAllAreas)

    const buildingArea = areas.find(a => a.buildingId === buildingId)
    const buildingDoors = doors.filter(d => d.areaId === buildingArea?.id)

    let attributes = ''

    buildingDoors.forEach((door, index) => {
      index > 0 ? (attributes += `&doorId=${door.id}`) : (attributes += `doorId=${door.id}`)
    })

    const { data } = yield call(axios.get, `/appointments?${attributes}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const normalizedData = normalize(data, [AppointmentSchema])
    yield put(setManyAppointments(normalizedData.entities.appointments))

    yield put(AppointmentsActions.getAppointmentsSuccess(data))
    yield put(DoorsActions.setAppointments(data))
  } catch (e) {
    console.error(e)
    yield put(AppointmentsActions.getAppointmentsFailure(e))
  }
}

function * clearRequest ({ id }) {
  yield put(AppointmentsActions.clearRequestLoading())
  try {
    const token = yield select(getToken)

    const { data } = yield call(axios.post, `${baseUrl}/${id}/clear-request`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(AppointmentsActions.clearRequestSuccess(data))
    yield put(CarrierRequestsActions.getAllCarrierRequests())
    yield put(CarrierRequestsActions.getNumberOfCarrierRequests())
  } catch (e) {
    yield put(AppointmentsActions.clearRequestFailure(e))
  }
}

function * createFromRequest ({ request, apptData = {} }) {
  if (!request) return
  try {
    const getDoorById = yield select(createGetDoorById)
    const getDriverById = yield select(createGetDriverEntityById)
    const getCarrierById = yield select(createGetCarrierById)
    const token = yield select(getToken)

    const requestOrders = request.get('carrierRequestOrders')
    const doorMeta = getDoorById(request.get('doorId'))
    const area = doorMeta?.area
    const building = doorMeta?.area?.building
    const site = doorMeta?.area?.building?.site
    const driverId = request.get('driverId')
    const carrierId = request.get('carrierId')

    let data = []
    if (requestOrders && requestOrders.size) {
      const poNumbers = requestOrders.map(ro => ro.get('poNumber'))
      const url = `/orders?otherRefs=${poNumbers.join('&otherRefs=')}`
      const resp = yield call(axios.get, url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      data = resp.data
      yield put(OrdersActions.setSelectedOrders(data.orders))
    } else {
      yield put(OrdersActions.setSelectedOrders([]))
    }

    let date = request.get('date') && moment(request.get('date'))
    if (date && request.get('timeStart') && !apptData.date) {
      date = moment.tz(
        `${date.format('YYYY-MM-DD')} ${moment.utc(request.get('timeStart')).format('HH:mm')}`,
        request.get('building')?.timezone ?? 'UTC'
      )
    } else {
      date = apptData.date
    }

    const placement = {
      area: area ? area.toJS() : null,
      building: building ? building.toJS() : null,
      site: site ? site.toJS() : null,
      areaId: area?.id,
      buildingId: building ? building.get('id') : null,
      siteId: site?.id
    }

    const carrier = getCarrierById(carrierId)
    const driver = getDriverById(driverId)
    const items = data.orders.reduce((items, order) => [...items, ...order.items], [])

    const appt = {
      ...(request.get('doorId') ? placement : {}),
      recalculateDuration: true,
      carrierRequestId: request.get('id'),
      date,
      time: date,
      items,
      duration: 60,
      carrier: carrier,
      carrierId: carrier?.id,
      driver: driver,
      driverId: driver?.id,
      contactPhone: request.get('phone'),
      email: request.get('email'),
      trailer: request.get('trailerLicense'),
      tractor: request.get('tractorNumber'),
      carrierRequests: new List([fromJS(request)]),
      orderIds: data.orders.map(o => o.id),
      requestId: request.get('id'),
      ...apptData
    }
    yield put(AppointmentsActions.openEditAppointment(appt))
  } catch (e) {
    console.error(e)
  }
}

function * updateAppointmentWithSocketAppointment ({ data }) {
  try {
    let normalizedData = {}
    if (data instanceof Array) {
      normalizedData = normalize(data, [AppointmentSchema])
    } else {
      normalizedData = normalize([data], [AppointmentSchema])
      data = [data]
    }
    yield put(setManyAppointments(normalizedData.entities.appointments))

    // update appointments on the grid
    const warehouse = yield select(selectCurrentBuildingId)
    const doors = yield select(selectAllDoors)
    const areas = yield select(selectAllAreas)
    const buildings = yield select(selectAllBuildings)
    let appointmentsForDoors = yield select(getAppointmentsForDoors)
    let appointmentsSideBar = yield select(state => appointmentSelectors.getAppointments(state))

    for (const appointment of data) {
      const appointmentDoor = doors.find(d => d.id === appointment.doorId)
      const appointmentArea = areas.find(a => a.id === appointmentDoor?.areaId)
      const appointmentBuilding = buildings.find(b => b.id === appointmentArea?.buildingId)

      const newAppt = new Map({
        ...appointment,
        carrierRequests:
          List(appointment.carrierRequests && appointment.carrierRequests.map(c => Map(c))) ||
          List([]),
        orders: List(appointment.orders && appointment.orders.map(o => fromJS(o))) || List([]),
        inventoryIssues: Map(appointment.inventoryIssues || [])
      })

      if (appointmentBuilding && appointmentBuilding.id === warehouse) {
        appointmentsForDoors = updateAppointmentOnList(newAppt, appointmentsForDoors, true)
      }

      // update appointments on appointments side bar
      appointmentsSideBar = updateAppointmentOnList(newAppt, appointmentsSideBar, false)
    }
    yield put(DoorsActions.setAppointments(appointmentsForDoors))
    yield put(AppointmentsActions.getAppointmentsSuccess(appointmentsSideBar))
  } catch (e) {
    console.error(e)
  }
}

function * searchAppointments ({ payload }) {
  yield put(AppointmentsActions.onAppointmentSearchChange(payload))
  const attributes = yield select(getSearchAttributes)
  const attributesCount = yield select(getSearchAttributesCount)

  const data = {
    ...attributes,
    appointmentStatusId: attributes.appointmentsStatusSelect,
    customerId: attributes.customerSelect,
    shippingDate: attributes.shippingDateSelect
      ? attributes.shippingDateSelect.format('YYYY-MM-DD')
      : null,
    destinationId: attributes.destinationSelect,
    page: attributes.currentPage
  }

  let dateFrom = null
  let dateTo = null

  if (attributes.shippingDateSelect) {
    dateFrom = moment(attributes.shippingDateSelect).subtract(1, 'day').format('L')
    dateTo = moment(attributes.shippingDateSelect).add(2, 'day').format('L')
  }

  if (!attributesCount) {
    data.buildingId = yield select(selectCurrentBuildingId)
  }

  yield put(
    AppointmentsActions.getAppointments({
      ...data,
      dateFrom,
      dateTo
    })
  )
}

function * showAppointment ({ appointment }) {
  const { siteId, buildingId } = appointment

  const date = moment(appointment.date)
  if (date.hour() < 6) {
    date.subtract(1, 'day')
  }
  const startDate = date.format('L')
  const startTime = date.startOf('day').format('HH:mm:ss')
  const endDate = date.add(1, 'days').add(6, 'hours').format('L')
  const endTime = date.format('HH:mm:ss')

  yield put(setSiteId(siteId))
  yield put(setBuildingId(buildingId))
  yield put(
    DoorsActions.getAppointmentsForDoors({
      buildingId,
      startDate,
      endDate,
      startTime,
      endTime
    })
  )
}

function * getMissingDownstreamAppointments ({ appointment }) {
  const downstreamConflicts = appointment.meta.appointmentIssues.hasConflictingInventory
  if (downstreamConflicts) {
    const conflictingAppointmentIds = Object.keys(downstreamConflicts).reduce(
      (flatAppoitments, sku) => {
        return Array.from(new Set([...flatAppoitments, ...Object.keys(downstreamConflicts[sku])]))
      },
      []
    )

    const cachedAppointments = yield select(state =>
      createGetAppointmentsById(state)(conflictingAppointmentIds)
    )
    const missingAppointmentIds = conflictingAppointmentIds.filter(id => {
      return !cachedAppointments[id]
    })
    if (missingAppointmentIds.length > 0) {
      // TODO: check why we are not reusing query here
      const queryParams = missingAppointmentIds.reduce((query, id) => {
        return `id=${id}&`
      }, '')

      const token = yield select(getToken)
      const { data } = yield call(axios.get, `${baseUrl}?page=1&per_page=100&${queryParams}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const normalizedData = normalize(data.appointments, [AppointmentSchema])
      yield put(setManyAppointments(normalizedData.entities.appointments))
    }
  }
}

function * openEditAppointment ({ appointment }) {
  if (!appointment.id) {
    yield put(AppointmentsActions.openEditAppointmentSuccess(appointment))
  } else {
    yield put(AppointmentsActions.openEditAppointmentIsLoading(appointment.id))
    const cachedAppointment = yield select(state => createGetAppointmentById(state)(appointment.id))
    if (!cachedAppointment) {
      const token = yield select(getToken)
      const { data } = yield call(axios.get, `${baseUrl}/${appointment.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const normalizedData = normalize(data, [AppointmentSchema])
      yield put(setManyAppointments(normalizedData.entities.appointments))
      yield put(OrdersActions.setSelectedOrders(data.appointment.orders))
      yield put(AppointmentsActions.openEditAppointmentSuccess(data.appointment))
    } else {
      yield put(OrdersActions.setSelectedOrders(cachedAppointment.orders))
      yield put(AppointmentsActions.openEditAppointmentSuccess(cachedAppointment))
    }
  }
}

/* Watchers */

function * updateAppointmentWithSocketAppointmentWatcher () {
  yield takeLatest(
    AppointmentsTypes.UPDATE_APPOINTMENT_WITH_SOCKET_APPOINTMENT,
    updateAppointmentWithSocketAppointment
  )
}

function * getAppointmentsWatcher () {
  yield takeLatest(AppointmentsTypes.GET_APPOINTMENTS, getAppointments)
}

function * getAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.GET_APPOINTMENT, getAppointment)
}

function * getAppointmentWithOrdersWatcher () {
  yield takeLatest(AppointmentsTypes.GET_APPOINTMENT_WITH_ORDERS, getAppointmentWithOrders)
}

function * createAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.CREATE_APPOINTMENT, createAppointment)
}

function * updateAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.UPDATE_APPOINTMENT, updateAppointment)
}

function * removeOrderFromAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.REMOVE_ORDER_FROM_APPOINTMENT, removeOrderFromAppointment)
}

function * deleteAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.DELETE_APPOINTMENT, deleteAppointment)
}

function * getAllAppointmentStatusesWatcher () {
  yield takeLatest(AppointmentsTypes.GET_ALL_APPOINTMENT_STATUSES, getAllAppointmentStatuses)
}

function * moveAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.MOVE_APPOINTMENT, moveAppointment)
}

function * getAppointmentsForWarehouseWatcher () {
  yield takeLatest(AppointmentsTypes.GET_APPOINTMENTS_FOR_WAREHOUSE, getAppointmentsForWarehouse)
}

function * clearRequestWatcher () {
  yield takeLatest(AppointmentsTypes.CLEAR_REQUEST, clearRequest)
}

function * createFromRequestWatcher () {
  yield takeLatest(AppointmentsTypes.CREATE_FROM_REQUEST, createFromRequest)
}

function * searchAppointmentsWatcher () {
  yield takeLatest(AppointmentsTypes.SEARCH_APPOINTMENTS, searchAppointments)
}

function * showAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.SHOW_APPOINTMENT, showAppointment)
}

function * openEditAppointmentWatcher () {
  yield takeLatest(AppointmentsTypes.OPEN_EDIT_APPOINTMENT, openEditAppointment)
}

function * getMissingDownstreamAppointmentsWatcher () {
  yield takeLatest(
    [AppointmentsTypes.CREATE_APPOINTMENT_SUCCESS, AppointmentsTypes.UPDATE_APPOINTMENT_SUCCESS],
    getMissingDownstreamAppointments
  )
}

export default function * root () {
  yield fork(updateAppointmentWithSocketAppointmentWatcher)
  yield fork(getAppointmentsWatcher)
  yield fork(getAppointmentWatcher)
  yield fork(getAppointmentWithOrdersWatcher)
  yield fork(createAppointmentWatcher)
  yield fork(updateAppointmentWatcher)
  yield fork(removeOrderFromAppointmentWatcher)
  yield fork(deleteAppointmentWatcher)
  yield fork(getAllAppointmentStatusesWatcher)
  yield fork(moveAppointmentWatcher)
  yield fork(getAppointmentsForWarehouseWatcher)
  yield fork(clearRequestWatcher)
  yield fork(createFromRequestWatcher)
  yield fork(searchAppointmentsWatcher)
  yield fork(showAppointmentWatcher)
  yield fork(openEditAppointmentWatcher)
  yield fork(getMissingDownstreamAppointmentsWatcher)
}
