import { CardContainer } from '../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../styled/Table'
import PropTypes from 'prop-types'
import React from 'react'

const COLUMNS_LENGTH = 9
const PREFIX_KEY = 'customerSummaryPreviousWeek'

const CustomerSummaryPreviousWeek = ({ data, isLoading, errorMessage }) => (
  <CardContainer>
    <StyledTable>
      <thead>
        <TableRow>
          <TableColumnHeader />
          <TableColumnHeader>CUSTOMER</TableColumnHeader>
          <TableColumnHeader>TRUCKS</TableColumnHeader>
          <TableColumnHeader>CASES</TableColumnHeader>
          <TableColumnHeader>ORDERS</TableColumnHeader>
          <TableColumnHeader>AVG LOAD TIME</TableColumnHeader>
          <TableColumnHeader>NO SHOWS</TableColumnHeader>
          <TableColumnHeader>OVER 2 HOUR LOAD TIMES</TableColumnHeader>
          <TableColumnHeader>OVER 4 HOUR LOAD TIMES</TableColumnHeader>
        </TableRow>
      </thead>
      <tbody>
        {(!isLoading && data) && data.map((s, key) => (
          <TableRow key={PREFIX_KEY + key + s.get('customer')}>
            <TableCol>{key + 1}</TableCol>
            <TableCol>{s.get('customer')}</TableCol>
            <TableCol>{s.get('trucks')}</TableCol>
            <TableCol>{s.get('totalCases')}</TableCol>
            <TableCol>{s.get('totalOrders')}</TableCol>
            <TableCol>{s.get('averageLoadTime')}</TableCol>
            <TableCol>{s.get('noShows')}</TableCol>
            <TableCol>{s.get('overTwoHoursLoad')}</TableCol>
            <TableCol>{s.get('overFourHoursLoad')}</TableCol>
          </TableRow>
        ))}
        {isLoading && (
          <TableRow key={`${PREFIX_KEY}Loading`}>
            <TableCol centerText colSpan={COLUMNS_LENGTH}> LOADING REPORT </TableCol>
          </TableRow>
        )}
        {(!isLoading && !errorMessage && (!data || data.isEmpty())) && (
          <TableRow key={`${PREFIX_KEY}NoData`}>
            <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
          </TableRow>
        )}
        {(errorMessage && !isLoading) && (
          <TableRow key={`${PREFIX_KEY}Error`}>
            <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
          </TableRow>
        )}
      </tbody>
    </StyledTable>
  </CardContainer>
)

CustomerSummaryPreviousWeek.propTypes = {
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool
}

export default CustomerSummaryPreviousWeek
