import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GridCell, GridCol } from '../../styled/Grids'
import TableStructure from './TableStructure'
import TableHeader from './TableHeader'
import { getAppointmentsForDoors } from '../../modules/doors/selectors'
import { createGetDoorById, selectDoorsForCurrentBuilding } from '../../doors/doors-slice'
import {
  createGetAppointmentsByDoorAndDate,
  getAllAppointmentStatuses,
  getOpenEditAppointmentIsLoading,
  getUpdateAppointmentIsLoading
} from '../../modules/appointments/selectors'
import DoorsActions from '../../modules/doors/actions'
import AppointmentsActions from '../../modules/appointments/actions'
import UiActions from '../../modules/ui/actions'
import { getDayStart, getWorkingDayEnd } from '../../utils/time'
import OrdersActions from '../../modules/orders/actions'
import { APPOINTMENTS_TAB } from '../modals/AppointmentModal'
import { createGetInventoryCalculationSetting } from '../../modules/settings/selector'
import {
  selectAppointmentType,
  selectCurrentBuildingId,
  selectCurrentBuildingTimezone,
  selectEndDate,
  selectEndShift,
  selectFocusAppointment,
  selectStartDate,
  selectStartShiift
} from '../../app/app-slice'
import { getUniqueSkusByHour } from './utils'

class TimeTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      is24Format: true,
      zoomLevel: 100,
      workingHours: this.getWorkingHours(props.startShift, props.endShift)
    }
    this.tableStructure = React.createRef()
  }

  componentDidMount () {
    this.onDateChange(this.props.startDate)
  }

  componentDidUpdate (prevProps, prevState) {
    const { startShift, endShift } = this.props
    if (prevProps.startShift !== startShift || prevProps.endShift !== endShift) {
      this.setState({
        workingHours: this.getWorkingHours(startShift, endShift)
      })
    }
  }

  set24Format = value =>
    this.setState({
      is24Format: value
    })

  setZoomLevel = value =>
    this.setState({
      zoomLevel: value
    })

  getWorkingHours = (shiftStart, shiftEnd) => {
    // I don't really care about the day right now, but this way we know the next shift day
    const hours = []
    let currentTime = shiftStart
    do {
      hours.push(currentTime)
      currentTime = currentTime.clone().add(1, 'hour')
    } while (currentTime < shiftEnd)
    return hours
  }

  onDropEventOnTable = ({ props, door, hour }) => {
    const { appointment } = props
    const { moveAppointment } = this.props
    const editingAppointment = { ...appointment }
    editingAppointment.doorId = door?.id
    editingAppointment.date = hour
    editingAppointment.rwConnect = true
    moveAppointment(editingAppointment)
  }

  /**
   * Triggered when a Date is choosen (from datepicker or through DayTabs)
   * @param date moment
   */
  onDateChange = date => {
    const { getAppointmentsForDoors } = this.props
    const endDate = getWorkingDayEnd(date)

    getAppointmentsForDoors({
      selectedStartDate: date,
      selectedEndDate: endDate,
      selectedEndShift: endDate
    })
  }

  /**
   * Triggered when the remove icon is clicked on an Table Event
   * @param appointment
   */
  onDeleteAppointment = appointment => {
    const { openDeleteAppointment } = this.props
    openDeleteAppointment(appointment)
  }

  /**
   * Triggered when the edit icon is clicked on an Table Event
   * @param appointment
   * @param tab
   */
  onEditAppointment = (appointment, tab = APPOINTMENTS_TAB) => {
    const { openEditAppointment, setSelectedOrders, changeTab } = this.props
    setSelectedOrders(appointment.orders)
    openEditAppointment(appointment)
    changeTab(tab)
  }

  /**
   * Triggered when a Table Event is resized (the Appointment has it's duration changed)
   * @param appointment
   * @param duration
   */
  onDurationChange = (appointment, duration) => {
    const { updateAppointment, getAppointmentsForDoors, warehouse, startDate, endDate } = this.props

    const newAppointment = {
      ...appointment,
      duration,
      rwConnect: true
    }

    updateAppointment(newAppointment)
    getAppointmentsForDoors({
      selectedWarehouse: warehouse,
      selectedStartDate: startDate,
      selectedEndDate: endDate
    })
  }

  /**
   * Triggered when user clicks the arrow on the Table Event
   * This needs some pre-calculated data from the event
   * To move the table we get an innerRef from the Scrollable
   * and only move Y axis
   * @param data
   */
  onNextOrPreviousDayClick = data => {
    const { getAppointmentsForDoors } = this.props
    const { finalTime, initTime, continueNextDay, continuePreviousDay } = data

    if (continueNextDay || continuePreviousDay) {
      const scrollable = this.tableStructure.current.scrollable.current

      const tzStart = getDayStart(continuePreviousDay ? initTime : finalTime)
      if (
        (continueNextDay && finalTime.hour() < 6) ||
        (continuePreviousDay && initTime.hour() < 6)
      ) {
        tzStart.subtract(1, 'day')
      }
      const tzEnd = getWorkingDayEnd(tzStart)
      getAppointmentsForDoors({
        selectedStartDate: tzStart,
        selectedEndDate: tzEnd,
        selectedEndShift: tzEnd
      })
      scrollable.scrollTop = continuePreviousDay ? scrollable.scrollHeight : 0
    }
  }

  render () {
    const { is24Format, zoomLevel, workingHours } = this.state
    const {
      appointments,
      appointmentStatuses,
      doors,
      timezone,
      getDoorById,
      getAppointmentsByDoorAndDate,
      getInventoryCalculationSetting,
      focusAppointment,
      startDate,
      startShift,
      endShift,
      warehouse,
      idAppointmentLoading,
      openDestinationDetailsModal,
      appointmentTypes
    } = this.props

    const isInventoryCalculationEnabled = getInventoryCalculationSetting(warehouse)

    const uniqueSkus = getUniqueSkusByHour(appointments, timezone, startShift, endShift)
    return (
      <GridCell padded='topLeft' noScroll fill={1} flexGrow={1} width={'65%'}>
        <GridCol flexGrow fill={1}>
          <TableHeader
            onDateChange={this.onDateChange}
            set24Format={this.set24Format}
            setZoomLevel={this.setZoomLevel}
            is24Format={is24Format}
            zoomLevel={zoomLevel}
            isInventoryCalculationEnabled={isInventoryCalculationEnabled}
          />

          <TableStructure
            ref={this.tableStructure}
            doors={doors || []}
            appointments={appointments}
            workingHours={workingHours || []}
            startDate={startDate}
            is24Format={is24Format}
            zoomLevel={zoomLevel}
            timezone={timezone}
            startShift={startShift}
            endShift={endShift}
            getDoorById={getDoorById}
            idAppointmentLoading={idAppointmentLoading}
            onDateChange={this.onDateChange}
            openDestinationDetailsModal={openDestinationDetailsModal}
            getAppointmentsByDoorAndDate={getAppointmentsByDoorAndDate}
            isInventoryCalculationEnabled={isInventoryCalculationEnabled}
            appointmentStatuses={appointmentStatuses}
            focusAppointment={focusAppointment}
            onDropEventOnTable={this.onDropEventOnTable}
            onNextOrPreviousDayClick={this.onNextOrPreviousDayClick}
            onDeleteAppointment={this.onDeleteAppointment}
            onEditAppointment={this.onEditAppointment}
            onDurationChange={this.onDurationChange}
            uniqueSkus={uniqueSkus}
            appointmentTypes={appointmentTypes}
          />
        </GridCol>
      </GridCell>
    )
  }
}

TimeTable.propTypes = {
  appointments: PropTypes.object,
  doors: PropTypes.array,
  warehouse: PropTypes.number,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  startShift: PropTypes.object,
  endShift: PropTypes.object,
  appointmentStatuses: PropTypes.object,
  timezone: PropTypes.string,
  openDestinationDetailsModal: PropTypes.func,
  getAppointmentsForDoors: PropTypes.func,
  openDeleteAppointment: PropTypes.func,
  openEditAppointment: PropTypes.func,
  setSelectedOrders: PropTypes.func,
  changeTab: PropTypes.func,
  getDoorById: PropTypes.func,
  getAppointmentsByDoorAndDate: PropTypes.func,
  getInventoryCalculationSetting: PropTypes.func,
  updateAppointment: PropTypes.func,
  moveAppointment: PropTypes.func,
  idAppointmentLoading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  focusAppointment: PropTypes.object,
  appointmentTypes: PropTypes.number
}

const mapToStateProps = state => ({
  appointments: getAppointmentsForDoors(state),
  warehouse: selectCurrentBuildingId(state),
  timezone: selectCurrentBuildingTimezone(state),
  doors: selectDoorsForCurrentBuilding(state),
  appointmentStatuses: getAllAppointmentStatuses(state),
  startDate: selectStartDate(state),
  endDate: selectEndDate(state),
  startShift: selectStartShiift(state),
  endShift: selectEndShift(state),
  focusAppointment: selectFocusAppointment(state),
  getDoorById: createGetDoorById(state),
  getAppointmentsByDoorAndDate: createGetAppointmentsByDoorAndDate(state),
  getInventoryCalculationSetting: createGetInventoryCalculationSetting(state),
  idAppointmentLoading:
    getUpdateAppointmentIsLoading(state) || getOpenEditAppointmentIsLoading(state),
  appointmentTypes: selectAppointmentType(state)
})

const mapActionToDispatch = dispatch => ({
  getAppointmentsForDoors: payload => dispatch(DoorsActions.getAppointmentsForDoors(payload)),
  openDeleteAppointment: payload => dispatch(AppointmentsActions.openDeleteAppointment(payload)),
  openEditAppointment: payload => dispatch(AppointmentsActions.openEditAppointment(payload)),
  setSelectedOrders: orders => dispatch(OrdersActions.setSelectedOrders(orders)),
  changeTab: tab => dispatch(AppointmentsActions.changeEditAppointmentTab(tab)),
  updateAppointment: appointment => dispatch(AppointmentsActions.updateAppointment(appointment)),
  moveAppointment: appointment => dispatch(AppointmentsActions.moveAppointment(appointment)),
  openDestinationDetailsModal: appointment =>
    dispatch(UiActions.openDestinationDetailsModal(appointment))
})

export default connect(mapToStateProps, mapActionToDispatch)(TimeTable)
