import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React from 'react'

const withParams = NavigateWithParamsWrapper => props => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <NavigateWithParamsWrapper {...props} params={params} navigate={navigate} location={location}/>
  )
}

export default withParams
