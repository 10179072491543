import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { UsersTypes } from './actions'

// getUsers
export const getUsersLoading = state =>
  state.merge({
    getUsersIsLoading: true,
    getUsersErrorMessage: ''
  })

export const getUsersSuccess = (state, { users }) =>
  state.merge({
    users,
    getUsersIsLoading: false,
    getUsersErrorMessage: null
  })

export const getUsersFailure = (state, { errorMessage }) =>
  state.merge({
    users: null,
    getUsersIsLoading: false,
    getUsersErrorMessage: errorMessage
  })

// getUser
export const getUserLoading = state =>
  state.merge({
    getUserIsLoading: true,
    getUserErrorMessage: ''
  })

export const getUserSuccess = (state, { user }) =>
  state.merge({
    user,
    getUserIsLoading: false,
    getUserErrorMessage: null
  })

export const getUserFailure = (state, { errorMessage }) =>
  state.merge({
    user: null,
    getUserIsLoading: false,
    getUserErrorMessage: errorMessage
  })

// getAllowIssuesUser
export const getAllowIssuesUserLoading = state =>
  state.merge({
    getAllowIssuesUserIsLoading: true,
    getAllowIssuesUserErrorMessage: ''
  })

export const getAllowIssuesUserSuccess = (state, { allowIssuesUser }) =>
  state.merge({
    allowIssuesUser,
    getAllowIssuesUserIsLoading: false,
    getAllowIssuesUserErrorMessage: null
  })

export const getAllowIssuesUserFailure = (state, { errorMessage }) =>
  state.merge({
    allowIssuesUser: null,
    getAllowIssuesUserIsLoading: false,
    getAllowIssuesUserErrorMessage: errorMessage
  })

// getRoles
export const getRolesLoading = state =>
  state.merge({
    getRolesIsLoading: true,
    getRolesErrorMessage: ''
  })

export const getRolesSuccess = (state, { roles }) =>
  state.merge({
    roles,
    getRolesIsLoading: false,
    getRolesErrorMessage: null
  })

export const getRolesFailure = (state, { errorMessage }) =>
  state.merge({
    roles: null,
    getRolesIsLoading: false,
    getRolesErrorMessage: errorMessage
  })

// getAccounts
export const getAccountsLoading = state =>
  state.merge({
    getAccountsIsLoading: true,
    getAccountsErrorMessage: ''
  })

export const getAccountsSuccess = (state, { accounts }) =>
  state.merge({
    accounts,
    getAccountsIsLoading: false,
    getAccountsErrorMessage: null
  })

export const getAccountsFailure = (state, { errorMessage }) =>
  state.merge({
    accounts: null,
    getAccountsIsLoading: false,
    getAccountsErrorMessage: errorMessage
  })

// createUser
export const createUserLoading = state =>
  state.merge({
    createUserIsLoading: true,
    createUserErrorMessage: null
  })

export const createUserSuccess = state =>
  state.merge({
    createUserIsLoading: false,
    createUserErrorMessage: null
  })

export const createUserFailure = (state, { errorMessage }) =>
  state.merge({
    createUserIsLoading: false,
    createUserErrorMessage: errorMessage
  })

// updateUser
export const updateUserLoading = state =>
  state.merge({
    updateUserIsLoading: true,
    updateUserErrorMessage: null
  })

export const updateUserSuccess = (state, { user }) =>
  state.merge({
    updateUserIsLoading: false,
    updateUserErrorMessage: null,
    users: state.get('users').map(
      u => u.get('id') !== user.id ? u : u.merge({ ...user })
    )
  })

export const updateUserFailure = (state, { errorMessage }) =>
  state.merge({
    updateUserIsLoading: false,
    updateUserErrorMessage: errorMessage
  })

// login
export const loginLoading = state =>
  state.merge({
    loginIsLoading: true,
    loginErrorMessage: null
  })

export const loginSuccess = (state, { token, user }) =>
  state.merge({
    token,
    user,
    loginIsLoading: false,
    loginErrorMessage: null
  })

export const loginFailure = (state, { errorMessage }) =>
  state.merge({
    token: null,
    user: null,
    loginIsLoading: false,
    loginErrorMessage: errorMessage
  })

// logout
export const logoutLoading = state =>
  state.merge({
    logoutIsLoading: true,
    logoutErrorMessage: null
  })

export const logoutSuccess = state =>
  state.merge({
    token: null,
    user: null,
    logoutIsLoading: false,
    logoutErrorMessage: null
  })

export const logoutFailure = (state, { errorMessage }) =>
  state.merge({
    token: null,
    user: null,
    logoutIsLoading: false,
    logoutErrorMessage: errorMessage
  })

// changeUserActivation
export const changeUserActivationLoading = state =>
  state.merge({
    changeUserActivationIsLoading: true,
    changeUserActivationErrorMessage: ''
  })

export const changeUserActivationSuccess = (state, { user }) =>
  state.merge({
    users: state
      .get('users')
      .map(
        u => (u.get('id') !== user.id ? u : u.setIn(['active'], user.active))
      ),
    changeUserActivationIsLoading: false,
    changeUserActivationErrorMessage: null
  })

export const changeUserActivationFailure = (state, { errorMessage }) =>
  state.merge({
    changeUserActivationIsLoading: false,
    changeUserActivationErrorMessage: errorMessage
  })

// deleteUser
export const deleteUserLoading = state =>
  state.merge({
    deleteUserIsLoading: true,
    deleteUserErrorMessage: ''
  })

export const deleteUserSuccess = (state, { userId }) => {
  return state.merge({
    users: state.get('users').filter(u => u.get('id') !== userId),
    deleteUserIsLoading: false,
    deleteUserErrorMessage: null
  })
}

export const deleteUserFailure = (state, { errorMessage }) =>
  state.merge({
    deleteUserIsLoading: false,
    deleteUserErrorMessage: errorMessage
  })

export const showSystemSettings = state =>
  state.merge({
    isSystemSettingsOpen: true
  })

export const hideSystemSettings = state =>
  state.merge({
    isSystemSettingsOpen: false
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UsersTypes.GET_USERS_LOADING]: getUsersLoading,
  [UsersTypes.GET_USERS_SUCCESS]: getUsersSuccess,
  [UsersTypes.GET_USERS_FAILURE]: getUsersFailure,
  [UsersTypes.GET_ROLES_LOADING]: getRolesLoading,
  [UsersTypes.GET_ROLES_SUCCESS]: getRolesSuccess,
  [UsersTypes.GET_ROLES_FAILURE]: getRolesFailure,
  [UsersTypes.GET_ACCOUNTS_LOADING]: getAccountsLoading,
  [UsersTypes.GET_ACCOUNTS_SUCCESS]: getAccountsSuccess,
  [UsersTypes.GET_ACCOUNTS_FAILURE]: getAccountsFailure,
  [UsersTypes.CREATE_USER_LOADING]: createUserLoading,
  [UsersTypes.CREATE_USER_SUCCESS]: createUserSuccess,
  [UsersTypes.CREATE_USER_FAILURE]: createUserFailure,
  [UsersTypes.UPDATE_USER_LOADING]: updateUserLoading,
  [UsersTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UsersTypes.UPDATE_USER_FAILURE]: updateUserFailure,
  [UsersTypes.LOGIN_LOADING]: loginLoading,
  [UsersTypes.LOGIN_SUCCESS]: loginSuccess,
  [UsersTypes.LOGIN_FAILURE]: loginFailure,
  [UsersTypes.LOGOUT_LOADING]: logoutLoading,
  [UsersTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [UsersTypes.LOGOUT_FAILURE]: logoutFailure,
  [UsersTypes.CHANGE_USER_ACTIVATION_LOADING]: changeUserActivationLoading,
  [UsersTypes.CHANGE_USER_ACTIVATION_SUCCESS]: changeUserActivationSuccess,
  [UsersTypes.CHANGE_USER_ACTIVATION_FAILURE]: changeUserActivationFailure,
  [UsersTypes.DELETE_USER_LOADING]: deleteUserLoading,
  [UsersTypes.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [UsersTypes.DELETE_USER_FAILURE]: deleteUserFailure,
  [UsersTypes.SHOW_SYSTEM_SETTINGS]: showSystemSettings,
  [UsersTypes.HIDE_SYSTEM_SETTINGS]: hideSystemSettings,
  [UsersTypes.GET_ALLOW_ISSUES_USER_LOADING]: getAllowIssuesUserLoading,
  [UsersTypes.GET_ALLOW_ISSUES_USER_SUCCESS]: getAllowIssuesUserSuccess,
  [UsersTypes.GET_ALLOW_ISSUES_USER_FAILURE]: getAllowIssuesUserFailure
})
