/* eslint-disable no-useless-constructor */
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { Scrollable } from '../../styled/Containers'
import { createGetDoorById } from '../../doors/doors-slice'
import { getAllAppointmentStatuses } from '../../modules/appointments/selectors'
import { getAllCarrierRequests } from '../../modules/carrierRequests/selectors'
import AppointmentsActions from '../../modules/appointments/actions'
import CarrierButton from '../../components/CarrierButton'
import CarrierRequestsActions from '../../modules/carrierRequests/actions'
import CarrierRescheduleRequestModal from '../../components/CarrierRescheduleRequestModal'
import FeedsActions from '../../modules/feeds/actions'
import socket from '../../utils/socket'
import ReactSelect from 'react-select'
import OptionSelect from '../../components/OptionSelect'
import SelectValueContainer from '../../components/SelectValueContainer'
import withParams from '../../utils/WithParams'
import { getAllCarriers, selectAllCarriers } from '../../carriers/carriers-slice'
import { getAppointments } from '../../appointments/appointments-slice'

const MAX_COLUMN = 12
const FULL_WIDTH_PERCENTAGE = 100

const CANCELED_STATUS = { value: 'canceled', label: 'Canceled' }
const DRAFT_STATUS = { value: 'draft', label: 'Draft' }
const DELETED_STATUS = { value: 'deleted', label: 'Deleted' }
const PENDING_STATUS = { value: 'pending', label: 'Pending' }
const RESCHEDULED_STATUS = { value: 'reschedule', label: 'Reschedule' }
const SCHEDULED_STATUS = { value: 'scheduled', label: 'Scheduled' }

const PaddedScrollable = styled(Scrollable)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${props => props.theme.spacing(3)} 0;
  width: 60%;
  overflow: visible;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const Col = styled.div`
  width: ${props => FULL_WIDTH_PERCENTAGE / (MAX_COLUMN / props.md)}%;
  ${props => props.capitalize && css`
    text-transform: capitalize;
  `}
`

const SectionTitle = styled.div`
  color: ${props => props.theme.colors.primaryAccent}
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 25px;
  font-weight: bold;
`

const Section = styled.div`
  background: #fff;
  padding: 16px 16px 0;
  box-shadow: 0px 1px 3px -2px #000;
  margin-bottom: 10px;
`

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.default}
  margin-bottom: 15px;
`

const Label = styled.div`
  color: ${props => props.theme.labelColor};
  margin: 6px 0;
  font-size: 12px;
  ${props => props.bold ? 'font-weight: bold;' : ''}
  ${props => props.bordered ? `border-bottom: 1px solid ${props => props.theme.colors.separatorColor};` : ''}

`

const Value = styled.div`
  color: ${props => props.theme.colors.default};
  font-size: 16px;
  font-weight: 300;
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
`

const StyledGreenButton = styled(CarrierButton)`
  height: 36px;
  width: 155px;
  color: ${props => props.disabled ? props.theme.colors.default : '#fff'};
  border-color: ${props => props.disabled ? props.theme.colors.default : props.theme.colors.primaryAccent};
  background-color: ${props => props.disabled ? '#fff' : props.theme.colors.primaryAccent};
`

const StyledWhiteButton = styled(CarrierButton)`
  height: 36px;
  width: 155px;
  color: ${props => props.disabled ? props.theme.colors.default : props.theme.colors.primaryAccent};
  border-color: ${props => props.disabled ? props.theme.colors.default : props.theme.colors.primaryAccent};
`

const ApptNumber = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`

class AppointmentRequests extends Component {
  state = {
    isRescheduleRequestModalOpen: false,
    selectedStatus: [PENDING_STATUS, RESCHEDULED_STATUS, SCHEDULED_STATUS]
  }

  componentDidMount () {
    this._searchOrders()
    this.props.getAllAppointmentStatuses()
    // array too big
    // this.props.getAllLocations()
    this.props.getBootstrapData({ carrierPortal: true })
    this.props.getAllCarriers()

    socket.requests.off('carrierRequest')
    // keep listening for changes
    socket.requests.on('carrierRequest', socketCarrierRequest => {
      this.props.updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide({ socketCarrierRequest })
      this._searchOrders()
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.params && this.props.params.guid && this.props.params.guid !== prevProps.params.guid) {
      this.props.getAllCarrierRequests({ carrierPortal: true, sortByDate: 'desc', guid: this.props.params.guid })
    }
  }

  onCloseRescheduleRequestModal = () => {
    this.setState({
      isRescheduleRequestModalOpen: false
    })
  }

  onRequestModifications = carrierRequestId => {
    this.setState({
      isRescheduleRequestModalOpen: carrierRequestId
    })
  }

  onSelectRescheduleDate = (carrierRequestId, selectedTime) => {
    const { carrierRequests } = this.props
    const carrierRequest = carrierRequests &&
      carrierRequests.find(cr => cr.get('id') === carrierRequestId)

    if (carrierRequest && !carrierRequest.get('appointmentId')) {
      this.props.updateCarrierRequest({
        id: carrierRequestId,
        status: PENDING_STATUS.value,
        timeStart: moment(selectedTime),
        timeEnd: moment(selectedTime).add(1, 'hour')
      })
    } else {
      this.props.updateCarrierRequest({
        id: carrierRequestId,
        status: RESCHEDULED_STATUS.value,
        rescheduleTimeSuggestion: selectedTime
      })
    }
    this.onCloseRescheduleRequestModal()
  }

  onRequestCancellation = carrierRequestId => this.props.updateCarrierRequest({
    id: carrierRequestId,
    status: CANCELED_STATUS.value
  })

  onCancelRequest = carrierRequestId => this.props.updateCarrierRequest({
    id: carrierRequestId,
    status: CANCELED_STATUS.value
  })

  _renderRow = carrierRequest => {
    const { carriers, getDoorById } = this.props

    const door = carrierRequest && carrierRequest.get('appointment') && getDoorById(carrierRequest.get('appointment').get('doorId'))
    const timezone = door?.area?.building?.timezone ?? 'UTC'
    const carrier = carriers && carriers.find(carrier => carrier.id === parseInt(carrierRequest.get('carrierId')))

    let timeStart = 'N/A'
    let date = 'N/A'
    if (carrierRequest.get('status') === SCHEDULED_STATUS.value && carrierRequest.get('appointment')) {
      timeStart = moment.tz(carrierRequest.get('appointment').get('date'), timezone).format('HH:mm')
      date = moment.tz(carrierRequest.get('appointment').get('date'), timezone).format('ddd, MM/DD/YYYY')
    } else if (carrierRequest.get('status') === PENDING_STATUS.value && !carrierRequest.get('appointmentId')) {
      timeStart = moment.utc(carrierRequest.get('timeStart')).format('HH:mm')
      date = moment.utc(carrierRequest.get('date')).format('ddd, MM/DD/YYYY')
    } else if (carrierRequest.get('status') === RESCHEDULED_STATUS.value && carrierRequest.get('rescheduleTimeSuggestion')) {
      timeStart = moment.tz(carrierRequest.get('rescheduleTimeSuggestion'), timezone).format('HH:mm')
      date = moment.tz(carrierRequest.get('rescheduleTimeSuggestion'), timezone).format('ddd, MM/DD/YYYY')
    } else if (carrierRequest.get('status') === DELETED_STATUS.value && carrierRequest.get('deletedAt')) {
      timeStart = moment.tz(carrierRequest.get('deletedAt'), timezone).format('HH:mm')
      date = moment.tz(carrierRequest.get('deletedAt'), timezone).format('ddd, MM/DD/YYYY')
    }

    return (
      <Row>
        <Col md={4}><Value>{carrier ? carrier.name : 'N/A'}</Value></Col>
        <Col md={4}><Value>{date}</Value></Col>
        <Col md={4}><Value>{timeStart}</Value></Col>
        <Col md={4} capitalize><Value>{carrierRequest ? carrierRequest.get('status') : 'N/A'}</Value></Col>
      </Row>
    )
  }

  _renderRescheduleModal = () => {
    const { isRescheduleRequestModalOpen } = this.state
    const { carrierRequests, getDoorById } = this.props
    const selectedRequest = carrierRequests &&
      carrierRequests.find(cr => cr.get('id') === isRescheduleRequestModalOpen)
    const door = selectedRequest && selectedRequest.get('appointment') &&
      getDoorById(selectedRequest.get('appointment').get('doorId'))
    const timezone = door?.area?.building?.timezone ?? 'UTC'
    const selectedRequestsDate = selectedRequest && (selectedRequest.get('appointment') ? moment.tz(selectedRequest.get('appointment').get('date'), timezone) : moment.utc(selectedRequest.get('date')))

    return (
      selectedRequestsDate && <CarrierRescheduleRequestModal
        id={isRescheduleRequestModalOpen}
        isOpen={!!isRescheduleRequestModalOpen}
        onClose={this.onCloseRescheduleRequestModal}
        onSelectDate={this.onSelectRescheduleDate}
        date={selectedRequestsDate}
        timezone={timezone}/>
    )
  }

  /* A carrier can only request cancel/change if there is:
  (1) no appt associated with the CR,
  (2) the appt is in draft or scheduled status. */
  _isRescheduleDisabled = (carrierRequest, appointment, appointmentStatus) => {
    // invalid state
    if ((carrierRequest.get('status') === SCHEDULED_STATUS.value && !carrierRequest.get('appointment')) || (carrierRequest.get('status') === DELETED_STATUS.value)) return true

    const disabled = !!carrierRequest && carrierRequest.get('status') ? carrierRequest.get('status').toLowerCase() === RESCHEDULED_STATUS.value : false
    const apptStatus = appointmentStatus ? appointmentStatus.get('name').toLowerCase() : null
    return disabled && (!appointment || (appointment && (apptStatus === SCHEDULED_STATUS.value || apptStatus === DRAFT_STATUS.value)))
  }

  _isCancelDisabled = (carrierRequest, appointment, appointmentStatus) => {
    // invalid state
    if ((carrierRequest.get('status') === SCHEDULED_STATUS.value && !carrierRequest.get('appointment')) || (carrierRequest.get('status') === DELETED_STATUS.value)) return true

    const disabled = !!carrierRequest && carrierRequest.get('status') ? carrierRequest.get('status').toLowerCase() === CANCELED_STATUS.value : false
    const apptStatus = appointmentStatus ? appointmentStatus.get('name').toLowerCase() : null
    return disabled && (!appointment || (appointment && (apptStatus === SCHEDULED_STATUS.value || apptStatus === DRAFT_STATUS.value)))
  }

  _renderButtons = carrierRequest => {
    const { appointments, appointmentStatuses } = this.props
    const appointment = appointments && Object.values(appointments).find(appointment => appointment.id === parseInt(carrierRequest.get('appointmentId')))
    let requestedStatus = 'N/A'
    let selectedAppointmentStatus
    if (appointment) {
      selectedAppointmentStatus = appointmentStatuses ? appointmentStatuses.find(appointmentStatus => appointmentStatus.get('id') === parseInt(appointment.appointmentStatusId)) : null
      requestedStatus = selectedAppointmentStatus ? selectedAppointmentStatus.get('name') : 'N/A'
    } else {
      requestedStatus = carrierRequest ? carrierRequest.get('status') : null
    }
    const rescheduleDisabled = this._isRescheduleDisabled(carrierRequest, appointment, selectedAppointmentStatus)
    const cancelDisabled = this._isCancelDisabled(carrierRequest, appointment, selectedAppointmentStatus)

    if (appointment && (requestedStatus.toLowerCase() === SCHEDULED_STATUS.value || requestedStatus.toLowerCase() === DRAFT_STATUS.value)) {
      return (
        <ButtonsContainer>
          <StyledGreenButton disabled={rescheduleDisabled}
            onClick={() => this.onRequestModifications(carrierRequest.get('id'))}>Request
            Modifications</StyledGreenButton>
          <StyledWhiteButton disabled={cancelDisabled}
            onClick={() => this.onRequestCancellation(carrierRequest.get('id'))}>Request
            Cancellation</StyledWhiteButton>
        </ButtonsContainer>
      )
    }
    if (!appointment) {
      return (
        <ButtonsContainer>
          <StyledGreenButton disabled={rescheduleDisabled}
            onClick={() => this.onRequestModifications(carrierRequest.get('id'))}>Request
            Modifications</StyledGreenButton>
          <StyledWhiteButton disabled={cancelDisabled} onClick={() => this.onCancelRequest(carrierRequest.get('id'))}>Cancel
            Request</StyledWhiteButton>
        </ButtonsContainer>
      )
    }
  }

  _onChange (value) {
    this.setState({ selectedStatus: value })
  }

  _searchOrders () {
    const status = this.state.selectedStatus.map(v => v.value).join(',')
    this.props.getAllCarrierRequests({
      carrierPortal: true,
      sortByDate: 'desc',
      guid: this.props.params.guid,
      carrierRequestStatus: status
    })
  }

  render () {
    const { carrierRequests } = this.props
    const { selectedStatus } = this.state
    const statusOptions = [CANCELED_STATUS, DELETED_STATUS, PENDING_STATUS, RESCHEDULED_STATUS, SCHEDULED_STATUS]
    return (
      <PaddedScrollable>
        <HeaderRow>
          <Col md={4}>
            <SectionTitle>Appointment Requests</SectionTitle>
          </Col>
          <Col md={3}>
            <ReactSelect
              className={null}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isMulti
              components={{ Option: OptionSelect, ValueContainer: SelectValueContainer }}
              isClearable={false}
              value={selectedStatus}
              placeholder={'Filtered Status '}
              onChange={value => this._onChange(value)}
              options={statusOptions}
              onMenuClose={() => this._searchOrders()}
            />
          </Col>
        </HeaderRow>
        <br/>

        {!carrierRequests || !carrierRequests.size ? (
          <Row>
            <Col md={12}><Label bordered bold>You have no appointment request to show.</Label></Col>
          </Row>
        ) : null}

        {carrierRequests && carrierRequests.map((carrierRequest, index) => <Section key={index}>
          <Row>
            <Col md={12}><Label bordered bold>Appt Number: {
              carrierRequest.get('appointment')
                ? <ApptNumber>#{carrierRequest.get('appointment').get('id')}</ApptNumber>
                : 'None'
            }</Label></Col>
          </Row>
          <Row>
            <Col md={4}><Label>Carrier Name</Label></Col>
            <Col md={4}><Label>Pick Up Date</Label></Col>
            <Col md={4}><Label>Requested Time</Label></Col>
            <Col md={4}><Label>Requested Status</Label></Col>
          </Row>
          {this._renderRow(carrierRequest)}
          {this._renderButtons(carrierRequest)}
          <HorizontalLine/>
        </Section>)}
        {this._renderRescheduleModal()}
      </PaddedScrollable>
    )
  }
}

AppointmentRequests.propTypes = {
  carrierRequests: PropTypes.object,
  appointments: PropTypes.object,
  carriers: PropTypes.array,
  appointmentStatuses: PropTypes.object,
  params: PropTypes.object,

  getAllAppointmentStatuses: PropTypes.any,
  getAllCarrierRequests: PropTypes.func,
  getAllCarriers: PropTypes.func,
  updateCarrierRequest: PropTypes.func,
  updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide: PropTypes.func,
  getBootstrapData: PropTypes.func,
  getDoorById: PropTypes.func
}

const mapStateToProps = state => ({
  carrierRequests: getAllCarrierRequests(state),
  appointments: getAppointments(state),
  carriers: selectAllCarriers(state),
  appointmentStatuses: getAllAppointmentStatuses(state),
  getDoorById: createGetDoorById(state)
})

const mapDispatchToProps = dispatch => ({
  getAllCarrierRequests: payload => dispatch(CarrierRequestsActions.getAllCarrierRequests(payload)),
  updateCarrierRequest: payload => dispatch(CarrierRequestsActions.updateCarrierRequest(payload)),
  updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide: payload => dispatch(CarrierRequestsActions.updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide(payload)),
  getAllAppointmentStatuses: () => dispatch(AppointmentsActions.getAllAppointmentStatuses()),
  getAllCarriers: () => dispatch(getAllCarriers()),
  getBootstrapData: payload => dispatch(FeedsActions.getBootstrapData(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(withParams(AppointmentRequests))
