import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit'
import { carrierLoginFullfiled, LoginSuccessResult } from '../carrier-app/carrier-app-slice'
import { EntityState, RequestStatus } from '../common-types'
import config from '../config'
import { RootState } from '../root-types'
import axios from '../utils/axios'
import socket from '../utils/socket'

export interface Carrier {
  code: string
  id: number
  name: string
}

export interface CarriersState extends EntityState<Carrier> {}

const adapter = createEntityAdapter<Carrier>()

const initialState: { loading: RequestStatus; error: SerializedError | null } = {
  loading: RequestStatus.Idle,
  error: null
}

export const getAllCarriers = createAsyncThunk<Carrier[], number, { state: RootState }>(
  'carriers/getAllCarriers',
  async () => {
    const { data } = await axios.get('/open/carriers', {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    return data
  }
)

export const getAllCarriersFulfilled = createAction<Carrier[]>('carriers/getAllCarriers/fulfilled')

const slice = createSlice({
  name: 'carrier',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    carriersUpserted: (state, action: PayloadAction<Carrier[]>) => {
      if (action.payload) adapter.upsertMany(state, action.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllCarriers.pending, state => {
        if (state.loading === RequestStatus.Idle) {
          state.loading = RequestStatus.Pending
        }
      })
      .addCase(getAllCarriers.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload)
        state.loading = RequestStatus.Succeded
      })
      .addCase(getAllCarriers.rejected, (state, action) => {
        if (state.loading === RequestStatus.Pending) {
          state.loading = RequestStatus.Failed
          state.error = action.error
        }
      })
      .addCase(carrierLoginFullfiled, (state, action: PayloadAction<LoginSuccessResult>) => {
        adapter.upsertOne(state, action.payload.user.carrier)
      })
  }
})

export default slice.reducer

export const { carriersUpserted } = slice.actions

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.carrier
})

export const selectAllCarriers = globalizedSelectors.selectAll
export const selectCarrierById = globalizedSelectors.selectById

export const createGetCarrierById = createSelector(
  globalizedSelectors.selectEntities,
  entities => (id: number) => {
    if (!id) return null

    const carrier = entities[id]

    if (!carrier) return null

    return carrier
  }
)

export const initCarriersUpdateSocketListener = () => (dispatch: Dispatch) =>
  // TODO: socketCarrierRequestType
  socket.requests.on('carrierRequest', (socketCarrierRequest: any[]) => {
    const carriers = socketCarrierRequest
      .map(carrierRequest => carrierRequest.carrier)
      .filter(carrier => carrier != null)
    if (carriers.length > 0) dispatch(carriersUpserted(carriers))
  })
