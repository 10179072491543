import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { theme } from '../../themes/taylor-farms'

export default class RequestsIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 29 29"
        className={className}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill={color}
            d="M16.577 25.912l-.4.4-2.675-2.677a.558.558 0 0 0-.789.79l2.637 2.637a4.347 4.347 0 0 1-5.615-.447l-5.428-5.428-.005-.005-1.917-1.918a4.346 4.346 0 0 1 0-6.139l.703-.703a1.049 1.049 0 0 1 1.482 0l2.94 2.94a1.049 1.049 0 0 1 0 1.482l-.192.191c-.07.07-.133.146-.19.225l-2.322-2.322a.558.558 0 1 0-.79.79l2.788 2.787c.046.401.224.773.514 1.064l2.103 2.102a1.8 1.8 0 0 0 2.543 0l.191-.191a1.049 1.049 0 0 1 1.483 0l2.94 2.94c.197.198.306.46.306.74 0 .28-.109.544-.307.742zm-2.15-5.211a2.166 2.166 0 0 0-3.06 0l-.192.191a.683.683 0 0 1-.965 0L8.107 18.79a.678.678 0 0 1-.2-.482c0-.182.071-.354.2-.483l.192-.191a2.166 2.166 0 0 0 0-3.06l-2.94-2.94a2.166 2.166 0 0 0-3.06 0l-.703.703a5.463 5.463 0 0 0 0 7.718l1.25 1.25.005.006 6.095 6.094A5.439 5.439 0 0 0 12.804 29a5.44 5.44 0 0 0 3.859-1.596l.703-.703A2.15 2.15 0 0 0 18 25.17a2.15 2.15 0 0 0-.634-1.53l-2.94-2.94zM27.882 10.333c0 .04-.004.08-.013.118l-4.767-5.537L27.86 1.51c.014.05.022.102.022.156v8.666zm-12.07.556H12.225l4.586-5.327 2.362 1.694a.56.56 0 0 0 .653 0l2.366-1.691 4.583 5.324H15.812zm-4.681-.438a.552.552 0 0 1-.013-.118V1.667c0-.06.01-.117.027-.17l4.757 3.412-4.771 5.542zm15.374-9.34l-7.004 5.01-6.983-5.01h13.987zm1.796-.797L28.28.3a1.674 1.674 0 0 0-.957-.3H11.677a1.672 1.672 0 0 0-.967.306c-.43.302-.71.8-.71 1.36v8.667c0 .92.752 1.667 1.676 1.667h15.648c.924 0 1.676-.748 1.676-1.667V1.667c0-.557-.276-1.05-.7-1.353z"
          />
          <path
            fill="#FFF"
            d="M26.436 13a.554.554 0 0 0-.563.543v10.37h-6.31a.554.554 0 0 0-.563.544c0 .3.252.543.563.543h6.873a.58.58 0 0 0 .395-.156l.004-.003a.532.532 0 0 0 .165-.384V13.543c0-.3-.252-.543-.564-.543M3.639 10c.352 0 .638-.261.638-.583v-5.25h4.084c.353 0 .639-.262.639-.584C9 3.261 8.714 3 8.361 3H3.64C3.286 3 3 3.261 3 3.583l.002.032L3 3.647v5.77c0 .322.286.583.639.583"
          />
        </g>
      </svg>
    )
  }
}

RequestsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

RequestsIcon.defaultProps = {
  width: 29,
  height: 29,
  color: theme.colors.primary
}
