import { NotificationManager } from 'react-notifications'
import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import { token as getToken } from '../users/selectors'
import BuildingsActions, { BuildingsTypes } from './actions'
import axios from '../../utils/axios'

// Sagas
function * getSettingsForAllBuildings () {
  yield put(BuildingsActions.getSettingsForAllBuildingsLoading())

  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.get, '/buildings/settings', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(BuildingsActions.getSettingsForAllBuildingsSuccess(data))
  } catch (e) {
    yield put(BuildingsActions.getSettingsForAllBuildingsFailure(e))
  }
}

function * updateSettingsForBuilding ({ payload }) {
  yield put(BuildingsActions.updateSettingsForBuildingLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.put, `/buildings/${payload.id}/settings`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    NotificationManager.success('Building settings updated')
    yield put(BuildingsActions.updateSettingsForBuildingSuccess(data))
  } catch (e) {
    NotificationManager.error('Building settings could not be changed due to a server error. Try again later.')

    yield put(BuildingsActions.updateSettingsForBuildingFailure(e))
  }
}

// Watchers
function * getSettingsForAllBuildingsWatcher () {
  yield takeLatest(BuildingsTypes.GET_SETTINGS_FOR_ALL_BUILDINGS, getSettingsForAllBuildings)
}

function * updateSettingsForBuildingWatcher () {
  yield takeLatest(BuildingsTypes.UPDATE_SETTINGS_FOR_BUILDING, updateSettingsForBuilding)
}

export default function * root () {
  yield fork(getSettingsForAllBuildingsWatcher)
  yield fork(updateSettingsForBuildingWatcher)
}
