import { Map } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  inventoryItems: null,
  getInventoryItemsIsLoading: false,
  getInventoryItemsErrorMessage: null,
  inventoryItemsProjection: null,
  getInventoryItemsProjectionIsLoading: false,
  getInventoryItemsProjectionErrorMessage: null
})
