import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import axios from '../../utils/axios'
import EmailsActions, { EmailsTypes } from './actions'
import { token as getToken } from '../users/selectors'

// Sagas
function * sendEmail ({ payload }) {
  yield put(EmailsActions.sendEmailLoading())
  try {
    const token = yield select(getToken)
    yield call(axios.post, '/email', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(EmailsActions.sendEmailSuccess())
  } catch (e) {
    yield put(EmailsActions.sendEmailFailure(e))
  }
}

// Watchers
function * sendEmailWatcher () {
  yield takeLatest(EmailsTypes.SEND_EMAIL, sendEmail)
}

export default function * root () {
  yield fork(sendEmailWatcher)
}
