import {
  call,
  put,
  takeLatest,
  fork,
  select
} from 'redux-saga/effects'

import axios from '../../utils/axios'
import ReportsActions, {
  ReportsTypes
} from './actions'
import AppointmentActions from '../appointments/actions'

import {
  token as getToken
} from '../users/selectors'
import {
  selectCurrentBuildingId
} from '../../app/app-slice'
import { selectAllAreas } from '../../areas/areas-slice'
import { selectAllBuildings } from '../../buildings/buildings-slice'

const baseUrl = '/reports'

/* Sagas */

function * getGenericReport ({ payload }) {
  yield put(ReportsActions.getReportLoading())
  try {
    const token = yield select(getToken)
    const {
      data
    } = yield call(axios.get, `${baseUrl}/${payload.endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(ReportsActions.getReportSuccess(data))
  } catch (e) {
    yield put(ReportsActions.getReportFailure(e))
  }
}

function * updateWarnings () {
  try {
    const token = yield select(getToken)
    // let's update the bubbles count with the new info
    const warehouse = yield select(selectCurrentBuildingId)
    const areas = yield select(selectAllAreas)
    const buildings = yield select(selectAllBuildings)

    if (warehouse) {
      const building = buildings.find(building => building.id === warehouse)
      const area = areas.find(area => area.buildingId === building.id)
      const doors = area?.doors ?? []

      const r = yield call(axios.get, `/reports/warnings?timezone=${building?.timezone}&doors=${doors}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      yield put(AppointmentActions.mergeAppointmentCounts({
        appointmentYesterdayCount: r.data.appointmentYesterdayCount,
        appointmentTodayCount: r.data.appointmentTodayCount,
        appointmentNext1Count: r.data.appointmentNext1Count,
        appointmentNext2Count: r.data.appointmentNext2Count,
        appointmentNext3Count: r.data.appointmentNext3Count
      }))
    }
  } catch (e) {
    console.log('ERROR:', e)
  }
}

/* Watchers */

function * updateWarningsWatcher () {
  yield takeLatest(ReportsTypes.UPDATE_WARNINGS, updateWarnings)
}

function * getGenericReportWatcher () {
  yield takeLatest(ReportsTypes.GET_REPORT, getGenericReport)
}

export default function * root () {
  yield fork(updateWarningsWatcher)
  yield fork(getGenericReportWatcher)
}
