import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

const customStyles = (error, disableDropdownIndicator) => ({
  container: base => ({ ...base, border: 'none', boxShadow: 'none', outline: 'none' }),
  control: base => ({
    ...base,
    borderRadius: 2,
    borderColor: error ? '#d9534f' : '#ebebeb',
    boxShadow: 'none',
    outline: 'none'
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? '#d2f8d8' : null,
    color: isSelected || isFocused ? '#61c9b5' : null
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: base => disableDropdownIndicator ? ({ display: 'none' }) : ({ ...base, transform: 'scale(0.6)' })
})

class CarrierSelect extends Component {
  render () {
    const { options, value, disableEmptyOptions, disableDropdownIndicator, onInputChange, onChange, placeholder, className, error, styles, isClearable, isDisabled, isSearchable } = this.props
    return (
      <ReactSelect
        noOptionsMessage={() => disableEmptyOptions ? null : 'No options'}
        className={className}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={!!isClearable}
        value={options.find(option => option.value === value) || ''}
        styles={styles ? styles(error) : customStyles(error, disableDropdownIndicator)}
        onInputChange={value => onInputChange && onInputChange(value)}
        onChange={option => onChange && onChange(option ? option.value : null)}
        options={options}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
      />
    )
  }
}

CarrierSelect.propTypes = {
  disableDropdownIndicator: PropTypes.bool,
  disableEmptyOptions: PropTypes.bool,
  onInputChange: PropTypes.func,
  options: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.any,
  styles: PropTypes.any,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool
}

export default CarrierSelect
