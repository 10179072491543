import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { StyledRequestCard } from '../styled/Cards'
import { TYPES } from './TimeTable/DropOnTableHoc'

function DraggableRequestCard (props) {
  const [{ isDragging }, drag, preview] = useDrag({
    type: TYPES.REQUEST,
    item: { ...props },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <StyledRequestCard drag={drag} {...props} isDragging={isDragging} />
  )
}

DraggableRequestCard.propTypes = {}

export default DraggableRequestCard
