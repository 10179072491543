import { Form, Field } from 'react-final-form'
import React from 'react'
import PropTypes from 'prop-types'

import { ButtonPrimary } from '../../styled/Buttons'
import { GridRow, GridCell } from '../../styled/Grids'
import AccountSelect from '../AccountSelect'
import FormSelect from '../FormSelect'
import TextInput from '../TextInput'

const validate = (values, isEditUser) => {
  const errors = {}
  if (!values.roles) {
    errors.roles = 'Required'
  }
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!isEditUser && !values.password) {
    errors.password = 'Required'
  }

  if (values.password) {
    if (!values.password.match(/^.*[a-z].*$/)) {
      errors.password = 'must contain a lower case'
    } else if (!values.password.match(/^.*[A-Z].*$/)) {
      errors.password = 'must contain a upper case'
    } else if (!values.password.match(/^.*[!@#$%^&*()_+\-=[\]{};':"\\].*$/)) {
      errors.password = 'must contain a special character'
    } else if (values.password.length < 6) {
      errors.password = 'must be at least 6 chars long'
    }
  }

  return errors
}

const UpsertUserForm = ({
  subscription,
  onSubmit,
  roleOptions,
  initialValues,
  label,
  accountOptions
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    validate={values => validate(values, !!initialValues)}
    subscription={subscription}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <GridRow padded="bottom">
          <Field name="roles" component="select">
            {({ input, meta }) => (
              <FormSelect
                {...input}
                error={meta.touched && meta.error}
                options={roleOptions}
                placeholder="Role..."
              />
            )}
          </Field>
        </GridRow>
        <GridRow padded="bottom">
          <Field name="accounts" component="select">
            {({ input, meta }) => (
              <AccountSelect
                {...input}
                options={accountOptions}
                error={meta.touched && meta.error}
                placeholder="Accounts..."
              />
            )}
          </Field>
        </GridRow>
        <GridRow padded="bottom">
          <GridCell space="left">
            <Field name="firstName">
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  error={meta.touched && meta.error}
                  fullwidth="true"
                  label="First Name"
                  type="text"
                  placeholder="Derik"
                />
              )}
            </Field>
          </GridCell>
          <GridCell spaced="left">
            <Field name="lastName">
              {({ input, meta }) => (
                <TextInput
                  {...input}
                  error={meta.touched && meta.error}
                  fullwidth="true"
                  label="Last Name"
                  type="text"
                  placeholder="Kodama"
                />
              )}
            </Field>
          </GridCell>
        </GridRow>
        <GridRow padded="bottom">
          <Field name="email">
            {({ input, meta }) => (
              <TextInput
                {...input}
                error={meta.touched && meta.error}
                fullwidth="true"
                label="Email Address"
                type="text"
                placeholder="unknown@gmail.com"
              />
            )}
          </Field>
        </GridRow>
        <GridRow padded="bottom">
          <Field name="password">
            {({ input, meta }) => (
              <TextInput
                {...input}
                error={meta.touched && meta.error}
                fullwidth="true"
                label="Password"
                type="password"
                placeholder="Password"
              />
            )}
          </Field>
        </GridRow>
        <ButtonPrimary type="submit" disabled={submitting}>
          {label}
        </ButtonPrimary>
      </form>
    )} />
)

UpsertUserForm.propTypes = {
  subscription: PropTypes.any,
  onSubmit: PropTypes.func,
  roleOptions: PropTypes.any,
  initialValues: PropTypes.any,
  label: PropTypes.any,
  accountOptions: PropTypes.any
}

export default UpsertUserForm
