import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { useDrop } from 'react-dnd'

export const TYPES = {
  ORDER: 'ORDER',
  APPOINTAMENT_ORDER: 'APPOINTAMENT_ORDER',
  REQUEST: 'REQUEST',
  EVENT: 'EVENT'
}

const THRESHOLD_TOP = 300
const THRESHOLD_BOTTOM = 1
const THRESHOLD_LEFT = 600
const THRESHOLD_RIGHT = 1

const collect = (connect) => {
  return ({
    isOver: connect.isOver({ shallow: true }),
    canDrop: connect.canDrop()
  })
}

function tick (props, monitor) {
  const offset = monitor.getClientOffset()
  if (!props.scrollable || !offset) {
    return
  }

  const { current } = props.scrollable
  const rect = current.getBoundingClientRect()
  const x = offset.x
  const y = offset.y
  const edge = {
    top: Math.floor(y),
    bottom: Math.floor(rect.height - y),
    left: Math.floor(x),
    right: Math.floor(rect.width - x)
  }

  const ease = (scroll, distance, easing = 10) => {
    const t = easing / Math.max(distance, 1)// Prevent division by zero
    return scroll * Math.pow(t, 2)
  }

  if (edge.left < THRESHOLD_LEFT) {
    current.scrollLeft -= ease(rect.width, edge.left, THRESHOLD_LEFT / 10)
  }
  if (edge.right < THRESHOLD_RIGHT) {
    current.scrollLeft += ease(rect.width, edge.right, THRESHOLD_RIGHT / 10)
  }
  if (edge.top < THRESHOLD_TOP) {
    current.scrollTop -= ease(rect.height, edge.top, THRESHOLD_TOP / 10)
  }
  if (edge.bottom < THRESHOLD_BOTTOM) {
    current.scrollTop += ease(rect.height, edge.bottom, THRESHOLD_BOTTOM / 10)
  }
}

const throttledTick = throttle(tick, 40, {
  leading: true,
  trailing: true
})

export default Component => {
  const EnhancedComponent = (props) => {
    const spec = useMemo(() => ({
      accept: Object.values(TYPES),
      canDrop: (_, monitor) => {
        const cardProps = monitor.getItem()
        const { canDrop } = props
        if (canDrop) return canDrop(cardProps)

        return true
      },
      hover: (props, monitor, component) => {
        if (props && monitor) {
          throttledTick(props, monitor, component)
        }
      },
      drop: (_, monitor) => {
        const cardProps = monitor.getItem()
        const { door, hour } = props
        const { onDropOnTable } = cardProps
        return onDropOnTable && onDropOnTable({ props: cardProps, door, hour })
      },
      collect
    }), [props])
    const [collectedProps, drop] = useDrop(() => spec, [props])

    return <Component {...props} {...collectedProps} drop={drop} />
  }

  EnhancedComponent.propTypes = {
    door: PropTypes.any,
    hour: PropTypes.any,
    canDrop: PropTypes.func
  }

  return EnhancedComponent
}
