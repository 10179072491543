import styled, { css } from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

import {
  Tab as UnstyledTab,
  Tabs as UnstyledTabs,
  TabPanel as UnstyledTabPanel,
  TabList as UnstyledTabList
} from 'react-tabs'

import { flex, flexGrow, flexAlignCenter } from './Utils'
import { _getContainerPadding, _getContainerSpacing } from './Containers'
import { ContrastText } from './Texts'
import { NavLink as BaseNavLink } from 'react-router-dom'

export const BottomBar = styled.div`
  width: 95px;
  background: ${props => props.theme.colors.primaryAccent};
  height: 1px;
  border-radius: 4px;
  border: solid 2px ${props => props.theme.colors.primaryAccent};
  position: absolute;
  bottom: 12px;
  transition: 0.5s ease-in-out left;
  left: ${props => {
  if (!props.alignComponent.current) {
    return '-9999px'
  }
  // we calculate this on the fly to set the correct initial position for the bar
  const val = props.alignComponent.current.node.offsetLeft
  // 105 size with padding for the tab, 38 margin right
  return val + ((105 + 38) * props.selected)
}}px;
`

const Tabs = styled(UnstyledTabs)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 55px);
  ${props => props.row ? 'flex-direction: row;' : ''}
`
const Tab = styled(UnstyledTab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
  outline: none;
  list-style: none;
  width: 95px;
  margin-right: 3rem;
  padding: 10px 5px;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  & svg {
    height: 30px;
    margin-bottom: 5px;
  }

  ${ContrastText} {
    margin: 0;
  }

  &.selected {
    background: ${props => props.theme.colors.primary};
  }
`

const NavLink = React.forwardRef(
  ({
    activeClassName,
    activeStyle,
    ...props
  }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) =>
          [
            props.className,
            isActive ? activeClassName : null
          ]
            .filter(Boolean)
            .join(' ')
        }
        style={({ isActive }) => ({
          ...props.style,
          ...(isActive ? activeStyle : null)
        })}
      />
    )
  }
)

NavLink.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  activeStyle: PropTypes.string,
  style: PropTypes.string
}

const TabLink = styled(NavLink).attrs({
  activeClassName: 'selected'
})`
  outline: none;
  list-style: none;
  width: 95px;
  margin-right: 3rem;
  padding: 10px 5px;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  & svg {
    height: 30px;
    margin-bottom: 5px;
  }

  ${ContrastText} {
    margin: 0;
  }

  &.selected {
    background: ${props => props.theme.colors.primary};
  }
`
const TabList = styled(UnstyledTabList)`
  position: relative;
  ${flexAlignCenter}
  ${flexGrow}
  ${flex}
  ${props => css`${_getContainerPadding(props)}`}
  ${props => css`${_getContainerSpacing(props)}`}
  ${props => props.vertical ? 'flex-direction: column;' : ''}
  margin: 0;

  ${props => props.vertical ? css`
    padding: 0;
    height: 100%;
    justify-content: space-evenly;
    ${TabLink} {
      margin: 0;
    }
  ` : ''}
`
const TabPanel = styled(({ fullWidth, ...rest }) => <UnstyledTabPanel {...rest} />).attrs({
  selectedClassName: 'selected'
})`
  width: ${props => props.fullWidth ? '100%' : '240px'};
  min-width: 240px;
  max-width: ${props => props.fullWidth ? '100%' : '440px'};
  display: none;
  overflow: hidden;
  &.selected {
    display: flex;
  }
`

const TabContainer = styled.div`
  position: relative;
`

const TabContent = styled.div`
  background-color: ${props => props.theme.contrastTextColor};
  ${props => props.keepHeight
    ? css`
      visibility: ${props => props.visible ? 'visible' : 'hidden'};
    `
    : css`
      display: ${props => props.visible ? 'inherit' : 'none'};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `}
`

Tab.tabsRole = 'Tab'
Tabs.tabsRole = 'Tabs'
TabPanel.tabsRole = 'TabPanel'
TabList.tabsRole = 'TabList'

export { Tab, TabList, Tabs, TabPanel, TabLink, TabContainer, TabContent }
