import styled from 'styled-components'
import Tag from '../components/Tag'

export const StyledTag = styled(Tag)`
  background: #202740;
  border-radius: 0.25rem;
  color: #fff;
  padding: 0.15rem 1rem;
  font-weight: bold;
  letter-spacing: 1.07px;
  margin: 0 0 0 0.35rem;
  white-space: nowrap;
  
  .close {
    transform: scale(0.5);
    margin-right: -12px;
  }
`

export const RoleTags = styled.div`
  display: flex;
  align-items: center
  margin: 0px 5px;
`
