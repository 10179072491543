import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

import { _flex, input } from './Utils'
import Select from '../components/Select'

export const DefaultSelect = styled(Select)`
  width: 100%;

  & .sdselect__control {
    ${input}
    border: 1px solid ${props => props.theme.colors.secondary};
    border-radius: 2px;
    background-color: ${props => props.theme.contrastTextColor};
    padding: 2px 4px;
    box-shadow: none !important;
    min-width: 4.5rem;

    &:hover {
      border: 1px solid ${props => props.theme.colors.secondary};
      ${props => props.leftSeparator ? `
        border-left: 1px solid ${props => props.theme.separatorColor};
      ` : ''}
    }

    ${props => props.leftSeparator ? `
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid ${props => props.theme.separatorColor};
    ` : ''}
  }

  & .sdselect__menu {
    margin-top: -0.5rem;
    z-index: 4000 !important;
  }

  & .sdselect__value-container  {
    padding: 0;
    outline: 0;
  }

  & .sdselect__indicator {
    ${props => props.disableDropdownIndicator ? 'display: none;' : ''}
    padding: 4px;
    color: ${props => props.theme.inputColor};
  }

  & .sdselect__indicator-separator {
    display: none;
  }

  & .sdselect__single-value {
    color: ${props => props.theme.selectTextColor};
  }
`

export const MaterialSelect = styled(Select)`
  width: 100%;

  &:focus {
    outline: none;
  }

  & .sdselect__control {
    ${input}
    padding-left: 0;
    border-width: 0px 0px 1px 0px;
    border-color: ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.selectTextColor};
    background-color: ${props => props.theme.contrastTextColor};

    &:hover {
      border-bottom: 1px solid ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.selectTextColor};
    }
  }

  & .sdselect__control--is-focused {
    outline: none;
    box-shadow: none;
  }

  & .sdselect__value-container  {
    outline: 0;
    padding: 0;
  }

  & .sdselect__indicator {
    ${props => props.disableDropdownIndicator ? 'display: none;' : ''}
    padding: 0;
    color: ${props => props.isDisabled ? props.theme.colors.disabled : props.theme.selectTextColor};
  }

  & .sdselect__indicator-separator {
    display: none;
  }

  & .sdselect__single-value {
    color: ${props => props.isDisabled ? props.theme.default : props.theme.selectTextColor};
  }
`

export const MaterialCreatableSelect = styled(CreatableSelect)`
  ${_flex}
  flex-direction: row;

  svg {
    margin-right: -0.25rem;
  }

  div {
    color: ${props => props.theme.selectTextColor};
  }

  div[class*="control"] {
    border-color: ${props => props.theme.selectTextColor};

    div:first-child {
      display: grid;

      div:first-child {
        margin-top: 4px;
      }
    }
  }
`
