import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Button, ButtonIcon, ButtonPrimary } from '../../styled/Buttons'
import { CalendarIcon, InfoIcon, IssueIcon, ScheduleIcon } from '../icons'
import { DefaultParagraph, NestedParagraph, PrimaryText, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent,
  StyledModalFooter
} from '../../styled/Modals'
import { theme } from '../../themes/taylor-farms'
import CloseButton from '../CloseButton'
import OrdersActions from '../../modules/orders/actions'

const DateIssue = () => (
  <DefaultParagraph>
    <ButtonIcon>
      <CalendarIcon color={theme.colors.danger} />
    </ButtonIcon>
    The scheduled date is in the past.
  </DefaultParagraph>
)

const LowInventoryIssue = () => (
  <DefaultParagraph>
    <ButtonIcon>
      <IssueIcon color={theme.colors.danger} />
    </ButtonIcon>
    There is an inventory shortage for this appointment.
  </DefaultParagraph>
)

const LateShippingIssue = ({ hasLateShippingOrders }) =>
  hasLateShippingOrders && hasLateShippingOrders.length > 0 ? (
    <DefaultParagraph>
      <ButtonIcon>
        <IssueIcon color={theme.colors.danger} />
      </ButtonIcon>
      {`
        The Appointment Date is after the ship date for the order${
          hasLateShippingOrders.length === 1 ? '' : '(s)'
        }: ${hasLateShippingOrders.join(', ')}
      `}
    </DefaultParagraph>
  ) : null

const ConflictingInventoryIssue = ({ conflictingInventory, onOpenOrderDetailsModal }) =>
  conflictingInventory && conflictingInventory.length > 0 ? (
    <NestedParagraph>
      <ButtonIcon>
        <ScheduleIcon color={theme.colors.danger} />
      </ButtonIcon>
      {`
        Scheduling will create future shortages on the following (${
          conflictingInventory.length
        }) SKU${conflictingInventory.length === 1 ? '' : 's'} (in the next 16 hours):
      `}
      {conflictingInventory.map(conflict => {
        return (
          <ul key={`${conflict.item.sku}`}>
            <PrimaryText>{`${conflict.item.name} (${conflict.item.sku}):`}</PrimaryText>
            {conflict.conflictingAppointments.map((conflictingAppointment, idx) => (
              <li key={`${idx}${conflictingAppointment.appointment.id}`}>
                {`Door "${conflictingAppointment.door.name}
                " at ${conflictingAppointment.appointment.date}`}
                <ButtonIcon
                  spaced
                  title='Order details'
                  onClick={() => {
                    onOpenOrderDetailsModal(
                      conflictingAppointment.appointment,
                      conflictingAppointment.order
                    )
                  }}
                >
                  <InfoIcon color={theme.colors.secondary} height={18} width={18} />
                </ButtonIcon>
              </li>
            ))}
          </ul>
        )
      })}
    </NestedParagraph>
  ) : null

ConflictingInventoryIssue.propTypes = {
  conflictingInventory: PropTypes.any,
  onOpenOrderDetailsModal: PropTypes.func
}

LateShippingIssue.propTypes = {
  hasLateShippingOrders: PropTypes.any
}

class AllowIssuesModal extends Component {
  onOpenOrderDetailsModal = (appointment, order) => {
    const { setOrderDetails, openOrderDetailsModal } = this.props

    const orderDetails = {
      order
    }

    setOrderDetails(orderDetails)
    openOrderDetailsModal()
  }

  render () {
    const {
      isSubmitting,
      isInventoryCalculationEnabled = true,
      hasLowInventory = false,
      hasConflictingInventory = false,
      hasLateShippingOrders = false,
      hasPastDate = false,
      onCancel,
      onSave
    } = this.props

    return (
      <StyledModal
        isOpen={
          isSubmitting &&
          ((isInventoryCalculationEnabled &&
            (hasLowInventory || (hasConflictingInventory && hasConflictingInventory.length > 0))) ||
            hasPastDate ||
            (hasLateShippingOrders && hasLateShippingOrders.length > 0))
        }
        size='small'
      >
        <StyledModalAlertHeader>
          <Title>Alerts</Title>
          <CloseButton onClick={onCancel} />
        </StyledModalAlertHeader>

        <StyledModalContent scrollable size='small'>
          {hasPastDate ? <DateIssue /> : null}
          {isInventoryCalculationEnabled && hasLowInventory ? <LowInventoryIssue /> : null}
          {isInventoryCalculationEnabled && hasConflictingInventory ? (
            <ConflictingInventoryIssue
              conflictingInventory={hasConflictingInventory}
              onOpenOrderDetailsModal={this.onOpenOrderDetailsModal}
            />
          ) : null}
          {hasLateShippingOrders && hasLateShippingOrders.length > 0 ? (
            <LateShippingIssue hasLateShippingOrders={hasLateShippingOrders} />
          ) : null}

          <DefaultParagraph>Would you like to proceed regardless?</DefaultParagraph>
        </StyledModalContent>

        <StyledModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <ButtonPrimary onClick={onSave}>Yes</ButtonPrimary>
        </StyledModalFooter>
      </StyledModal>
    )
  }
}

AllowIssuesModal.propTypes = {
  isSubmitting: PropTypes.bool,
  isInventoryCalculationEnabled: PropTypes.bool,
  hasLowInventory: PropTypes.bool,
  hasConflictingInventory: PropTypes.any,
  hasLateShippingOrders: PropTypes.any,
  hasPastDate: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  setOrderDetails: PropTypes.func,
  openOrderDetailsModal: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  openOrderDetailsModal: () => dispatch(OrdersActions.openOrderDetailsModal()),
  setSelectedOrders: orders => dispatch(OrdersActions.setSelectedOrders(orders)),
  setOrderDetails: orderDetails => dispatch(OrdersActions.setOrderDetails(orderDetails))
})
export default connect(null, mapDispatchToProps)(AllowIssuesModal)
