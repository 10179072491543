import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { theme } from '../../themes/taylor-farms'

export default class OrdersIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        wwidth={width}
        height={height}
        className={className}
        viewBox="0 0 28 30"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M14.272 27.494V13.62l5.3-2.573v3.629c0 .41.336.745.746.745s.745-.335.745-.745V10.32l4.536-2.2v13.875l-11.327 5.5zM1.492 8.146l11.29 5.474v13.874L1.491 22.02V8.146zm12.034 4.175L2.442 6.947 7.736 4.38 18.82 9.755l-5.294 2.566zm0-10.75l11.085 5.376-4.082 1.982L9.444 3.554l4.082-1.982zm13.564 5.37a.77.77 0 0 0-.074-.33.718.718 0 0 0-.504-.398L13.856.075a.75.75 0 0 0-.653 0L.423 6.269A.749.749 0 0 0 0 6.915v15.578a.74.74 0 0 0 .423.67l12.78 6.195c.007 0 .007 0 .013.006.019.007.037.013.056.025.006 0 .012.006.025.006l.056.019c.006 0 .012.006.018.006.019.006.044.006.062.013h.019c.025 0 .056.006.08.006.026 0 .057 0 .082-.006h.018a.224.224 0 0 0 .062-.013c.007 0 .013-.006.02-.006l.055-.019c.006 0 .012-.006.025-.006a.214.214 0 0 0 .056-.025c.006 0 .006 0 .012-.006l12.818-6.213a.752.752 0 0 0 .423-.671V6.959c-.013-.006-.013-.012-.013-.019z"
          />
          <path
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
            d="M9 4l11 6v5.004"
          />
        </g>
      </svg>

    )
  }
}

OrdersIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

OrdersIcon.defaultProps = {
  width: 28,
  height: 30,
  color: theme.colors.primary
}
