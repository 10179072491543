import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TruckIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={height}
        width={width}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z"
        />
      </svg>
    )
  }
}

TruckIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

TruckIcon.defaultProps = {
  width: 17,
  height: 18,
  color: '#ffffff'
}
