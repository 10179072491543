import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getAllCustomers
  getAllCustomers: ['payload'],
  // The operation has started and is loading
  getAllCustomersLoading: null,
  // The customers was successfully fetched
  getAllCustomersSuccess: ['customers'],
  // An error occurred
  getAllCustomersFailure: ['errorMessage']
})

export const CustomersTypes = Types
export default Creators
