import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { CustomersTypes } from './actions'

// getAllCustomers
export const getAllCustomersLoading = state =>
  state.merge({
    getAllCustomersIsLoading: true,
    getAllCustomersErrorMessage: ''
  })

export const getAllCustomersSuccess = (state, { customers }) =>
  state.merge({
    customers,
    getAllCustomersIsLoading: false,
    getAllCustomersErrorMessage: null
  })

export const getAllCustomersFailure = (state, { errorMessage }) =>
  state.merge({
    customers: null,
    getAllCustomersIsLoading: false,
    getAllCustomersErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CustomersTypes.GET_ALL_CUSTOMERS_LOADING]: getAllCustomersLoading,
  [CustomersTypes.GET_ALL_CUSTOMERS_SUCCESS]: getAllCustomersSuccess,
  [CustomersTypes.GET_ALL_CUSTOMERS_FAILURE]: getAllCustomersFailure
})
