import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { FeedsTypes } from './actions'

export const getBootstrapDataLoading = state =>
  state.merge({
    getBootstrapDataIsLoading: true,
    getBootstrapDataErrorMessage: ''
  })

export const getBootstrapDataSuccess = (state, { data }) =>
  state.merge({
    getBootstrapDataIsLoading: false,
    getBootstrapDataErrorMessage: null
  })

export const getBootstrapDataFailure = (state, { errorMessage }) =>
  state.merge({
    getBootstrapDataIsLoading: false,
    getBootstrapDataErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [FeedsTypes.GET_BOOTSTRAP_DATA_LOADING]: getBootstrapDataLoading,
  [FeedsTypes.GET_BOOTSTRAP_DATA_SUCCESS]: getBootstrapDataSuccess,
  [FeedsTypes.GET_BOOTSTRAP_DATA_FAILURE]: getBootstrapDataFailure
})
