import Card from '@mui/material/Card'
import styled, { css } from 'styled-components'

import { DefaultText, Field, Label } from './Texts'
import { HorizontalLine } from './Separators'
import {
  _fill,
  _flex,
  _flexAlignCenter,
  fill,
  flex,
  flexAlignCenter,
  flexGrow
} from './Utils'
import { Link } from 'react-router-dom'

export const _getContainerPadding = props => {
  if (props.padded === true || props.padded === 'true') {
    return css`
      padding: ${props.theme.spacing(2)} ${props.theme.spacing(3)};
    `
  }
  const paddedMap = {
    small: `padding: ${props.theme.spacing(2)};`,
    vertical: `padding: ${props.theme.spacing(2)} 0;`,
    horizontal: `padding: 0 ${props.theme.spacing(3)};`,
    topLeft: `padding: ${props.theme.spacing(2)} 0 0 ${
      props.theme.spacing(3)
    };`,
    top: `padding-top: ${props.theme.spacing(2)};`,
    bottom: `padding-bottom: ${props.theme.spacing(2)};`,
    left: `padding-left: ${props.theme.spacing(3)};`,
    smallLeft: `padding-left: ${props.theme.spacing(2)};`,
    right: `padding-right: ${props.theme.spacing(3)};`
  }
  return paddedMap[props.padded] || ''
}

export const _getContainerSpacing = props => {
  if (props.spaced === true || props.spaced === 'true') {
    return css`
      margin: ${props.theme.spacing(1)};
    `
  }
  const spacedMap = {
    vertical: `margin: ${props.theme.spacing(1)} 0;`,
    horizontal: `margin: 0 ${props.theme.spacing(1)};`,
    top: `margin-top: ${props.theme.spacing(1)};`,
    bottom: `margin-bottom: ${props.theme.spacing(1)};`,
    left: `margin-left: ${props.theme.spacing(1)};`,
    right: `margin-right: ${props.theme.spacing(1)};`
  }
  return spacedMap[props.spaced] || ''
}

export const Container = styled.div`
  ${flexAlignCenter}
  ${flex}
  ${flexGrow}
  ${fill}
  ${props =>
    props.leftside
      ? css`
          margin-left: 250px;
        `
      : css`
          margin: 0;
        `}
  ${props =>
    css`
      ${_getContainerPadding(props)}
    `}
  ${props =>
    css`
      ${_getContainerSpacing(props)}
    `}
  ${props => (props.block ? 'width: 100%;' : '')}
  ${props => (props.alignRight ? 'text-align: right;' : '')}
  position: relative;
  outline: none;
  font-size: ${props => props.theme.fonts.defaultFontSize};
  ${props =>
    props.page
      ? css`
          height: 100%;
        `
      : ''}
  ${props =>
    props.height
      ? css`
          height: ${props => props.height}px;
        `
      : ''}
  ${props =>
    props.maxHeight
      ? css`
          max-height: ${props => props.maxHeight}px;
        `
      : ''}
  ${props => {
    if (props.width && props.width.indexOf && props.width.indexOf('%') > 0) {
      return props.width
      ? css`
          width: ${props => props.width};
        `
      : ''
    }

    return props.width
      ? css`
          width: ${props => props.width}px;
        `
      : ''
    }
    }
  ${props =>
    props.opaque
      ? css`
          background-color: ${props.theme.colors.contrast};
        `
      : ''}
  ${props =>
    props.isDisabled
      ? css`
          opacity: 0.5;
        `
      : ''}
  ${props =>
    props.separator
      ? css`border-bottom: 1px solid #aab0c0;`
      : ''}
`

export const PrimaryContainer = styled(Container)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.contrastTextColor};
`

export const SecondaryContainer = styled(Container)`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.contrastTextColor};
`

export const SecondaryBorderedContainer = styled(Container)`
  border: 1px solid ${props => props.theme.colors.secondary};
  background: ${props => props.theme.contrastTextColor};
  font-size: 0.75rem;
  align-items: center;
  padding: 2px 0px 2px 0px;

  ${Label} {
    color: ${props => props.theme.colors.secondary};
    text-align: left;
    font-size: 0.75rem;
    flex-grow: 2;
    padding: 10px 0px 10px 0px;
    font-weight: normal;

    &.orderSequence {
      margin-left: 10px;
      min-width: 20px;
      flex-grow: 1;
    }

    &.requiredShipDate {
      color: #f44336;
      font-weight: bold;
      min-width: 40px;
      margin-left: 2px;
    }
  }
`

export const TernaryContainer = styled(Container)`
  background-color: ${props => props.theme.colors.ternary};
  color: ${props => props.theme.contrastTextColor};
  border: solid 1px ${props => props.theme.colors.secondary};
  ${props => props.isDisabled ? 'opacity: 0.5' : ''};

  ${DefaultText}, ${Field} {
    color: ${props => props.theme.colors.secondary};
  }

  > ${Container}:first-child {
    border-bottom: solid 1px ${props => props.theme.colors.secondary};

    ${Label} {
      color: ${props => props.theme.colors.secondary};
      font-size: 0.75rem;
      font-weight: normal;
    }
  }
`

export const SideContainer = styled(Container)`
  background-color: rgba(60, 65, 78, 0.04);
  width: 250px;
  padding: 0;
  margin: -${props => props.theme.spacing(2)};

  ${HorizontalLine} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`

export const ExpandableContainer = styled.div`
  width: ${props =>
    props.isExpanded || (!props.horizontal && props.vertical) ? props.width || '240px' : '0px'};
  height: ${props =>
    props.isExpanded || (props.horizontal && !props.vertical) ? props.height || '480px' : '0px'};
  overflow: ${props => props.isExpanded ? 'visible' : 'hidden'};
  transition: 0.3s ease-in-out all;
`

export const ContainerOverlay = styled.div`
  ${_flexAlignCenter}
  color: ${props => props.theme.contrastTextColor};
  background: rgba(0,0,0,0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
`

export const CardContainer = styled(Card)`
  margin-bottom: 0.5rem;
  overflow: auto !important;
`

export const PageContainer = styled(Container)`
  ${_flex}
  ${_fill}
  flex-direction: column;
  overflow: hidden;
`

export const AppContainer = styled(PageContainer)`
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
`

export const BackgroundPageContainer = styled(AppContainer)`
  background: url(${props => props.imageSrc});
  background-size: cover;
`

export const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.noFlex ? '' : 'flex: 1;'}
  overflow-x: hidden;
  overflow-y: auto;
  ${props => props.page ? 'height: 100%;' : ''}
`

export const DroppableContainer = styled.div`
  height: 75%;
  max-height  320px;
  overflow-x: auto;
  padding: 1.5rem 1rem;
`

export const OrderCardContainer = styled(TernaryContainer)`
  cursor: ${props => (props.isDisabled) ? 'not-allowed' : 'grab'};
  background-color: ${props =>
    props.theme.orderStatuses[props.status]
      ? props.theme.orderStatuses[props.status].background
      : '#fff'};
  ${DefaultText}, ${Field} {
    cursor: inherit;
    color: ${props =>
    props.theme.orderStatuses[props.status]
      ? props.theme.orderStatuses[props.status].text
      : '#000'};
  }
  > ${Container}:first-child {
    ${Label} {
      cursor: inherit;
      font-size: 0.75rem;
      font-weight: normal;
      color: ${props => props.theme.orderStatuses[props.status].text};
      padding: 14px 10px 14px 10px;
    }
  }
`

export const ListContainer = styled.ul`
  width: 15vw;
  padding: 16px 0;
  margin: 0;
  list-style: none;
  padding-right: 24px;
  margin-right: 24px;
  border-right: 1px solid ${props => props.theme.timeTable.lines};
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ClickableListItem = styled(Link)`
  display: flex;
  margin-bottom: 4px;
  padding: 8px 16px;
  background: ${props => props.theme.timeTable.lines};
  color: ${props => props.theme.colors.secondary};
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  white-space: pre-wrap;
  text-decoration: none;

  &.active {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.contrast};
  }

  &:hover {
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.contrast};
  }
`
