import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TableElement = styled.table`
  border-collapse: collapse;
  position: relative;
`
const Tr = styled.tr`
  background-color: ${props =>
    props.issue ? props.theme.colors.issues : props.theme.colors.contrast};
`
const Th = styled.th`
  text-align: left;
  font-weight: 300;
  padding: 10px 24px;
  border-bottom: 1px solid ${props => props.theme.flatBackground};
  white-space: nowrap;
  position: sticky;
  top: 0;
`
const Td = styled.td`
  padding: 10px 24px;
  border-bottom: 1px solid ${props => props.theme.flatBackground};
`

class ControlledTable extends Component {
  render () {
    const { data, children, className, hideIssues } = this.props
    const columns = React.Children.map(children, ({ props }) => props)

    return (
      <TableElement className={className}>
        <thead>
          <Tr>
            {columns.map((column, i) => (
              <Th title={column.title} key={i}>{column.heading}</Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {data
            ? data.map((item, i) => {
              return (
                <Tr key={i} issue={!hideIssues && ((item.get && item.get('issue')) || item.issue)}>
                  {columns.map((column, j) => (
                    <Td key={j}>{column.content && column.content(item)}</Td>
                  ))}
                </Tr>
              )
            })
            : ''}
        </tbody>
      </TableElement>
    )
  }
}

ControlledTable.propTypes = {
  data: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  hideIssues: PropTypes.bool
}

export const ControlledColumn = () => null

export default ControlledTable
