import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
import axios from '../../utils/axios'
import ItemsActions, { ItemsTypes } from './actions'
import { token as getToken } from '../users/selectors'

const baseUrl = '/items'

// Sagas
function * getItems ({ payload }) {
  yield put(ItemsActions.getItemsLoading())
  try {
    const token = yield select(getToken)
    const { ids } = payload
    let url = baseUrl

    if (ids && !!ids.length) {
      url = ids.reduce((url, id) => url + `&id=${id}`, baseUrl)
    }

    const { data } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(ItemsActions.getItemsSuccess(data))
  } catch (e) {
    yield put(ItemsActions.getItemsFailure(e))
  }
}

// Watchers
function * getItemsWatcher () {
  yield takeLatest(ItemsTypes.GET_ITEMS, getItems)
}

export default function * root () {
  yield fork(getItemsWatcher)
}
