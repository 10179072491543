import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getItems
  getItems: ['payload'],
  // The operation has started and is loading
  getItemsLoading: null,
  // The items was successfully fetched
  getItemsSuccess: ['items'],
  // An error occurred
  getItemsFailure: ['errorMessage']
})

export const ItemsTypes = Types
export default Creators
