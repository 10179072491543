import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { GridCol, GridRow } from '../../../styled/Grids'
import { DefaultText, SectionTitle } from '../../../styled/Texts'
import { SimpleSpinner } from '../../../styled/Loading'
import { Button } from '../../../styled/Buttons'
import { SideContainer } from '../../../styled/Containers'

class TopSuggestions extends Component {
  render () {
    const {
      isSaving,
      topEditingAppointmentSuggestions,
      selectedDoorId,
      selectedDate,
      onApplySuggestions
    } = this.props

    return (
      <SideContainer>
        <GridCol padded={'small'}>
          <SectionTitle
            title="Suggestions based on future available inventory in a 48 hour range of the appointment's date."
          >
            Suggestions*
          </SectionTitle>
          {isSaving ? (
            <GridRow centered>
              <SimpleSpinner />
            </GridRow>
          ) : null}
          {!topEditingAppointmentSuggestions.suggestions.length ? (
            <DefaultText wrap="true">
              Insufficient data for appointment suggestion.
            </DefaultText>
          ) : null}
          {topEditingAppointmentSuggestions.suggestions.length
            ? topEditingAppointmentSuggestions.suggestions.map(suggestion => {
              const suggestedDate = suggestion.date
              const suggestedDoorId = suggestion.door.id

              return (
                <Button
                  multiLine
                  key={suggestion.id}
                  selected={
                    selectedDoorId === suggestedDoorId &&
                    selectedDate.isSame(suggestedDate)
                  }
                  onClick={e => {
                    e.preventDefault()
                    onApplySuggestions({
                      time: suggestedDate,
                      doorId: suggestedDoorId
                    })
                  }}
                >
                  <DefaultText bold>{`Door: ${suggestion.door.name} at`}</DefaultText>
                  <DefaultText bold>{suggestion.dateDescription}</DefaultText>
                </Button>
              )
            })
            : null}
        </GridCol>
      </SideContainer>
    )
  }
}

TopSuggestions.propTypes = {
  isSaving: PropTypes.bool,
  topEditingAppointmentSuggestions: PropTypes.shape({
    suggestions: PropTypes.array,
    hasMore: PropTypes.bool
  }),
  selectedDoorId: PropTypes.number,
  selectedDate: PropTypes.number,
  onApplySuggestions: PropTypes.func
}

TopSuggestions.defaultProps = {
  isSaving: false,
  topEditingAppointmentSuggestions: []
}

export default TopSuggestions
