import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'

import { Badge } from '../styled/Notifications'
import { Button, ButtonIcon, ButtonPrimary } from '../styled/Buttons'
import {
  Container,
  OrderCardContainer,
  SecondaryBorderedContainer
} from '../styled/Containers'
import { DefaultParagraph, DefaultText, Field, Label, Title } from '../styled/Texts'
import { Section, SectionBottom } from '../styled/Boxes'
import { StyledActionBarCheckbox } from '../styled/Inputs'
import { getItems } from '../modules/items/selectors'
import { getOrderItems } from '../modules/orderItems/selectors'
import { getInboundOutboundLabel, getOriginDestinationLabel, getVendorCustomerLabel, isNumeric } from '../utils/common'
import { theme } from '../themes/taylor-farms'
import ArrowIcon from './icons/Arrow'
import DeleteIcon from './icons/Delete'
import InfoIcon from './icons/Info'
import ScheduleIcon from './icons/Schedule'
import { SimpleSpinner } from '../styled/Loading'
import { getOpenEditAppointmentIsLoading } from '../modules/appointments/selectors'
import { createGetDoorById } from '../doors/doors-slice'
import { selectAllOrderStatuses } from '../orders/orders-slice'
import { ORDER_STATUS } from '@smartdock-shared/orderStatus'
import ResetIcon from './icons/Reset'
import { StyledModal, StyledModalAlertHeader, StyledModalContent, StyledModalFooter } from '../styled/Modals'
import CloseButton from './CloseButton'
import OrdersActions from '../modules/orders/actions'

const quantityUOMMap = {
  CASE: 'Cases'
}

class OrderCard extends Component {
  state = {
    orderCheckbox: false,
    hide: false,
    showOrderResetModal: false
  }

  constructor (props) {
    super(props)

    if (props.startCollapsed) {
      this.state.hide = true
    }
  }

  handleSelectedChange = event => {
    const { onSelectedChange } = this.props
    if (onSelectedChange) {
      onSelectedChange(event.target.checked)
    }
  }

  toggleHide = () => {
    this.setState(state => ({ hide: !state.hide }))
  }

  render () {
    const {
      order,
      orderStatuses,
      onCreateAppointment,
      onOpenOrderDetailsModal,
      className,
      isCreateAppointment,
      isDragging,
      isPreview,
      onDeleteOrder,
      onEditAppointment,
      isDisabled,
      checkedOrders,
      orderQuantities,
      orderWeight,
      orderSKUs,
      orderPallets,
      orderDoor,
      isSelected,
      openEditAppointmentIsLoading,
      getDoorById,
      selected,
      drag,
      appointmentDate,
      pickingRate,
      resetOrder,
      index
    } = this.props
    // TODO disconnect from redux and get selectors from parent
    const { hide } = this.state
    const orderStatus =
      orderStatuses &&
      orderStatuses.find(s => s.id === order.orderStatusId)
    const isScheduled =
      orderStatus &&
      orderStatus.name?.toLowerCase() === 'scheduled'
    const orderStatusName = orderStatus
      ? orderStatus.name?.toLowerCase()
      : 'draft'

    const { requiredShipDate, primaryRefValue, destination } = order
    const shipDate = (moment(appointmentDate).isAfter(requiredShipDate, 'day')) ? requiredShipDate.substr(5, 5) : ''

    const IconColor =
      !isScheduled || isCreateAppointment || isDisabled || isSelected
        ? theme.colors.secondary
        : theme.colors.contrastTextColor
    const Cont =
      isCreateAppointment || isSelected
        ? SecondaryBorderedContainer
        : OrderCardContainer

    const appointment = order.appointment
    const appointmentTimezone = (appointment) ? getDoorById(appointment?.doorId)?.area?.building?.timezone : null
    const timezone = appointmentTimezone ?? 'UTC'
    const onResetOrderClick = () => {
      this.setState({
        showOrderResetModal: true
      })
    }

    return (
      <div ref={drag} className={orderStatusName} title={(isDisabled) ? 'An Inbound Appointment can not have mixed order types' : ''}>
        {isDragging &&
          isPreview &&
          (checkedOrders && checkedOrders.size > 1) && (
          <Badge> {checkedOrders.size} </Badge>
        )}
        <Cont
          hide={hide ? 1 : 0}
          className={className}
          isDisabled={isDisabled}
          isCreateAppointment={isCreateAppointment}
          status={orderStatusName}
        >
          {isCreateAppointment ? (
            <Container flex centered column padded={false}>
              <Label className='orderSequence'>{index !== undefined ? index + 1 : order.orderSequence}</Label>
              <ButtonIcon onClick={e => {
                e.preventDefault()
                onDeleteOrder && onDeleteOrder(order)
              }}>
                <DeleteIcon width={12} height={16} color={IconColor} />
              </ButtonIcon>
              <Label title={primaryRefValue}>{primaryRefValue}</Label>
              <Label className='requiredShipDate'>{shipDate}</Label>
              <Container>
                <ButtonIcon onClick={() => this.toggleHide()}>
                  <ArrowIcon
                    color={IconColor}
                    direction={hide ? 'up' : 'down'}
                  />
                </ButtonIcon>
              </Container>
            </Container>
          ) : (
            <Container flex centered>
              <Label title={primaryRefValue} grow={4}>
                {`${getInboundOutboundLabel(order.isOutbound)} ${primaryRefValue}`}
              </Label>
              {!isScheduled && (
                <Container status={orderStatusName}>
                  <StyledActionBarCheckbox
                    disabled={isScheduled || isDisabled || false}
                    checked={
                      selected || isSelected
                    }
                    onChange={this.handleSelectedChange}
                    value="orderCheckbox"
                    color="default"
                    status={orderStatusName}
                  />
                </Container>
              )}
              <Container className="actions">
                {
                  order.orderStatusId === ORDER_STATUS.SCHEDULED && order.appointmentId == null && (
                    <>
                      <ButtonIcon title="Reset Order" onClick={onResetOrderClick}>
                        <ResetIcon color={IconColor} />
                      </ButtonIcon>
                      {/* TODO: Create a new component to support this modal */}
                      <StyledModal
                        isOpen={this.state.showOrderResetModal}
                        size="small"
                      >
                        <StyledModalAlertHeader>
                          <Title>Alerts</Title>
                          <CloseButton onClick={() => this.setState({ showOrderResetModal: false })} />
                        </StyledModalAlertHeader>
                        <StyledModalContent scrollable size="small">

                          <DefaultParagraph>
                            Would you like to reset this order?
                          </DefaultParagraph>
                        </StyledModalContent>

                        <StyledModalFooter>
                          <Button onClick={() => this.setState({ showOrderResetModal: false })}>Cancel</Button>
                          <ButtonPrimary onClick={() => resetOrder(order.id)}>Yes</ButtonPrimary>
                        </StyledModalFooter>
                      </StyledModal>
                    </>
                  )
                }
                {
                  order.appointment && order.appointment.id === openEditAppointmentIsLoading ? (
                    <SimpleSpinner inline small />
                  ) : (
                    <ButtonIcon
                      title={
                        isScheduled
                          ? 'Edit the related appointment'
                          : 'Create new appointment'
                      }
                      onClick={() =>
                        isScheduled ? onEditAppointment() : onCreateAppointment()
                      }
                      disabled={isDisabled}
                    >
                      <ScheduleIcon color={IconColor} />
                    </ButtonIcon>
                  )
                }

                <ButtonIcon
                  title="Order details"
                  onClick={onOpenOrderDetailsModal}
                  disabled={isDisabled}
                >
                  <InfoIcon color={IconColor} height={18} width={18} />
                </ButtonIcon>
              </Container>
            </Container>
          )}
          {hide || isSelected ? null : (
            <Container padded={isCreateAppointment ? 'smallLeft' : 'small'}>
              <Section>
                <Field>
                  <DefaultText bold minimumWidth>
                    Status:{' '}
                  </DefaultText>
                  <DefaultText>
                    {orderStatus ? orderStatus.name : 'Open'}
                  </DefaultText>
                </Field>
                <Field>
                  <DefaultText bold>
                    Door:{' '}
                  </DefaultText>
                  <DefaultText>{orderDoor}</DefaultText>
                </Field>
              </Section>
              <Section>
                <Field>
                  <DefaultText bold minimumWidth>
                    Appt Date:{' '}
                  </DefaultText>
                  <DefaultText title={appointment ? `Appt #: ${appointment.id}` : undefined}>
                    {appointment ? moment.utc(appointment.date).tz(timezone).format('L') : 'N/A'}
                  </DefaultText>
                </Field>
                <Field>
                  <DefaultText bold minimumWidth>
                    Time:{' '}
                  </DefaultText>
                  <DefaultText mediumWidth>
                    {appointment ? moment.utc(appointment.date).tz(timezone).format('HH:mm') : 'N/A'}
                  </DefaultText>
                </Field>
              </Section>
              <Section>
                <Field>
                  <DefaultText bold mediumWidth>
                    {`${getVendorCustomerLabel(order.isOutbound)}:`}
                  </DefaultText>
                  <DefaultText fixedWidth>
                    {order.customer.name}
                  </DefaultText>
                </Field>
              </Section>
              <Section>
                <Field>
                  <DefaultText bold mediumWidth>
                    {`${getOriginDestinationLabel(order.isOutbound)}:`}
                  </DefaultText>
                  <DefaultText fixedWidth>
                    {destination && destination.city},{' '}
                    {destination && destination.state}
                  </DefaultText>
                </Field>
              </Section>
              <Section>
                <Field>
                  <DefaultText bold mediumWidth>
                    { (order.isOutbound) ? 'Ship Date: ' : 'Arrival date' }
                  </DefaultText>
                  <DefaultText fixedWidth>
                    {moment.utc(order.requiredShipDate).format('L')}
                  </DefaultText>
                </Field>
              </Section>
              <Section>
                <Field>
                  <DefaultText bold mediumWidth>
                    % Complete:{' '}
                  </DefaultText>
                  <DefaultText fixedWidth>
                    {pickingRate}%
                  </DefaultText>
                </Field>
              </Section>
              <SectionBottom>
                {Object.keys(orderQuantities).map(orderedQtyUOM => (
                  <Field key={orderedQtyUOM}>
                    <DefaultText bold minimumWidth>
                      {`${quantityUOMMap[orderedQtyUOM] || 'Units'}: `}
                    </DefaultText>
                    <DefaultText>
                      {isNumeric(orderQuantities[orderedQtyUOM]) ? orderQuantities[orderedQtyUOM] : 'N/A'}
                    </DefaultText>
                  </Field>
                ))}
                <Field>
                  <DefaultText bold minimumWidth>
                    Weight:{' '}
                  </DefaultText>
                  <DefaultText>
                    {Object.keys(orderWeight).map(orderedWeightUOM => (
                      <DefaultText key={orderedWeightUOM}>
                        {isNumeric(orderWeight[orderedWeightUOM])
                          ? `${orderWeight[orderedWeightUOM]} ${orderedWeightUOM}`
                          : 'N/A'}
                      </DefaultText>
                    ))}
                  </DefaultText>
                </Field>
              </SectionBottom>
              <SectionBottom>
                <Field>
                  <DefaultText bold minimumWidth>
                    SKUs:{' '}
                  </DefaultText>
                  <DefaultText>{orderSKUs ? orderSKUs.length : 0}</DefaultText>
                </Field>
                <Field>
                  <DefaultText bold minimumWidth>
                    Pallets:{' '}
                  </DefaultText>
                  <DefaultText>
                    {(orderPallets && Math.ceil(orderPallets)) || 'N/A'}
                  </DefaultText>
                </Field>
              </SectionBottom>
            </Container>
          )}
        </Cont>
      </div>
    )
  }
}

OrderCard.propTypes = {
  checkedOrders: PropTypes.any,
  isPreview: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDisabled: PropTypes.bool,
  order: PropTypes.object,
  selected: PropTypes.bool,
  orderDoor: PropTypes.any,
  getDoorById: PropTypes.any,
  onSelectedChange: PropTypes.func,
  onCreateAppointment: PropTypes.func,
  onOpenOrderDetailsModal: PropTypes.func,
  className: PropTypes.string,
  isCreateAppointment: PropTypes.bool,
  onDeleteOrder: PropTypes.func,
  onEditAppointment: PropTypes.func,
  orderStatuses: PropTypes.array,
  orderQuantities: PropTypes.object,
  orderWeight: PropTypes.object,
  orderSKUs: PropTypes.array,
  orderPallets: PropTypes.any,
  openEditAppointmentIsLoading: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  isSelected: PropTypes.bool,
  startCollapsed: PropTypes.bool,
  drag: PropTypes.any,
  appointmentDate: PropTypes.any,
  pickingRate: PropTypes.number,
  index: PropTypes.number,
  resetOrder: PropTypes.func,
}

const mapStateToProps = state => ({
  openEditAppointmentIsLoading: getOpenEditAppointmentIsLoading(state),
  orderStatuses: selectAllOrderStatuses(state),
  orderItems: getOrderItems(state),
  items: getItems(state),
  getDoorById: createGetDoorById(state)
})
const mapDispatchToProps = dispatch => ({
  resetOrder: (id) => dispatch(OrdersActions.revertOrder(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCard)
