import React from 'react'

import { Container } from '../styled/Containers'
import { DefaultText, MaterialLabel } from '../styled/Texts'
import { MaterialInput } from '../styled/Inputs'
import PropTypes from 'prop-types'

const TextInput = ({ label, fullwidth, spaced = true, ...props }) => (
  <Container spaced={spaced} fill={fullwidth ? 'true' : ''}>
    <MaterialInput {...props} />
    {label && (
      <MaterialLabel theme={props.theme} error={props.error}>
        {label}
      </MaterialLabel>
    )}
    {props.error && (
      <DefaultText theme={props.theme} error={props.error}>
        {props.error}
      </DefaultText>
    )}
  </Container>
)

TextInput.propTypes = {
  label: PropTypes.string,
  spaced: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  error: PropTypes.any,
  theme: PropTypes.any,
  fullwidth: PropTypes.any
}

export default TextInput
