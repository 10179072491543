import 'react-notifications/lib/notifications.css'

import { NotificationContainer } from 'react-notifications'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import React, { Component } from 'react'

import { AppContainer } from './styled/Containers'
import CarrierApp from './apps/carrier/App'
import SchedulerApp from './apps/scheduler/App'
import Login from './apps/scheduler/pages/Login'
import NotFound from './NotFound'

class Main extends Component {
  render () {
    return (
      <AppContainer fillHeight>
        <Router>
          <Routes>
            {/* keep old urls working */}
            <Route path="/" element={<Navigate to="/login"/>}/>
            <Route path="/home" element={<Navigate to="/scheduler/orders"/>}/>
            <Route path="/scheduler" element={<Navigate to="/scheduler/orders"/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/scheduler/*" element={<SchedulerApp />}/>
            <Route path="/carrier/*" element={<CarrierApp />}/>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <NotificationContainer/>
      </AppContainer>
    )
  }
}

export default Main
