import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class EditIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 15 15"
      >
        <defs>
          <path id="a" d="M0 0h15v15H0z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use href="#a"/>
          </mask>
          <path
            fill={color}
            d="M9.327 2.503L12.38 5.57 4.655 13.33l-3.05-3.066 7.722-7.762zm5.367-.74L13.334.397a1.346 1.346 0 0 0-1.908 0l-1.304 1.31 3.052 3.066 1.52-1.527c.408-.41.408-1.071 0-1.481zM.008 14.576a.348.348 0 0 0 .42.415l3.4-.829L.78 11.095l-.77 3.48z"
            mask="url(#b)"
          />
        </g>
      </svg>
    )
  }
}

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

EditIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#ffffff'
}
