import { CardContainer } from '../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../styled/Table'
import PropTypes from 'prop-types'
import React from 'react'

const COLUMNS_LENGTH = 12
const PREFIX_KEY = 'todayAppointments'

const TodayAppointments = ({ data, isLoading, errorMessage }) => (
  <CardContainer>
    <StyledTable>
      <thead>
        <TableRow>
          <TableColumnHeader />
          <TableColumnHeader>WAREHOUSE</TableColumnHeader>
          <TableColumnHeader>DATE</TableColumnHeader>
          <TableColumnHeader>TIME</TableColumnHeader>
          <TableColumnHeader>SKUS</TableColumnHeader>
          <TableColumnHeader>CASES</TableColumnHeader>
          <TableColumnHeader>WEIGHT</TableColumnHeader>
          <TableColumnHeader>CHECK IN TIME</TableColumnHeader>
          <TableColumnHeader>DESTINATION</TableColumnHeader>
          <TableColumnHeader>CARRIER</TableColumnHeader>
          <TableColumnHeader>ORDERS</TableColumnHeader>
          <TableColumnHeader>CUSTOMERS</TableColumnHeader>
        </TableRow>
      </thead>
      <tbody>
        {(!isLoading && data) && data.map((a, key) => (
          <TableRow key={PREFIX_KEY + key + a.get('warehouse')}>
            <TableCol>{key + 1}</TableCol>
            <TableCol>{a.get('warehouse')}</TableCol>
            <TableCol singleLine>{a.get('date')}</TableCol>
            <TableCol>{a.get('time')}</TableCol>
            <TableCol>{a.get('skus')}</TableCol>
            <TableCol>{a.get('cases')}</TableCol>
            <TableCol>{a.get('orderedWeight')}</TableCol>
            <TableCol>{a.get('checkInTime')}</TableCol>
            <TableCol>{a.get('destination')}</TableCol>
            <TableCol>{a.get('carrier')}</TableCol>
            <TableCol>{a.get('orders')}</TableCol>
            <TableCol>{a.get('customers')}</TableCol>
          </TableRow>
        ))}
        {isLoading && (
          <TableRow key={`${PREFIX_KEY}Loading`}>
            <TableCol centerText colSpan={COLUMNS_LENGTH}> LOADING REPORT </TableCol>
          </TableRow>
        )}
        {(!isLoading && !errorMessage && (!data || data.isEmpty())) && (
          <TableRow key={`${PREFIX_KEY}NoData`}>
            <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
          </TableRow>
        )}
        {(errorMessage && !isLoading) && (
          <TableRow key={`${PREFIX_KEY}Error`}>
            <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
          </TableRow>
        )}
      </tbody>
    </StyledTable>
  </CardContainer>
)

TodayAppointments.propTypes = {
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool
}

export default TodayAppointments
