import { createSelector } from 'reselect'
import { selectCarrierToken } from '../../carrier-app/carrier-app-slice'
import { getStateUsers } from '../common/selectors'

const createGenericUsersSelector = field => createSelector(
  getStateUsers,
  users => users.get(field)
)

export const getLoggedUser = createGenericUsersSelector('user')

export const token = createGenericUsersSelector('token')

export const isLoggedIn = createSelector(
  token,
  token => !!token
)

export const isAdmin = createSelector(
  getLoggedUser,
  user => {
    const roles = user && user.get('roles')
    if (!roles) return false
    return roles.find(role => role.get('name') === 'admin') !== undefined
  }
)

export const getUserName = createSelector(
  getLoggedUser,
  user => user && `${user.get('firstName')} ${user.get('lastName')}`
)

export const getUserId = createSelector(
  getLoggedUser,
  user => user && user.get('id')
)

export const getUserAccounts = createSelector(
  getLoggedUser,
  user => user && user.get('accounts')
)

export const getUsers = createGenericUsersSelector('users')

export const getRoles = createGenericUsersSelector('roles')

export const getAccounts = createGenericUsersSelector('accounts')

export const isSystemSettingsOpen = createGenericUsersSelector('isSystemSettingsOpen')

export const getAllowIssuesUser = createGenericUsersSelector('allowIssuesUser')

export const getUsersIsLoading = createGenericUsersSelector('getUsersIsLoading')

export const getDefaultBuildingId = createSelector(
  getLoggedUser,
  user => user ? user.get('defaultBuildingId') : null
)

export const getDefaultAppointmentType = createSelector(
  getLoggedUser,
  user => user ? user.get('defaultApptType') : null
)

export const createGetUserById = createSelector(
  getUsers,
  users => userId => users.filter(u => u.get('id') === userId)
)

export const getUserToken = createSelector(
  token,
  selectCarrierToken,
  (token, carrierToken) => carrierToken || token
)
