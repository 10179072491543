import React from 'react'

export interface ResetIconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const ResetIcon: React.FC<ResetIconProps> = ({
  width = 18,
  height = 18,
  color = '#ffffff',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    fill={color}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <circle cx="256" cy="281.6" r="76.8" />
      <path d="M481.7,235.18c-3.08-14.93-7.6-29.48-13.43-43.23c-5.77-13.62-12.92-26.77-21.25-39.2c-8.3-12.25-17.75-23.7-28.12-34   c-10.32-10.4-21.82-19.88-34.1-28.2c-12.35-8.35-25.55-15.45-39.1-21.23c-13.78-5.85-28.33-10.37-43.3-13.45   c-15.35-3.15-31.2-4.67-47.05-4.62V0L153.6,76.8l101.75,76.8v-51.15c12.4-0.05,24.77,1.12,36.73,3.6   c11.62,2.4,22.95,5.9,33.65,10.45c10.57,4.47,20.8,10.05,30.4,16.5c9.55,6.45,18.5,13.82,26.58,21.93   c8.07,8.05,15.47,16.97,21.88,26.5c6.5,9.62,12.05,19.88,16.52,30.5c4.57,10.68,8.05,22,10.45,33.55   c2.4,11.75,3.65,23.92,3.65,36.12c0,12.23-1.25,24.38-3.65,36.1c-2.4,11.62-5.88,22.95-10.45,33.67   c-4.48,10.58-10.02,20.8-16.48,30.4c-6.48,9.55-13.88,18.48-21.98,26.6c-8.05,8.1-16.98,15.45-26.52,21.88   c-9.52,6.42-19.73,11.98-30.42,16.5c-10.68,4.52-21.98,8.05-33.58,10.45c-23.55,4.8-48.77,4.8-72.23,0   c-11.62-2.4-22.95-5.93-33.65-10.48c-10.65-4.5-20.85-10.05-30.43-16.5c-9.5-6.4-18.43-13.77-26.5-21.88s-15.47-17.02-21.9-26.52   c-6.5-9.62-12.05-19.85-16.53-30.45c-4.58-10.7-8.05-22.02-10.45-33.6c-2.42-11.8-3.65-23.95-3.65-36.17H25.6   c0,15.65,1.57,31.27,4.67,46.45c3.07,14.9,7.6,29.43,13.45,43.23c5.75,13.62,12.9,26.77,21.25,39.2   c8.28,12.2,17.72,23.65,28.1,34.02c10.4,10.42,21.85,19.9,34.08,28.12c12.25,8.33,25.45,15.48,39.12,21.25   c13.78,5.88,28.33,10.38,43.3,13.48c15.15,3.07,30.78,4.65,46.43,4.65c15.62,0,31.25-1.58,46.42-4.67   c14.93-3.1,29.48-7.6,43.23-13.45c13.73-5.8,26.93-12.95,39.15-21.25c12.25-8.23,23.73-17.7,34.08-28.12   c10.38-10.38,19.83-21.85,28.17-34.1c8.3-12.38,15.45-25.55,21.23-39.12c5.85-13.82,10.35-28.35,13.43-43.3   c3.1-15.1,4.7-30.73,4.7-46.38C486.4,265.98,484.8,250.35,481.7,235.18z" />
    </g>
  </svg>
)

export default ResetIcon
