const from = 'lorem@ipsum.com'

const subject = 'DO NOT REPLY: Pickup Appointment Confirmation'

const body =
`
This email is to confirm your pickup appointment scheduled for: <PICKUP_DATE> <PICKUP_TIME>.
Drivers must check in within an hour of the scheduled appointment.
Location: <SITE> - <BUILDING_NAME>
Address:
<ADDRESS>
Appointment Number: <APPOINTMENT_ID>
SO Number(s):
    <SOs>
PO Numbers(s):
    <POs>
Trailer must be:
    - Clean and sanitized
    - Pre-cooled to 34 degrees and set at 35 continuous
    - Air chute must be in good repair
    - Please remove all load locks

For changes or assistance, contact support at (800) 444-5555 (insert phone number for origin shipping facility - TBD)
`

export default { subject, body, from }
