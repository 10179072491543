import { createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

import { BuildingsTypes } from './actions'
import { INITIAL_STATE } from './initialState'

export const getSettingsForAllBuildingsLoading = state =>
  state.merge({
    getSettingsForAllBuildingsIsLoading: true,
    getSettingsForAllBuildingsErrorMessage: ''
  })

export const getSettingsForAllBuildingsSuccess = (state, { settings }) =>
  state.merge({
    settings,
    getSettingsForAllBuildingsIsLoading: false,
    getSettingsForAllBuildingsErrorMessage: null
  })

export const getSettingsForAllBuildingsFailure = (state, { errorMessage }) =>
  state.merge({
    settings: null,
    getSettingsForAllBuildingsIsLoading: false,
    getSettingsForAllBuildingsErrorMessage: errorMessage
  })

export const updateSettingsForBuildingLoading = state =>
  state.merge({
    updateSettingsForBuildingIsLoading: true,
    updateSettingsForBuildingErrorMessage: ''
  })

export const updateSettingsForBuildingSuccess = (state, { settings }) =>
  state.merge({
    settings: state
      .get('settings')
      .map(stateBuildingSettings =>
        fromJS(settings
          .find(payloadBuildingSettings =>
            stateBuildingSettings.get('building').get('id') ===
            payloadBuildingSettings.building.id &&
            stateBuildingSettings.get('setting').get('id') ===
            payloadBuildingSettings.setting.id
          )) || stateBuildingSettings
      ),
    updateSettingsForBuildingIsLoading: false,
    updateSettingsForBuildingErrorMessage: null
  })

export const updateSettingsForBuildingFailure = (state, { errorMessage }) =>
  state.merge({
    settings: null,
    updateSettingsForBuildingIsLoading: false,
    updateSettingsForBuildingErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [BuildingsTypes.GET_SETTINGS_FOR_ALL_BUILDINGS_LOADING]: getSettingsForAllBuildingsLoading,
  [BuildingsTypes.GET_SETTINGS_FOR_ALL_BUILDINGS_SUCCESS]: getSettingsForAllBuildingsSuccess,
  [BuildingsTypes.GET_SETTINGS_FOR_ALL_BUILDINGS_FAILURE]: getSettingsForAllBuildingsFailure,
  [BuildingsTypes.UPDATE_SETTINGS_FOR_BUILDING_LOADING]: updateSettingsForBuildingLoading,
  [BuildingsTypes.UPDATE_SETTINGS_FOR_BUILDING_SUCCESS]: updateSettingsForBuildingSuccess,
  [BuildingsTypes.UPDATE_SETTINGS_FOR_BUILDING_FAILURE]: updateSettingsForBuildingFailure
})
