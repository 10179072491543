import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  sendEmail: ['payload'],
  sendEmailLoading: null,
  sendEmailSuccess: null,
  sendEmailFailure: ['errorMessage']
})

export const EmailsTypes = Types
export default Creators
