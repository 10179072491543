import { Badge } from '../../../styled/Notifications'
import { ButtonIcon } from '../../../styled/Buttons'
import React from 'react'
import { Moment } from 'moment'
import { parseHour } from '../utils'

export interface SkuBadgeProps {
  skus?: Map<string, string[]>;
  hour: Moment;
  is24Format: boolean;
}
const SkuBadge = (props: SkuBadgeProps) => {
  const { skus, hour, is24Format } = props
  const hourKey = parseHour(hour)
  const skuCount = skus?.get(hourKey)?.length

  if (!skuCount) {
    return <></>
  }

  return (
    <Badge sku>
      <ButtonIcon title={`There are ${skuCount} unique SKU for appointments at ${hour.format(is24Format ? 'HH:00' : 'hh:00a')}`} >
        {skuCount}
      </ButtonIcon>
    </Badge>
  )
}

export default SkuBadge
