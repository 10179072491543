import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { DefaultText } from './Texts'

import { ButtonCss, ButtonPrimaryCss } from './Buttons'
import CsvLink from '../components/CsvLink'

export const GuestLoginLink = styled.div``
export const ForgottenLink = styled(DefaultText)``
export const CSVLinkStyled = styled(CsvLink)`
  ${ButtonCss}
  ${ButtonPrimaryCss}

  float: right;
  text-decoration: none;
  font-size: 12px;
  vertical-align: middle;
  margin: 0 0 9px;
  padding: 0.35rem 0.5rem;
  
  svg {
    vertical-align: top;
  }
`
export const LogoutLink = styled(Link)`
  text-decoration: none;
`
