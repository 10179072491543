import { Field, Form } from 'react-final-form'
import React, { Component } from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { Container } from '../../styled/Containers'
import { Button, ButtonPrimary } from '../../styled/Buttons'
import { StyledTextInput, StyledTextArea } from '../../styled/Inputs'
import { GridCol, GridRow } from '../../styled/Grids'

const FlexForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

class HiddenEmailForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isErrors: false
    }
  }

  validate = values => {
    const errors = {}
    if (!values.to) {
      errors.to = 'Required'
    }
    if (!values.data) {
      errors.data = 'Required'
    }

    const isErrors = !!Object.entries(errors).length

    this.setState({ isErrors })
    return errors
  }

  render () {
    const { to, data, onSubmit, onCancel } = this.props
    return (
      <Container page flex>
        <Form
          onSubmit={() => onSubmit}
          initialValues={{
            to,
            data
          }}
          validate={() => this.validate}
          render={({ handleSubmit }) => (
            <FlexForm onSubmit={handleSubmit}>
              <GridRow>
                <GridCol flex spaced="bottom">
                  <Field name="to">
                    {({ input, meta }) => (
                      <StyledTextInput
                        {...input}
                        error={meta.touched && meta.error}
                        fullwidth="true"
                        placeholder="Email Address"
                        label="To"
                        type="email"
                      />
                    )}
                  </Field>
                </GridCol>
              </GridRow>
              <GridRow grow fill="true" flex>
                <GridCol grow fill="true" flex spaced="bottom">
                  <Field name="data">
                    {({ input, meta }) => (
                      <StyledTextArea
                        {...input}
                        error={meta.touched && meta.error}
                        fullwidth="true"
                        label="Message* (Click field to edit email)"
                        rows="15"
                      />
                    )}
                  </Field>
                </GridCol>
              </GridRow>
              <Container>
                <ButtonPrimary primary type="submit">
                  Send
                </ButtonPrimary>
                <Button onClick={onCancel}>Cancel</Button>
              </Container>
            </FlexForm>
          )}
        />
      </Container>
    )
  }
}

HiddenEmailForm.propTypes = {
  to: PropTypes.string,
  data: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default HiddenEmailForm
