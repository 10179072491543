import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { CSVLinkStyled } from '../../../styled/Links'
import { ClickableListItem, ListContainer, Scrollable } from '../../../styled/Containers'
import { GridRow, GridCell, GridCol } from '../../../styled/Grids'
import { getReportData, getReportErrorMessage, getReportIsLoading } from '../../../modules/reports/selectors'
import AppointmentsActions from '../../../modules/appointments/actions'
import ReportsActions from '../../../modules/reports/actions'
import { ListTitle } from '../../../styled/Texts'
import LateAppointments from '../../../components/reports/LateAppointments'
import TodaySummary from '../../../components/reports/TodaySummary'
import YesterdaySummary from '../../../components/reports/YesterdaySummary'
import TodayAppointmentsSummary from '../../../components/reports/TodayAppointmentsSummary'
import TodayAppointments from '../../../components/reports/TodayAppointments'
import YesterdayAppointments from '../../../components/reports/YesterdayAppointments'
import LastWeekAppointments from '../../../components/reports/LastWeekAppointments'
import CustomerSummaryPreviousDay from '../../../components/reports/CustomerSummaryPreviousDay'
import CustomerSummaryPreviousWeek from '../../../components/reports/CustomerSummaryPreviousWeek'
import InventoryIssues from '../../../components/reports/InventoryIssues'
import ScheduledInventoryItems from '../../../components/reports/ScheduledInventoryItems'
import MissingInventoryItems from '../../../components/reports/MissingInventoryItems'
import InventoryIssuesOverTime from '../../../components/reports/InventoryIssuesOverTime'
import HandleError from '../../../components/hocs/HandleError'
import withParams from '../../../utils/WithParams'

const reports = [
  { title: 'Summary - Start shift to now', reportFile: 'summary_today', endpoint: 'summary', component: TodaySummary },
  { title: 'Summary - Previous shift', reportFile: 'summary_yesterday', endpoint: 'summaryYesterday', component: YesterdaySummary },
  { title: 'Today\'s - Appointment Summary', reportFile: 'today_appointments_summary', endpoint: 'todayAppointmentsSummary', component: TodayAppointmentsSummary },
  { title: 'Today\'s - Appointments', reportFile: 'today_appointments', endpoint: 'todayAppointments', component: TodayAppointments },
  { title: 'Yesterday\'s - Appointments', reportFile: 'yesterday_appointments', endpoint: 'yesterdayAppointments', component: YesterdayAppointments },
  { title: 'Last Week\'s - Appointments', reportFile: 'lastWeek_appointments', endpoint: 'lastWeekAppointments', component: LastWeekAppointments },
  { title: 'Summary by Customer, Previous Day', reportFile: 'summary_customer_yesterday', endpoint: 'summaryCustomerYesterday', component: CustomerSummaryPreviousDay },
  { title: 'Summary by Customer, Previous Week', reportFile: 'summary_customer_last_week', endpoint: 'summaryCustomerLastWeek', component: CustomerSummaryPreviousWeek },
  { title: 'Late Appointments\nTaylor California', reportFile: 'late_appointments_california', endpoint: 'lateAppointments?buildingGroup=california', component: LateAppointments },
  { title: 'Late Appointments\nTaylor Retail', reportFile: 'late_appointments_retail', endpoint: 'lateAppointments?buildingGroup=retail', component: LateAppointments },
  { title: 'Inventory Issues\nTaylor California', reportFile: 'inventory_issues_california', endpoint: 'inventoryIssues?buildingGroup=california', component: InventoryIssues },
  { title: 'Inventory Issues\nTaylor Retail', reportFile: 'inventory_issues_retail', endpoint: 'inventoryIssues?buildingGroup=retail', component: InventoryIssues },
  { title: 'Scheduled Inventory Items', reportFile: 'scheduled_inventory_items', endpoint: 'scheduledInventoryItems', component: ScheduledInventoryItems },
  { title: 'Missing Inventory Items', reportFile: 'missing_inventory_items', endpoint: 'missingInventoryItems', component: MissingInventoryItems },
  { title: 'Inventory Issues Over Time', reportFile: 'inventory_issues_over_time', endpoint: 'inventoryIssuesOverTime', component: InventoryIssuesOverTime }
]

const getIndex = index => index ? parseInt(index) : 0

class Reports extends Component {
  constructor (props) {
    super(props)

    const { index } = props.params

    this.state = {
      active: getIndex(index)
    }
  }

  componentDidMount () {
    const { active } = this.state
    const { endpoint } = reports[active]
    const { closeAppointmentModal, getReport } = this.props

    getReport({ endpoint })
    closeAppointmentModal()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { index } = this.props.params
    const matchedIndex = getIndex(index)
    if (matchedIndex !== this.state.active) {
      this.onChangeReport(getIndex(index))()
    }
  }

  generateCSV = data => {
    return data ? data.toJS() : []
  }

  onChangeReport = active => () => {
    this.setState({ active })
    const { endpoint } = reports[active]
    this.props.getReport({ endpoint })
  }

  render () {
    const {
      data,
      isLoading,
      errorMessage
    } = this.props

    const {
      active
    } = this.state

    const activeReport = reports[active]
    const ReportComponent = HandleError(activeReport.component)
    const ReportCsvFile = `${activeReport.reportFile}_${moment().format('YYYYMMDDHHmmss')}`
    return (
      <GridRow block page>
        <GridCol flex={0} page>
          <GridRow block page>
            <GridCell fill={1}>
              <ListTitle>Reports</ListTitle>
              <ListContainer>
                <Scrollable>
                  {reports.map((report, index) => (
                    <ClickableListItem
                      key={report.title}
                      to={`/scheduler/reports/${index}`}
                      className={active === index ? 'active' : undefined}
                    >
                      {report.title}
                    </ClickableListItem>
                  ))}
                </Scrollable>
              </ListContainer>
            </GridCell>
          </GridRow>
        </GridCol>
        <GridCol flex={1}>
          <GridRow block>
            <GridCell fill={1}>
              <div className="actions">
                <CSVLinkStyled filename={`${ReportCsvFile}.csv`} data={this.generateCSV(data)} />
              </div>
              <Scrollable>
                <ReportComponent data={data} isLoading={isLoading} errorMessage={errorMessage} />
              </Scrollable>
            </GridCell>
          </GridRow>
        </GridCol>
      </GridRow>
    )
  }
}

Reports.propTypes = {
  params: PropTypes.object,
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  getReport: PropTypes.func,
  closeAppointmentModal: PropTypes.func
}

const mapStateToProps = state => ({
  data: getReportData(state),
  isLoading: getReportIsLoading(state),
  errorMessage: getReportErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  getReport: payload => dispatch(ReportsActions.getReport(payload)),
  closeAppointmentModal: () => dispatch(AppointmentsActions.closeUpsertAppointment())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withParams(Reports))
