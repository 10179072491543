import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { DoorsTypes } from './actions'
import { AppointmentsTypes } from '../appointments/actions'

// clearAppointments
export const clearAppointments = state =>
  state.merge({
    appointments: []
  })

// setAppointments
export const setAppointments = (state, { appointments }) =>
  state.merge({
    appointments: appointments
  })

// editAppointments
export const editAppointment = (state, { appointment }) => {
  return state.merge({
    appointments: state.get('appointments').map(
      appt => (appt.get('id') !== appointment.id ? appt : appt.merge({
        duration: appointment.duration,
        doorId: appointment.doorId,
        date: appointment.date
      }))
    )
  })
}

// getAppointmentsForDoor
export const getAppointmentsForDoorLoading = state =>
  state.merge({
    getAppointmentsForDoorIsLoading: true,
    getAppointmentsForDoorErrorMessage: ''
  })

export const getAppointmentsForDoorSuccess = (state, { appointments }) =>
  state.merge({
    appointments: [...state.appointments, appointments],
    getAppointmentsForDoorIsLoading: false,
    getAppointmentsForDoorErrorMessage: null
  })

export const getAppointmentsForDoorFailure = (state, { errorMessage }) =>
  state.merge({
    appointments: null,
    getAppointmentsForDoorIsLoading: false,
    getAppointmentsForDoorErrorMessage: errorMessage
  })

// getAppointmentsForDoors
export const getAppointmentsForDoorsLoading = state =>
  state.merge({
    getAppointmentsForDoorsIsLoading: true,
    getAppointmentsForDoorsErrorMessage: ''
  })

export const getAppointmentsForDoorsSuccess = state =>
  state.merge({
    getAppointmentsForDoorsIsLoading: false,
    getAppointmentsForDoorsErrorMessage: null
  })

export const getAppointmentsForDoorsFailure = (state, { errorMessage }) =>
  state.merge({
    getAppointmentsForDoorsIsLoading: false,
    getAppointmentsForDoorsErrorMessage: errorMessage
  })

// appointments socket
export const updateAppointmentForDoorWithSocketAppointment = (state, { appointment }) => {
  return state.merge({
    appointments: state.get('appointments').map(
      appt => (appt.get('id') !== appointment.id ? appt : appt.merge({ ...appointment }))
    )
  })
}

export const updateAppointment = (state, { payload }) => {
  const appointments = state.get('appointments')
  // TODO: don't know why I had to add it as without it the carrier request can't be closed
  if (!appointments || !payload?.id) return state

  return state.merge({
    appointments: appointments.map(
      appt => (appt.get('id') !== payload.id ? appt : appt.merge({ ...payload }))
    )
  })
}
/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DoorsTypes.CLEAR_APPOINTMENTS]: clearAppointments,

  [DoorsTypes.SET_APPOINTMENTS]: setAppointments,

  [DoorsTypes.EDIT_APPOINTMENT]: editAppointment,

  [DoorsTypes.GET_APPOINTMENTS_FOR_DOOR_LOADING]: getAppointmentsForDoorLoading,
  [DoorsTypes.GET_APPOINTMENTS_FOR_DOOR_SUCCESS]: getAppointmentsForDoorSuccess,
  [DoorsTypes.GET_APPOINTMENTS_FOR_DOOR_FAILURE]: getAppointmentsForDoorFailure,

  [DoorsTypes.GET_APPOINTMENTS_FOR_DOORS_LOADING]: getAppointmentsForDoorsLoading,
  [DoorsTypes.GET_APPOINTMENTS_FOR_DOORS_SUCCESS]: getAppointmentsForDoorsSuccess,
  [DoorsTypes.GET_APPOINTMENTS_FOR_DOORS_FAILURE]: getAppointmentsForDoorsFailure,

  [DoorsTypes.UPDATE_APPOINTMENT_FOR_DOOR_WITH_SOCKET_APPOINTMENT]: updateAppointmentForDoorWithSocketAppointment,

  [AppointmentsTypes.UPDATE_APPOINTMENT]: updateAppointment
})
