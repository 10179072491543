import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { createStore, compose, applyMiddleware } from 'redux'
import { NotificationManager } from 'react-notifications'
import throttle from 'lodash/throttle'

import { rootReducer, rootSaga } from './modules/index'
import { loadState, saveState } from './utils/storage'
import config from './config'
import { getUserToken } from './modules/users/selectors'
import UsersActions from './modules/users/actions'

const blacklisted = [
  'orders',
  'appointments',
  'buildings',
  'carriers',
  'customers',
  'drivers',
  'orderItems',
  'items',
  'emails',
  'entities',
  'carrierRequests',
  'feeds',
  'sms',
  'reports',
  'inventoryItems',
  'doors',
  'settings',
  'area',
  'door',
  'doorDuration',
  'site',
  'time',
  'building',
  'location',
  'carrier',
  'driver',
  'appointment',
  'order',
  'user',
  'users',
  'carrierApp',
  'ui'
]

/**
 * Handle 401 response to logout the user
 */
const handleErrorsMiddleware = store => {
  return next => action => {
    const parts = action.type.split('_')
    const retType = parts.pop() // we care for FAILURE
    if (
      retType === 'FAILURE' && action.errorMessage &&
      action.errorMessage.response &&
      action.errorMessage.response.status === 401
    ) {
      const token = getUserToken(store.getState())

      NotificationManager.create({
        type: 'error',
        message: token ? 'Your session is expired' : 'You are not logged in',
        id: 'expired-session'
      })
      store.dispatch(UsersActions.logout())

      return next(action)
    }

    return next(action)
  }
}

export default () => {
  const middleware = []
  const enhancers = []
  const composeEnhancers = (
    config.ENV === 'production' ||
    config.ENV === 'staging' ||
    !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  )
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionsBlacklist: ['time/updateTimestamp']
    })

  // Connect the sagas to the redux store
  middleware.push(thunk)
  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)
  middleware.push(handleErrorsMiddleware)
  enhancers.push(applyMiddleware(...middleware))

  // Redux store
  const loadedState = loadState()
  const store = createStore(rootReducer, loadedState, composeEnhancers(...enhancers))

  // Redux persist
  const throttledPersist = throttle(() => {
    saveState(store.getState(), { blacklisted })
  }, 1000)
  store.subscribe(throttledPersist)

  if (module.hot) {
    module.hot.accept('./modules/', () => {
      store.replaceReducer(
        require('./modules/index').rootReducer
      )
    })
  }

  // Kick off the root saga
  sagaMiddleware.run(rootSaga)

  return { store }
}
