import { getStateEmails } from '../common/selectors'
import { createSelector } from 'reselect'

export const getSendEmailIsLoading = createSelector(
  getStateEmails,
  emails => emails.get('sendEmailIsLoading')
)

export const getSendEmailErrorMessage = createSelector(
  getStateEmails,
  emails => emails.get('sendEmailErrorMessage')
)
