import { NotificationManager } from 'react-notifications'
import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import { token as getToken } from './selectors'
import UsersActions, { UsersTypes } from './actions'
import axios from '../../utils/axios'
import config from '../../config'

// Sagas
function * getUsers ({ payload }) {
  yield put(UsersActions.getUsersLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.get, '/users', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(UsersActions.getUsersSuccess(data.users))
  } catch (e) {
    yield put(UsersActions.getUsersFailure(e))
  }
}

function * getUser ({ payload }) {
  yield put(UsersActions.getUserLoading())
  try {
    const { token, id } = payload
    const { data } = yield call(axios.get, `/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(UsersActions.getUserSuccess(data.user))
  } catch (e) {
    yield put(UsersActions.getUserFailure(e))
  }
}

function * getAllowIssuesUser ({ payload }) {
  yield put(UsersActions.getAllowIssuesUserLoading())
  try {
    const { id } = payload
    const { data } = yield call(axios.get, `/open/users/${id}`, {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    yield put(UsersActions.getAllowIssuesUserSuccess(data.user))
  } catch (e) {
    yield put(UsersActions.getAllowIssuesUserFailure(e))
  }
}

function * getRoles () {
  yield put(UsersActions.getRolesLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.get, 'roles', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(UsersActions.getRolesSuccess(data))
  } catch (e) {
    yield put(UsersActions.getRolesFailure(e))
  }
}

function * getAccounts () {
  yield put(UsersActions.getAccountsLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.get, 'accounts', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(UsersActions.getAccountsSuccess(data))
  } catch (e) {
    yield put(UsersActions.getAccountsFailure(e))
  }
}

function * createUser ({ payload }) {
  yield put(UsersActions.createUserLoading())
  try {
    const token = yield select(getToken)
    yield call(axios.post, '/users/create', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(UsersActions.createUserSuccess())
    yield put(UsersActions.getUsers())
  } catch (e) {
    yield put(UsersActions.createUserFailure(e))
  }
}

function * updateUser ({ payload }) {
  yield put(UsersActions.updateUserLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.put, `/users/${payload.id}/update`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    NotificationManager.success('User updated')
    yield put(UsersActions.updateUserSuccess(data))
  } catch (e) {
    if (e.response.status === 422) {
      NotificationManager.error('User missing required fields.')
    } else {
      NotificationManager.error('User could not be created due server error. Try again later.')
    }
    yield put(UsersActions.updateUserFailure(e))
  }
}

function * login ({ payload }) {
  const { username, password } = payload
  yield put(UsersActions.loginLoading())
  try {
    const { data } = yield call(axios.post, '/users/login', {
      email: username,
      password
    })
    yield put(UsersActions.loginSuccess(data.token, data.user))
  } catch (e) {
    yield put(UsersActions.loginFailure(e))
  }
}

function * logout () {
  yield put(UsersActions.logoutLoading())
  try {
    yield put(UsersActions.logoutSuccess())
  } catch (e) {
    yield put(UsersActions.logoutFailure(e))
  }
}

function * changeUserActivation ({ payload }) {
  yield put(UsersActions.changeUserActivationLoading())
  try {
    const { active, id } = payload
    const token = yield select(getToken)

    const { data } = yield call(axios.put, `/users/${id}/activation`, {
      active
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    yield put(UsersActions.changeUserActivationSuccess(data.user[0]))
  } catch (e) {
    yield put(UsersActions.changeUserActivationSuccess(e))
  }
}

function * deleteUser ({ payload }) {
  yield put(UsersActions.deleteUserLoading())
  try {
    const { id } = payload
    const token = yield select(getToken)

    const { data } = yield call(axios.delete, `/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(UsersActions.deleteUserSuccess(data.userId))
  } catch (e) {
    yield put(UsersActions.deleteUserFailure(e))
  }
}

// Watchers
function * getUsersWatcher () {
  yield takeLatest(UsersTypes.GET_USERS, getUsers)
}

function * getUserWatcher () {
  yield takeLatest(UsersTypes.GET_USER, getUser)
}

function * getAllowIssuesUserWatcher () {
  yield takeLatest(UsersTypes.GET_ALLOW_ISSUES_USER, getAllowIssuesUser)
}

function * getRolesWatcher () {
  yield takeLatest(UsersTypes.GET_ROLES, getRoles)
}

function * getAccountsWatcher () {
  yield takeLatest(UsersTypes.GET_ACCOUNTS, getAccounts)
}

function * createUserWatcher () {
  yield takeLatest(UsersTypes.CREATE_USER, createUser)
}

function * updateUserWatcher () {
  yield takeLatest(UsersTypes.UPDATE_USER, updateUser)
}

function * loginWatcher () {
  yield takeLatest(UsersTypes.LOGIN, login)
}

function * logoutWatcher () {
  yield takeLatest(UsersTypes.LOGOUT, logout)
}

function * changeUserActivationWatcher () {
  yield takeLatest(UsersTypes.CHANGE_USER_ACTIVATION, changeUserActivation)
}
function * deleteUserWatcher () {
  yield takeLatest(UsersTypes.DELETE_USER, deleteUser)
}

export default function * root () {
  yield fork(getUsersWatcher)
  yield fork(getUserWatcher)
  yield fork(getAllowIssuesUserWatcher)
  yield fork(getRolesWatcher)
  yield fork(getAccountsWatcher)
  yield fork(createUserWatcher)
  yield fork(updateUserWatcher)
  yield fork(loginWatcher)
  yield fork(logoutWatcher)
  yield fork(changeUserActivationWatcher)
  yield fork(deleteUserWatcher)
}
