import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import { createGetBuildingById } from '../../buildings/buildings-slice'
import { createGetDoorById } from '../../doors/doors-slice'
import { createGetInventoryCalculationSetting } from '../settings/selector'
import { getSelectedQuantitiesBySku } from '../orders/selectors'
import { token as getToken } from '../users/selectors'
import InventoryItemsActions, { InventoryItemsTypes } from './actions'
import axios from '../../utils/axios'
import { selectAreaById } from '../../areas/areas-slice'

const baseUrl = '/inventoryItems'
// Sagas
function * getInventoryItems ({ payload }) {
  yield put(InventoryItemsActions.getInventoryItemsLoading())
  try {
    const token = yield select(getToken)
    const { data } = yield call(axios.get, baseUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(InventoryItemsActions.getInventoryItemsSuccess(data))
  } catch (e) {
    yield put(InventoryItemsActions.getInventoryItemsFailure(e))
  }
}

function * getInventoryItemsProjection ({ payload }) {
  try {
    const { appointment } = payload

    const door = yield select(state => createGetDoorById(state)(appointment.get('doorId')))
    const area = yield select(() => selectAreaById(door?.areaId))
    const building = yield select(state => createGetBuildingById(state)(area?.buildingId))
    const getInventoryCalculationSetting = yield select(createGetInventoryCalculationSetting)
    const date = appointment.get('date')

    if (!(getInventoryCalculationSetting(building?.id) && date)) {
      return null
    }

    yield put(InventoryItemsActions.getInventoryItemsProjectionLoading()) // findsecrets-ignore-line

    const selectedQuantitiesBySku = yield select(getSelectedQuantitiesBySku)
    const skus = selectedQuantitiesBySku.keySeq()
    const location = building?.location

    let url = `${baseUrl}/projection`
    url += `?locationCode=${location.get('code')}`

    skus.forEach((sku, index) => {
      url += `&sku=${sku}`
    })

    const token = yield select(getToken)
    const { data } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    yield put(InventoryItemsActions.getInventoryItemsProjectionSuccess(data))
  } catch (e) {
    console.error(e)

    yield put(
      InventoryItemsActions.getInventoryItemsProjectionFailure(e)
    )
  }
}

function * getInventoryItemsProjections ({ payload }) {
  yield put(InventoryItemsActions.getInventoryItemsLoading())
  try {
    const token = yield select(getToken)
    const { buildingId } = payload
    const url = buildingId ? `/areas?buildingId=${buildingId}` : '/areas'
    const { data } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(InventoryItemsActions.getInventoryItemsSuccess(data))
  } catch (e) {
    yield put(InventoryItemsActions.getInventoryItemsFailure(e))
  }
}

// Watchers
function * getInventoryItemsWatcher () {
  yield takeLatest(InventoryItemsTypes.GET_INVENTORY_ITEMS, getInventoryItems)
}

function * getInventoryItemsProjectionWatcher () {
  yield takeLatest(
    InventoryItemsTypes.GET_INVENTORY_ITEMS_PROJECTION,
    getInventoryItemsProjection
  )
}
function * getInventoryItemsProjectionsWatcher () {
  yield takeLatest(
    InventoryItemsTypes.GET_INVENTORY_ITEMS_PROJECTION,
    getInventoryItemsProjections
  )
}

export default function * root () {
  yield fork(getInventoryItemsWatcher)
  yield fork(getInventoryItemsProjectionWatcher)
  yield fork(getInventoryItemsProjectionsWatcher)
}
