import { fromJS, List } from 'immutable'
import { createSelector, defaultMemoize } from 'reselect'
import moment from 'moment'

import { createGetDoorById } from '../../doors/doors-slice'
import { getAppointmentsForDoors } from '../doors/selectors'
import { createGetDriverEntityById } from '../../drivers/drivers-slice'
import { getStateAppointments } from '../common/selectors'
import { injectIsOutbound } from '../orders/selectors'
import { immutableEntityToOptions } from '../../utils/dropdownOptions'
import { pick } from '../../utils/common'
import sortById from '../../utils/sortById'
import { selectStartShiift } from '../../app/app-slice'
import { createGetCarrierById } from '../../carriers/carriers-slice'
import { createGetAppointmentById } from '../../appointments/appointments-slice'
import Raven from 'raven-js'

const MAX_SUGGESTIONS_COUNT = 8

export const createGetAppointmentsByDoorId = createSelector(
  getAppointmentsForDoors,
  appointments => id => {
    if (!appointments) return new List()

    return appointments.filter(appointment => appointment.get('doorId') === id) || new List()
  }
)

const getOutboundAwareOrdersForAppointment = appointment => {
  const rawOrders = appointment.get('orders', new List())
  const isOutboundAwareOrders = injectIsOutbound(rawOrders)
  return isOutboundAwareOrders.sort(sortById)
}
const getOutboundOrdersAwareAppointment = appointment => {
  if (!appointment) return appointment

  const orders = getOutboundAwareOrdersForAppointment(appointment)
  const isOutbound = orders.size === 0 || orders.get(0).get('isOutbound')

  return appointment.merge({
    orders,
    isOutbound
  })
}

export const createGetAppointmentsByDoorAndDate = createSelector(
  createGetAppointmentsByDoorId,
  selectStartShiift,
  createGetDoorById,
  (getAppointmentsByDoorId, startShift, getDoorById) => doorId => date => {
    const appointments = getAppointmentsByDoorId(doorId)
    const door = getDoorById(doorId)
    const timezone = door?.area?.building?.timezone || 'UTC'
    return (
      appointments.filter(appointment => {
        const apptDate = moment.tz(appointment.get('date'), timezone)
        const isSameDay = apptDate.isSame(date, 'date')
        const isSameDoor = appointment.get('doorId') === doorId
        let isSameHour = apptDate.hour() === date.hour()

        // it's startShift and apptDate is earlier then startShift
        if (date === startShift && apptDate < startShift) {
          // we say it's ok
          isSameHour = true
        }

        return isSameDoor && isSameDay && isSameHour
      }) || new List()
    ).map(getOutboundOrdersAwareAppointment)
  }
)

const createGenericAppointmentsSelector = field =>
  createSelector(getStateAppointments, appointments => appointments.get(field))

export const getAppointment = createGenericAppointmentsSelector('appointment')

export const getPages = createGenericAppointmentsSelector('pages')

export const getAllAppointments = createGenericAppointmentsSelector('appointments')

export const getAppointmentOrders = createGenericAppointmentsSelector('appointmentOrders')

export const getAllAppointmentStatuses = createGenericAppointmentsSelector('appointmentStatuses')

export const getIsAppointmentModalVisible = createGenericAppointmentsSelector(
  'isUpsertAppointmentVisible'
)

export const getEditingAppointmentTab = createGenericAppointmentsSelector('editingAppointmentTab')

export const getOpenEditAppointmentIsLoading = createGenericAppointmentsSelector(
  'openEditAppointmentIsLoading'
)

export const getStateEditingAppointment = createGenericAppointmentsSelector('editingAppointment')

export const getEditingAppointment = createSelector(
  getStateEditingAppointment,
  getOutboundOrdersAwareAppointment
)

export const getEditingAppointmentIssues = createGenericAppointmentsSelector(
  'editingAppointmentIssues'
)

export const getDeletingAppointment = createGenericAppointmentsSelector('removingAppointment')

export const getAppointmentCounts = createGenericAppointmentsSelector('appointmentCounts')

export const getRecalculateDurationFlag =
  createGenericAppointmentsSelector('recalculateDurationFlag')

export const getClearRequestIsLoading = createGenericAppointmentsSelector('clearRequestIsLoading')

export const getCreateAppointmentIsLoading = createGenericAppointmentsSelector(
  'createAppointmentIsLoading'
)

export const getUpdateAppointmentIsLoading = createGenericAppointmentsSelector(
  'updateAppointmentIsLoading'
)

export const getAppointmentsIsLoading = createGenericAppointmentsSelector(
  'getAppointmentsIsLoading'
)

export const createGetAppointmentStatusById = createSelector(
  getAllAppointmentStatuses,
  appointmentStatuses => appointmentStatusId =>
    appointmentStatuses.filter(as => as.get('id') === appointmentStatusId).first()
)

function getConflictingInventory (appointmentIssues, getAppointmentById, getDoorById) {
  return appointmentIssues
    .get('hasConflictingInventory')
    .reduce((downstreamConflict, conflictByAppointmentId, sku) => {
      const conflictingAppointments = conflictByAppointmentId
        .keySeq()
        .map(appointmentId => {
          const appointment = getAppointmentById(String(appointmentId))
          if (!appointment) return null
          if (!appointment.orders) {
            try {
              Raven.captureMessage('hasConflictingInventory: Appointment without orders found', {
                level: 'warn',
                extra: { appointment }
              })
            } finally {
              console.debug('hasConflictingInventory: no orders found', { appointment })
            }
            return null
          }
          const order = appointment.orders.find(order =>
            order.items.find(item => {
              return String(item.sku) === String(sku)
            })
          )

          const door = getDoorById(appointment.doorId)
          const timezone = door?.area?.building?.timezone

          return {
            appointment: {
              ...appointment,
              date: moment.tz(appointment.date, timezone).format('MM-DD-YYYY HH:mm')
            },
            order,
            door
          }
        })
        .filter(conflict => conflict !== null)

      // TODO: Why aren't we using item to reduce this correctly?
      // FIXME: Hack to obtain item entity for not having indexed items
      const item = conflictingAppointments.reduce((item, conflict) => {
        return conflict?.order?.items ? conflict?.order?.items?.find(item => item.sku === sku) : {}
      }, {})

      const newItem = {
        item: pick(item, ['name', 'sku']),
        conflictingAppointments
      }

      return [...downstreamConflict, newItem]
    }, [])
}

function getLateShippingOrders (lateOrders) {
  return !lateOrders._tail ? [] : lateOrders._tail.array
}

export const createGetAppointmentIssues = createSelector(
  createGetAppointmentById,
  createGetDoorById,
  (getAppointmentById, getDoorById) =>
    defaultMemoize(appointmentIssues => {
      if (
        !appointmentIssues.get('hasConflictingInventory') &&
        !appointmentIssues.get('hasLateShippingOrders')
      ) {
        return appointmentIssues
      }

      let hasConflictingInventory = null
      if (appointmentIssues.get('hasConflictingInventory')) {
        hasConflictingInventory = getConflictingInventory(
          appointmentIssues,
          getAppointmentById,
          getDoorById
        )
      }

      let hasLateShippingOrders = null
      if (appointmentIssues.get('hasLateShippingOrders')) {
        hasLateShippingOrders = getLateShippingOrders(
          appointmentIssues.get('hasLateShippingOrders')
        )
      }

      return fromJS({
        ...appointmentIssues.toJS(),
        hasConflictingInventory,
        hasLateShippingOrders
      })
    })
)

export const getAppointments = createSelector(
  getAllAppointments,
  createGetDoorById,
  createGetDriverEntityById,
  createGetCarrierById,
  (appointments, getDoorById, getDriverById, getCarrierById) =>
    appointments &&
    appointments.map(appt => {
      const extendedAppt = appt
        .set('door', getDoorById(appt.get('doorId')))
        .set('driver', getDriverById(appt.get('driverId')))
        .set('carrier', getCarrierById(appt.get('carrierId')))

      return getOutboundOrdersAwareAppointment(extendedAppt)
    })
)

export const getEditingAppointmentSuggestions = createGenericAppointmentsSelector(
  'editingAppointmentSuggestionsTimes'
)

export const getTopEditingAppointmentSuggestions = createSelector(
  getEditingAppointmentSuggestions,
  createGetDoorById,
  (editingAppointmentSuggestions, getDoorById) => {
    if (!editingAppointmentSuggestions || !editingAppointmentSuggestions.count() > 0) {
      return fromJS({
        suggestions: [],
        hasMore: false
      })
    }

    const topSuggestions = []
    let index = 0
    let doorsLength = 0
    while (
      topSuggestions.length < MAX_SUGGESTIONS_COUNT &&
      index < editingAppointmentSuggestions.count()
    ) {
      const suggestedAppointmentTime = editingAppointmentSuggestions.get(index)
      doorsLength += suggestedAppointmentTime.get('doors', new List()).count()

      const suggestedAppointmentDoors = suggestedAppointmentTime
        .get('doors')
        .slice(0, MAX_SUGGESTIONS_COUNT - topSuggestions.length)

      suggestedAppointmentDoors.forEach(doorId => {
        const door = getDoorById(doorId)
        const timezone = door?.area?.building?.timezone ?? 'UTC'
        const date = suggestedAppointmentTime.get('time')
        topSuggestions.push({
          id: `${suggestedAppointmentTime.get('time')}-${doorId}`,
          date,
          dateDescription: moment.tz(date, timezone).format('MM-DD-YYYY, HH:mm'),
          door
        })
      })

      index++
    }

    return fromJS({
      suggestions: topSuggestions,
      hasMore: topSuggestions.length < doorsLength && index < editingAppointmentSuggestions.count()
    })
  }
)

export const getAppointmentStatusesAsOptions = createSelector(
  getAllAppointmentStatuses,
  (_, isOutbound) => isOutbound,
  (appointmentStatuses, isOutbound) => {
    if (!appointmentStatuses) return []

    appointmentStatuses = !isOutbound
      ? appointmentStatuses.filter(apptStatus => apptStatus.get('id') !== 40)
      : appointmentStatuses
    appointmentStatuses = isOutbound
      ? appointmentStatuses.filter(apptStatus => apptStatus.get('id') !== 45)
      : appointmentStatuses

    return immutableEntityToOptions(appointmentStatuses)
  }
)

export const getSearchAttributes = createSelector(getStateAppointments, appointments => ({
  id: appointments.get('id'),
  searchText: appointments.get('searchText'),
  customerPurchaseOrder: appointments.get('customerPurchaseOrder'),
  customerSelect: appointments.get('customerSelect'),
  appointmentsStatusSelect: appointments.get('appointmentsStatusSelect'),
  shippingDateSelect: appointments.get('shippingDateSelect'),
  destinationSelect: appointments.get('destinationSelect'),
  buildingId: appointments.get('buildingId'),
  currentPage: appointments.get('currentPage') || 1
}))

export const getSearchAttributesCount = createSelector(getSearchAttributes, searchAttributes => {
  const keys = [
    'searchText',
    'customerPurchaseOrder',
    'customerSelect',
    'appointmentsStatusSelect',
    'shippingDateSelect',
    'destinationSelect'
  ]

  return pick(searchAttributes, keys)
    .filter(attribute => attribute)
    .count()
})

export const isEditingAppointmentOutbound = createSelector(
  getEditingAppointment,
  editingAppointment => {
    return editingAppointment ? editingAppointment.get('isOutbound') : true
  }
)

// FIXME pending naming hack for name collision in the saga
export default {
  getAppointments,
  createGetAppointmentById
}
