import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { InventoryItemsTypes } from './actions'

// getInventoryItems
export const getInventoryItemsLoading = state =>
  state.merge({
    getInventoryItemsIsLoading: true,
    getInventoryItemsErrorMessage: ''
  })

export const getInventoryItemsSuccess = (state, { inventoryItems }) =>
  state.merge({
    inventoryItems,
    getInventoryItemsIsLoading: false,
    getInventoryItemsErrorMessage: null
  })

export const getInventoryItemsFailure = (state, { errorMessage }) =>
  state.merge({
    inventoryItems: null,
    getInventoryItemsIsLoading: false,
    getInventoryItemsErrorMessage: errorMessage
  })

// getInventoryItemsProjection
export const getInventoryItemsProjectionLoading = state =>
  state.merge({
    getInventoryItemsProjectionIsLoading: true,
    getInventoryItemsProjectionErrorMessage: ''
  })

export const getInventoryItemsProjectionSuccess = (
  state,
  { inventoryItemsProjection }
) =>
  state.merge({
    inventoryItemsProjection,
    getInventoryItemsProjectionIsLoading: false,
    getInventoryItemsProjectionErrorMessage: null
  })

export const getInventoryItemsProjectionFailure = (state, { errorMessage }) =>
  state.merge({
    inventoryItemsProjection: null,
    getInventoryItemsProjectionIsLoading: false,
    getInventoryItemsProjectionErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InventoryItemsTypes.GET_INVENTORY_ITEMS_LOADING]: getInventoryItemsLoading,
  [InventoryItemsTypes.GET_INVENTORY_ITEMS_SUCCESS]: getInventoryItemsSuccess,
  [InventoryItemsTypes.GET_INVENTORY_ITEMS_FAILURE]: getInventoryItemsFailure,

  [InventoryItemsTypes.GET_INVENTORY_ITEMS_PROJECTION_LOADING]: getInventoryItemsProjectionLoading,
  [InventoryItemsTypes.GET_INVENTORY_ITEMS_PROJECTION_SUCCESS]: getInventoryItemsProjectionSuccess,
  [InventoryItemsTypes.GET_INVENTORY_ITEMS_PROJECTION_FAILURE]: getInventoryItemsProjectionFailure
})
