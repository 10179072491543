import styled, { css } from 'styled-components'
import ControlledTable from '../components/ControlledTable'

export const TableCss = css`
  background-color: ${props => props.theme.colors.contrast};
  border-spacing: 0;
  width: 100%;
`

export const StyledTable = styled.table`
  ${TableCss}
`

export const TableHeaderCss = css`
  background-color: ${props => props.theme.flatBackground};
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fonts.defaultFontSize};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 1rem 0.75rem 0.75rem;
  text-align: left;
`

export const TableColumnHeader = styled.th`
  ${TableHeaderCss}
  ${props => props.nowrap ? css`white-space: nowrap;` : ''}
`

export const TableColCss = css`
  padding: 1rem 0.75rem;
  font-size: 0.75rem;
  color: ${props => props.theme.selectTextColor};
  ${props => props.singleLine ? 'white-space: nowrap;' : ''}
`

export const TableCol = styled.td`
  ${TableColCss}
  ${props => props.centerText ? 'text-align: center;' : ''}
`

export const TableRowCss = css`
  background: ${props => props.theme.colors.contrast};
`

export const TableRow = styled.tr`
  ${TableRowCss}
`
export const StyledControlledTable = styled(ControlledTable)`
  ${TableCss}
  margin-bottom: 1rem;

  td {
    ${TableColCss}
    color: ${props => props.theme.darkGray};
    font-size: 0.75rem;
    letter-spacing: 1.07px;
    text-align: left;
    border-bottom: 1px solid ${props => props.theme.flatBackground};
  }

  th {
    ${TableHeaderCss}
    background-color: ${props => props.theme.colors.contrast};
    color: ${props => props.theme.inputColor};
    font-size: 0.75rem;
    font-weight: bold;
    text-align: left;
  }`
