import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Container } from '../../../styled/Containers'
import { DefaultText, DefaultTextBold, PrimaryText } from '../../../styled/Texts'

class PickupLocation extends Component {
  render () {
    const { location } = this.props
    return (
      <Container>
        <PrimaryText uppercase>Pickup</PrimaryText>
        <p>
          {
            location ? (
              <Fragment>
                <DefaultText spaced>{location.name}, </DefaultText>
                <DefaultText spaced>{location.address1}</DefaultText>
                <br />
                <DefaultTextBold spaced>
                  {location.city}, {location.state}
                </DefaultTextBold>
              </Fragment>
            ) : (
              <DefaultTextBold spaced>N/A</DefaultTextBold>
            )
          }
        </p>
      </Container>
    )
  }
}

PickupLocation.propTypes = {
  location: PropTypes.object
}

export default PickupLocation
