import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import PropTypes from 'prop-types'

import { StyledTableEventCard } from '../styled/Cards'
import { TYPES } from './TimeTable/DropOnTableHoc'

export default function DraggableTableEventCard (props) {
  const [{ isDragging, canDrag }, drag, preview] = useDrag({
    type: TYPES.EVENT,
    item: { ...props },
    canDrag: () => {
      const { isDisabled } = props
      return !isDisabled
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <StyledTableEventCard drag={drag} {...props} isDragging={isDragging} canDrag={canDrag}/>
  )
}

DraggableTableEventCard.propTypes = {
  isDisabled: PropTypes.bool
}
