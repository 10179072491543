import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ButtonIcon } from '../../styled/Buttons'
import { CloseIcon } from '../icons'
import { GridCol } from '../../styled/Grids'
import { DefaultText, ModalTitle } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent
} from '../../styled/Modals'
import { getIsOrderDetailsModalOpen, getOrderDetailsModalAppointment } from '../../modules/ui/selectors'
import UiActions from '../../modules/ui/actions'
import CustomerInformation from '../tabs/InventoryTab/CustomerInformation'
import { DestinationBox, DestinationHeader } from '../../styled/Boxes'
import { Scrollable } from '../../styled/Containers'
import { getOriginDestinationLabel, getPurchaseSalesLabel } from '../../utils/common'

class DestinationDetailsModal extends Component {
  componentDidMount () {
    document.addEventListener('keydown', this.onEscClose)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onEscClose)
  }

  onEscClose = event => {
    if (event.keyCode === 27) this.props.close()
  }

  render () {
    const { isOpen, appointment, close, theme } = this.props

    if (!isOpen) {
      return null
    }

    return (
      <StyledModal isOpen={isOpen}>
        <StyledModalAlertHeader>
          <ModalTitle>{`${getOriginDestinationLabel(appointment.isOutbound)} details for appointment: #${appointment.id}`}</ModalTitle>
          <ButtonIcon onClick={close}>
            <CloseIcon color={theme.contrastTextColor} />
          </ButtonIcon>
        </StyledModalAlertHeader>
        <StyledModalContent>
          <GridCol maxHeight={480} noMaxHeight>
            <Scrollable>
              {
                appointment.orders.map(order => {
                  const references = order.data.otherRefs || []

                  return (
                    <DestinationBox key={order.id}>
                      <DestinationHeader>
                        <h3>{getPurchaseSalesLabel(order.isOutbound)}: <span>{order.primaryRefValue}</span></h3>

                        <div>
                          {
                            references ? references.map((ref, index) => (
                              <span key={index} className={'content gray'}>
                                <DefaultText spaced bold>{ref.type.trim()}:</DefaultText>
                                <DefaultText spaced bold>{ref.val}</DefaultText>
                              </span>
                            )) : (
                              <span>No references</span>
                            )
                          }
                        </div>
                      </DestinationHeader>

                      <CustomerInformation
                        customer={order.customer}
                        destination={order.destination}
                        noReference
                        isOutbound={order.isOutbound}
                      />
                    </DestinationBox>
                  )
                })
              }
            </Scrollable>
          </GridCol>
        </StyledModalContent>
      </StyledModal>
    )
  }
}

DestinationDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  appointment: PropTypes.object,
  theme: PropTypes.object
}

const mapStateToProps = state => {
  const appointment = getOrderDetailsModalAppointment(state)
  return {
    isOpen: getIsOrderDetailsModalOpen(state),
    appointment: appointment ? appointment.toJS() : null
  }
}

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(UiActions.closeDestinationDetailsModal())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(DestinationDetailsModal))
