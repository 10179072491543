import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { SMSTypes } from './actions'

export const sendSMSLoading = state =>
  state.merge({
    sendSMSIsLoading: true,
    sendSMSErrorMessage: null
  })

export const sendSMSSuccess = state =>
  state.merge({
    sendSMSIsLoading: false,
    sendSMSErrorMessage: null
  })

export const sendSMSFailure = (state, { errorMessage }) =>
  state.merge({
    sendSMSIsLoading: false,
    sendSMSErrorMessage: errorMessage.message
  })

export const reducer = createReducer(INITIAL_STATE, {
  [SMSTypes.SEND_SMS_LOADING]: sendSMSLoading,
  [SMSTypes.SEND_SMS_SUCCESS]: sendSMSSuccess,
  [SMSTypes.SEND_SMS_FAILURE]: sendSMSFailure
})
