import { Map } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  orderItems: null,
  getOrderItemsIsLoading: false,
  getOrderItemsErrorMessage: null
})
