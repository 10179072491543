import React, { Component } from 'react'
import { ButtonIcon } from '../styled/Buttons'
import CloseIcon from './icons/Close'
import { theme } from '../themes/taylor-farms'
import PropTypes from 'prop-types'

export default class CloseButton extends Component {
  render () {
    return (
      <ButtonIcon className="close" onClick={this.props.onClick}>
        <CloseIcon color={theme.contrastTextColor} />
      </ButtonIcon>
    )
  }
}

CloseButton.propTypes = {
  onClick: PropTypes.func
}
