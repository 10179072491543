import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
import {
  token as getToken
} from '../users/selectors'

import axios from '../../utils/axios'
import SMSActions, { SMSTypes } from './actions'
import AppointmentsActions from '../appointments/actions'

function * sendSMS ({ to, template, content }) {
  yield put(SMSActions.sendSMSLoading())
  try {
    const token = yield select(getToken)
    const payload = { to, template, content }
    const {
      data
    } = yield call(axios.post, '/sms', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(SMSActions.sendSMSSuccess(data))
    yield put(AppointmentsActions.closeUpsertAppointment())
  } catch (e) {
    yield put(SMSActions.sendSMSFailure(e))
  }
}

function * sendSMSWatcher () {
  yield takeLatest(SMSTypes.SEND_SMS, sendSMS)
}

export default function * root () {
  yield fork(sendSMSWatcher)
}
