import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import DownloadIcon from './icons/Download'
import PropTypes from 'prop-types'

export default class CsvLink extends Component {
  render () {
    const {
      filename,
      children,
      data,
      className
    } = this.props
    return (
      <CSVLink className={className} filename={filename} data={data}>
        { children || 'Download CSV' }
        <DownloadIcon/>
      </CSVLink>
    )
  }
}

CsvLink.propTypes = {
  filename: PropTypes.string,
  children: PropTypes.any,
  data: PropTypes.any,
  className: PropTypes.any
}
