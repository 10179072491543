export const getStateApp = state => state.app || new Map()

export const getStateAppointmentOrders = state => state.appointmentOrders || new Map()

export const getStateAppointments = state => state.appointments || new Map()

export const getStateBuildings = state => state.buildings || new Map()

export const getStateCarrierRequests = state => state.carrierRequests || new Map()

export const getStateCarriers = state => state.carriers || new Map()

export const getStateCustomers = state => state.customers || new Map()

export const getStateDoors = state => state.doors || new Map()

export const getStateEmails = state => state.emails || new Map()

export const getStateEntities = state => state.entities || new Map()

export const getStateFeeds = state => state.feeds || new Map()

export const getStateInventoryItems = state => state.inventoryItems || new Map()

export const getStateItems = state => state.items || new Map()

export const getStateLocations = state => state.locations || new Map()

export const getStateOrderItems = state => state.orderItems || new Map()

export const getStateOrders = state => state.orders || new Map()

export const getStateReports = state => state.reports || new Map()

export const getStateSettings = state => state.settings || new Map()

export const getStateSites = state => state.sites || new Map()

export const getStateSms = state => state.sms || new Map()

export const getStateUsers = state => state.users || new Map()

export const getStateUI = state => state.ui || new Map()
