import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getReport
  getReport: ['payload'],
  getReportLoading: null,
  getReportSuccess: ['data'],
  getReportFailure: ['errorMessage'],

  // warning bubbles
  updateWarnings: null
})

export const ReportsTypes = Types
export default Creators
