import { Map } from 'immutable'
import React, { PureComponent, Fragment } from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'

import { theme } from '../themes/taylor-farms'
import { ButtonIcon } from '../styled/Buttons'
import { CartIcon, StyledTruckIcon } from '../styled/Icons'
import { Container } from '../styled/Containers'
import { DragHandle, Info, Issues, ShipTo, TotalOrders } from '../styled/Boxes'
import { Title } from '../styled/Texts'
import { convertToCamelCase, getInboundOutboundLabel, ignore } from '../utils/common'
import { appointmentStatusesMap, requestStatusesMap } from '../utils/time'
import cartIcon from '../assets/images/group.svg'
import DeleteIcon from './icons/Delete'
import ScheduleIcon from './icons/Schedule'
import IssueIcon from './icons/Issue'
import BubbleIcon from './icons/Bubble'
import { Badge } from '../styled/Notifications'
import ArrowIcon from './icons/Arrow'
import { SimpleSpinner } from '../styled/Loading'

class Event extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isTotalOrdersChanged: false,
      draggingDuration: false,
      newDuration: props.duration,
      draggingStartY: 0,
      draggingStartDuration: 0,
      isRequestLate: false
    }
  }

  calculateLateness = () => {
    const { calculateLateness, appointment } = this.props
    this.setState({
      isRequestLate: calculateLateness(appointment)
    })
  }

  componentDidMount () {
    document.addEventListener('mouseup', this.onMouseUp)
    this.calculateLateness()
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  componentDidUpdate (prevProps, prevState) {
    const { duration } = this.props
    if (duration !== prevProps.duration) {
      this.setState({ newDuration: duration })
    }
  }

  onHandleMouseDown = e => {
    e.stopPropagation()
    e.preventDefault()

    document.body.style.cursor = 'row-resize'

    document.addEventListener('mousemove', this.onMouseMove)
    this.setState({
      draggingDuration: true,
      draggingStartY: e.clientY,
      draggingStartDuration: this.props.duration,
      newDuration: this.props.duration
    })
  }

  onMouseMove = e => {
    const { size } = this.props
    const { draggingStartY, draggingStartDuration } = this.state
    const diffY = e.clientY - draggingStartY

    const diffDuration = Math.round((diffY * 100 * 4) / size / 140) * 15
    const newDuration = Math.min(
      Math.max(draggingStartDuration + diffDuration, 15),
      240
    )

    this.setState({ newDuration })
  }

  onMouseUp = e => {
    e.stopPropagation()

    const { onDurationChange, duration } = this.props
    const { newDuration } = this.state

    document.removeEventListener('mousemove', this.onMouseMove)
    document.body.style.cursor = 'inherit'

    this.setState({
      draggingDuration: false
    })

    if (newDuration !== duration && onDurationChange) {
      onDurationChange(newDuration)
    }
  }

  getTotalOrderStyle = isTotalOrdersChanged => ({
    animationName: isTotalOrdersChanged ? 'text-animation' : 'none'
  })

  getEventStatusIcons = (
    appointment,
    inventoryIssues,
    requestStatus,
    appointmentStatus,
    isRequestLate,
    status
  ) => {
    const appointmentStatusWithPossibleException = [
      appointmentStatusesMap.draft,
      appointmentStatusesMap.scheduled
    ].includes(appointmentStatus)
    const inventoryReviewUserId = appointment.inventoryReviewUserId
    const defaultProps = { color: theme.appointmentStatuses[status]?.icon || '#ffffff' }
    const calendarProps = { ...defaultProps }
    const icons = {
      DeleteIcon: <DeleteIcon {...defaultProps} />,
      CalendarIcon: <ScheduleIcon key="scheduleicon" {...calendarProps} />,
      InfoIcon: <BubbleIcon key="bubble-icon" {...defaultProps} />,
      TruckIcon: <StyledTruckIcon key="truck-icon" {...defaultProps} scheduled outbound={appointment.isOutbound} />,
      IssueIcon: null
    }

    if (
      appointmentStatusWithPossibleException &&
      (inventoryIssues && inventoryIssues.length > 0 && !inventoryReviewUserId)
    ) {
      icons.IssueIcon = <IssueIcon key="inventory-issue-icon" />
    }

    return icons
  }

  doOnNextOrPreviousDayClick = data => e => {
    e.preventDefault()
    const { onNextOrPreviousDayClick } = this.props
    onNextOrPreviousDayClick(data)
  }

  showDetailsModal = () => {
    const { openDestinationDetailsModal } = this.props
    openDestinationDetailsModal(this.props.appointment)
  }

  render () {
    const {
      id,
      appointment,
      totalOrders,
      onDelete,
      className,
      size,
      onEdit,
      onMessage,
      onInventoryIssueClick,
      duration,
      inProgress,
      width,
      requestStatus,
      appointmentStatus,
      timezone,
      initTime,
      drag,
      startShift,
      endShift,
      isDragging,
      isLoading,
      isDisabled,
      isInventoryCalculationEnabled,
      areIssuesDisplayed,
      isContinueNextDayHandled,
      isSizeDurationAware
    } = this.props
    const {
      isRequestLate,
      isTotalOrdersChanged,
      draggingDuration,
      newDuration
    } = this.state
    let issues = 0
    let inventoryIssues
    if (Array.isArray(appointment.inventoryIssues)) {
      issues = appointment.inventoryIssues.length
      inventoryIssues = appointment.inventoryIssues
    } else {
      if (!appointment.inventoryIssues) {
        appointment.inventoryIssues = {}
      }
      inventoryIssues = Object.values(
        // while system has Immutable poking around, ignore returns Immutable
        // this logic should match "inventory issues report"
        ignore(appointment.inventoryIssues, ['appointmentDate']).toJS()
      ).filter(inventory =>
        inventory?.remainderQty > 0 &&
        inventory?.projectedStock < 0
      )
      issues = inventoryIssues ? inventoryIssues.length : 0
    }

    let height = size

    if (isSizeDurationAware) {
      height = ((draggingDuration ? newDuration : duration) * size * 140) / 6000
    }

    initTime.tz(timezone)
    let continueNextDay = false
    let continuePreviousDay = false
    const finalTime = initTime.clone().add(draggingDuration ? newDuration : duration, 'minutes').subtract(1, 'second')
    if (isContinueNextDayHandled) {
      if (!isDragging && finalTime > endShift) {
        const diffDur = endShift.diff(initTime, 'minutes')
        height = (isSizeDurationAware) ? (diffDur * size * 140) / 6000 : height
        continueNextDay = true
      } else if (!isDragging && initTime < startShift) {
        const diffDur = finalTime.diff(startShift, 'minutes')
        height = (isSizeDurationAware) ? (diffDur * size * 140) / 6000 : height
        continuePreviousDay = true
      }
    }

    if (!isDragging && initTime.day() !== finalTime.day() && isSizeDurationAware) {
      height += Math.ceil(finalTime.diff(initTime, 'days', true)) * 30 // DaySeparator height
    }

    const validAppointmentStatuses = [
      'Draft',
      'Scheduled',
      'Checked In',
      'Loading',
      'Checked Out'
    ]
    const isCheckedOut = appointmentStatus === 'Checked Out'
    const status = inProgress
      ? convertToCamelCase('inProgress')
      : convertToCamelCase(
        isRequestLate && !isCheckedOut ? requestStatusesMap.carrierLate : appointmentStatus
      )
    const { DeleteIcon, CalendarIcon, InfoIcon, IssueIcon, TruckIcon } = this.getEventStatusIcons(
      appointment,
      inventoryIssues,
      requestStatus,
      validAppointmentStatuses.find(as => as === appointmentStatus),
      isRequestLate,
      status
    )

    const orderDestinations = appointment.orders.map(order => `- ${order.destination.name}`)

    // Added in order to turn on buttons easily, it needed
    const isButtonDisabled = isDisabled
    const isFullSize = (size === 100 || size === 140)

    return (
      <div ref={drag} className={className}>
        <Container className={className} isDisabled={isDisabled}>
          {
            isInventoryCalculationEnabled && IssueIcon && size > 25 ? (
              <Badge right>
                <ButtonIcon
                  isDisabled={isButtonDisabled}
                  title="There are inventory issues within this appointment"
                  onClick={isLoading || isButtonDisabled ? undefined : onInventoryIssueClick}
                >
                  {IssueIcon}
                </ButtonIcon>
              </Badge>
            ) : null
          }
          <Container
            height={height}
            width={width}
            status={status}
            className="event-wrapper"
          >
            {status === appointmentStatusesMap.loading && (
              <Container>
                <CartIcon src={cartIcon} alt="cartIcon" />
              </Container>
            )}
            {size > 25 && (
              <Container>
                {duration <= 15 && (
                  <Title className="empty" status={status}>{' '}</Title>
                )}
                {size >= 100 && duration > 15 && (
                  <Title status={status}>{`${getInboundOutboundLabel(appointment.isOutbound)} Appt #${id}`}</Title>
                )}
                {size < 100 &&
                      size >= 75 && duration > 15 && (
                      <Title status={status}>{`${getInboundOutboundLabel(appointment.isOutbound)} Appt #${id}`}</Title>
                )}
                {height > 35 && (
                  <Info small={size < 100} status={status}>
                    <TotalOrders
                      small={size < 100}
                      style={this.getTotalOrderStyle(
                        isTotalOrdersChanged
                      )}
                    >{`Orders (${totalOrders})`}</TotalOrders>
                    {areIssuesDisplayed && <Issues
                      small={size < 100}
                    >{`Issues (${issues})`}</Issues>}
                  </Info>
                )}
              </Container>
            )}
            <Container className="actions">
              {
                isLoading ? (
                  <SimpleSpinner inline small />
                ) : (
                  <ButtonIcon
                    isDisabled={isButtonDisabled}
                    title="Change appointment information"
                    onClick={(isButtonDisabled) ? undefined : onEdit}
                  >
                    {CalendarIcon}
                  </ButtonIcon>
                )
              }

              {
                size > 25 ? (
                  <Fragment>
                    {appointment.isOutbound && <ButtonIcon
                      isDisabled={isButtonDisabled}
                      title="Summon driver details"
                      onClick={(isButtonDisabled) ? undefined : onMessage}
                    >
                      {InfoIcon}
                    </ButtonIcon>}

                    <ButtonIcon
                      isDisabled={isButtonDisabled}
                      title="Delete this appointment"
                      onClick={(isButtonDisabled) ? undefined : onDelete}
                    >
                      {DeleteIcon}
                    </ButtonIcon>

                    {
                      ((orderDestinations.length && size >= 50 && size <= 75) || duration <= 30) ? (
                        <ButtonIcon
                          isDisabled={isButtonDisabled}
                          onClick={(isButtonDisabled) ? undefined : this.showDetailsModal}
                          title={`Destinations:\n\n${orderDestinations.join('\n')}`}
                        >
                          {TruckIcon}
                        </ButtonIcon>
                      ) : null
                    }
                  </Fragment>
                ) : ''
              }
            </Container>

            {
              orderDestinations.length && isFullSize && duration > 30 ? (
                <ShipTo duration={duration} title={`Destinations:\n\n${orderDestinations.join('\n')}`}>
                  <ButtonIcon
                      isDisabled={isButtonDisabled}
                      onClick={(isButtonDisabled) ? undefined : this.showDetailsModal}
                  >
                    {TruckIcon}

                    <span>
                      {`${orderDestinations[0].substr(2)}`}
                    </span>
                  </ButtonIcon>
                </ShipTo>
              ) : null
            }

            { (continueNextDay) ? null : <DragHandle left onMouseDown={this.onHandleMouseDown} />}
            { (continueNextDay || continuePreviousDay) ? (
              <ButtonIcon onClick={this.doOnNextOrPreviousDayClick({
                // pass some already calculated stuff
                continueNextDay,
                continuePreviousDay,
                initTime,
                finalTime
              })} className="next-day" title={continueNextDay ? 'This appointment continues on the next shift' : 'This appointment started in the previous shift'}>
                <ArrowIcon direction={continueNextDay ? 'down' : 'up'} />
              </ButtonIcon>
            ) : null }
            { continueNextDay ? null : <DragHandle right onMouseDown={this.onHandleMouseDown} /> }
          </Container>
        </Container>
      </div>
    )
  }
}

Event.propTypes = {
  id: PropTypes.number,
  initTime: PropTypes.object,
  endTime: PropTypes.object,
  primaryRefValue: PropTypes.string,
  PO: PropTypes.string,
  // prevent warnings
  duration: PropTypes.any,
  appointmentStatuses: PropTypes.any,
  appointment: PropTypes.any,
  carrierRequest: PropTypes.any,
  totalOrders: PropTypes.any,
  size: PropTypes.any,
  calculateLateness: PropTypes.func,
  onDurationChange: PropTypes.func,
  onDelete: PropTypes.func,
  onMessage: PropTypes.func,
  onNextOrPreviousDayClick: PropTypes.func,
  onInventoryIssueClick: PropTypes.func,
  openDestinationDetailsModal: PropTypes.func,
  onEdit: PropTypes.func,
  isUpsertAppointmentVisible: PropTypes.bool,
  inProgress: PropTypes.bool,
  width: PropTypes.any,
  requestStatus: PropTypes.any,
  appointmentStatus: PropTypes.any,
  className: PropTypes.string,
  timezone: PropTypes.string,
  startShift: PropTypes.object,
  endShift: PropTypes.object,
  isDragging: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInventoryCalculationEnabled: PropTypes.bool,
  drag: PropTypes.any,
  areIssuesDisplayed: PropTypes.bool,
  isContinueNextDayHandled: PropTypes.bool,
  isSizeDurationAware: PropTypes.bool
}

Event.defaultProps = {
  id: 0,
  initTime: moment(),
  endTime: moment().add(1, 'hours'),
  primaryRefValue: '',
  PO: '',
  size: 100,
  appointmentStatus: Map({}),
  areIssuesDisplayed: true,
  isContinueNextDayHandled: true,
  isSizeDurationAware: true
}

export default Event
