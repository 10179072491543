import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'

import { Container, Scrollable } from '../../../styled/Containers'
import { GridCol } from '../../../styled/Grids'
import { createGetDoorById } from '../../../doors/doors-slice'
import {
  getAllCarrierRequests,
  getSearchAttributes,
  getSearchAttributesCount,
  numberOfCarrierRequests
} from '../../../modules/carrierRequests/selectors'
import { selectAllSites } from '../../../sites/sites-slice'
import { getOrders } from '../../../modules/orders/selectors'
import { requestStatusesMap } from '../../../utils/time'
import AppointmentsActions from '../../../modules/appointments/actions'
import CarrierRequestsActions from '../../../modules/carrierRequests/actions'
import DraggableRequestCard from '../../../components/DraggableRequestCard'
import Filters from '../layout/Filters'
import HandleError from '../../../components/hocs/HandleError'
import Notification from '../../../components/Notification'
import OrdersActions from '../../../modules/orders/actions'
import socket from '../../../utils/socket'
import { APPOINTMENT_STATUS } from '@smartdock-shared/appointmentStatus'
import { selectBuildingsForCurrentSite, selectCurrentBuildingId, selectCurrentSiteId } from '../../../app/app-slice'

const statusOptions = [
  { label: 'Pending', value: 'pending' },
  { label: 'Cancel', value: 'canceled' },
  { label: 'Reschedule', value: 'reschedule' }
]

class Requests extends PureComponent {
  state = {
    hide: false,
    showFilters: false
  }

  toggleShowFilters = () => {
    return this.setState({
      showFilters: !this.state.showFilters
    })
  }

  componentDidMount () {
    const {
      getAllCarrierRequests,
      updateCarrierRequestsWithSocketCarrierRequest,
      updateCountCarrierRequestsWithSocketCountCarrierRequests,
      getNumberOfCarrierRequests
    } = this.props

    getAllCarrierRequests({
      includes: ['carrier', 'appointment', 'carrierRequestOrder']
    })
    getNumberOfCarrierRequests()
    // remove listeners from previous event
    socket.requests.off('carrierRequest')
    // keep listening for changes
    socket.requests.on('carrierRequest', socketCarrierRequest => {
      updateCountCarrierRequestsWithSocketCountCarrierRequests({
        numberOfCarrierRequests: socketCarrierRequest.count
      })
      updateCarrierRequestsWithSocketCarrierRequest({ socketCarrierRequest })
      getAllCarrierRequests({ socketCarrierRequest })
      getNumberOfCarrierRequests()
    })
  }

  collapseAll = () =>
    this.setState(() => ({
      hide: true
    }))

  expandAll = () =>
    this.setState(() => ({
      hide: false
    }))

  onCarrierDeleteClick (request) {
    this.props.openDeleteCarrierRequest(request)
  }

  onCarrierRequestClick (request) {
    const { site, warehouse, sites, siteBuildings } = this.props

    const selectedSite = sites.find(s => s.id === site)
    const selectedBuilding = siteBuildings.find(
      sb => sb.id === warehouse
    )

    if (request.get('appointment')) {
      let appt = request
        .get('appointment')
        .set(
          'carrierRequests',
          request
            .getIn(['appointment', 'carrierRequests'], [])
            .map(cr => (cr.get('id') === request.get('id') ? request : cr))
        )

      this.props.setSelectedOrders(appt.get('orders'))
      appt = appt.set('recalculateDuration', true)

      this.props.openEditAppointment(appt)
    } else {
      request = request.set('site', selectedSite)
      request = request.set('building', selectedBuilding)
      request = request.set('recalculateDuration', true)
      this.props.createFromRequest(request)
    }
  }

  showRequestCard (carrierRequest) {
    if (carrierRequest.get('deletedAt')) {
      return false
    }
    return carrierRequest.get('status') !== requestStatusesMap.scheduled
  }

  onDropOnTable = ({ props, door, hour }) => {
    const {
      carrierRequest
    } = props

    const {
      createFromRequest,
      getDoorById
    } = this.props

    const doorMeta = getDoorById(door?.id)
    const areaId = doorMeta?.areaId
    const buildingId = doorMeta?.area?.buildingId
    const siteId = doorMeta?.area?.building?.siteId

    createFromRequest(carrierRequest, {
      areaId,
      buildingId,
      siteId,
      door: door,
      doorId: door?.id,
      date: hour,
      appointmentStatusId: APPOINTMENT_STATUS.SCHEDULED
      // inProgress: true, // if we need to remove it when close, comment above
    })
  }

  renderRequestCard (carrierRequest, index) {
    const { hide } = this.state
    const { getDoorById } = this.props

    const door =
      carrierRequest && carrierRequest.get('appointment')
        ? getDoorById(carrierRequest.get('appointment').get('doorId'))
        : null
    const building = door?.area?.building
    const timezone = door?.area?.building?.timezone ?? 'UTC'

    return (
      <div key={`request-${index}`}>
        <DraggableRequestCard
          onClick={() => this.onCarrierRequestClick(carrierRequest)}
          hide={hide}
          timezone={timezone}
          carrierRequest={carrierRequest}
          building={building}
          onDropOnTable={this.onDropOnTable}
          onCreate={() => this.onCarrierRequestClick(carrierRequest)}
          onDelete={() => this.onCarrierDeleteClick(carrierRequest)}
        />
      </div>
    )
  }

  debouncedShowFilters = debounce(this.toggleShowFilters, 300)

  onScroll = throttle(() => {
    if (this.state.showFilters) {
      this.debouncedShowFilters()
    }
  }, 1000)

  clearFilters = () => {
    this.props.searchRequests({
      searchText: '',
      carrierRequestStatus: null,
      carrierSelect: null
    })
  }

  onSearch = filters => {
    const { searchRequests } = this.props
    searchRequests(filters)
  }

  render () {
    const { carrierRequests, numberOfRequests, searchAttributes, searchAttributesCount } = this.props

    const filteredRequests = carrierRequests.filter(this.showRequestCard)

    return (
      <GridCol flex={1}>
        <Filters
          open={this.state.showFilters}
          onToggle={this.toggleShowFilters}
          showDate={false}
          showCustomers={false}
          showLocations={false}
          showCarriers={true}
          searchAttributes={searchAttributes}
          searchAttributesCount={searchAttributesCount}
          statuses={{
            options: statusOptions,
            label: 'Request Type',
            key: 'carrierRequestStatus'
          }}
          onClear={this.clearFilters}
          onSearch={this.onSearch}
        />

        <Scrollable onScroll={this.onScroll}>
          {numberOfRequests &&
        numberOfRequests.get('numberOfCanceledRequests') > 0 && (
        <Notification type="error">
          ({numberOfRequests && numberOfRequests.get('numberOfCanceledRequests')})
          Scheduled Appointment<br/>Cancellation Request(s)
        </Notification>
          )}
          {numberOfRequests &&
        numberOfRequests.get('numberOfRescheduledRequests') > 0 && (
        <Notification type="warning">
          ({numberOfRequests && numberOfRequests.get('numberOfRescheduledRequests')})
          Scheduled Appointment<br/>Reschedule Request(s)
        </Notification>
          )}

          {filteredRequests.size
            ? filteredRequests.map(
              (carrierRequest, index) =>
                this.renderRequestCard(carrierRequest, index)
            ) : <Container>{'No Results'}</Container>}
        </Scrollable>
      </GridCol>
    )
  }
}

Requests.propTypes = {
  site: PropTypes.any,
  warehouse: PropTypes.any,
  getAllCarrierRequests: PropTypes.func,
  setSelectedOrders: PropTypes.func,
  openEditAppointment: PropTypes.func,
  createFromRequest: PropTypes.func,
  searchRequests: PropTypes.func,
  carrierRequests: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchAttributesCount: PropTypes.number,
  updateCarrierRequestsWithSocketCarrierRequest: PropTypes.func,
  updateCountCarrierRequestsWithSocketCountCarrierRequests: PropTypes.func,
  getNumberOfCarrierRequests: PropTypes.func,
  numberOfRequests: PropTypes.object,
  sites: PropTypes.any,
  siteBuildings: PropTypes.any,
  getDoorById: PropTypes.func,
  openDeleteCarrierRequest: PropTypes.func
}

const mapStateToProps = state => ({
  site: selectCurrentSiteId(state),
  warehouse: selectCurrentBuildingId(state),
  sites: selectAllSites(state),
  siteBuildings: selectBuildingsForCurrentSite(state),
  carrierRequests: getAllCarrierRequests(state),
  numberOfRequests: numberOfCarrierRequests(state),
  searchAttributes: getSearchAttributes(state),
  searchAttributesCount: getSearchAttributesCount(state),
  getDoorById: createGetDoorById(state),
  orders: getOrders(state)
})

const mapDispatchToProps = dispatch => ({
  getAllCarrierRequests: payload =>
    dispatch(CarrierRequestsActions.getAllCarrierRequests(payload)),
  openEditAppointment: appointment =>
    dispatch(AppointmentsActions.openEditAppointment(appointment)),
  createFromRequest: (request, apptData) =>
    dispatch(AppointmentsActions.createFromRequest(request, apptData)),
  setSelectedOrders: orders =>
    dispatch(OrdersActions.setSelectedOrders(orders)),
  getNumberOfCarrierRequests: () =>
    dispatch(CarrierRequestsActions.getNumberOfCarrierRequests()),
  searchRequests: payload =>
    dispatch(CarrierRequestsActions.searchRequests(payload)),
  updateCarrierRequestsWithSocketCarrierRequest: payload =>
    dispatch(
      CarrierRequestsActions.updateCarrierRequestsWithSocketCarrierRequest(
        payload
      )
    ),
  updateCountCarrierRequestsWithSocketCountCarrierRequests: payload =>
    dispatch(
      CarrierRequestsActions.updateCountCarrierRequestsWithSocketCountCarrierRequests(
        payload
      )
    ),
  openDeleteCarrierRequest: payload =>
    dispatch(CarrierRequestsActions.openDeleteCarrierRequest(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandleError(Requests))
