import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class PackageIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        height={height}
        width={width}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z"
        />
      </svg>
    )
  }
}

PackageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

PackageIcon.defaultProps = {
  width: 20,
  height: 20,
  color: '#aab0c0'
}
