import React, { Component } from 'react'

import { Container } from '../styled/Containers'
import { DefaultText, MaterialLabel } from '../styled/Texts'
import { MaterialSelect } from '../styled/Selects'
import PropTypes from 'prop-types'

class FormSelect extends Component {
  render () {
    const { label, error } = this.props

    return (
      <Container block>
        <MaterialSelect {...this.props} />
        <MaterialLabel error={error} isDisabled={this.props.isDisabled}>{label}</MaterialLabel>
        {error && <DefaultText error={error}>{error}</DefaultText>}
      </Container>
    )
  }
}

FormSelect.propTypes = {
  label: PropTypes.string,
  error: PropTypes.any,
  isDisabled: PropTypes.bool
}

export default FormSelect
