import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Button, ButtonPrimary } from '../../styled/Buttons'
import { DefaultParagraph, SectionTitle, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent,
  StyledModalFooter
} from '../../styled/Modals'
import CloseButton from '../CloseButton'
import { connect } from 'react-redux'
import { getDeletingCarrierRequest } from '../../modules/carrierRequests/selectors'
import CarrierActions from '../../modules/carrierRequests/actions'

class DeleteCarrierRequestModal extends Component {
  render () {
    const { request, onClose, onConfirmDelete } = this.props

    if (request === null) {
      return null
    }

    return (
      <StyledModal
        isOpen
        contentLabel="Delete Carrier Request"
        size="small"
      >
        <StyledModalAlertHeader>
          <Title>Delete Carrier Request</Title>
          <CloseButton onClick={onClose} />
        </StyledModalAlertHeader>
        <StyledModalContent>
          <SectionTitle>Are you sure you want to delete this carrier request?</SectionTitle>
          <DefaultParagraph>
            All orders will be removed from this carrier request and will be
            available for scheduling.
          </DefaultParagraph>
        </StyledModalContent>
        <StyledModalFooter>
          <Button type="submit" onClick={onClose}>
            No
          </Button>
          <ButtonPrimary type="submit" onClick={() => onConfirmDelete(request)}>
            Yes, Delete
          </ButtonPrimary>
        </StyledModalFooter>
      </StyledModal>
    )
  }
}

DeleteCarrierRequestModal.propTypes = {
  request: PropTypes.object,
  onClose: PropTypes.func,
  onConfirmDelete: PropTypes.func
}

const mapToStateProps = state => ({
  request: getDeletingCarrierRequest(state)
})

const mapActionToDispatch = dispatch => ({
  /**
   * Triggered whenever the Modal needs to be closed
   */
  onClose: () => dispatch(
    CarrierActions.closeDeleteCarrierRequest()
  ),
  /**
   * Triggered whenever we confirm the deletion
   *
   * Request Appointment here should be an immutable representation of appointment
   * After dispatched the action we close the modal for a better UX
   *
   * Forces an UpsertAppointment modal to close if open
   *
   * @param request
   */
  onConfirmDelete: request => {
    dispatch(CarrierActions.deleteCarrierRequest(request.get('id')))
    dispatch(CarrierActions.closeDeleteCarrierRequest())
    dispatch(CarrierActions.closeUpsertCarrierRequest())
  }
})

export default connect(mapToStateProps, mapActionToDispatch)(DeleteCarrierRequestModal)
