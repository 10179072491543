import React, { Component } from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'

class OptionSelect extends Component {
  render () {
    return (
      <components.Option {...this.props}>
        <input
          type="checkbox"
          checked={this.props.isSelected}
          onChange={e => null}
        />{' '}
        <label>{this.props.data.label} </label>
      </components.Option>
    )
  }
}

OptionSelect.propTypes = {
  // prevent warning
  isSelected: PropTypes.bool,
  data: PropTypes.any
}

export default OptionSelect
