export const getSiteOptions = sites => {
  if (!sites) {
    return []
  }

  return sites
    .valueSeq()
    .map(site => ({
      label: site.name,
      value: site.id
    }))
    .toArray()
}

export const entityToOptions = entity => {
  if (!entity) {
    return []
  }

  return entity.map(item => ({
    label: item.get('name'),
    value: item.get('id')
  })).sort((a, b) =>
    a.value > b.value
      ? 1
      : (a.value < b.value ? -1 : 0)
  )
}

export const immutableEntityToOptions = entity => {
  if (!entity || !entity.toArray()) {
    return []
  }

  return entityToOptions(entity.toArray())
}
