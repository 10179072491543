import React, { Component } from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { INVENTORY_TAB, SUMMON_SMS_TAB } from '../modals/AppointmentModal'
import { appointmentStatusesMap, requestStatusesMap } from '../../utils/time'
import { convertToCamelCase } from '../../utils/common'
import DraggableTableEventCard from '../DraggableTableEventCard'
import DropOnTableHoc from './DropOnTableHoc'
import { createGetIsDoorAvailable } from '../../door-schedule/door-schedule-slice'
import { connect } from 'react-redux'

const Td = styled.td`
  min-width: ${props => props.width}px;
  max-width: ${props => props.width}px;
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  position: relative;

  ${props => (props.isOver ? 'background: #ccc;' : '')}
  ${props =>
    props.disabled
      ? `background: repeating-linear-gradient(
  45deg,
  #bbb,
  #bbb 5px,
  #444 5px,
  #444 10px
);`
      : ''}
`

export const DEFAULT_APPOINTMENT_STATUS = appointmentStatusesMap.draft

class DoorStep extends Component {
  render () {
    const {
      drop,
      width,
      height,
      canDrop,
      hour,
      isOver,
      appointments,
      zoomLevel,
      appointmentStatuses,
      timezone,
      startShift,
      endShift,
      first,
      door,
      isInventoryCalculationEnabled,
      calculateLateness,
      onDropEventOnTable,
      onDeleteAppointment,
      onEditAppointment,
      onDurationChange,
      getIsDoorAvailable,
      idAppointmentLoading,
      onNextOrPreviousDayClick,
      openDestinationDetailsModal,
      appointmentTypes
    } = this.props
    const doorAvailable = getIsDoorAvailable({
      doorId: door.id,
      hour
    })

    return (
      <Td
        ref={doorAvailable ? drop : null}
        width={width}
        height={height}
        disabled={!doorAvailable}
        isOver={isOver}
        canDrop={canDrop && doorAvailable}
        title={doorAvailable ? '' : 'Door is not available at this time'}
      >
        &nbsp;
        {appointments?.map((appointment, i) => {
          const initTime = moment(appointment.date).tz(timezone || 'UTC')
          const duration = appointment.duration
          const endTime = initTime
            .clone()
            .add(duration, duration === 1 ? 'minute' : 'minutes')
            .subtract(1, 'second')
          const appointmentStatus = appointmentStatuses
            ? appointmentStatuses.find(as => as.get('id') === appointment.appointmentStatusId)
            : null

          const carrierRequest = appointment.carrierRequests[0]

          const isRequestLateBool = calculateLateness(appointment)
          const inProgress = appointment.inProgress
          const appointmentStatusText = appointmentStatus
            ? appointmentStatus.get('name')
            : DEFAULT_APPOINTMENT_STATUS
          const status = inProgress
            ? convertToCamelCase('inProgress')
            : convertToCamelCase(
              isRequestLateBool ? requestStatusesMap.carrierLate : appointmentStatusText
            )
          const isDisabled =
            (appointmentTypes === 1 && appointment.isOutbound) ||
            (appointmentTypes === 2 && !appointment.isOutbound)

          if (endTime < hour) {
            return null
          }

          return (
            <DraggableTableEventCard
              first={first}
              zindex={10 + i}
              size={zoomLevel}
              key={appointment.id}
              id={appointment.id}
              appointment={appointment}
              status={status}
              initTime={initTime}
              isLoading={idAppointmentLoading === appointment.id}
              isDisabled={isDisabled || idAppointmentLoading === appointment.id}
              calculateLateness={calculateLateness}
              endTime={endTime}
              startShift={startShift}
              endShift={endShift}
              timezone={timezone}
              duration={duration}
              onDurationChange={duration => {
                onDurationChange(appointment, duration)
              }}
              primaryRefValue={appointment.primaryRefValue || ''}
              PO={appointment.PO || ''}
              onEdit={() => {
                onEditAppointment(appointment)
              }}
              onMessage={() => {
                onEditAppointment(appointment, SUMMON_SMS_TAB)
              }}
              onInventoryIssueClick={() => {
                onEditAppointment(appointment, INVENTORY_TAB)
              }}
              onDelete={() => {
                onDeleteAppointment(appointment)
              }}
              onDropOnTable={onDropEventOnTable}
              openDestinationDetailsModal={openDestinationDetailsModal}
              onNextOrPreviousDayClick={onNextOrPreviousDayClick}
              totalOrders={appointment.orders.length}
              isUpsertAppointmentVisible={false}
              topPosition={0}
              width={width}
              height={height}
              inProgress={inProgress}
              isInventoryCalculationEnabled={isInventoryCalculationEnabled}
              isRequestLate={isRequestLateBool}
              requestStatus={requestStatusesMap.scheduled}
              carrierRequest={carrierRequest}
              appointmentStatuses={appointmentStatus}
              appointmentStatus={appointmentStatusText}
            />
          )
        })}
      </Td>
    )
  }
}

DoorStep.propTypes = {
  drop: PropTypes.any,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  onDeleteAppointment: PropTypes.func,
  onEditAppointment: PropTypes.func,
  onDurationChange: PropTypes.func,
  onNextOrPreviousDayClick: PropTypes.func,
  onDropEventOnTable: PropTypes.func,
  openDestinationDetailsModal: PropTypes.func,
  calculateLateness: PropTypes.func,
  getIsDoorAvailable: PropTypes.func,
  door: PropTypes.object,
  hour: PropTypes.object,
  startShift: PropTypes.object,
  endShift: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  first: PropTypes.bool,
  appointments: PropTypes.array,
  zoomLevel: PropTypes.number,
  appointmentStatuses: PropTypes.object,
  timezone: PropTypes.string,
  idAppointmentLoading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isInventoryCalculationEnabled: PropTypes.bool,
  appointmentTypes: PropTypes.number
}

DoorStep.defaultProps = {
  width: 140,
  height: 140
}

const mapStateToProps = state => ({
  getIsDoorAvailable: createGetIsDoorAvailable(state)
})

export default DropOnTableHoc(connect(mapStateToProps)(DoorStep))
