import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Button, ButtonIcon } from './Buttons'
import { ReactNode } from 'react'

// TODO: define a better type than any for these props
// @ts-ignore
const getNotificationTheme = props => {
  return props.theme.notification[props.type] ? props.theme.notification[props.type] : {
    border: props.theme.colors.default,
    background: props.theme.colors.default
  }
}

// @ts-ignore
const getBackgroundColor = props => {
  return getNotificationTheme(props).background
}

// @ts-ignore
const getBorderColor = props => {
  return getNotificationTheme(props).border
}

export interface styledNotificationAreaProps {
  isExpanded?: boolean
}

export const StyledNotificationArea = styled.div<styledNotificationAreaProps>`
  height: ${props => props.isExpanded ? '4rem' : '0'};
  opacity: ${props => props.isExpanded ? 1 : 0};
  overflow: hidden;
`

export const StyledNotification = styled.div`
  border-radius: 1px;
  border: solid 1px ${props => getBorderColor(props)};
  background-color: ${props => getBackgroundColor(props)};
  cursor: default;
  padding: 0.5rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.625rem;
  }

  .notification-text {
    flex: 1;
  }

  ${Button} {
    color: ${props => props.theme.contrastTextColor};
    text-transform: uppercase;
    font-weight: bold;
  }

  ${ButtonIcon} {
    margin-left: ${props => props.theme.spacing(1)};
  }

  ${Button}, ${ButtonIcon} {
    height: auto;
    font-size: 0.625rem;
  }
`

export interface BadgeProps {
  position?: {
    top: string;
    left: string;
  };
  right?: boolean;
  sku?: boolean;
  children?: ReactNode;
  css?: FlattenSimpleInterpolation;
}

export const Badge = styled.div<BadgeProps>`
  box-shadow: 1px 0 2px -1px #000;
  background-color: #ffffff;
  border-radius: 50% 50%;
  line-height: 24px;
  position: ${props => props.position ? 'fixed' : 'absolute'};
  text-align: center;
  height: 24px;
  width: 24px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg, ${ButtonIcon} {
    margin: 0;
  }

  ${ButtonIcon} {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  ${props => {
    if (props.position) {
      return css`
      top: ${props.position.top};
      left: ${props.position.left};
      `
    }

    return css`
      top: -12px;
      ${props.right ? 'right: -8px;' : props.sku ? 'top: 3px; left: 5px' : 'left: -12px;'}`
  }}
`
