import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import ErrorDisplay from '../ErrorDisplay'

class HandleError extends Component {
  state = {
    hasError: false,
    error: null
  }

  static getDerivedStateFromError (error) {
    // Update the state to show an fallback UI
    return { hasError: true, error }
  }

  componentDidCatch (error, errorInfo) {
    if (Raven.isSetup()) {
      Raven.captureException(error)
    }
  }

  handleOnClose = () => this.setState({ hasError: false, error: null, errorHandled: true })

  render () {
    const {
      ChildComponent,
      ...props
    } = this.props

    const {
      hasError,
      error,
      errorHandled
    } = this.state

    if (errorHandled) {
      return null
    }

    if (hasError) {
      return (
        <ErrorDisplay
          error={error}
          onClose={this.handleOnClose}
        />
      )
    }

    return (
      <ChildComponent
        {...props}
      />
    )
  }
}

HandleError.propTypes = {
  token: PropTypes.string,
  editingAppointment: PropTypes.object,
  ChildComponent: PropTypes.func
}

export default ChildComponent => function HandleErrorWrapper (props) {
  return (
    <HandleError
      ChildComponent={ChildComponent}
      {...props}
    />
  )
}
