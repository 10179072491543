import {
  createAction,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityState
} from '@reduxjs/toolkit'
import { Carrier, createGetCarrierById } from '../carriers/carriers-slice'
import { RequestStatus } from '../common-types'
import { createGetDoorById } from '../doors/doors-slice'
import { createGetDriverById, Driver } from '../drivers/drivers-slice'
import { RootState } from '../root-types'
import { createSelector, defaultMemoize } from 'reselect'
import { Door } from './appointments-types'

export interface AppointmentEntity {
  appointmentStatusId: number
  carrierId: number | null
  carrierRequests: any[]
  checkinTime: string | null
  checkoutTime: string | null
  contactPhone: string | null
  createdAt: string
  date: string | null
  deletedAt: string | null
  destination: any | null
  doorId: number | null
  driverId: number | null
  duration: number
  guid: string
  id: number
  inProgress: boolean
  inventoryReviewUserId: number | null
  notes: any | null
  orders: number[]
  startLoadingTime: string | null
  trailer: any | null
  type: string
  updatedAt: string | null
}

export interface Appointment {
  appointmentStatusId: number
  carrier: Carrier | null
  carrierRequests: any[]
  checkinTime: string | null
  checkoutTime: string | null
  contactPhone: string | null
  createdAt: string
  date: string | null
  deletedAt: string | null
  destination: any | null
  door: Door | null
  driver: Driver | null
  duration: number
  guid: string
  id: number
  inProgress: boolean
  inventoryReviewUserId: number | null
  notes: any | null
  orders: number[]
  startLoadingTime: string | null
  trailer: any | null
  type: string
  updatedAt: string | null
}

export interface AppointmentsState extends EntityState<AppointmentEntity> {}

const adapter = createEntityAdapter<AppointmentEntity>()

const initialState = {
  loading: RequestStatus.Idle
}

export const getAppointmentsFulfilled = createAction<AppointmentEntity[]>(
  'appointment/getAppointments/fulfilled'
)
export const setManyAppointments = createAction<AppointmentEntity[]>(
  'appointment/setManyAppointments'
)

const slice = createSlice({
  name: 'appointment',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAppointmentsFulfilled, (state, action) => {
        if (!action.payload) {
          console.warn('appointment/getAppointments/fulfilled called with undefined payload')
          return state
        }

        adapter.setAll(state, action.payload)
        state.loading = RequestStatus.Succeded
      })
      .addCase(setManyAppointments, (state, action) => {
        if (!action.payload) {
          console.warn('appointment/setManyAppointments called with undefined payload')

          return state
        }

        adapter.setMany(state, action.payload)
      })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.appointment
})
export const selectAllAppointments = globalizedSelectors.selectAll
const selectAppointmentEntities = globalizedSelectors.selectEntities

export const getAppointments = createSelector(
  selectAppointmentEntities,
  createGetDoorById,
  createGetDriverById,
  createGetCarrierById,
  (appointmentEntities, getDoorById, getDriverById, getCarrierById) => {
    const denormalizedAppointments: Dictionary<Appointment> = {}
    Object.keys(appointmentEntities).forEach(key => {
      const appointmentEntity = appointmentEntities[key]
      if (appointmentEntity) {
        denormalizedAppointments[key] = {
          ...appointmentEntity,
          door: appointmentEntity.doorId ? getDoorById(appointmentEntity.doorId) : null,
          driver: appointmentEntity.driverId ? getDriverById(appointmentEntity.driverId) : null,
          carrier: appointmentEntity.carrierId ? getCarrierById(appointmentEntity?.carrierId) : null
        }
      }
    })

    return denormalizedAppointments
  }
)

export const createGetAppointmentById = createSelector(selectAppointmentEntities, entities =>
  defaultMemoize(id => entities[id] || null)
)

export const createGetAppointmentsById = createSelector(selectAppointmentEntities, entities =>
  defaultMemoize(ids =>
    Object.keys(entities).reduce((objs, current) => {
      if (ids.indexOf(current) < 0) return objs

      return {
        ...objs,
        [current]: entities[current]
      }
    }, {})
  )
)
