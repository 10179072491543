import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit'
import {
  carrierLoginFullfiled,
  LoginSuccessResult,
  selectCarrierToken
} from '../carrier-app/carrier-app-slice'
import { Carrier } from '../carriers/carriers-slice'
import { RequestStatus } from '../common-types'
import config from '../config'
import { Driver } from '../drivers/drivers-slice'
import { RootState } from '../root-types'
import axios from '../utils/axios'

export interface UserEntity {
  id: number
  firstName: string | null
  lastName: string | null
  email: string
  password: string
  carrierId: number
  driverId: number | null
  defaultBuildingId: number | null
  defaultApptType: number
  phone: string
  prefContactMethod: string
  createdAt: string
  updatedAt: string
}

export interface User {
  id: number
  firstName: string | null
  lastName: string | null
  email: string
  password: string
  carrier: Carrier
  driver: Driver | null
  defaultBuildingId: number | null
  defaultApptType: number
  phone: string
  prefContactMethod: string
  createdAt: string
  updatedAt: string
}

const normalizeUser = (user: any): UserEntity => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  password: user.password,
  carrierId: user.carrier.id,
  driverId: user.driver?.id ?? null,
  defaultBuildingId: user.defaultBuildingId,
  defaultApptType: user.defaultApptType,
  phone: user.phone,
  prefContactMethod: user.prefContactMethod,
  createdAt: user.createdAt,
  updatedAt: user.updatedAt
})

export interface UsersState extends EntityState<UserEntity> {
  loading: RequestStatus
  error: SerializedError | null
  createCarrierUserLoading: RequestStatus
  createCarrierUserLoadingError: SerializedError | null
  updateCarrierUserLoading: RequestStatus
  updateCarrierUserLoadingError: SerializedError | null
}

export interface UpsertUserConfig {
  id?: number
  email: string
  prefContactMethod: string
  phone: string
  password: string
  roles: number[]
  carrierName?: string
  driverName?: string
}

export interface UpsertUserSuccessResponse {
  id: number
  firstName: string | null
  lastName: string | null
  email: string
  password: string
  carrierId: number
  carrier: {
    code: string
    id: number
    name: string
  }
  driverId: number
  driver: {
    active: boolean
    carrierId: number
    email: string
    firstName: string
    id: number
    lastName: string
    phone: string
    prefContactMethod: string
  }
  defaultBuildingId: number | null
  defaultApptType: number
  phone: string
  prefContactMethod: string
  createdAt: string
  updatedAt: string
}

export const createCarrierUser = createAsyncThunk<void, UpsertUserConfig, { state: RootState }>(
  'user/createCarrierUser',
  async (upsertUserConfig, { rejectWithValue }) => {
    try {
      await axios.post('/users/carrier/create', upsertUserConfig, {
        headers: {
          Authorization: `Key ${config.API_KEY}`
        }
      })
    } catch (err: any) {
      const response = err.response
      return rejectWithValue({
        message: response.statusText,
        code: response.status,
        stack: response.data
      })
    }
  }
)

export const updateCarrierUser = createAsyncThunk<
  UpsertUserSuccessResponse,
  UpsertUserConfig,
  { state: RootState }
>('user/updateCarrierUser', async (upsertUserConfig, thunkApi) => {
  const token = selectCarrierToken(thunkApi.getState())
  try {
    const { data } = await axios.put('/users/me', upsertUserConfig, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return data
  } catch (err: any) {
    const response = err.response
    return thunkApi.rejectWithValue({
      message: response.statusText,
      code: response.status,
      stack: response.data
    })
  }
})

const adapter = createEntityAdapter<UserEntity>()

const initialState: UsersState = {
  ids: [],
  entities: {},
  loading: RequestStatus.Idle,
  error: null,
  createCarrierUserLoading: RequestStatus.Idle,
  createCarrierUserLoadingError: null,
  updateCarrierUserLoading: RequestStatus.Idle,
  updateCarrierUserLoadingError: null
}

const slice = createSlice({
  name: 'user',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    createCarrierUserCleared (state) {
      state.createCarrierUserLoading = RequestStatus.Idle
      state.createCarrierUserLoadingError = null
    },
    updateCarrierUserCleared (state) {
      state.updateCarrierUserLoading = RequestStatus.Idle
      state.updateCarrierUserLoadingError = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(carrierLoginFullfiled, (state, action: PayloadAction<LoginSuccessResult>) => {
        if (!action.payload.user) {
          console.warn('On carrierLoginFullfiled user should not be null')

          return state
        }

        adapter.upsertOne(state, normalizeUser(action.payload.user))
      })
      .addCase(createCarrierUser.pending, state => {
        state.createCarrierUserLoading = RequestStatus.Pending
      })
      .addCase(createCarrierUser.fulfilled, state => {
        if (state.createCarrierUserLoading === RequestStatus.Pending) {
          state.createCarrierUserLoading = RequestStatus.Succeded
          state.createCarrierUserLoadingError = null
        }
      })
      .addCase(createCarrierUser.rejected, (state, action) => {
        if (state.createCarrierUserLoading === RequestStatus.Pending) {
          state.createCarrierUserLoading = RequestStatus.Failed
          state.createCarrierUserLoadingError = action.error
        }
      })
      .addCase(updateCarrierUser.pending, state => {
        state.updateCarrierUserLoading = RequestStatus.Pending
      })
      .addCase(updateCarrierUser.fulfilled, (state, action) => {
        if (state.updateCarrierUserLoading === RequestStatus.Pending) {
          state.updateCarrierUserLoading = RequestStatus.Succeded
          state.updateCarrierUserLoadingError = null

          adapter.upsertOne(state, normalizeUser(action.payload))
        }
      })
      .addCase(updateCarrierUser.rejected, (state, action) => {
        if (state.updateCarrierUserLoading === RequestStatus.Pending) {
          state.updateCarrierUserLoading = RequestStatus.Failed
          state.updateCarrierUserLoadingError = action.error
        }
      })
  }
})

export default slice.reducer

export const { createCarrierUserCleared, updateCarrierUserCleared } = slice.actions

const selectUser = createSelector(
  (state: RootState) => state.user,
  user => user
)
const globalizedSelectors = adapter.getSelectors((state: RootState) => state.user)
export const selectUserById = globalizedSelectors.selectById
export const selectUserEntities = globalizedSelectors.selectEntities
export const selectUpdateCarrierUserStatus = createSelector(
  selectUser,
  user => user.updateCarrierUserLoading
)
export const selectCreateCarrierUserStatus = createSelector(
  selectUser,
  user => user.createCarrierUserLoading
)
export const selectUpdateCarrierUserError = createSelector(
  selectUser,
  user => ({
    isError: user.updateCarrierUserLoadingError != null,
    error: user.updateCarrierUserLoadingError
  })
)
export const selectCreateCarrierUserError = createSelector(
  selectUser,
  user => ({
    isError: user.createCarrierUserLoadingError != null,
    error: user.createCarrierUserLoadingError
  })
)
