import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getInventoryItems
  getInventoryItems: null,
  // The operation has started and is loading
  getInventoryItemsLoading: null,
  // The inventoryItems was successfully fetched
  getInventoryItemsSuccess: ['inventoryItems'],
  // An error occurred
  getInventoryItemsFailure: ['errorMessage'],

  getInventoryItemsProjection: ['payload'],
  getInventoryItemsProjectionLoading: null,
  getInventoryItemsProjectionSuccess: ['inventoryItemsProjection'],
  getInventoryItemsProjectionFailure: ['errorMessage']
})

export const InventoryItemsTypes = Types
export default Creators
