import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAppointmentTypesOptions, selectAppointmentType, selectBuildingsForCurrentSite, selectCurrentBuildingId, selectCurrentSiteId, selectEndDate, selectStartDate, setAppointmentType, setBuildingId, setSiteId } from '../../../app/app-slice'
import {
  createGetBuildingsBySiteId,
  selectAllBuildings
} from '../../../buildings/buildings-slice'
import UserDropdown from '../../../components/UserSelect'
import { getDefaultBuildingId } from '../../../modules/users/selectors'
import { selectAllSites } from '../../../sites/sites-slice'
import { Container } from '../../../styled/Containers'
import { Header } from '../../../styled/Headers'
import { DefaultSelect } from '../../../styled/Selects'
import { HeaderTitle, Label, LabelLeft } from '../../../styled/Texts'
import { convertEntitiesToSelectOptions } from '../../../ui'

class HomeHeader extends Component {
  onSiteSelectChange = selectedSite => {
    const { setSite, setWarehouse, getBuildingsBySiteId } = this.props

    setSite(selectedSite)

    const buildings = getBuildingsBySiteId(selectedSite)
    const selectedWarehouse = (buildings && buildings.length > 0) ? buildings[0] : null
    if (selectedWarehouse) {
      setWarehouse(selectedWarehouse.id)
    }
  }

  onWarehouseSelectChange = selectedWarehouse => {
    const { setWarehouse } = this.props
    setWarehouse(selectedWarehouse)
  }

  onAppointmentTypesChange = appointmentTypeId => {
    const { setAppointmentType } = this.props

    setAppointmentType(appointmentTypeId)
  }

  render () {
    const { site, sites, getBuildingsBySiteId, warehouse, appointmentType, appointmentTypesOptions } = this.props
    const buildings = convertEntitiesToSelectOptions(getBuildingsBySiteId(site))
    return (
      <Header flex padded>
        <HeaderTitle centered>
          SmartDock | <strong>Taylor Farms</strong>
        </HeaderTitle>
        <Container flex centered grow>
          <Label nospace flex>
            <LabelLeft>Sites</LabelLeft>
            <DefaultSelect
              notSpaced
              inputWidth={10}
              options={sites}
              placeholder='Select Site'
              value={site}
              onChange={this.onSiteSelectChange}
            />
          </Label>
          <Label nospace flex>
            <LabelLeft>Warehouse</LabelLeft>
            <DefaultSelect
              notSpaced
              inputWidth={10}
              options={buildings}
              placeholder='Select Warehouse'
              value={warehouse}
              onChange={this.onWarehouseSelectChange}
            />
          </Label>
          <Label nospace flex>
            <LabelLeft>Appt. types</LabelLeft>
            <DefaultSelect
              notSpaced
              inputWidth={10}
              options={appointmentTypesOptions}
              placeholder="Select Appt Type"
              value={appointmentType}
              onChange={this.onAppointmentTypesChange}
            />
          </Label>
        </Container>
        <UserDropdown />
      </Header>
    )
  }
}

HomeHeader.propTypes = {
  warehouse: PropTypes.number,
  setSite: PropTypes.func,
  setWarehouse: PropTypes.func,
  setAppointmentType: PropTypes.func,
  appointmentType: PropTypes.number,
  appointmentTypesOptions: PropTypes.array,
  getBuildingsBySiteId: PropTypes.func,
  site: PropTypes.number,
  sites: PropTypes.array
}

const mapStateToProps = state => ({
  site: selectCurrentSiteId(state),
  sites: convertEntitiesToSelectOptions(selectAllSites(state)),
  defaultBuildingId: getDefaultBuildingId(state),
  getBuildingsBySiteId: createGetBuildingsBySiteId(state),
  buildings: selectAllBuildings(state),
  siteBuildings: selectBuildingsForCurrentSite(state),
  warehouse: selectCurrentBuildingId(state),
  startDate: selectStartDate(state),
  endDate: selectEndDate(state),
  appointmentType: selectAppointmentType(state),
  appointmentTypesOptions: getAppointmentTypesOptions()
})

const mapDispatchToProps = dispatch => ({
  setSite: payload => dispatch(setSiteId(payload)),
  setWarehouse: payload => dispatch(setBuildingId(payload)),
  setAppointmentType: payload => dispatch(setAppointmentType(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader)
