import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { ItemsTypes } from './actions'

// getItems
export const getItemsLoading = state =>
  state.merge({
    getItemsIsLoading: true,
    getItemsErrorMessage: ''
  })

export const getItemsSuccess = (state, { items }) =>
  state.merge({
    items,
    getItemsIsLoading: false,
    getItemsErrorMessage: null
  })

export const getItemsFailure = (state, { errorMessage }) =>
  state.merge({
    items: null,
    getItemsIsLoading: false,
    getItemsErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemsTypes.GET_ITEMS_LOADING]: getItemsLoading,
  [ItemsTypes.GET_ITEMS_SUCCESS]: getItemsSuccess,
  [ItemsTypes.GET_ITEMS_FAILURE]: getItemsFailure
})
