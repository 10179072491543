import styled, { css } from 'styled-components'
import AppointmentCard from '../components/AppointmentCard'
import RequestCard from '../components/RequestCard'
import OrderCard from '../components/OrderCard'
import { Header } from './Headers'
import { DefaultText, Label, Title } from './Texts'
import { Container, SecondaryBorderedContainer } from './Containers'
import { StyledCheckbox } from './Inputs'
import Event from '../components/Event'
import { Badge } from './Notifications'
import { ButtonIcon } from './Buttons'
import { Info, Issues, TotalOrders } from './Boxes'
import { StyledTruckIcon } from '../styled/Icons'

export const StyledAppointmentCard = styled(AppointmentCard)`
  margin-bottom: 0.5rem;
  width: 230px;
  ${props => props.drag && props.isDragging && !props.isPreview ? 'opacity: 0;' : ''}

  > ${Container} {
    background: ${props => props.theme.appointmentStatuses[props.status]?.background};
    border: solid 1px ${props => props.theme.colors.secondary};
    border-radius: 5px;
    padding: 0.625rem;
    align-items: center;

    &${Header} {
      border-bottom: 0;
      display: flex;
      padding: 0px;

      ${StyledTruckIcon} {
        margin-left: 6px
      }

      ${Title} {
        flex-grow: 2;
        padding: 14px 10px 14px 10px;
        margin: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      ${Container} {
        white-space: nowrap;
      }
    }
    ${Title} {
      text-transform: uppercase;
      font-size: 0.75rem;
      color: ${props => props.theme.appointmentStatuses[props.status]?.text};
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
    ${DefaultText} {
      color: ${props => props.theme.appointmentStatuses[props.status]?.text};
    }
  }
`

export const StyledRequestCard = styled(RequestCard)`
  background-color: #ffffff;
  color: ${props => props.theme.colors.secondary};
  border-radius: 5px;
  margin-bottom: 0.5rem;
  width: 230px;

  ${props => props.drag && props.isDragging && !props.isPreview ? 'opacity: 0;' : ''}


  ${Header} {
    border-bottom: solid 1px ${props => props.theme.colors.secondary};
    padding: 0.5rem 0 0.5rem 1rem;
    display: flex;

    ${Title} {
      font-size: 0.625rem;
    }
  }
`
export const StyledOrderCard = styled(OrderCard)`
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background ease 0.3s;
  width: ${props => props.isCreateAppointment ? '210px' : '230px'};

  ${props => props.drag && props.isDragging && !props.isPreview ? 'opacity: 0;' : ''}

  .actions {
    white-space: nowrap;
  }

  ${Title} {
   font-weight: bold;
  }

  ${StyledCheckbox} {
    svg {
      fill: ${props => props.theme.colors.secondary};
    }
  }

  ${Label} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-align: left;
    margin: 0px
  }

  ${props => props.isSelected ? css`
    border: 2px solid ${props.theme.colors.primary};

    ${StyledCheckbox} {
      svg {
        fill: ${props.theme.colors.primary};
      }
    }

    ${Label}, .actions {
      margin-bottom: 0 !important;
    }
  ` : ''}

  ${props => props.nopadding ? css`
    &${SecondaryBorderedContainer} {
      > ${Container} {
        padding: 0;
        ${Label} {
          margin-left: 10px;
        }
      }
    }
  ` : ''}

  ${props => props.drag && props.isOver ? 'background-color: #ccc;' : ''}
`

export const StyledTableEventCard = styled(Event)`
  cursor: ${props => (props.isDisabled) ? 'not-allowed' : 'grab'};
  position: absolute;
  left: 0px;
  top: ${props => props.topPosition}px;
  width: ${props => props.width}px;
  z-index: ${props => props.zindex ? props.zindex + 2 : 2};

  ${props => props.drag && props.isDragging && !props.isPreview ? 'opacity: 0;height: 0;' : ''}

  ${Badge} {
    ${props => props.first ? 'top: 0;' : ''}
    svg {
      transform: scale(0.8);
    }
  }
  ${Container}.event-wrapper {
    overflow: hidden;
    background: ${props => props.theme.appointmentStatuses[props.status]?.background};
    transition: background ease 0.3s;
    border-radius: 5px;
    border: solid 1px ${props => props.theme.colors.secondary};
    ${props => props.size > 50 && props.size <= 75 ? css`
      display: flex;
    ` : ''}
    ${Container}.actions {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.75rem 0.25rem;
    }
  }
  ${Title} {
    cursor: ${props => (props.isDisabled) ? 'not-allowed' : 'grab'};
    padding: 0.625rem 0.75rem 0;
    line-height: 1;
    border-top: solid 5px ${props => props.theme.colors.secondary};
    color: ${props => props.theme.appointmentStatuses[props.status]?.text};
    font-size: 0.75rem;
    display: block;
    width: 100%;
    margin: 0;
    text-transform: uppercase;
    ${props => props.size <= 50 ? css`
      padding-bottom: 0.05rem;
    ` : ''}

    &.empty {
      padding-top: 4px;
      margin-top: 0;
    }
  }
  ${ButtonIcon} {
    display: inline-block;

    &.next-day {
      background: #000;
      border-radius: 50% 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  ${props => props.size > 50 && props.size <= 75 ? css`
    display: flex;
    ${ButtonIcon} {
      margin-right: 0;
    }
    ${Info} {
      ${TotalOrders}, ${Issues} {
        display: block;
        margin: 0;
      }
    }
    ${Container}.event-wrapper {
      ${Container}.actions {
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: flex-start;
        border-top: 5px solid #202740;
        padding: 1.5rem 0.25rem 0 0;
      }
    }
  ` : ''}
  ${props => props.size <= 50 ? css`
    display: block;
    ${ButtonIcon} {
      margin-right: 0;
    }
    ${Container}.event-wrapper {
      ${props => props.duration < 60 ? css`
        align-items: center;
        justify-content: center;
        display: flex;
      ` : ''}
      ${Info} {
        white-space: nowrap;
        padding-top: 0.5rem;
        ${TotalOrders}, ${Issues} {
          display: block;
          margin: 0;
        }
      }
      ${Container}.actions {
        padding: 0;
        ${props => props.size === 25 ? css`
          svg {
            transform: scale(0.8);
          }
        ` : ''}
        ${ButtonIcon} {
          margin-right: 4px;
        }
      }
    }
  ` : ''}
  ${Info} {
    padding: 0 0.75rem 0.25rem;
    ${TotalOrders}, ${Issues} {
      color: ${props => props.theme.appointmentStatuses[props.status]?.text};
      font-size: 0.625rem;
      margin-right: 0.5rem;
    }
  }
`
