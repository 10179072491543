import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCarrierUser } from '../../carrier-app/carrier-app-slice'
import {
  createCarrierUser,
  createCarrierUserCleared,
  selectCreateCarrierUserError,
  selectCreateCarrierUserStatus
} from '../../users/users-slice'
import UpsertCarrierUser from './UpsertCarrierUser'

function CreateCarrierUser () {
  const user = useSelector(selectCarrierUser)
  const createStatus = useSelector(selectCreateCarrierUserStatus)
  const createError = useSelector(selectCreateCarrierUserError)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/carrier/account')
  })

  if (!createError) return null

  return (
    <UpsertCarrierUser
      upsertStatus={createStatus}
      upsertError={createError}
      upsertCleared={createCarrierUserCleared}
      upsert={createCarrierUser}
    ></UpsertCarrierUser>
  )
}

export default CreateCarrierUser
