import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import moment from 'moment'

import { ButtonIcon } from '../styled/Buttons'
import { Container } from '../styled/Containers'
import { DefaultText, Field, Title } from '../styled/Texts'
import { Header } from '../styled/Headers'
import { Section } from '../styled/Boxes'
import { appointmentStatusesMap, requestStatusesMap } from '../utils/time'
import {
  formatPhoneNumber,
  getInboundOutboundLabel,
  getOriginDestinationLabel,
  ignore
} from '../utils/common'
import { theme } from '../themes/taylor-farms'
import DeleteIcon from './icons/Delete'
import InfoIcon from './icons/Info'
import IssueIcon from './icons/Issue'
import WarningIcon from './icons/Warning'
import { SimpleSpinner } from '../styled/Loading'

export const getDate = (appointment, showTime) => {
  let timezone = 'UTC'
  try {
    timezone = appointment.door.area.building.timezone
  } catch (e) {}

  if (!appointment.date) {
    return ''
  }

  const date = moment.utc(appointment.date)
  return date.tz(timezone).format(showTime ? 'L HH:mm' : 'L')
}

const getDoor = appointment => (appointment.door ? appointment.door.name : '')

const getBuilding = appointment => appointment.door && appointment.door.area.building.name

const getLocation = appointment => appointment.door && appointment.door.area.building.location.name

const getCarrier = appointment => (appointment.carrier ? appointment.carrier.name : '')

const getDriver = appointment => {
  if (!appointment.driver) {
    return ''
  }

  const firstName = appointment.driver.firstName
  const lastName = appointment.driver.lastName
  return `${firstName || ''} ${lastName || ''}`
}

const getIcons = (requestStatus, appointmentStatus, isRequestLate, appointment) => {
  const requestException = [
    requestStatusesMap.canceled,
    requestStatusesMap.reschedule,
    requestStatusesMap.carrierLate
  ].includes(requestStatus)
  const appointmentStatusWithPossibleException = [
    appointmentStatusesMap.draft,
    appointmentStatusesMap.scheduled
  ].includes(appointmentStatus)

  if (!appointment.inventoryIssues) {
    appointment.inventoryIssues = {}
  }
  const inventoryIssues = Object.values(
    // while system has Immutable poking around, ignore returns Immutable
    ignore(appointment.inventoryIssues, ['appointmentDate']).toJS()
  ).filter(inventory => {
    const validRemainderQty = inventory?.remainderQty && inventory.remainderQty > 0
    const validProjectedStock = inventory?.projectedStock && inventory.projectedStock < 0
    return validRemainderQty && validProjectedStock
  })
  const inventoryInvalid = inventoryIssues && inventoryIssues.length
  const inventoryReviewUserId = appointment.inventoryReviewUserId

  let issueIcon = ''
  let warningIcon = ''
  const deleteIcon = <DeleteIcon color={theme.contrastTextColor} />
  const infoIcon = <InfoIcon width={18} height={18} color={theme.contrastTextColor} />
  if (appointmentStatusWithPossibleException && inventoryInvalid && !inventoryReviewUserId) {
    issueIcon = <IssueIcon color={theme.contrastTextColor} />
  } else if (requestException && appointmentStatusWithPossibleException) {
    warningIcon = <WarningIcon color={theme.contrastTextColor} />
  }

  return {
    DeleteIcon: deleteIcon,
    InfoIcon: infoIcon,
    IssueIcon: issueIcon,
    WarningIcon: warningIcon
  }
}

class AppointmentCard extends PureComponent {
  state = { isRequestLate: false }

  calculateLateness = () => {
    this.setState({
      isRequestLate: this.props.calculateLateness()
    })
  }

  componentDidMount () {
    this.calculateLateness()
    this.interval = setInterval(() => {
      this.calculateLateness()
    }, 4000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    const {
      appointment,
      onClick,
      onEdit,
      onIssue,
      onDelete,
      className,
      appointmentStatus,
      requestStatus,
      drag,
      isLoading,
      getDoorById
    } = this.props
    const { isRequestLate } = this.state

    const { InfoIcon, DeleteIcon, IssueIcon } = getIcons(
      requestStatus,
      appointmentStatus,
      isRequestLate,
      appointment
    )

    return (
      <Container ref={drag} className={className}>
        <Header>
          <Title>
            {getInboundOutboundLabel(appointment.isOutbound)} Appt #{appointment.id}
          </Title>
          <Container>
            {isLoading ? (
              <SimpleSpinner inline small />
            ) : (
              <ButtonIcon title='Appointment informations' onClick={onEdit}>
                {InfoIcon}
              </ButtonIcon>
            )}

            {IssueIcon ? (
              <ButtonIcon
                title='There are inventory issues within this appointment'
                onClick={isLoading ? undefined : onIssue}
              >
                {IssueIcon}
              </ButtonIcon>
            ) : (
              ''
            )}
            <ButtonIcon title='Delete this appointment' onClick={onDelete}>
              {DeleteIcon}
            </ButtonIcon>
          </Container>
        </Header>
        <Container onClick={onClick}>
          <Section>
            <Field>
              <DefaultText bold minimumWidth>
                Status:
              </DefaultText>
              <DefaultText>{appointmentStatus}</DefaultText>
            </Field>
          </Section>
          <Section>
            <DefaultText bold spaced nospace uppercase>
              General
            </DefaultText>
          </Section>
          <Section>
            <Field>
              <DefaultText bold>Date:</DefaultText>
              <DefaultText>{getDate(appointment, getDoorById)}</DefaultText>
            </Field>
            <Field>
              <DefaultText bold>Duration:</DefaultText>
              <DefaultText>{appointment.duration}</DefaultText>
            </Field>
          </Section>
          <Section>
            <Field>
              <DefaultText bold>Notes:</DefaultText>
              <DefaultText>{appointment.notes}</DefaultText>
            </Field>
          </Section>
          <Section>
            <DefaultText bold spaced nospace uppercase>
              Location
            </DefaultText>
          </Section>
          <Section>
            <Field>
              <DefaultText bold minimumWidth>
                Location:
              </DefaultText>
              <DefaultText>{getLocation(appointment)}</DefaultText>
            </Field>
            <Field>
              <DefaultText bold minimumWidth>
                Door:{' '}
              </DefaultText>
              <DefaultText>{getDoor(appointment, getDoorById)}</DefaultText>
            </Field>
          </Section>
          <Section>
            <Field>
              <DefaultText bold minimumWidth>
                {`${getOriginDestinationLabel(appointment.isOutbound)}:`}
              </DefaultText>
              <DefaultText fixedWidth>{appointment.destination}</DefaultText>
            </Field>
          </Section>
          <Section>
            <Field>
              <DefaultText bold minimumWidth>
                Building:
              </DefaultText>
              <DefaultText fixedWidth>{getBuilding(appointment, getDoorById)}</DefaultText>
            </Field>
          </Section>
          <Section>
            <DefaultText bold spaced nospace uppercase>
              Carrier
            </DefaultText>
          </Section>
          <Section>
            <Field>
              <DefaultText bold minimumWidth>
                Name:
              </DefaultText>
              <DefaultText>{getCarrier(appointment)}</DefaultText>
            </Field>
            <Field>
              <DefaultText bold>Trailer:</DefaultText>
              <DefaultText>{appointment.trailer}</DefaultText>
            </Field>
          </Section>
          <Section>
            <DefaultText bold spaced nospace uppercase>
              Driver
            </DefaultText>
          </Section>
          <Section>
            <Field>
              <DefaultText minimumWidth bold>
                Name:
              </DefaultText>
              <DefaultText fixedWidth>{getDriver(appointment)}</DefaultText>
            </Field>
          </Section>
          <Section>
            <Field>
              <DefaultText mediumWidth bold>
                Phone Number:
              </DefaultText>
              <DefaultText fixedWidth>{formatPhoneNumber(appointment.contactPhone)}</DefaultText>
            </Field>
          </Section>
        </Container>
      </Container>
    )
  }
}

AppointmentCard.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  appointment: PropTypes.object,
  onClick: PropTypes.func,
  appointmentStatus: PropTypes.string,
  requestStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  calculateLateness: PropTypes.func,
  getDoorById: PropTypes.func,
  onIssue: PropTypes.func,
  drag: PropTypes.any
}

export default AppointmentCard
