import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { EntityState, RequestStatus } from '../common-types'
import { createGetLocationById, Location } from '../locations/locations-slice'
import { RootState } from '../root-types'
import { createGetSiteById, Site } from '../sites/sites-slice'

export interface BuildingEntity {
  id: number
  name: string
  locationId: number
  siteId: number
  timezone: string
}

export interface Building {
  id: number
  name: string
  location: Location
  site: Site
  timezone: string
}

export interface BuildingsState extends EntityState<BuildingEntity>{}

const adapter = createEntityAdapter<BuildingEntity>()

const initialState = adapter.getInitialState({
  loading: RequestStatus.Idle,
  error: null
})

export const getBuildingsFulfilled = createAction<BuildingEntity[]>(
  'building/getBuildings/fulfilled'
)

const slice = createSlice({
  name: 'building',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBuildingsFulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

export default slice.reducer
const globalizedSelectors = adapter.getSelectors((state: RootState) => state.building)

export const selectAllBuildings = globalizedSelectors.selectAll
export const selectBuildingEntities = globalizedSelectors.selectEntities
export const selectBuildingById = globalizedSelectors.selectById

export const createGetBuildingById = createSelector(
  selectBuildingEntities,
  createGetSiteById,
  createGetLocationById,
  (entities, getSiteById, getLocationById) => (id: number) => {
    if (!id) return null

    const buildingEntity = entities[id]
    if (!buildingEntity) return null

    const site = getSiteById(buildingEntity.siteId)
    if (!site) return null

    const location = getLocationById(buildingEntity.locationId)
    if (!location) return null

    const building: Building = {
      id: buildingEntity.id,
      name: buildingEntity.name,
      location,
      site,
      timezone: buildingEntity.timezone
    }

    return building
  }
)

// TODO: do sth with selector factory
export const createGetBuildingsBySiteId = createSelector(
  selectAllBuildings,
  buildings => (siteId: number) => buildings.filter(building => building.siteId === siteId)
)
