import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

export default class LoadingIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 49 48">
        <g fill={color} fillRule="evenodd">
          <g className="animate">
            <path d="M27.059 2.783a2.297 2.297 0 1 1-4.595 0 2.297 2.297 0 0 1 4.595 0z"/>
            <path d="M27.059 45.62a2.298 2.298 0 1 1-4.596 0 2.298 2.298 0 0 1 4.596 0z" opacity=".3"/>
            <path d="M16.29 5.655a2.297 2.297 0 1 1-4.595 0 2.297 2.297 0 0 1 4.594 0z" opacity=".9"/>
            <path d="M37.388 41.705c.613 1.072.23 2.45-.842 3.14-1.073.612-2.451.23-3.14-.843-.613-1.072-.23-2.45.842-3.14 1.072-.612 2.527-.23 3.14.843z" opacity=".25"/>
            <path d="M7.23 11.564c1.072.613 1.455 1.991.842 3.14-.613 1.072-1.991 1.454-3.14.842-1.072-.613-1.455-1.991-.842-3.14.612-1.148 2.067-1.455 3.14-.842z" opacity=".8"/>
            <path d="M44.443 32.965c1.072.613 1.455 1.992.842 3.14-.613 1.072-1.99 1.455-3.14.843-1.072-.613-1.455-1.991-.842-3.14.612-1.072 1.991-1.455 3.14-.843z" opacity=".2"/>
            <path d="M5.52 24.201a2.297 2.297 0 1 1-4.595.001 2.297 2.297 0 0 1 4.595 0z" opacity=".7"/>
            <path d="M48.477 24.201a2.298 2.298 0 1 1-4.595 0 2.298 2.298 0 0 1 4.595 0z" opacity=".15"/>
            <path d="M4.966 32.965c1.072-.612 2.451-.23 3.14.843.613 1.072.23 2.45-.842 3.14-1.072.612-2.45.23-3.14-.843-.69-1.072-.23-2.45.842-3.14z" opacity=".6"/>
            <path d="M42.145 11.547c1.072-.613 2.45-.23 3.14.842.613 1.073.23 2.451-.842 3.14-1.073.613-2.451.23-3.14-.842-.613-1.072-.23-2.527.842-3.14z" opacity=".1"/>
            <path d="M11.987 41.705c.613-1.072 1.991-1.455 3.14-.842 1.072.612 1.455 1.99.842 3.14-.612 1.072-1.99 1.454-3.14.842-1.072-.69-1.455-2.068-.842-3.14z" opacity=".4"/>
            <path d="M33.406 4.492c.612-1.072 1.99-1.455 3.14-.843 1.071.613 1.455 1.992.842 3.14-.613 1.073-1.991 1.456-3.14.843-1.072-.613-1.455-1.991-.842-3.14z" opacity=".05"/>
          </g>
          <g className="blink">
            <path d="M17.308 31.714h14.384V21.43H17.308v10.285zm2.615-15.107a.31.31 0 0 1 .327-.321h.654a.31.31 0 0 1 .327.321V19.5a.31.31 0 0 1-.092.231.322.322 0 0 1-.235.09h-.654a.31.31 0 0 1-.327-.321v-2.893zm7.847 0a.31.31 0 0 1 .326-.321h.654a.31.31 0 0 1 .327.321V19.5a.31.31 0 0 1-.327.321h-.654a.31.31 0 0 1-.327-.321v-2.893zm4.842 1.346a1.268 1.268 0 0 0-.92-.382h-1.307v-.964c0-.442-.16-.82-.48-1.135A1.587 1.587 0 0 0 28.75 15h-.654c-.45 0-.834.157-1.154.472-.32.315-.48.693-.48 1.135v.964h-3.923v-.964c0-.442-.16-.82-.48-1.135A1.587 1.587 0 0 0 20.903 15h-.654c-.45 0-.834.157-1.154.472-.32.315-.48.693-.48 1.135v.964h-1.308c-.355 0-.661.128-.92.382a1.226 1.226 0 0 0-.388.904v12.857c0 .348.13.65.388.904.259.255.565.382.92.382h14.384c.354 0 .661-.127.92-.382.259-.254.388-.556.388-.904V18.857c0-.348-.13-.65-.388-.904z"/>
            <path d="M21.38 26.988h2.632v2.632c0 .12.162.38.488.38.325 0 .488-.26.488-.38v-2.632h2.632c.12 0 .38-.162.38-.488 0-.326-.26-.488-.38-.488h-2.632V23.38c0-.12-.163-.38-.488-.38-.326 0-.488.26-.488.38v2.632H21.38c-.12 0-.38.162-.38.488 0 .325.26.488.38.488"/>
          </g>
        </g>
      </svg>
    )
  }
}

LoadingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

LoadingIcon.defaultProps = {
  width: 49,
  height: 48,
  color: '#ffffff'
}

const rotate = keyframes`
  100% {
    transform:rotate(360deg);
  }
`
const blink = keyframes`
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

export const AnimatedLoadingIcon = styled(LoadingIcon)`
  .animate, .blink {
    transform-origin: center;
  }

  .animate {
    animation: ${rotate} 3s linear infinite;
  }
  .blink {
    animation: ${blink} 3s linear infinite;
  }
`
