import { createSelector } from 'reselect'
import { getStateReports } from '../common/selectors'

const createGenericReportsSelector = field => createSelector(
  getStateReports,
  carrierRequests => carrierRequests.get(field)
)

export const getReportData = createGenericReportsSelector('data')

export const getReportIsLoading = createGenericReportsSelector('isLoading')

export const getReportErrorMessage = createGenericReportsSelector('errorMessage')
