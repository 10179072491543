import { Form, Field } from 'react-final-form'
import React, { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { Button, ButtonPrimary } from '../../styled/Buttons'
import { GridRow, GridCol } from '../../styled/Grids'
import { ErrorText, Label } from '../../styled/Texts'
import { StyledTextInput, StyledTextArea } from '../../styled/Inputs'

class SummonSMSForm extends Component {
  handleCancel = e => {
    const { onCancel } = this.props
    e.preventDefault()
    onCancel && onCancel()
  }

  handleOnSubmit = values => {
    const { onSubmit, appointmentData } = this.props
    onSubmit && onSubmit({
      ...values,
      content: this.getData(appointmentData, values.to)
    })
  }

  getData = (appointmentData, to) => {
    let customer, destination

    if (appointmentData.orders && appointmentData.orders[0]) {
      const firstOrder = appointmentData.orders[0]
      customer = firstOrder.customer ? firstOrder.customer.name : null
      destination = firstOrder.destination ? firstOrder.destination.name : null
    }

    return {
      customer: customer || '',
      destination: destination || '',
      carrier: appointmentData.carrier ? appointmentData.carrier.name : '',
      site: appointmentData.site ? appointmentData.site.name : '',
      door: appointmentData.door ? appointmentData.door.name : '',
      time: appointmentData.time && appointmentData.building
        ? moment(appointmentData.time)
          .tz(appointmentData.building.timezone)
          .format('MM-DD-YYYY HH:mm')
        : '',
      to: appointmentData.to || to || ''
    }
  }

  generateContent = (appointmentData, to) => {
    const data = this.getData(appointmentData, to)
    return (
      `Customer: ${data.customer}\n` +
      `Destination: ${data.destination}\n` +
      `Carrier: ${data.carrier}\n` +
      `Site: ${data.site}\n` +
      `Door: ${data.door}\n` +
      `Time: ${data.time}\n` +
      `To: ${data.to}`
    )
  }

  render () {
    const { appointmentData, loading, error } = this.props
    const content = this.generateContent(appointmentData)
    return (
      <GridCol page flex>
        {error && <ErrorText>{error}</ErrorText>}
        <Form
          onSubmit={this.handleOnSubmit}
          initialValues={{ to: appointmentData.to }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <GridRow>
                <GridCol flex spaced="bottom">
                  <Field name="to">
                    {({ input, meta }) => (
                      <StyledTextInput
                        {...input}
                        error={meta.touched && meta.error}
                        fullwidth="true"
                        placeholder="Phone number"
                        label="To"
                      />
                    )}
                  </Field>
                </GridCol>
              </GridRow>
              <GridRow grow fill="true" spaced="top">
                <GridCol page fill="true" flex>
                  <Label block>Body: </Label>
                  <StyledTextArea value={content} readOnly fullwidth="true" rows="15"/>
                </GridCol>
              </GridRow>
              <GridRow spaced="top">
                <ButtonPrimary softRounded type="submit" disabled={loading}>
                  Send
                </ButtonPrimary>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </GridRow>
            </form>
          )}
        />
      </GridCol>
    )
  }
}

SummonSMSForm.propTypes = {
  appointmentData: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string
}

export default SummonSMSForm
