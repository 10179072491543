import { Map } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  doors: null,
  appointments: null,
  getDoorsIsLoading: false,
  getDoorsErrorMessage: null,
  getAppointmentsForDoorIsLoading: false,
  getAppointmentsForDoorErrorMessage: null,
  getAppointmentsForDoorsIsLoading: false,
  getAppointmentsForDoorsErrorMessage: null
})
