import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import 'react-tabs/style/react-tabs.css'
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from '@mui/material/styles'

import createStore from './store'
import Main from './Main'
import registerServiceWorker from './registerServiceWorker'
import { theme } from './themes/taylor-farms'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  body {
    font-family: ${props => props.theme.fonts.fontFamily};
    background-color: ${props => props.theme.background};
    margin: 0;
    font-size: 16px;

    * {
      box-sizing: border-box;
    }

    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  .react-datepicker-popper {
    z-index: 4000 !important;
  }

  ::-webkit-scrollbar-track{background-color:#dfdfdf;}
  ::-webkit-scrollbar{width:6px!important;height:6px!important;}
  ::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,.2);display:none;}
  :hover::-webkit-scrollbar-thumb{display:block;}
`

const muiTheme = createMuiTheme(theme)
const { store } = createStore()

const router = (
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <DndProvider
          backend={HTML5Backend}
        >
        <Main />
        <GlobalStyles />
      </DndProvider>
    </MuiThemeProvider>
  </Provider>
)

ReactDOM.render(router, document.getElementById('root'))
registerServiceWorker()

// Opt-in to Webpack hot module replacement
if (module.hot) module.hot.accept()
