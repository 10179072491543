import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Badge } from '../styled/Notifications'
import { ButtonPrimarySimple } from '../styled/Buttons'
import { Day } from '../styled/Boxes'
import { GridCell, GridRow } from '../styled/Grids'

class DayTabs extends Component {
  render () {
    const { appointmentCounts, handleClick, days, isInventoryCalculationEnabled } = this.props

    return (
      <GridRow>
        {days.map(day => {
          let total = 0
          let countDayData = null
          if (appointmentCounts) {
            countDayData = appointmentCounts.get(day.countKey)
            if (countDayData) {
              total = Object.values(countDayData.toJS()).reduce((total, currentValue) => total + Number(currentValue), 0)
            }
          }

          return (
            <GridCell spaced="right" key={day.id}>
              <ButtonPrimarySimple
                size="small"
                selected={day.status === 'selected'}
                past={day.status === 'past'}
                onClick={e => handleClick(day.id)}
                title={
                  isInventoryCalculationEnabled
                    ? (
                      `Number of inventory issues (${countDayData ? countDayData.get('allowIssuesCount') : 0}), ` +
                      `late appointments (${countDayData ? countDayData.get('lateApptCount') : 0}) and/or ` +
                      `carrier requests (${countDayData ? countDayData.get('pendingCarrierRequestCount') : 0}) for this day`
                      ) : undefined}
              >
                {isInventoryCalculationEnabled && appointmentCounts && total > 0 && (
                  <Badge right>
                    {total}{' '}
                  </Badge>
                )}
                <Day status={day.status}>
                  {day.date.month() + 1}/{day.date.date()}
                </Day>
              </ButtonPrimarySimple>
            </GridCell>
          )
        })}
      </GridRow>
    )
  }
}

DayTabs.propTypes = {
  days: PropTypes.array,
  handleClick: PropTypes.func,
  appointmentCounts: PropTypes.object,
  isInventoryCalculationEnabled: PropTypes.bool
}

export default DayTabs
