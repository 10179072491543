import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import { ButtonInvisible, ButtonPrimary } from '../../../styled/Buttons'
import { DefaultSelect } from '../../../styled/Selects'
import { ExpandableContainer } from '../../../styled/Containers'
import { FilterIcon } from '../../../components/icons'
import { GridCol, GridRow } from '../../../styled/Grids'
import { HorizontalLine } from '../../../styled/Separators'
import { StyledCheckbox, StyledSimpleInput } from '../../../styled/Inputs'
import { getAllCustomersAsOptions } from '../../../modules/customers/selectors'
import CustomersActions from '../../../modules/customers/actions'
import DatePicker from '../../../components/DatePicker'
import { SelectAllBox } from '../../../styled/Boxes'
import { selectAllLocations } from '../../../locations/locations-slice'
import { convertEntitiesToSelectOptions } from '../../../ui'
import { isAppointmentTypeInbound, isAppointmentTypeOutbound } from '../../../app/app-slice'
import { selectAllCarriers } from '../../../carriers/carriers-slice'

class Filters extends PureComponent {
  state = {
    values: {
      searchText: '',
      customerPurchaseOrder: ''
    },
    isMulti: false
  }

  handleClearFilters = () =>
    this.setState({
      values: {
        ...Object.keys(this.state.values).reduce((values, key) => {
          values[key] = ''
          return values
        }, {})
      },
      isMulti: false
    }, this.doClear)

  getValue = key => this.state.values[key]

  setValue = (key, value) => this.setState({
    values: {
      ...this.state.values,
      [key]: value
    }
  }, () => {
    const isMulti = this.props.allowMulti &&
      this.state.values.searchText &&
      this.state.values.searchText.indexOf(',') > -1

    this.setState({
      isMulti
    }, this.handleSearch)
  })

  doSearch = () =>
    this.props.onSearch && this.props.onSearch({
      ...Object.keys(this.state.values).reduce((values, key) => {
        values[key] = this.state.values[key] && this.state.values[key].trim
          ? this.state.values[key].trim()
          : (
              this.state.values[key] === '' || this.state.values[key] === undefined
                ? null
                : this.state.values[key]
            )
        return values
      }, {}),
      currentPage: 1
    })

  handleSearch = debounce(() => {
    const { isMulti } = this.state
    !isMulti && this.doSearch()
  }, 500)

  doClear = debounce(() => {
    this.props.onClear && this.props.onClear({
      ...this.state.values,
      currentPage: 1
    })
  }, 200)

  componentDidMount () {
    const { searchAttributes } = this.props

    this.setState(state => ({
      values: {
        ...state.values,
        ...searchAttributes
      }
    }))
  }

  handleSelectAll = event => {
    const { onSelectAll } = this.props
    onSelectAll && onSelectAll(event.target.checked)
  }

  getFilterCustomerLabel = (appointmentType) => {
    if (isAppointmentTypeInbound(appointmentType)) return 'Vendor'
    if (isAppointmentTypeOutbound(appointmentType)) return 'Customer'

    return 'Vendor/Customer'
  }

  getFilterDestinationLabel = (appointmentType) => {
    if (isAppointmentTypeInbound(appointmentType)) return 'Origin'
    if (isAppointmentTypeOutbound(appointmentType)) return 'Destination'

    return 'Origin/Destination'
  }

  render () {
    const { isMulti } = this.state

    const {
      showId,
      idPlaceholder,
      appointmentType,
      searchAttributesCount,
      open,
      carriers,
      customers,
      locations,
      showCarriers,
      showCustomers,
      showLocations,
      showSelectAll,
      isSelectedAll,
      showDate,
      getAllCustomers,
      getAllLocations,
      statuses,
      dateField
    } = this.props

    let height = 325
    if (!showId) {
      height -= 50
    }
    if (!showCustomers) {
      height -= 39
    }
    if (!showLocations) {
      height -= 39
    }
    if (!showDate) {
      height -= 39
    }
    if (showCarriers) {
      height += 27
    }
    if (isMulti) {
      height += 39
    }

    return (
      <GridCol>
        <GridRow padded="bottom">
          <ButtonPrimary onClick={this.props.onToggle}>
            {`Filters ${searchAttributesCount > 0 ? `(${searchAttributesCount})` : ''}`}
            <FilterIcon />
          </ButtonPrimary>
          {open ? (
            <ButtonInvisible onClick={this.handleClearFilters}>
              Clear Filters
            </ButtonInvisible>
          ) : null}
        </GridRow>
        <ExpandableContainer isExpanded={open} vertical height={`${height}px`} width="230px">
          {
            showId && <StyledSimpleInput
              placeholder={idPlaceholder}
              value={this.getValue('id') || ''}
              onChange={event => this.setValue('id', event.target.value)}
              onKeyDown={event => {
                // if user press enter
                if (event.keyCode === 13) {
                  this.doSearch()
                }
              }}
            />
          }
          <StyledSimpleInput
            placeholder="Order"
            value={this.getValue('searchText') || ''}
            onChange={event => this.setValue('searchText', event.target.value)}
            onKeyDown={event => {
              // if user press enter
              if (event.keyCode === 13) {
                this.doSearch()
              }
            }}
          />
          {
            isAppointmentTypeOutbound(appointmentType) && <StyledSimpleInput
              placeholder="Customer Purchase Order"
              value={this.getValue('customerPurchaseOrder') || ''}
              onChange={event => this.setValue('customerPurchaseOrder', event.target.value)}
              onKeyDown={event => {
                // if user press enter
                if (event.keyCode === 13) {
                  this.doSearch()
                }
              }}
            />
          }
          <DefaultSelect
            isClearable
            options={statuses.options}
            placeholder={statuses.label}
            value={this.getValue(statuses.key)}
            onChange={payload => this.setValue(statuses.key, payload)}
          />
          {
            showCustomers ? (
              <DefaultSelect
                disableDropdownIndicator
                disableEmptyOptions
                isClearable
                options={customers}
                placeholder={this.getFilterCustomerLabel(appointmentType)}
                value={this.getValue('customerSelect')}
                onInputChange={payload => {
                  payload && payload.length > 1 && getAllCustomers({ name: payload })
                }}
                onChange={payload => this.setValue('customerSelect', payload)}
              />
            ) : null
          }
          {
            showDate ? (
              <DatePicker
                SearchOrderDatePicker
                dateFormat="LL"
                placeholderText={dateField.label}
                // After clear filter the value is set for all fields to '' and in this case it should be null
                // TODO: refactor clear filter
                selected={(this.getValue(dateField.key) === '') ? null : this.getValue(dateField.key)}
                onChange={payload => this.setValue(dateField.key, payload)}
              />
            ) : null
          }
          {
            showLocations ? (
              <DefaultSelect
                disableDropdownIndicator
                disableEmptyOptions
                isClearable
                options={locations}
                placeholder={this.getFilterDestinationLabel(appointmentType)}
                value={this.getValue('destinationSelect')}
                onInputChange={payload => {
                  payload && payload.length > 1 && getAllLocations({ name: payload })
                }}
                onChange={payload => this.setValue('destinationSelect', payload)}
              />
            ) : null
          }
          {
            showCarriers ? (
              <DefaultSelect
                isClearable
                options={carriers}
                placeholder="Carrier"
                value={this.getValue('carrierSelect')}
                onChange={payload => this.setValue('carrierSelect', payload)}
              />
            ) : null
          }

          {
            isMulti ? (
              <ButtonPrimary block onClick={this.doSearch}>Search</ButtonPrimary>
            ) : null
          }
        </ExpandableContainer>

        {
          (isAppointmentTypeInbound(appointmentType) || isAppointmentTypeOutbound(appointmentType)) && showSelectAll ? (
            <SelectAllBox>
              <StyledCheckbox
                checked={isSelectedAll}
                onChange={this.handleSelectAll}
                color="default"
              /> Select All
            </SelectAllBox>
          ) : null
        }

        <HorizontalLine />
      </GridCol>
    )
  }
}

Filters.propTypes = {
  showId: PropTypes.bool,
  idPlaceholder: PropTypes.string,
  appointmentType: PropTypes.number,
  onToggle: PropTypes.func,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  getAllLocations: PropTypes.func,
  getAllCustomers: PropTypes.func,
  onSelectAll: PropTypes.func,
  open: PropTypes.bool,
  showDate: PropTypes.bool,
  showCarriers: PropTypes.bool,
  showCustomers: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  showLocations: PropTypes.bool,
  isSelectedAll: PropTypes.bool,
  allowMulti: PropTypes.bool,
  statuses: PropTypes.shape({
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }),
  dateField: PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }),
  searchAttributes: PropTypes.object,
  customers: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  locations: PropTypes.array,
  carriers: PropTypes.array,
  searchAttributesCount: PropTypes.number
}

Filters.defaultProps = {
  showId: false,
  idPlaceholder: 'Id',
  appointmentType: 0,
  allowMulti: false,
  showSelectAll: false,
  showCarriers: false,
  showCustomers: true,
  showLocations: true,
  showDate: true,
  open: false,
  searchAttributesCount: 0,
  statuses: {
    options: [],
    label: 'Statuses',
    key: 'statusSelect'
  },
  dateField: {
    label: 'Date',
    key: 'dateSelect'
  }
}

const mapStateToProps = (state, ownProps) => ({
  carriers: ownProps.showCarriers ? convertEntitiesToSelectOptions(selectAllCarriers(state)) : [],
  customers: ownProps.showCustomers ? getAllCustomersAsOptions(state) : [],
  locations: ownProps.showLocations ? convertEntitiesToSelectOptions(selectAllLocations(state)) : []
})

const mapDispatchToProps = dispatch => ({
  getAllCustomers: payload =>
    dispatch(CustomersActions.getAllCustomers(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
