import { createGetDriverEntityById } from '../../drivers/drivers-slice'
import { List } from 'immutable'
import { createSelector } from 'reselect'
import { getStateCarrierRequests } from '../common/selectors'
import { pick } from '../../utils/common'
import { createGetCarrierById } from '../../carriers/carriers-slice'
import { createGetAppointmentById } from '../../appointments/appointments-slice'

export const getAllCarrierRequests = createSelector(
  getStateCarrierRequests,
  createGetAppointmentById,
  createGetDriverEntityById,
  createGetCarrierById,
  (carrierRequests, getAppointmentById, getDriverById, getCarrierById) =>
    carrierRequests.get('carrierRequests')
      ? carrierRequests.get('carrierRequests')
        .map(request => request.set('appointment', getAppointmentById(request.get('appointmentId')))
          .set('driver', getDriverById(request.get('driverId')))
          .set('carrier', getCarrierById(request.get('carrierId'))))
      : new List()
)

const createGenericCarrierRequestsSelector = field => createSelector(
  getStateCarrierRequests,
  carrierRequests => carrierRequests.get(field)
)

export const getAddPoFailure = createGenericCarrierRequestsSelector('addPoFailure')
export const getAddPoSuccess = createGenericCarrierRequestsSelector('addPoSuccess')
export const getAddPoIsLoading = createGenericCarrierRequestsSelector('addPoIsLoading')

export const numberOfCarrierRequests = createGenericCarrierRequestsSelector('numberOfCarrierRequests')

export const getCreatingCarrierRequest = createGenericCarrierRequestsSelector('creatingCarrierRequest')

export const getCarrierRequestPickUpTimes = createGenericCarrierRequestsSelector('carrierRequestsPickUpTimes')

export const getAllCarrierRequestsIsLoading = createGenericCarrierRequestsSelector('getAllCarrierRequestsIsLoading')

export const getDeletingCarrierRequest = createGenericCarrierRequestsSelector(
  'removingRequest'
)

export const getSearchAttributes = createSelector(
  getStateCarrierRequests,
  carrierRequests => ({
    searchText: carrierRequests.get('searchText'),
    customerPurchaseOrder: carrierRequests.get('customerPurchaseOrder'),
    carrierRequestStatus: carrierRequests.get('carrierRequestStatus'),
    carrierSelect: carrierRequests.get('carrierSelect'),
    currentPage: carrierRequests.get('currentPage')
  })
)

export const getSearchAttributesCount = createSelector(
  getSearchAttributes,
  searchAttributes => {
    const keys = [
      'searchText',
      'customerPurchaseOrder',
      'customerSelect',
      'carrierRequestStatus',
      'carrierSelect'
    ]

    return pick(searchAttributes, keys)
      .filter(attribute => attribute)
      .count()
  }
)

export const createGetCarrierRequestByAppointmentId = createSelector(
  getAllCarrierRequests,
  carrierRequests => appointmentId =>
    carrierRequests.filter(cr => cr.get('appointmentId') === appointmentId)
)
