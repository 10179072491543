import { CardContainer } from '../../styled/Containers'
import { StyledTable, TableCol, TableColumnHeader, TableRow } from '../../styled/Table'
import PropTypes from 'prop-types'
import React from 'react'

const COLUMNS_LENGTH = 6
const PREFIX_KEY = 'yesterdaySummary'

const YesterdaySummary = ({ data, isLoading, errorMessage }) => (
  <CardContainer>
    <StyledTable>
      <thead>
        <TableRow>
          <TableColumnHeader />
          <TableColumnHeader>WAREHOUSE</TableColumnHeader>
          <TableColumnHeader>TRUCKS</TableColumnHeader>
          <TableColumnHeader>TOTAL CASES</TableColumnHeader>
          <TableColumnHeader>TOTAL ORDERS</TableColumnHeader>
          <TableColumnHeader>AVG LOAD TIME</TableColumnHeader>
        </TableRow>
      </thead>
      <tbody>
        {(!isLoading && data) && data.map((s, key) => (
          <TableRow key={PREFIX_KEY + key + s.get('warehouse')}>
            <TableCol>{key + 1}</TableCol>
            <TableCol>{s.get('warehouse')}</TableCol>
            <TableCol>{s.get('trucks')}</TableCol>
            <TableCol>{s.get('totalCases')}</TableCol>
            <TableCol>{s.get('totalOrders')}</TableCol>
            <TableCol>{s.get('averageLoadTime')}</TableCol>
          </TableRow>
        ))}
        {isLoading && (
          <TableRow key={`${PREFIX_KEY}Loading`}>
            <TableCol colSpan={COLUMNS_LENGTH}> LOADING REPORT </TableCol>
          </TableRow>
        )}
        {(!isLoading && !errorMessage && (!data || data.isEmpty())) && (
          <TableRow key={`${PREFIX_KEY}NoData`}>
            <TableCol colSpan={COLUMNS_LENGTH}> NO DATA FOUND </TableCol>
          </TableRow>
        )}
        {(errorMessage && !isLoading) && (
          <TableRow key={`${PREFIX_KEY}Error`}>
            <TableCol colSpan={COLUMNS_LENGTH}> THERE WAS AN ERROR LOADING THE REPORT. </TableCol>
          </TableRow>
        )}
      </tbody>
    </StyledTable>
  </CardContainer>
)

YesterdaySummary.propTypes = {
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool
}

export default YesterdaySummary
