import { List } from 'immutable'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'

import { Container, ExpandableContainer, Scrollable } from '../../../styled/Containers'
import { GridCol, GridRow } from '../../../styled/Grids'
import { SimpleSpinner } from '../../../styled/Loading'
import { createGetDoorById } from '../../../doors/doors-slice'
import {
  getCheckedOrders,
  getOrders,
  getOrdersIsLoading,
  getPages,
  getSearchAttributes,
  getSearchAttributesCount,
  getSelectedOrders
} from '../../../modules/orders/selectors'
import {
  getAppointments,
  getIsAppointmentModalVisible,
  isEditingAppointmentOutbound
} from '../../../modules/appointments/selectors'
import { getOrderPickingRate, getOrderSummary } from '../../../utils/getOrderSummary'
import AppointmentsActions from '../../../modules/appointments/actions'
import DraggableOrderCard from '../../../components/DraggableOrderCard'
import Filters from '../layout/Filters'
import HandleError from '../../../components/hocs/HandleError'
import OrdersActions from '../../../modules/orders/actions'
import socket from '../../../utils/socket'
import { ORDER_STATUS } from '@smartdock-shared/orderStatus'
import {
  isAppointmentTypeInbound,
  isAppointmentTypeOutbound,
  selectAppointmentType
} from '../../../app/app-slice'
import { selectAllOrderStatuses, selectOrderEntities } from '../../../orders/orders-slice'
import { convertEntitiesToSelectOptions } from '../../../ui'
import moment from 'moment'

const getDoorByOrder = (order, getDoorById) => {
  if (!order.appointment) return null
  return getDoorById(order.appointment.doorId)
}

const convertSearchOrderParamsToGetOrdersPayload = payload => {
  const data = {
    customerPurchaseOrder: payload.customerPurchaseOrder,
    searchText: payload.searchText,
    orderStatusId: payload.ordersStatusSelect,
    customerId: payload.customerSelect,
    deliveryDate: payload.deliveryDateSelect
      ? payload.deliveryDateSelect.format('YYYY-MM-DD')
      : null,
    requiredShipDate: payload.requiredShipDateSelect
      ? payload.requiredShipDateSelect.format('YYYY-MM-DD')
      : null,
    destinationId: payload.destinationSelect,
    page: payload.currentPage,
    include: 'appointments'
  }

  if (data.deliveryDate) {
    const deliveryDate = moment(data.deliveryDate)
    data.deliveryDateFrom = deliveryDate.subtract(1, 'day').format('YYYY-MM-DD')
    data.deliveryDateTo = deliveryDate.add(2, 'days').format('YYYY-MM-DD')
    // change is done inplace so we need to normalize
    deliveryDate.subtract(1, 'day')
  }

  return data
}

class Orders extends Component {
  state = {
    activeDroppedOrder: this.props.droppedOrder,
    collapseAll: false,
    expandAll: false,
    isSelectAllOrders: false,
    showFilters: false
  }

  constructor (props) {
    super(props)

    this.toggleShowFilters = this.toggleShowFilters.bind(this)
    this.onScrollOrderList = this.onScrollOrderList.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (
      nextState !== this.state ||
      nextProps.orders !== this.props.orders ||
      nextProps.selectedOrders !== this.props.selectedOrders ||
      nextState.showFilters !== this.state.showFilters ||
      nextProps.appointmentType !== this.props.appointmentType
    )
  }

  componentDidUpdate (prevProps) {
    const {
      pages,
      loading,
      searchOrders,
      searchAttributes,
      selectedOrders,
      orders,
      isUpsertAppointmentVisible,
      appointmentType
    } = this.props

    if (prevProps.appointmentType !== appointmentType) {
      searchOrders({ currentPage: 1 })
      this.clearFilters()

      return
    }

    if (
      prevProps.searchAttributes.currentPage !== this.props.searchAttributes.currentPage &&
      this.orderList &&
      searchAttributes.currentPage < pages &&
      !loading &&
      !isUpsertAppointmentVisible
    ) {
      const currentPage =
        this.props.orders.size === 0
          ? searchAttributes.currentPage
          : searchAttributes.currentPage + 1
      searchOrders({ ...this.searchFilters, currentPage })
    }

    if (
      (!selectedOrders.size || selectedOrders.size !== orders.size) &&
      !isUpsertAppointmentVisible &&
      this.state.isSelectAllOrders &&
      prevProps.selectedOrders.size > 0
    ) {
      this.setState({
        isSelectAllOrders: false
      })
    }
  }

  componentDidMount () {
    const { searchOrders, appointmentType } = this.props

    // TODO: Fix this in proper way, this fixed pagination with filters
    this.searchFilters = {}

    if (appointmentType !== -1) {
      searchOrders({ currentPage: 1 })
    }
    // remove listeners from previous event
    socket.orders.off('order')
    // keep listening for changes
    socket.orders.on('order', socketOrder => {
      this.props.updateOrdersWithSocketOrder({ socketOrder })
    })
  }

  toggleShowFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters
    })
  }

  onSelectOrder = order => selected => {
    const orderId = order.get('id')
    const { checkedOrders, setCheckedOrders } = this.props

    if (selected) {
      setCheckedOrders(checkedOrders.push(order))
    } else {
      setCheckedOrders(checkedOrders.filter(o => o.get('id') !== orderId))
    }
  }

  onDropOnTable = ({ props, door, hour }) => {
    const { setSelectedOrders, openEditAppointment } = this.props

    const orders = props.checkedOrders.isEmpty() ? [props.order] : props.checkedOrders.toJS()
    const items = orders.reduce((items, order) => [...items, ...order.items], [])

    const newAppointment = {
      door: door,
      doorId: door.id,
      date: hour,
      time: hour,
      inProgress: true,
      duration: 60,
      items,
      orders,
      orderIds: orders.map(order => order.id || order.get('id'))
    }

    setSelectedOrders(orders)
    openEditAppointment({
      ...newAppointment,
      recalculateDuration: true
    })
  }

  onDropOnPlaceholder = ({ props }) => {
    const {
      allOrders,
      checkedOrders,
      selectedOrders,
      setSelectedOrders,
      setRecalculateDurationFlag
    } = this.props

    const selectedOrderIds = selectedOrders.map(order => order.get('id'))
    const checkedOrderIds = checkedOrders.map(order => order.get('id'))
    const droppedOrderId = props.order.id
    const updatedOrderIds = [...new Set([...selectedOrderIds, ...checkedOrderIds, droppedOrderId])]
    setSelectedOrders(updatedOrderIds.map(orderId => allOrders[orderId]))
    setRecalculateDurationFlag(true)
  }

  orderCardRenderer = (order, index) => {
    const {
      selectedOrders,
      checkedOrders,
      orderStatuses,
      isUpsertAppointmentVisible,
      getDoorById
    } = this.props
    const plainOrder = order.toJS()

    const pickingRate = getOrderPickingRate(plainOrder.items)

    // TODO move all legacy data transformation to selectors (memoize)
    const orderSummary = getOrderSummary(plainOrder.items)
    const orderPallets = plainOrder.pallets || null
    const door = getDoorByOrder(plainOrder, getDoorById)
    const orderDoor = door ? door.name : 'N/A'

    const orderStatus =
      orderStatuses && orderStatuses.find(s => s.value === order.get('orderStatusId'))
    const isScheduled = orderStatus && orderStatus.label.toLowerCase() === 'scheduled'
    const isOrderSelected = selectedOrders.some(o => o.get('id') === order.get('id'))
    const isOrderChecked = checkedOrders.some(o => o.get('id') === order.get('id'))
    const isDisabled =
      (isUpsertAppointmentVisible &&
        (isOrderSelected ||
          (this.props.isEditingAppointmentOutbound !== null &&
            order.get('isOutbound') !== this.props.isEditingAppointmentOutbound))) ||
      (checkedOrders.size > 0 &&
        checkedOrders.first().get('isOutbound') !== order.get('isOutbound'))

    // FIXME only use plain JS in all this file
    return (
      <DraggableOrderCard
        key={index + '-' + order.get('id')}
        nopadding
        isCreateAppointment={false}
        order={plainOrder}
        isSelected={isOrderSelected}
        selected={isOrderChecked}
        onSelectedChange={this.onSelectOrder(order)}
        onCreateAppointment={this.onCreateAppointmentForOrder(order)}
        onEditAppointment={this.onEditAppointmentForOrder(order)}
        onOpenOrderDetailsModal={this.onOpenOrderDetailsModal(order)}
        onDropOnTable={this.onDropOnTable}
        onDropOnPlaceholder={this.onDropOnPlaceholder}
        checkedOrders={checkedOrders}
        isScheduled={isScheduled}
        isDisabled={isDisabled}
        orderDoor={orderDoor}
        orderQuantities={orderSummary.totalOrderedQuantities}
        orderWeight={orderSummary.totalWeight}
        orderSKUs={orderSummary.totalSkus}
        orderPallets={orderPallets}
        pickingRate={pickingRate}
      />
    )
  }

  onCreateAppointment = () => {
    const { checkedOrders, setSelectedOrders, openEditAppointment } = this.props

    setSelectedOrders(checkedOrders)
    openEditAppointment({
      duration: 60,
      recalculateDuration: true,
      isCreateAppointment: true
    })
  }

  clearFilters = () => {
    this.props.searchOrders({
      searchText: '',
      customerPurchaseOrder: '',
      isScheduledSelect: null,
      ordersStatusSelect: null,
      customerSelect: null,
      deliveryDateSelect: null,
      requiredShipDateSelect: null,
      destinationSelect: null,
      currentPage: 1
    })
  }

  onCreateAppointmentForOrder = order => () => {
    const { setSelectedOrders, openEditAppointment } = this.props
    setSelectedOrders(new List([order]))
    openEditAppointment({
      duration: 60,
      recalculateDuration: true,
      isCreateAppointment: true,
      inProgress: true,
      orders: [order.toJS()]
    })
  }

  onEditAppointmentForOrder = order => () => {
    const { openEditAppointment } = this.props

    const appointment = order.get('appointment')
    if (appointment && !appointment.isEmpty()) {
      openEditAppointment(appointment.toJS())
    } else {
      NotificationManager.error('Could not find the appointment for this order.')
    }
  }

  onOpenOrderDetailsModal = order => () => {
    const { openOrderDetailsModal, setOrderDetails } = this.props

    const orderDetails = {
      order
    }

    setOrderDetails(orderDetails)
    openOrderDetailsModal()
  }

  debouncedShowFilters = debounce(this.toggleShowFilters, 300)

  onScrollOrderList = throttle(() => {
    const { pages, loading, searchOrders, searchAttributes } = this.props
    if (this.orderList && searchAttributes.currentPage < pages && !loading) {
      const scrollPos = this.orderList.scrollTop + this.orderList.clientHeight
      if (scrollPos + 500 > this.orderList.scrollHeight) {
        searchOrders({ ...this.searchFilters, currentPage: searchAttributes.currentPage + 1 })
      }
    }
    if (this.state.showFilters) {
      this.debouncedShowFilters()
    }
  }, 1000)

  collapseAll = () =>
    this.setState(() => ({
      collapseAll: true,
      expandAll: false
    }))

  expandAll = () =>
    this.setState(() => ({
      expandAll: true,
      collapseAll: false
    }))

  handleSelectAllOrdersChange = checked => {
    const { setCheckedOrders, orders = [] } = this.props
    this.setState(
      {
        isSelectAllOrders: checked
      },
      () => {
        if (this.state.isSelectAllOrders) {
          setCheckedOrders(orders.filter(order => order.get('orderStatusId') === ORDER_STATUS.OPEN))
        } else {
          setCheckedOrders([])
        }
      }
    )
  }

  onSearch = filters => {
    const { searchOrders } = this.props
    this.searchFilters = filters
    console.warn(`onSearch ${filters.searchText}`, this.props)
    searchOrders({ ...this.searchFilters, ...filters })
  }

  getFilterDateLabel = appointmentType => {
    if (isAppointmentTypeInbound(appointmentType)) return 'Arrival date'
    if (isAppointmentTypeOutbound(appointmentType)) return 'Ship date'

    return 'Arrival/Ship date'
  }

  render () {
    const { isSelectAllOrders } = this.state
    const {
      loading,
      orders = new List([]),
      searchAttributes,
      searchAttributesCount,
      orderStatuses,
      appointmentType
    } = this.props

    return (
      <GridCol flex={1}>
        <Filters
          allowMulti
          open={this.state.showFilters}
          onToggle={this.toggleShowFilters}
          searchAttributes={searchAttributes}
          searchAttributesCount={searchAttributesCount}
          dateField={{
            label: this.getFilterDateLabel(appointmentType),
            key: 'requiredShipDateSelect'
          }}
          statuses={{
            options: orderStatuses,
            label: 'Order Status',
            key: 'ordersStatusSelect'
          }}
          onClear={this.clearFilters}
          onSearch={this.onSearch}
          showCustomers
          showLocations
          showSelectAll
          isSelectedAll={isSelectAllOrders}
          onSelectAll={this.handleSelectAllOrdersChange}
          appointmentType={appointmentType}
        />

        <Scrollable
          ref={ref => {
            this.orderList = ref
          }}
          onScroll={this.onScrollOrderList}
        >
          {orders && orders.size > 0 ? (
            orders.map(this.orderCardRenderer)
          ) : (
            <Container>{'No Results'}</Container>
          )}
        </Scrollable>
        <ExpandableContainer isExpanded={loading} vertical height='50'>
          <GridRow centered padded='right'>
            <SimpleSpinner />
          </GridRow>
        </ExpandableContainer>
      </GridCol>
    )
  }
}

Orders.propTypes = {
  updateOrdersWithSocketOrder: PropTypes.func,
  searchOrders: PropTypes.func,
  orders: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  searchAttributes: PropTypes.object,
  appointmentType: PropTypes.number,
  orderStatuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  searchAttributesCount: PropTypes.number,
  pages: PropTypes.number,
  loading: PropTypes.bool,

  // preventWarning
  droppedOrder: PropTypes.any,
  checkedOrders: PropTypes.any,
  setCheckedOrders: PropTypes.func,
  getDoorById: PropTypes.func,
  selectedOrders: PropTypes.any,
  isUpsertAppointmentVisible: PropTypes.bool,
  setSelectedOrders: PropTypes.func,
  openEditAppointment: PropTypes.func,
  openOrderDetailsModal: PropTypes.func,
  setOrderDetails: PropTypes.func,
  setRecalculateDurationFlag: PropTypes.func,
  allOrders: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isEditingAppointmentOutbound: PropTypes.bool
}

const mapStateToProps = state => ({
  allOrders: selectOrderEntities(state) || [],
  orders: getOrders(state),
  getDoorById: createGetDoorById(state),
  orderStatuses: convertEntitiesToSelectOptions(selectAllOrderStatuses(state)),
  selectedOrders: getSelectedOrders(state),
  checkedOrders: getCheckedOrders(state) || List(),
  appointments: getAppointments(state),
  isUpsertAppointmentVisible: getIsAppointmentModalVisible(state),
  pages: getPages(state),
  loading: getOrdersIsLoading(state),
  searchAttributes: getSearchAttributes(state),
  appointmentType: selectAppointmentType(state),
  searchAttributesCount: getSearchAttributesCount(state),
  isEditingAppointmentOutbound: isEditingAppointmentOutbound(state)
})

const mapDispatchToProps = dispatch => ({
  setSelectedOrders: orders => dispatch(OrdersActions.setSelectedOrders(orders)),
  setCheckedOrders: orders => dispatch(OrdersActions.setCheckedOrders(orders)),
  openEditAppointment: appointment =>
    dispatch(AppointmentsActions.openEditAppointment(appointment)),
  openOrderDetailsModal: () => dispatch(OrdersActions.openOrderDetailsModal()),
  setOrderDetails: orderDetails => dispatch(OrdersActions.setOrderDetails(orderDetails)),
  updateOrdersWithSocketOrder: payload =>
    dispatch(OrdersActions.updateOrdersWithSocketOrder(payload)),
  searchOrders: payload =>
    dispatch(OrdersActions.getOrders(convertSearchOrderParamsToGetOrdersPayload(payload))),
  setRecalculateDurationFlag: flag => dispatch(AppointmentsActions.setRecalculateDurationFlag(flag))
})

export default connect(mapStateToProps, mapDispatchToProps)(HandleError(Orders))
