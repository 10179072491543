import {
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  PayloadAction
} from '@reduxjs/toolkit'
import { Appointment } from '../appointments/appointments-slice'
import { EntityState, RequestStatus } from '../common-types'
import { RootState } from '../root-types'
import orderStatusesReducer, {
  initialState as orderStatusesInitialState,
  OrderStatusesState,
  _selectAllOrderStatuses,
  setManyOrderStatuses
} from './order-statuses-slice'

export interface OrderItemContent {
  remainderQty: number
}

export interface OrderItem {
  id: number
  sku: string
  orderItem: OrderItemContent
}

export interface Order {
  id: number
  items: Dictionary<OrderItem>
  pallets: number
  appointment: Appointment
}

export interface OrderEntity {
  id: number
  items: Dictionary<OrderItem>
  pallets: number
  appointmentId: number
  orderStatusId: number
}

export interface OrdersState extends EntityState<OrderEntity> {
  orderStatus: OrderStatusesState
}

const adapter = createEntityAdapter<OrderEntity>()

const initialState = adapter.getInitialState({
  loading: RequestStatus.Idle,
  error: null
})

const slice = createSlice({
  name: 'order',
  initialState: {
    ...initialState,
    orderStatus: orderStatusesInitialState
  } as OrdersState,
  reducers: {
    setManyOrders: (state: OrdersState, action: PayloadAction<OrderEntity[]>) => (
      adapter.setMany(state, action.payload)
    )
  },
  extraReducers: {
    [setManyOrderStatuses.toString()]: (state: OrdersState, action) => {
      orderStatusesReducer(state.orderStatus, action)
    }
  }
})

export default slice.reducer
export const { setManyOrders } = slice.actions

const selectOrdersState = (state: RootState) => {
  return state.order
}
const globalizedSelectors = adapter.getSelectors(selectOrdersState)

export const selectAllOrders = globalizedSelectors.selectAll
export const selectOrderEntities = globalizedSelectors.selectEntities
export const selectAllOrderStatuses = createSelector(selectOrdersState, orderStatusState =>
  _selectAllOrderStatuses(orderStatusState)
)
