import DatePicker from 'react-datepicker'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { ButtonIcon } from '../styled/Buttons'
import { Container } from '../styled/Containers'
import { MaterialDatePickerContainer } from '../styled/DatePicker'
import { DefaultText, MaterialLabel } from '../styled/Texts'
import { StyledCalendarIcon } from '../styled/Icons'

class FormDatePicker extends Component {
  constructor (props) {
    super(props)
    this.picker = null
  }

  onOutsideClick = () => {
    this.picker.cancelFocusInput()
    this.picker.setOpen(false)
  }

  render () {
    const { label, error, showIcon } = this.props

    return (
      <Container>
        <MaterialDatePickerContainer disabled={this.props.disabled}>
          <DatePicker
            {...this.props}
            ref={node => {
              this.picker = node
            }}
            onClickOutside={this.onOutsideClick}
            onSelect={this.onOutsideClick}
          />
          {showIcon && (
            <ButtonIcon onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              this.picker.setOpen(true)
            }}>
              <StyledCalendarIcon
                color={(this.props.disabled) ? '#aab0c0' : '#000000'}
                width={17}
                height={16}
              />
            </ButtonIcon>
          )}
        </MaterialDatePickerContainer>
        <MaterialLabel error={error} isDisabled={this.props.disabled}>{label}</MaterialLabel>
        {error && <DefaultText error={error}>{error}</DefaultText>}
      </Container>
    )
  }
}

FormDatePicker.propTypes = {
  label: PropTypes.any,
  error: PropTypes.any,
  showIcon: PropTypes.bool,
  disabled: PropTypes.bool
}

export default FormDatePicker
