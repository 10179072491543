import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { selectAllDrivers } from '../../drivers/drivers-slice'
import { getCreatingCarrierRequest, getCarrierRequestPickUpTimes } from '../../modules/carrierRequests/selectors'
import CarrierButton from '../../components/CarrierButton'
import CarrierRequestsActions from '../../modules/carrierRequests/actions'
import { selectAllCarriers } from '../../carriers/carriers-slice'

const MAX_COLUMN = 12
const FULL_WIDTH_PERCENTAGE = 100

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const Col = styled.div`
  width: ${props => FULL_WIDTH_PERCENTAGE / (MAX_COLUMN / props.md)}%;
`

const SectionTitle = styled.div`
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 25px;
  font-weight: bold;
`

const Section = styled.div`
  background: #fff;
  padding: 16px;
  box-shadow: 0px 1px 3px -2px #000;
  margin-bottom: 10px;
`

const Label = styled.div`
  color: ${props => props.theme.labelColor};
  margin: 6px 0;
  font-size: 12px;
`

const Value = styled.div`
  color: ${props => props.theme.colors.default};
  font-size: 16px;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 16px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
`

const BackContainer = styled.div`
  display: flex;
  align-items: center;
`

const Back = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.primaryAccent};
  background-color: #fff;
  margin-right: 10px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${props => props.theme.colors.primaryAccent};
  cursor: pointer;
`

const BackText = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 12px;
`

const SubmitRequestButton = styled(CarrierButton)`
  font-size: 12px;
  background-color: ${props => props.theme.colors.primaryAccent};
  color: #fff;
  width: 34%;
`

const SuccessMessage = styled.div`
    color: ${props => props.theme.colors.default};
    font-size: 12px;
    margin-left: 160px;
`

const ErrorMessage = styled.div`
    color: ${props => props.theme.colors.default};
    font-size: 12px;
    margin-left: 160px;
`

class ConfirmCarrierRequest extends Component {
  onSubmitRequest = () => {
    const { stepForward, createCarrierRequest, creatingCarrierRequest, carrierRequestsPickUpTimes } = this.props
    const POs = creatingCarrierRequest.get('orders').toJS().map(order => order.PO)
    const date = creatingCarrierRequest.get('date')
    const pickupTime = carrierRequestsPickUpTimes.find(time => time.get('id') === creatingCarrierRequest.get('pickupTime'))
    const timeStart = pickupTime.get('startTime')
    const timeEnd = pickupTime.get('endTime')
    const email = creatingCarrierRequest.get('email')
    const driverName = creatingCarrierRequest.get('driverName') || ''
    const driverId = creatingCarrierRequest.get('driverId') || undefined
    const carrierName = creatingCarrierRequest.get('carrierName') || ''
    const carrierId = creatingCarrierRequest.get('carrierId') || undefined
    const phone = creatingCarrierRequest.get('phone')
    const tractorNumber = creatingCarrierRequest.get('tractorNumber')
    const trailerLicense = creatingCarrierRequest.get('trailerLicense')
    const prefContactMethod = creatingCarrierRequest.get('prefContactMethod')
    const appointmentId = creatingCarrierRequest.get('appointmentId')

    createCarrierRequest({ date, timeStart, timeEnd, POs, email, phone, driverName, driverId, carrierName, carrierId, tractorNumber, trailerLicense, prefContactMethod, appointmentId })

    stepForward()
  }

  getMessage = () => {
    const { creatingCarrierRequest } = this.props
    if ((creatingCarrierRequest.get('carrierId') || creatingCarrierRequest.get('carrierName')) &&
    creatingCarrierRequest.get('email') &&
    creatingCarrierRequest.get('phone') &&
    creatingCarrierRequest.get('tractorNumber') &&
    creatingCarrierRequest.get('trailerLicense') &&
    (creatingCarrierRequest.get('driverId') || creatingCarrierRequest.get('driverName')) &&
    creatingCarrierRequest.get('date') &&
    creatingCarrierRequest.get('pickupTime') &&
    creatingCarrierRequest.get('orders')) {
      return (<SuccessMessage>Everything looks great!</SuccessMessage>)
    } else {
      return (<ErrorMessage>Not all fields are filled in</ErrorMessage>)
    }
  }

  render () {
    const { creatingCarrierRequest, stepBack, carrierRequestsPickUpTimes, drivers, carriers } = this.props
    const pickupTime = carrierRequestsPickUpTimes.find(time => time.get('id') === creatingCarrierRequest.get('pickupTime'))
    const driver = drivers.find(driver => driver.id === parseInt(creatingCarrierRequest.get('driverId')))
    const carrier = carriers.find(carrier => carrier.id === parseInt(creatingCarrierRequest.get('carrierId')))

    return (
      <Container>
        <SectionTitle>Carrier information</SectionTitle>
        <Section>
          <Row>
            <Col md={4}><Label>Carrier Name</Label></Col>
            <Col md={4}><Label>Email</Label></Col>
            <Col md={4}><Label>Phone Number</Label></Col>
          </Row>
          <Row>
            <Col md={4}><Value>{creatingCarrierRequest.get('carrierName') || (carrier ? carrier.name : 'N/A')}</Value></Col>
            <Col md={4}><Value>{creatingCarrierRequest.get('email')}</Value></Col>
            <Col md={4}><Value>{creatingCarrierRequest.get('phone')}</Value></Col>
          </Row>
          <Row>
            <Col md={4}><Label>Tractor Number</Label></Col>
            <Col md={4}><Label>Trailer License</Label></Col>
            <Col md={4}><Label>Driver Name</Label></Col>
          </Row>
          <Row>
            <Col md={4}><Value>{creatingCarrierRequest.get('tractorNumber')}</Value></Col>
            <Col md={4}><Value>{creatingCarrierRequest.get('trailerLicense')}</Value></Col>
            <Col md={4}><Value>{creatingCarrierRequest.get('driverName') || (driver ? driver.firstName + ' ' + (driver.lastName ?? '') : 'N/A') }</Value></Col>
          </Row>
        </Section>
        <SectionTitle>Request(s)</SectionTitle>
        <Section>
          <Row>
            <Col md={4}><Label>Pick up date</Label></Col>
            <Col md={4}><Label>Requested pick up time</Label></Col>
            <Col md={4}>{' '}</Col>
          </Row>
          <Row>
            <Col md={4}><Value>{moment(creatingCarrierRequest.get('date')).format('ddd, MMMM D, YYYY')}</Value></Col>
            <Col md={4}><Value>{pickupTime.get('name')}</Value></Col>
            <Col md={4}>{' '}</Col>
          </Row>
          <Row>
            <Col md={4}><Label>PO Number</Label></Col>
            <Col md={4}><Label>Customer Name</Label></Col>
            <Col md={4}><Label>Destination</Label></Col>
          </Row>
          { creatingCarrierRequest.toJS().orders.map((order, index) =>
            <TableRow key={index}>
              <Col md={4}><Value>{order.PO}</Value></Col>
              <Col md={4}><Value>{order.customer ? order.customer.name : 'N/A'}</Value></Col>
              <Col md={4}><Value>{order.destination ? order.destination.name : 'N/A'}</Value></Col>
            </TableRow>
          )}
        </Section>
        <ButtonsContainer>
          <BackContainer>
            <Back onClick={stepBack}>&#8810;</Back>
            <BackText>BACK</BackText>
          </BackContainer>
          {this.getMessage()}
          <SubmitRequestButton type="button" onClick={() => this.onSubmitRequest()}>Submit request</SubmitRequestButton>
        </ButtonsContainer>
      </Container>
    )
  }
}

ConfirmCarrierRequest.propTypes = {
  creatingCarrierRequest: PropTypes.object,
  stepBack: PropTypes.func,
  stepForward: PropTypes.func,
  createCarrierRequest: PropTypes.func,
  carrierRequestsPickUpTimes: PropTypes.object,
  drivers: PropTypes.array,
  carriers: PropTypes.array
}

const mapStateToProps = state => ({
  creatingCarrierRequest: getCreatingCarrierRequest(state),
  carrierRequestsPickUpTimes: getCarrierRequestPickUpTimes(state),
  carriers: selectAllCarriers(state),
  drivers: selectAllDrivers(state)
})

const mapDispatchToProps = dispatch => ({
  updateCreatingCarrierRequest: payload => dispatch(CarrierRequestsActions.updateCreatingCarrierRequest(payload)),
  createCarrierRequest: payload => dispatch(CarrierRequestsActions.createCarrierRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCarrierRequest)
