import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import DoorStep from './DoorStep'
import DaySeparator from './components/DaySeparator'
import { isRequestLate } from '../../utils/time'
import { fromJS } from 'immutable'

import SkuBadge from './components/SkuBadge'

const TableScrollable = styled.div`
  overflow: auto;
  padding-right: 8px;
`

const StyledTableStructure = styled.table`
  width: 100%;
  border-spacing: 0;
  border-right: 1px solid ${props => props.theme.timeTable.lines};
`
const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  padding: ${props => (props.zoomLevel === 25 ? '4px' : '8px')};
  position: sticky;
  top: 0;
  background: ${props => props.theme.background};
  border-left: 1px solid ${props => props.theme.timeTable.lines};
  width: ${props => props.width}px;
  z-index: 99;

  ${props => (props.zoomLevel === 25 ? 'font-size: 8px;' : null)}
`
const EmptyTh = styled.th`
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  padding: 8px;
  background: ${props => props.theme.background};
  border-left: 1px solid ${props => props.theme.timeTable.lines};
  position: sticky;
  top: 0;
`
const ThNoBorder = styled(Th)`
  text-align: right;
  border-left: none;
  width: 77px;
`
const ThTime = styled.th`
  vertical-align: top;
  text-align: right;
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  color: #000;
  background: rgba(226, 226, 228, 1);
  height: ${props => props.height}px;
  min-height: ${props => props.height}px;
  z-index: 100;
  position: sticky;
  left: 0;
  top: 0;
`
const EmptyTd = styled.td`
  border-left: 1px solid ${props => props.theme.timeTable.lines};
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
`

const DEFAULT_COLUMN_SIZE = 140

class TableStructure extends Component {
  constructor (props) {
    super(props)
    this.state = this.calculateColumnSize(props.zoomLevel)
    this.scrollable = React.createRef()
  }

  calculateColumnSize = zoomLevel => ({
    width: (DEFAULT_COLUMN_SIZE * zoomLevel) / 100,
    height: (DEFAULT_COLUMN_SIZE * zoomLevel) / 100
  })

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.zoomLevel !== this.props.zoomLevel) {
      const { zoomLevel } = this.props
      this.setState(this.calculateColumnSize(zoomLevel))
    }

    if (prevProps.focusAppointment === this.props.focusAppointment) {
      return
    }

    const { doors, focusAppointment, startDate, timezone } = this.props
    const startDateTz = startDate.clone().tz(timezone)

    if (!focusAppointment) {
      return
    }

    const doorId = focusAppointment.get('doorId', null)
    const hour = moment(focusAppointment.get('date', null))
    if (!doorId || !hour) {
      return
    }

    const { width, height } = this.state
    let doorIndex = -1
    doors.forEach((door, index) => {
      if (door.id === doorId) {
        doorIndex = index
        return false
      }
    })
    let calculatedHeight = Math.abs(hour.hour() - 6)
    if (!hour.isSame(startDateTz, 'date')) {
      // a day in hours
      calculatedHeight += 24
    }
    calculatedHeight = height * calculatedHeight
    if (!hour.isSame(startDateTz, 'date')) {
      // height of the day separator
      calculatedHeight += 30
    }
    this.scrollable.current.scrollTo(width * doorIndex - width, calculatedHeight)
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return (
      nextState !== this.state ||
      nextProps.doors !== this.props.doors ||
      nextProps.endShift !== this.props.endShift ||
      nextProps.zoomLevel !== this.props.zoomLevel ||
      nextProps.startDate !== this.props.startDate ||
      nextProps.startShift !== this.props.startShift ||
      nextProps.is24Format !== this.props.is24Format ||
      nextProps.appointments !== this.props.appointments ||
      nextProps.workingHours !== this.props.workingHours ||
      nextProps.focusAppointment !== this.props.focusAppointment ||
      nextProps.isInventoryCalculationEnabled !== this.props.isInventoryCalculationEnabled ||
      nextProps.idAppointmentLoading !== this.props.idAppointmentLoading ||
      nextProps.appointmentTypes !== this.props.appointmentTypes ||
      nextProps.appointmentStatuses !== this.props.appointmentStatuses
    )
  }

  static renderDoorTitles (doors, { width, zoomLevel }) {
    return doors.map(
      door =>
        !door.isYard && (
          <Th key={door.id + 'event'} zoomLevel={zoomLevel} width={width}>
            {door.name}
          </Th>
        )
    )
  }

  calculateLateness = appointment => {
    const { appointmentStatuses } = this.props
    const appointmentStatus = appointmentStatuses
      ? appointmentStatuses.find(as => as.get('id') === appointment.get('appointmentStatusId'))
      : null
    const carrierRequest = appointment.get('carrierRequests').valueSeq().first()
    if (appointmentStatus && appointment) {
      return isRequestLate(carrierRequest, appointment, appointmentStatus)
    }
  }

  renderDoorStep = (index, hour) => (door, doorIndex) => {
    if (door.isYard) return null

    const {
      zoomLevel,
      appointmentStatuses,
      timezone,
      endShift,
      onDeleteAppointment,
      onEditAppointment,
      onDurationChange,
      onNextOrPreviousDayClick,
      onDropEventOnTable,
      getAppointmentsByDoorAndDate,
      isInventoryCalculationEnabled,
      openDestinationDetailsModal,
      idAppointmentLoading,
      startShift,
      appointmentTypes
    } = this.props

    const { width, height } = this.state
    const appointments = getAppointmentsByDoorAndDate(door.id)(hour).toJS()

    return (
      <DoorStep
        key={door.id + 'event'}
        first={index === 0}
        firstColumn={doorIndex === 0}
        scrollable={this.scrollable}
        zoomLevel={zoomLevel}
        width={width}
        height={height}
        appointmentStatuses={appointmentStatuses}
        calculateLateness={appointment => this.calculateLateness(fromJS(appointment))}
        appointments={appointments}
        isInventoryCalculationEnabled={isInventoryCalculationEnabled}
        hour={hour}
        idAppointmentLoading={idAppointmentLoading}
        startShift={startShift}
        endShift={endShift}
        door={door}
        timezone={timezone}
        // actions
        openDestinationDetailsModal={openDestinationDetailsModal}
        onDropEventOnTable={onDropEventOnTable}
        onNextOrPreviousDayClick={onNextOrPreviousDayClick}
        onDeleteAppointment={onDeleteAppointment}
        onEditAppointment={onEditAppointment}
        onDurationChange={onDurationChange}
        appointmentTypes={appointmentTypes}
      />
    )
  }

  render () {
    const { doors, workingHours, zoomLevel, is24Format, uniqueSkus } = this.props

    const { width, height } = this.state

    return (
      <TableScrollable ref={this.scrollable}>
        <StyledTableStructure>
          <thead>
            <tr>
              <ThNoBorder>Time</ThNoBorder>
              {TableStructure.renderDoorTitles(doors, {
                width,
                zoomLevel
              })}
              {doors.length > 7 && zoomLevel > 25 ? null : <EmptyTh>&nbsp;</EmptyTh>}
            </tr>
          </thead>
          <tbody>
            {doors && workingHours
              ? workingHours.map((hour, index) => {
                const parts = []
                const prevHour = workingHours[index - 1]
                if (prevHour && !hour.isSame(prevHour, 'day')) {
                  parts.push(
                    <DaySeparator
                        key={'diff-day' + hour.format('DD')}
                        hour={hour}
                        doorsCount={doors.length}
                      />
                  )
                }

                parts.push(
                  <tr key={hour.format('HH') + 'hour-event'}>
                    <ThTime height={height}>
                      <SkuBadge hour={hour} is24Format={is24Format} skus={uniqueSkus} />
                      {hour.format(is24Format ? 'HH:00' : 'hh:00a')}
                    </ThTime>
                    {doors ? doors.map(this.renderDoorStep(index, hour)) : ''}
                    {doors.length > 7 && zoomLevel > 25 ? null : <EmptyTd>&nbsp;</EmptyTd>}
                  </tr>
                )

                return parts
              })
              : ''}
          </tbody>
        </StyledTableStructure>
      </TableScrollable>
    )
  }
}

TableStructure.propTypes = {
  onDropEventOnTable: PropTypes.func,
  onDeleteAppointment: PropTypes.func,
  onEditAppointment: PropTypes.func,
  onDurationChange: PropTypes.func,
  getAppointmentsByDoorAndDate: PropTypes.func,
  onNextOrPreviousDayClick: PropTypes.func,
  openDestinationDetailsModal: PropTypes.func,
  isInventoryCalculationEnabled: PropTypes.bool,
  appointments: PropTypes.object,
  doors: PropTypes.array,
  endShift: PropTypes.object,
  focusAppointment: PropTypes.object,
  startDate: PropTypes.object,
  startShift: PropTypes.object,
  workingHours: PropTypes.array,
  zoomLevel: PropTypes.number,
  is24Format: PropTypes.bool,
  timezone: PropTypes.string,
  idAppointmentLoading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  appointmentStatuses: PropTypes.object,
  uniqueSkus: PropTypes.object,
  appointmentTypes: PropTypes.number
}

TableStructure.defaultProps = {
  doors: [],
  workingHours: []
}

export default TableStructure
