import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { EntityState, RequestStatus } from '../common-types'
import { RootState } from '../root-types'

export interface Location {
  id: number
  address1: string
  address2: string
  city: string
  code: string
  country: string
  createdAt: string
  name: string
  phone: string
  postalCode: string
  state: string
  timezone: string
  updatedAt: string
}

export interface LocationState extends EntityState<Location> {}

const adapter = createEntityAdapter<Location>()

const initialState = {
  loading: RequestStatus.Idle,
  error: null
}

export const getLocationsFulfilled = createAction<Location[]>('area/getLocations/fulfilled')

const slice = createSlice({
  name: 'location',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLocationsFulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => state.location)
const selectEntities = globalizedSelectors.selectEntities
export const selectAllLocations = globalizedSelectors.selectAll

export const createGetLocationById = createSelector(
  selectEntities,
  entities => (id: number) => (id) ? (entities[id]) : undefined
)
