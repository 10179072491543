import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { EntityState, RequestStatus } from '../common-types'
import { RootState } from '../root-types'

export interface DoorDuration {
  id: number
  doorId: number
  quantityFrom: number
  duration: number
}

export interface DoorDurationsState extends EntityState<DoorDuration>{}

const adapter = createEntityAdapter<DoorDuration>()

const initialState: { loading: RequestStatus } = {
  loading: RequestStatus.Idle
}

export const getDoorDurationsFulfilled = createAction<DoorDuration[]>('doorDuration/getDoorDurations/fulfilled')

const slice = createSlice({
  name: 'doorDuration',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDoorDurationsFulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

export default slice.reducer

const globalizedSelectors = adapter.getSelectors((state: RootState) => {
  return state.doorDuration
})

export const selectAllDoorDurations = globalizedSelectors.selectAll

export const createGetDoorDurationsByDoorId = createSelector(
  selectAllDoorDurations,
  doorDurations => (doorId: number) => doorDurations.filter(doorDuration => doorDuration.doorId === doorId)
)
