import moment from 'moment-timezone'

export const appointmentStatusesMap = {
  draft: 'Draft',
  scheduled: 'Scheduled',
  checkedIn: 'Checked In',
  loading: 'Loading',
  checkedOut: 'Checket Out'
}

export const requestStatusesMap = {
  pending: 'pending',
  scheduled: 'scheduled',
  reschedule: 'reschedule',
  canceled: 'canceled',
  carrierLate: 'carrier Late'
}

export function getDayStart (instance = moment()) {
  return instance.clone().startOf('day')
}

export function getDayEnd (instance = moment()) {
  return getDayStart(instance).endOf('day')
}

export function getWorkingDayStart (instance = moment()) {
  return getDayStart(instance).add(6, 'hours')
}

export function getWorkingDayEnd (instance = moment()) {
  return getDayEnd(instance).add(6, 'hours')
}

function isAppointmentLate (appointment) {
  const building = appointment.getIn(['door', 'area', 'building'])
  const timezone = building ? building.get('timezone') : 'UTC'
  const now = moment.tz(timezone)
  const initTime = moment.tz(appointment.get('date'), timezone)
  return now.isSameOrAfter(initTime)
}

export function isRequestLate (carrierRequest, appointment, appointmentStatusObj) {
  const appointmentStatusOKList = [appointmentStatusesMap.draft, appointmentStatusesMap.scheduled]
  const appointmentStatus = appointmentStatusObj ? appointmentStatusObj.get('name') : null
  const appointmentStatusOK = appointmentStatusOKList.indexOf(appointmentStatus) > -1
  return (appointmentStatusOK && isAppointmentLate(appointment))
}
