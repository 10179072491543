import styled, { css } from 'styled-components'

const CarrierButton = styled.button`
  border-radius: 2px;
  border: 1px solid ${props => props.theme.colors.primaryAccent};
  background-color: white;
  color: ${props => props.theme.colors.primaryAccent};
  text-align: center;
  font-weight: normal;
  height: 30px;
  padding: 0px 15px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${props => props.disabled && css`
    opacity: 0.6;
  `}

  ${props => props.primary && css`
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: white;
  `}

  ${props => props.danger && css`
    background-color: #fdedef;
    border-color: #ff1e3a;
    color: #ff1e3a;
  `}
`

export default CarrierButton
