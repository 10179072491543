import moment from 'moment-timezone'
import { createSelector } from 'reselect'
import { getAppointmentsSlice } from '../root-selectors'
import { getCurrentTimestamp } from '../time/time-selectors'
import { Appointment, AppointmentStatus } from './appointments-types'

export const getEditingAppointment = createSelector(
  getAppointmentsSlice,
  (stateAppointments): Appointment | null => {
    const editingAppointmentId = stateAppointments?.editingAppointment?.guid
    const appointments = stateAppointments?.appointments

    if (!(editingAppointmentId && appointments)) return null

    return appointments.find(a => a.guid === editingAppointmentId) ?? null
  }
)

// TODO: It's taken from time.js, but is it really necessary, isn't the date in utc always?
const isAppointmentDateInPast = (appointment: Appointment, timestamp: number) => {
  const building = appointment?.door?.area?.building
  const timezone = building?.timezone || 'UTC'
  const now = moment.tz(timestamp, timezone)
  const initTime = moment.tz(appointment.date, timezone)
  return now.isSameOrAfter(initTime)
}

export const isEditingAppointmentDateInPast = createSelector(
  getEditingAppointment,
  getCurrentTimestamp,
  (editingAppointment, timestamp) =>
    editingAppointment !== null ? isAppointmentDateInPast(editingAppointment, timestamp) : false
)

export const canAppointmentBeLate = (status: AppointmentStatus, isYard: boolean) => {
  if (isYard) return false

  return [AppointmentStatus.Draft, AppointmentStatus.Scheduled].includes(status)
}
