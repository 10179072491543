import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getOrderItems
  getOrderItems: ['payload'],
  // The operation has started and is loading
  getOrderItemsLoading: null,
  // The appointmentOrders was successfully fetched
  getOrderItemsSuccess: ['orderItems'],
  // An error occurred
  getOrderItemsFailure: ['errorMessage']
})

export const OrderItemsTypes = Types
export default Creators
