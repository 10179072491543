import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import moment from 'moment-timezone'

import { Button } from '../../../styled/Buttons'
import { SimpleSpinner } from '../../../styled/Loading'
import { requestStatusesMap } from '../../../utils/time'
import Notification from '../../Notification'

class Notifications extends Component {
  getRescheduleTime = (carrierRequest, timezone) => {
    if (!carrierRequest) {
      return null
    }
    if (!carrierRequest.rescheduleTimeSuggestion) {
      return 'UNKNOWN'
    }

    return moment.tz(carrierRequest.rescheduleTimeSuggestion, timezone).format('HH:mm')
  }

  clearRequest = () => {
    const { clearRequest, appointmentData } = this.props
    clearRequest(appointmentData.id)
  }

  render () {
    const {
      isSaving,
      appointmentData,
      editingAppointment,
      clearRequestIsLoading,
      isSuggestionEnabled,
      editingAppointmentIssues,
      displayAppointmentTimeSuggestions,
      topEditingAppointmentSuggestions = {
        suggestions: [],
        hasMore: false
      }
    } = this.props

    const conflictingInventory = editingAppointmentIssues.hasConflictingInventory
    const carrierRequests = editingAppointment ? editingAppointment.carrierRequests || [] : []
    const cancelRequested = carrierRequests.find(c => c.status === requestStatusesMap.canceled)
    const rescheduleRequested = carrierRequests.find(
      c => c.status === requestStatusesMap.reschedule
    )
    const timezone = appointmentData.building ? appointmentData.building.timezone : 'UTC'
    return !isSaving ? (
      <Fragment>
        <Notification
          type='error'
          closeable
          isExpanded={Boolean(cancelRequested)}
          action={
            <Button disabled={clearRequestIsLoading} onClick={this.clearRequest}>
              Clear request
            </Button>
          }
        >
          A cancellation has been requested for this appointment.
        </Notification>
        <Notification
          type='warning'
          closeable
          isExpanded={conflictingInventory && conflictingInventory.length > 0}
        >
          This appointment creates a inventory conflict with another appointment.
          <br />
          {conflictingInventory &&
            conflictingInventory.map &&
            conflictingInventory.map(conflict => {
              return conflict.conflictingAppointments.map(appt => {
                if (!appt.appointment || !appt.order || !conflict.item) {
                  return null
                }

                return `Appt id: ${appt.appointment.id} -> Order: ${appt.order.primaryRefValue} -> Item: ${conflict.item.sku} - ${conflict.item.name} \n`
              })
            })}
        </Notification>
        <Notification
          type='warning'
          closeable
          isExpanded={Boolean(rescheduleRequested)}
          action={
            <Button disabled={clearRequestIsLoading} onClick={this.clearRequest}>
              Clear request
            </Button>
          }
        >
          Request to reschedule this appointment to{' '}
          {this.getRescheduleTime(rescheduleRequested, timezone)}
        </Notification>
        <Notification
          type={topEditingAppointmentSuggestions.suggestions.length > 0 ? 'info' : 'warning'}
          closeable
          isExpanded={isSuggestionEnabled}
          action={
            topEditingAppointmentSuggestions.hasMore ? (
              <Button disabled={isSaving} onClick={displayAppointmentTimeSuggestions}>
                See all suggestions
              </Button>
            ) : null
          }
        >
          {topEditingAppointmentSuggestions.suggestions.length > 0
            ? 'There are other times with available inventory.'
            : 'There will be an inventory shortage in this appointment.'}
        </Notification>
      </Fragment>
    ) : (
      <Notification isExpanded action={<SimpleSpinner small />}>
        Loading appointment.
      </Notification>
    )
  }
}

Notifications.propTypes = {
  isSaving: PropTypes.bool,
  clearRequestIsLoading: PropTypes.bool,
  isSuggestionEnabled: PropTypes.bool,
  topEditingAppointmentSuggestions: PropTypes.object,
  displayAppointmentTimeSuggestions: PropTypes.func,
  editingAppointmentIssues: PropTypes.object,
  appointmentData: PropTypes.object,
  editingAppointment: PropTypes.object,
  clearRequest: PropTypes.func
}

export default Notifications
