import LoadingButton from '@mui/lab/LoadingButton'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import UnstyledTextInput from '../../components/CarrierTextInput'

import { SerializedError } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { selectCarrierById } from '../../carriers/carriers-slice'
import { RequestStatus } from '../../common-types'
import { selectDriverById } from '../../drivers/drivers-slice'
import { RootState } from '../../root-types'
import { UpsertUserConfig, UserEntity } from '../../users/users-slice'

const Container = styled.div`
  margin: 100px auto;
  margin-top: 50px;
`

const Row = styled.div`
  display: flex;
  margin: 0px -10px 10px 0px;
`

const Column = styled.div`
  width: 50%;
  padding: 0px 10px;
  height: 80px;
`

const ContactMethod = styled.div`
  margin-top: 12px;
  color: #898989;
  font-weight: bold;
  font-size: 12px;
`

const TextInput = styled(UnstyledTextInput)`
  background-color: ${props => props.theme.background};
  color: hsl(0, 0%, 20%);
`

const RadioOptions = styled.div`
  display: flex;
  padding: 10px 0px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #898989;
`

const Radio = styled(Field)`
  margin-left: 10px;
  margin-right: 5px;
`

type IsErrorProps = {
  $isError?: boolean
}

const ConfirmButton = styled(LoadingButton)<IsErrorProps>`
  height: 36px;
  font-size: 12px;
  width: 100%;
  border-radius: 2px;
  text-transform: none;
  border-color: ${props => props.theme.colors.primaryAccent};
  background-color: ${props => props.theme.colors.primary};

  &:hover {
    background-color: ${props => props.theme.colors.primaryAccent};

    ${props =>
      props.disabled &&
      css`
        background-color: #fff;
        border-color: #fff;
        color: ${props.theme.contrastTextColor};
      `}

    ${props =>
      props.$isError &&
      css`
        background-color: #ff1e3a;
        border-color: #ff1e3a;
        color: ${props.theme.contrastTextColor};
      `}
  }

  ${props =>
    props.disabled &&
    css`
      background-color: #fff;
      border-color: #fff;
      color: ${props.theme.contrastTextColor};
    `}

  ${props =>
    props.$isError &&
    css`
      background-color: #ff1e3a;
      border-color: #ff1e3a;
      color: ${props.theme.contrastTextColor};
    `}
`

const ErrorText = styled.div`
  color: #ff1e3a;
  padding-top: 10px;
  font-weight: 300;
`

const Header = styled.h4`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primary};
`

const StyledForm = styled.form`
  width: 600px;
`

type FieldsConfig = {
  id: number
  email: string
  phone: string
  prefContactMethod: string
  password: string
  password2: string
  carrier: string
  driver: string
}

// TODO: Should normalized state leak to components?
const getDriverById = (id: number | null | undefined) => (state: RootState) =>
  id ? selectDriverById(state, id) : null
const getCarrierById = (id: number | null | undefined) => (state: RootState) =>
  id ? selectCarrierById(state, id) : null

interface UpsertUserProps {
  user?: UserEntity
  upsertStatus: RequestStatus
  upsertError: { isError: boolean; error: SerializedError | null }
  // TODO: need to find a type for general action creator
  upsertCleared: any
  upsert: any
}

export function UpsertCarrierUser ({
  user,
  upsertStatus,
  upsertError,
  upsertCleared,
  upsert
}: UpsertUserProps) {
  const driver = useSelector(getDriverById(user?.driverId))
  const carrier = useSelector(getCarrierById(user?.carrierId))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(upsertCleared())
  }, [])

  useEffect(() => {
    if (upsertStatus === RequestStatus.Succeded) {
      navigate('/carrier')
      dispatch(upsertCleared())
    }
  }, [upsertStatus])

  const onSubmit = (data: FieldsConfig) => {
    const payload: UpsertUserConfig = {
      email: data?.email?.trim().toLowerCase(),
      prefContactMethod: data.prefContactMethod,
      phone: data.phone,
      password: data.password,
      roles: [3]
    }

    if (user?.id) payload.id = user.id
    if (data.carrier) payload.carrierName = data.carrier
    if (data.driver) payload.driverName = data.driver

    dispatch(upsert(payload))
  }

  const validate = (values: Partial<FieldsConfig>): Partial<FieldsConfig> => {
    const errors: Partial<FieldsConfig> = {}

    const reNumber = new RegExp('[0-9]')
    const reLowerCase = new RegExp('[a-z]')
    const reUpperCase = new RegExp('[A-Z]')
    const reSpecialChar = new RegExp('[^A-Za-z0-9]')
    const reEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (!values.carrier) {
      errors.carrier = 'Required'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else {
      if (!reEmail.test(values.email)) {
        errors.email = 'Must be a valid e-mail'
      }
    }

    if (!values.prefContactMethod) {
      errors.prefContactMethod = 'Required'
    }

    if (!user && !values.password) {
      errors.password = 'Required'
    }

    if (values.password) {
      if (values.password.length < 6) {
        if (!errors.password) errors.password = ''
        errors.password += 'Must be at least 6 characters long. '
      }
      if (!reNumber.test(values.password)) {
        if (!errors.password) errors.password = ''
        errors.password += 'Must contain a number. '
      }
      if (!reLowerCase.test(values.password)) {
        if (!errors.password) errors.password = ''
        errors.password += 'Must contain a lower case. '
      }
      if (!reUpperCase.test(values.password)) {
        if (!errors.password) errors.password = ''
        errors.password += 'Must contain a upper case. '
      }
      if (!reSpecialChar.test(values.password)) {
        if (!errors.password) errors.password = ''
        errors.password += 'Must contain a special character. '
      }
    }

    if (!user && !values.password2) {
      errors.password2 = 'Required'
    }

    if (values.password && values.password2 && values.password !== values.password2) {
      errors.password = 'Passwords needs to match'
      errors.password2 = 'Passwords needs to match'
    }

    if (values.prefContactMethod === 'sms' && !values.phone) {
      errors.phone = 'If "Preferred contact method" is "Text", "Mobile Number" is required'
    }

    return errors
  }

  let accountTitle = 'Create an Account'
  let passwordTitle = 'Password'
  let buttonText = 'Create Account'
  const initialValues = {
    id: user?.id,
    email: user?.email,
    phone: user?.phone,
    driver: driver && `${driver.firstName || ''} ${driver.lastName || ''}`,
    carrier: carrier?.name,
    prefContactMethod: user?.prefContactMethod ?? 'email'
  }

  if (initialValues && initialValues.id) {
    accountTitle = 'Update Account'
    passwordTitle = 'Change Password'
    buttonText = 'Update Changes'
  }

  return (
    <Container>
      <Form
        initialValues={initialValues || { prefContactMethod: 'email' }}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Header>{accountTitle}</Header>
            <Row>
              <Column>
                <Field
                  name='carrier'
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      error={meta.touched && meta.error}
                      fixedLabel={true}
                      label='Carrier Name*'
                    />
                  )}
                />
              </Column>
              <Column>
                <Field
                  name='email'
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      error={meta.touched && meta.error}
                      fixedLabel={true}
                      label='Email*'
                      autoComplete='email'
                    />
                  )}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Field
                  name='driver'
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      error={meta.touched && meta.error}
                      fixedLabel={true}
                      label='Driver'
                      autoComplete='name'
                    />
                  )}
                />
              </Column>
              <Column>
                <Field
                  name='phone'
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      error={meta.touched && meta.error}
                      fixedLabel={true}
                      label='Mobile Number'
                      autoComplete='mobile tel'
                    />
                  )}
                />
              </Column>
            </Row>
            <Row>
              <Column> </Column>
              <Column>
                <ContactMethod>Preferred method of contact</ContactMethod>
                <RadioOptions>
                  <Radio type='radio' name='prefContactMethod' value='email' component='input' />{' '}
                  Email
                  <Radio type='radio' name='prefContactMethod' value='sms' component='input' /> Text
                </RadioOptions>
              </Column>
            </Row>
            <Header>{passwordTitle}</Header>
            <Row>
              <Column>
                <Field
                  name='password'
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      error={meta.touched && meta.error}
                      type={'password'}
                      fixedLabel={true}
                      label='New password*'
                      autoComplete='new-password'
                    />
                  )}
                />
              </Column>
              <Column>
                <Field
                  name='password2'
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      error={meta.touched && meta.error}
                      type={'password'}
                      fixedLabel={true}
                      label='Re-Enter New Password*'
                      autoComplete='new-password'
                    />
                  )}
                />
              </Column>
            </Row>
            <Row />
            <Row />
            <Row>
              <Column>
                <ConfirmButton
                  variant='contained'
                  type='submit'
                  disableElevation
                  disableRipple
                  loading={upsertStatus === RequestStatus.Pending}
                  $isError={upsertError.isError}
                >
                  {buttonText}
                </ConfirmButton>
                {upsertError.isError && !(upsertError?.error?.code === '422') && (
                  <ErrorText>
                    User could not be upserted because of a server error. Please, try again later.
                  </ErrorText>
                )}
                {upsertError?.error?.code === '422' && (
                  <ErrorText>User missing required fields.</ErrorText>
                )}
              </Column>
            </Row>
          </StyledForm>
        )}
      />
    </Container>
  )
}

export default UpsertCarrierUser

UpsertCarrierUser.propTypes = {
  user: PropTypes.object,
  upsertStatus: PropTypes.number,
  upserError: PropTypes.object,
  upsertCleared: PropTypes.func,
  upsert: PropTypes.func
}
