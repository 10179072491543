import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BubbleIcon extends Component {
  render () {
    const { width, height, color, className } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 13"
        className={className}
      >
        <path fill={color} fillRule="evenodd" d="M.803 2.621A4.699 4.699 0 0 0 0 5.268c0 .954.268 1.836.804 2.645.535.81 1.263 1.45 2.183 1.918.92.47 1.924.704 3.013.704.313 0 .636-.022.971-.066.884.96 1.91 1.624 3.08 1.992.22.077.473.137.764.181a.23.23 0 0 0 .204-.074c.06-.06.1-.14.117-.238v-.009c.014-.022.015-.055.004-.099a.267.267 0 0 1-.014-.082c.002-.01-.008-.037-.03-.078l-.04-.074-.047-.07a24.807 24.807 0 0 0-.261-.358l-.231-.313a4.24 4.24 0 0 1-.208-.325 3.522 3.522 0 0 1-.217-.42 7.157 7.157 0 0 1-.355-1.11 5.435 5.435 0 0 0 1.657-1.811c.404-.72.606-1.49.606-2.313a4.61 4.61 0 0 0-.476-2.046 5.336 5.336 0 0 0-1.279-1.683A6.093 6.093 0 0 0 8.33.416 6.6 6.6 0 0 0 6 0C4.91 0 3.906.235 2.986.704 2.066 1.173 1.34 1.812.803 2.62z"/>
      </svg>
    )
  }
}

BubbleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

BubbleIcon.defaultProps = {
  width: 12,
  height: 13,
  color: '#ffffff'
}
