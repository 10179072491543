import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridCol, GridRow } from '../../../styled/Grids'
import { Button, ButtonPrimary } from '../../../styled/Buttons'
import { DefaultText, Label } from '../../../styled/Texts'
import { StyledCheckbox } from '../../../styled/Inputs'
import { Footer } from '../../../styled/Footers'

class IssuesReview extends Component {
  render () {
    const {
      appointmentData,
      isInventoryCalculationEnabled,
      onSave,
      onCancel,
      onChangeReviewUserId,
      reviewer,
      selectedOrders,
      isSaving
    } = this.props

    return (
      <Footer>
        <GridRow flex>
          <GridCol>
            <ButtonPrimary softRounded onClick={onSave} disabled={isSaving || !selectedOrders.length}>
              { isSaving ? 'Saving...' : 'Save' }
            </ButtonPrimary>
          </GridCol>
          <GridCol>
            <Button onClick={onCancel}>Cancel</Button>
          </GridCol>
          {
            isInventoryCalculationEnabled && (
              <GridCol flex="1 1 auto" row centered>
                <Button>
                  <Label>Allow issues
                    <StyledCheckbox
                      checked={
                        appointmentData.inventoryReviewUserId !== null &&
                        appointmentData.inventoryReviewUserId !== undefined
                      }
                      onChange={onChangeReviewUserId}
                      color="default"
                    />
                  </Label>
                </Button>
              </GridCol>
            )
          }
          {
            reviewer && (
              <GridCol>
                <DefaultText>Reviewed by {reviewer.name}</DefaultText>
              </GridCol>
            )
          }
        </GridRow>
      </Footer>
    )
  }
}

IssuesReview.propTypes = {
  reviewer: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  appointmentData: PropTypes.object,
  isInventoryCalculationEnabled: PropTypes.bool,
  selectedOrders: PropTypes.array,
  isSaving: PropTypes.bool,
  onChangeReviewUserId: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

export default IssuesReview
