import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getSettingsForBuilding
  getSettings: ['payload'],
  getSettingsLoading: null,
  getSettingsSuccess: ['settings'],
  getSettingsFailure: ['errorMessage']
})

export const SettingsTypes = Types
export default Creators
