import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Container } from '../../styled/Containers'
import { ControlledColumn } from '../ControlledTable'
import { DefaultSelect } from '../../styled/Selects'
import { GridCell, GridRow } from '../../styled/Grids'
import { StyledControlledTable } from '../../styled/Table'
import { TopNavigation } from '../../styled/Navs'
import { getAllSettings } from '../../modules/settings/selector'
import { selectAllSites } from '../../sites/sites-slice'
import { createGetSettingsForSiteBuildings } from '../../modules/buildings/selectors'
import BuildingsActions from '../../modules/buildings/actions'
import SettingsActions from '../../modules/settings/actions'
import Toggle from '../Toggle'
import { selectCurrentSiteId } from '../../app/app-slice'
import { convertEntitiesToSelectOptions } from '../../ui'

const settingsNameMap = {
  inventoryCalculation: 'Inventory Calculation'
}

class BuildingsTab extends Component {
  state = {
    siteId: -1
  }

  componentDidMount () {
    if (this.state.siteId === -1 && this.props.currentSiteId) {
      this.setState({ siteId: this.props.currentSiteId })
    }
    this.props.getSettings()
  }

  onSiteChange = siteId => {
    this.setState({ siteId })
  }

  onActivateInventoryCalculations = buildingId => () => {
    this.props.updateSettingsForBuilding({
      id: buildingId,
      inventoryCalculation: 1
    })
  }

  onDeactivateInventoryCalculations = buildingId => () => {
    this.props.updateSettingsForBuilding({
      id: buildingId,
      inventoryCalculation: 0
    })
  }

  render () {
    const { sites, settings, getSettingsForSiteBuildings } = this.props
    const sitesOptions = convertEntitiesToSelectOptions(sites)
    const siteId = this.state.siteId
    const buildingSettings = getSettingsForSiteBuildings(siteId)

    // If you're adding a new setting to hide here, consider creating a database field to load only what is needed
    const filteredSettings = settings && settings.filter(setting => setting.name !== 'buildingGroup')
    const filteredBuildingSettings = buildingSettings &&
      buildingSettings.filter(bs => bs.getIn(['setting', 'name']) !== 'buildingGroup')

    return (
      <Container>
        <TopNavigation>
          <GridRow>
            <GridCell>
              <DefaultSelect
                inputWidth="10"
                leftSeparator
                options={sitesOptions}
                placeholder="Sites"
                value={siteId}
                onChange={this.onSiteChange}
              />
            </GridCell>
          </GridRow>
        </TopNavigation>
        <StyledControlledTable data={filteredBuildingSettings}>
          <ControlledColumn
            title="Name"
            content={settingForBuilding =>
              settingForBuilding.get('building').get('name')
            }
          />
          {filteredSettings
            ? filteredSettings.map(setting => (
              <ControlledColumn
                key={setting.name}
                title={settingsNameMap[setting.name]}
                content={settingForBuilding => (
                  <Toggle
                    isActive={settingForBuilding.get('value') === '1'}
                    onActivate={this.onActivateInventoryCalculations(
                      settingForBuilding.get('building').get('id')
                    )}
                    onDeactivate={this.onDeactivateInventoryCalculations(
                      settingForBuilding.get('building').get('id')
                    )}
                  />
                )}
              />
            ))
            : ''}
        </StyledControlledTable>
      </Container>
    )
  }
}

BuildingsTab.propTypes = {
  currentSiteId: PropTypes.number,
  sites: PropTypes.array,
  settings: PropTypes.array,
  getSettings: PropTypes.func,
  updateSettingsForBuilding: PropTypes.func,
  getSettingsForSiteBuildings: PropTypes.func
}

const mapStateToProps = state => ({
  currentSiteId: selectCurrentSiteId(state),
  sites: selectAllSites(state),
  getSettingsForSiteBuildings: createGetSettingsForSiteBuildings(state),
  settings: getAllSettings(state)
})

const mapDispatchToProps = dispatch => ({
  getSettings: () => dispatch(SettingsActions.getSettings()),
  updateSettingsForBuilding: payload =>
    dispatch(BuildingsActions.updateSettingsForBuilding(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildingsTab)
