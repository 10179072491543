import { connect } from 'react-redux'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

import {
  StyledModal,
  StyledModalContent,
  StyledModalTabbedHeader
} from '../../styled/Modals'
import { Tab, Tabs } from '../../styled/Tabs'
import { isSystemSettingsOpen } from '../../modules/users/selectors'
import CloseButton from '../CloseButton'
import SystemSettingsTabs from '../tabs/SystemSettingsTabs'
import UsersActions from '../../modules/users/actions'

const tabMap = {
  USERS_TAB: 0,
  BUILDINGS_TAB: 1,
  DEFAULT_TAB: 0
}

class SystemSettingsModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tab: tabMap.DEFAULT_TAB
    }
  }

  componentDidUpdate (prevProps) {
    const { isOpen } = this.props

    if (!isOpen && prevProps.isOpen) {
      this.setState({ tab: tabMap.DEFAULT_TAB })
    }
  }

  switchToTab = tabId => () => this.setState({ tab: tabId })

  render () {
    const { isOpen, hideSystemSettings } = this.props

    return (
      <StyledModal
        isOpen={isOpen}
        size="biggest"
        onRequestClose={hideSystemSettings}
      >
        <StyledModalTabbedHeader>
          <Tabs as="ul" row>
            <Tab
              onClick={this.switchToTab(tabMap.USERS_TAB)}
              selected={this.state.tab === tabMap.USERS_TAB}
            >
              Users
            </Tab>
            <Tab
              onClick={this.switchToTab(tabMap.BUILDINGS_TAB)}
              selected={this.state.tab === tabMap.BUILDINGS_TAB}
            >
              Buildings
            </Tab>
          </Tabs>
          <CloseButton onClick={hideSystemSettings} />
        </StyledModalTabbedHeader>
        <StyledModalContent scrollable>
          <SystemSettingsTabs tab={this.state.tab} tabMap={tabMap} />
        </StyledModalContent>
      </StyledModal>
    )
  }
}

SystemSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  hideSystemSettings: PropTypes.func,
  tabMap: PropTypes.shape({
    USERS_TAB: PropTypes.number,
    BUILDINGS_TAB: PropTypes.number,
    DEFAULT_TAB: PropTypes.number
  })
}

const mapStateToProps = state => ({
  isOpen: isSystemSettingsOpen(state)
})

const mapDispatchToProps = dispatch => ({
  hideSystemSettings: () => dispatch(UsersActions.hideSystemSettings())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemSettingsModal)
