import styled from 'styled-components'
import { GridCell, GridRow } from './Grids'

export const Footer = styled.div`
  ${GridRow} {
    margin-top: 16px;
    
    ${GridCell} {
      align-items: center;
      justify-items: center;
      justify-content: center;
      
      button label {
        margin: 0;
      }
    }
  }
`
export const Copyright = styled.div``
export const TermsAndConditions = styled.div``
