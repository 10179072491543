import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Button, ButtonPrimary } from '../../styled/Buttons'
import { DefaultParagraph, SectionTitle, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent,
  StyledModalFooter
} from '../../styled/Modals'
import CloseButton from '../CloseButton'
import { connect } from 'react-redux'
import { getDeletingAppointment } from '../../modules/appointments/selectors'
import AppointmentsActions from '../../modules/appointments/actions'

class DeleteAppointmentModal extends Component {
  render () {
    const { appointment, onClose, onConfirmDelete } = this.props

    if (appointment === null) {
      return null
    }

    return (
      <StyledModal
        isOpen
        contentLabel="Delete Appointment"
        size="small"
      >
        <StyledModalAlertHeader>
          <Title>Delete Appointment</Title>
          <CloseButton onClick={onClose} />
        </StyledModalAlertHeader>
        <StyledModalContent>
          <SectionTitle>Are you sure you want to delete this appointment?</SectionTitle>
          <DefaultParagraph>
            All orders will be removed from this appointment and will be
            available for scheduling.
          </DefaultParagraph>
        </StyledModalContent>
        <StyledModalFooter>
          <Button type="submit" onClick={onClose}>
            No
          </Button>
          <ButtonPrimary type="submit" onClick={() => onConfirmDelete(appointment)}>
            Yes, Delete
          </ButtonPrimary>
        </StyledModalFooter>
      </StyledModal>
    )
  }
}

DeleteAppointmentModal.propTypes = {
  appointment: PropTypes.object,
  onClose: PropTypes.func,
  onConfirmDelete: PropTypes.func
}

const mapToStateProps = state => ({
  appointment: getDeletingAppointment(state)
})

const mapActionToDispatch = dispatch => ({
  /**
   * Triggered whenever the Modal needs to be closed
   */
  onClose: () => dispatch(
    AppointmentsActions.closeDeleteAppointment()
  ),
  /**
   * Triggered whenever we confirm the deletion
   *
   * Appointment here should be an immutable representation of appointment
   * After dispatched the action we close the modal for a better UX
   *
   * Forces an UpsertAppointment modal to close if open
   *
   * @param appointment
   */
  onConfirmDelete: appointment => {
    dispatch(AppointmentsActions.deleteAppointment(appointment.get('id')))
    dispatch(AppointmentsActions.closeDeleteAppointment())
    dispatch(AppointmentsActions.closeUpsertAppointment())
  }
})

export default connect(mapToStateProps, mapActionToDispatch)(DeleteAppointmentModal)
