import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getUsers
  getUsers: ['payload'],
  // The operation has started and is loading
  getUsersLoading: null,
  // The users was successfully fetched
  getUsersSuccess: ['users'],
  // An error occurred
  getUsersFailure: ['errorMessage'],

  // getUser
  getUser: ['payload'],
  getUserLoading: null,
  getUserSuccess: ['users'],
  getUserFailure: ['errorMessage'],

  // getAllowIssuesUser
  getAllowIssuesUser: ['payload'],
  getAllowIssuesUserLoading: null,
  getAllowIssuesUserSuccess: ['allowIssuesUser'],
  getAllowIssuesUserFailure: ['errorMessage'],

  // createUser
  createUser: ['payload'],
  createUserLoading: null,
  createUserSuccess: null,
  createUserFailure: ['errorMessage'],

  // updateUser
  updateUser: ['payload'],
  updateUserLoading: null,
  updateUserSuccess: ['user'],
  updateUserFailure: ['errorMessage'],

  // login
  login: ['payload'],
  loginLoading: null,
  loginSuccess: ['token', 'user'],
  loginFailure: ['errorMessage'],

  // logout
  logout: null,
  logoutLoading: null,
  logoutSuccess: null,
  logoutFailure: null,

  // changeUserActivation
  changeUserActivation: ['payload'],
  changeUserActivationLoading: null,
  changeUserActivationSuccess: ['user'],
  changeUserActivationFailure: ['errorMessage'],

  // deleteUser
  deleteUser: ['payload'],
  deleteUserLoading: null,
  deleteUserSuccess: ['userId'],
  deleteUserFailure: ['errorMessage'],

  // getRoles
  getRoles: null,
  getRolesLoading: null,
  getRolesSuccess: ['roles'],
  getRolesFailure: ['errorMessage'],

  // getAccounts
  getAccounts: null,
  getAccountsLoading: null,
  getAccountsSuccess: ['accounts'],
  getAccountsFailure: ['errorMessage'],

  hideSystemSettings: null,
  showSystemSettings: null
})

export const UsersTypes = Types
export default Creators
