import 'react-datepicker/dist/react-datepicker.css'

import DatePicker from 'react-datepicker'
import styled from 'styled-components'

import { Container } from './Containers'
import { input } from './Utils'
import { ButtonIcon } from './Buttons'

export const DatePickerContainer = styled(Container)`
  .react-datepicker-wrapper {
    width: 100%;
    outline: 0;
  }

  .react-datepicker__input-container {
    outline: 0;
  }

  svg {
    margin-right: 8px;
  }

  input {
    ${input}
    width:100%;
  }
`
export const StyledDatePicker = styled(DatePicker)`
  ${input}
  height: 2.4rem;
  border: 1px solid ${props => props.theme.darkGray};
`
export const StyledInvisibleDatePicker = styled(DatePicker)`
  height: 2.4rem;
  width: 13rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: ${props => props.theme.background};
  border: none;
  outline: 0 !important;
`

export const MaterialDatePickerContainer = styled(Container)`
  display:flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => (props.disabled) ? props.theme.colors.disabled : props.theme.selectTextColor};
  transition: border ease 1s;

  &:hover {
    border-bottom: 1px solid ${props => (props.disabled) ? props.theme.colors.disabled : props.theme.selectTextColor}};
  }

  .react-datepicker-wrapper {
    width: 100%;
    outline: 0;
  }

  svg {
    margin-right: 8px;
  }

  input {
    ${input}
    padding-left: 0;
    border: 0;
    margin: 0;
    width: 100%;
    color: ${props => (props.disabled) ? props.theme.colors.disabled : props.theme.selectTextColor};
    background-color: #FFFFFF;
  }

  input:hover {
    border: 0;
  }
  input:focus {
    border: 0;
  }

  ${ButtonIcon} {
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`
