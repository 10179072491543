import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'

import { StyledOrderCard } from '../styled/Cards'
import { TYPES } from './TimeTable/DropOnTableHoc'

function DraggableAppointmentOrderCard (props) {
  const ref = useRef()

  let targetIdx; let draggedIdx

  const [{ isDragging }, drag] = useDrag({
    type: TYPES.APPOINTAMENT_ORDER,
    item: { index: props.index, sequence: props.order.orderSequence },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ isOver }, drop] = useDrop({
    accept: TYPES.APPOINTAMENT_ORDER,
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    hover (item, monitor) {
      const draggedIndex = item.index
      const targetIndex = props.index

      if (draggedIndex === targetIndex) {
        return false
      }

      const targetSize = ref.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const draggedTop = draggedOffset.y - targetSize.top

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return false
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return false
      }

      targetIdx = targetIndex
      draggedIdx = draggedIndex
    },
    drop () {
      props.selectedOrders.splice(targetIdx, 0, props.selectedOrders.splice(draggedIdx, 1)[0])
      props.setSelectedOrders(props.selectedOrders.map((order, idx) => {
        order.orderSequence = idx + 1
        return order
      }))

      targetIdx = undefined
      draggedIdx = undefined
    }
  })

  drag(drop(ref))

  return (
    <StyledOrderCard drag={ref} {...props} isDragging={isDragging} isOver={isOver} />
  )
}

DraggableAppointmentOrderCard.propTypes = {
  order: PropTypes.object,
  index: PropTypes.number,
  isScheduled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectedOrders: PropTypes.array,
  setSelectedOrders: PropTypes.func
}

export default DraggableAppointmentOrderCard
