import { connect } from 'react-redux'
import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import {
  getAddPoFailure,
  getAddPoIsLoading,
  getAddPoSuccess,
  getCreatingCarrierRequest
} from '../../modules/carrierRequests/selectors'
import { getOrders } from '../../modules/orders/selectors'
import CarrierAppointmentExistModal from '../../components/CarrierAppointmentExistModal'
import CarrierButton from '../../components/CarrierButton'
import CarrierRequestsActions from '../../modules/carrierRequests/actions'
import DeletePOConfirmationModal from '../../components/modals/DeletePOConfirmationModal'
import OrdersActions from '../../modules/orders/actions'
import CarrierTextInput from '../../components/CarrierTextInput'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { selectIsCarrierUserLoggedIn } from '../../carrier-app/carrier-app-slice'

const MAX_COLUMN = 12
const FULL_WIDTH_PERCENTAGE = 100

const FormContent = styled.form`
  display: flex;
  flex-direction: column;
`
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`
const StyledTextInput = styled(CarrierTextInput)`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colors.default};
`

const StyledButton = styled(CarrierButton)`
  height: 36px;
  width: 48%;
  margin-top: 10px;
  color: ${props => props.disabled ? props.theme.colors.default : '#fff'};
  border-color: ${props => props.disabled ? props.theme.colors.default : props.theme.colors.primaryAccent};
  background-color: ${props => props.disabled ? '#fff' : props.theme.colors.primaryAccent};
`

const CreateAccountButton = styled(CarrierButton)`
  height: 36px;
  width: 48%;
  margin-top: 10px;
`
const AddPOButton = styled(CarrierButton)`
  height: 36px;
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-left: 20px;
`
const FormRow = styled.div`
  display: flex;
  margin: 0px -18px 10px -18px;
`

const Col = styled.div`
  width: ${props => FULL_WIDTH_PERCENTAGE / (MAX_COLUMN / props.md)}%;
  padding: 0px 18px;
`
const Label = styled.div`
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 12px;
  margin-top: 5px;
`
const HorizontalLine = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.default};
  margin: 20px 0;
`

const ListElement = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.default};
  padding-top: 15px;
  padding-bottom: 5px;
`

const PONumber = styled.div`
  font-size: 18px;
  color: ${props => props.theme.colors.primaryAccent};
`

const DeletePO = styled.div`
  margin-right: 10px;
`
const DeleteOrdersButton = styled(CarrierButton)`
  height: 36px;
  margin-top: 10px;
  width: 100%;
  background-color: #FDEDEF;
  color: red;
  border-color: red;
  font-size: 12px;
  `

class ConfirmOrder extends Component {
  state = {
    PO: '',
    errorMessage: '',
    order: null,
    isModalOpen: false,
    isDeletePOConfirmationModalOpen: false,
    addedOrders: []
  }

  componentDidMount = () => {
    const { creatingCarrierRequest } = this.props
    if (creatingCarrierRequest && creatingCarrierRequest.get('orders')) {
      this.setState({
        addedOrders: creatingCarrierRequest.get('orders').toJS()
      })
    }
  }

  componentDidUpdate = prevProps => {
    const { creatingCarrierRequest } = this.props
    if (creatingCarrierRequest !== prevProps.creatingCarrierRequest) {
      if (creatingCarrierRequest) {
        this.setState({
          addedOrders: creatingCarrierRequest.get('orders').toJS()
        })
      } else {
        this.setState({ addedOrders: [] })
      }
    }

    const { addedOrders } = this.state
    const { error, success, addPoReset } = this.props

    // Instead of passing everything to redux, I check the updates and reset to avoid a infinite loop
    // this is to ensure we can do a proper validation with the time we have
    if (success) {
      if (success.getIn(['order', 'appointment'])) {
        this.setState(state => ({
          isModalOpen: true,
          order: success.get('order')
        }))
      } else {
        const isAddedPO = (addedOrders.length > 0) ? addedOrders.find(order => order.PO === success.get('PO')) : false
        if (!isAddedPO) {
          this.setState(state => ({
            PO: '',
            errorMessage: '',
            addedOrders: [...state.addedOrders, success.get('order').toJS()]
          }))
        } else {
          this.setState({
            errorMessage: 'PO is already added'
          })
        }
      }
      addPoReset()
    }

    if (error) {
      if (error.get('type')) {
        this.setState({
          errorMessage: error.get('message')
        })
      }
      addPoReset()
    }
  }

  addPO = () => {
    const { addPo } = this.props
    const { PO } = this.state

    addPo(PO)
  }

  onDeletePO = PO => {
    this.setState(state => ({
      addedOrders: state.addedOrders.filter(order => order.PO !== PO),
      PO: ''
    }))
  }

  onChangePO = e => {
    this.setState({
      PO: e.target.value
    })
  }

  handleEnterKey = e => {
    e.preventDefault()
    this.addPO()
  }

  checkOrders = debounce(() => {
    this.props.getOrders({ searchText: this.state.PO, isOpen: true })
  }, 300)

  onCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  onCloseConfirmDeletePOsModal = () => {
    this.setState({
      isDeletePOConfirmationModalOpen: false
    })
  }

  onDeleteOrders = () => {
    this.setState({
      isDeletePOConfirmationModalOpen: true
    })
  }

  onConfirmDeleteOrders = () => {
    this.setState({
      addedOrders: []
    })
    this.onCloseConfirmDeletePOsModal()
  }

  confirmOrder = () => {
    const { updateCreatingCarrierRequest, stepForward } = this.props
    const { addedOrders } = this.state

    updateCreatingCarrierRequest({
      orders: addedOrders
    })
    stepForward()
  }

  render () {
    const { PO, errorMessage, order, isModalOpen, addedOrders, isDeletePOConfirmationModalOpen } = this.state
    const { loading } = this.props

    return (
      <FormContent onSubmit={e => this.handleEnterKey(e)}>
        <CarrierAppointmentExistModal isOpen={isModalOpen} onClose={this.onCloseModal} order={order} />
        <DeletePOConfirmationModal isOpen={isDeletePOConfirmationModalOpen} onClose={this.onCloseConfirmDeletePOsModal} onConfirmDelete={this.onConfirmDeleteOrders}/>
        <Label>Search / Add PO Number(s)</Label>
        {
          addedOrders.map((order, index) =>
            <ListElement key={index}>
              <PONumber>{order.PO}</PONumber>
              <DeletePO onClick={() => this.onDeletePO(order.PO)}><img src={deleteIcon} /></DeletePO>
            </ListElement>
          )
        }
        <FormRow>
          <Col md={7}>
            <StyledTextInput
              value={PO}
              placeholder='Enter PO Number'
              type='text'
              onChange={e => {
                this.onChangePO(e)
                // this.checkOrders(e)
              }}
            />
          </Col>
          <Col md={4}>
            <AddPOButton primary type="button" disabled={loading} onClick={this.addPO}>{addedOrders.length > 0 ? 'Add another PO' : 'Add PO'}</AddPOButton>
          </Col>
        </FormRow>
        <FormRow>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </FormRow>
        {(addedOrders.length > 0) &&
        <FormRow>
          <Col md={4}>
          </Col>
          <Col md={4}>
            <DeleteOrdersButton type="button" onClick={() => this.onDeleteOrders()}>Remove All Orders</DeleteOrdersButton>
          </Col>
          <Col md={4}>
          </Col>
        </FormRow>
        }
        <HorizontalLine />
        <Label>Confirm this order and proceed</Label>
        <Buttons>
          <CreateAccountButton type="button" onClick={this.props.onCreateAccount}>{this.props.loggedIn ? 'Update Account' : 'Create Account'}</CreateAccountButton>
          <StyledButton type="button" onClick={() => this.confirmOrder()} disabled={!(addedOrders.length > 0)}>Confirm and Pick a Time Range</StyledButton>
        </Buttons>
      </FormContent>
    )
  }
}

ConfirmOrder.propTypes = {
  getOrders: PropTypes.func,
  stepForward: PropTypes.func,
  onCreateAccount: PropTypes.func,
  updateCreatingCarrierRequest: PropTypes.func,
  creatingCarrierRequest: PropTypes.object,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  success: PropTypes.object,
  addPoReset: PropTypes.func,
  addPo: PropTypes.func
}

const mapStateToProps = state => ({
  orders: getOrders(state),
  creatingCarrierRequest: getCreatingCarrierRequest(state),
  loggedIn: selectIsCarrierUserLoggedIn(state),

  // we're using this on componentDidUpdate to move forward
  error: getAddPoFailure(state),
  success: getAddPoSuccess(state),
  loading: getAddPoIsLoading(state)
})

const mapDispatchToProps = dispatch => ({
  addPo: (payload = {}) => dispatch(CarrierRequestsActions.addPo(payload)),
  addPoReset: () => dispatch(CarrierRequestsActions.addPoReset()),
  getOrders: (payload = {}) => dispatch(OrdersActions.getOrders(payload)),
  updateCreatingCarrierRequest: payload => dispatch(CarrierRequestsActions.updateCreatingCarrierRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder)
