import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { EmailsTypes } from './actions'

// sendEmail
export const sendEmailLoading = state =>
  state.merge({
    sendEmailIsLoading: true,
    sendEmailErrorMessage: ''
  })

export const sendEmailSuccess = state =>
  state.merge({
    sendEmailIsLoading: false,
    sendEmailErrorMessage: null
  })

export const sendEmailFailure = (state, { errorMessage }) =>
  state.merge({
    sendEmailIsLoading: false,
    sendEmailErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [EmailsTypes.SEND_EMAIL_LOADING]: sendEmailLoading,
  [EmailsTypes.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [EmailsTypes.SEND_EMAIL_FAILURE]: sendEmailFailure
})
