import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { theme } from '../../themes/taylor-farms'

export default class CalendarIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 27 27"
      >
        <g fill={color} fillRule="evenodd">
          <path d="M23.508 12.6H4.392a.782.782 0 0 0-.792.771v9.258c0 .425.355.771.792.771h19.116a.782.782 0 0 0 .792-.771V13.37a.782.782 0 0 0-.792-.771zM5.184 21.857h3.169v-3.086H5.184v3.086zm0-4.628h3.169v-3.086H5.184v3.086zm4.753 4.628h3.274v-3.086H9.937v3.086zm0-4.628h3.274v-3.086H9.937v3.086zm4.858 4.628h3.168v-3.086h-3.168v3.086zm0-4.628h3.168v-3.086h-3.168v3.086zm4.752 4.628h3.169v-3.086h-3.169v3.086zm0-4.628h3.169v-3.086h-3.169v3.086z"/>
          <path d="M24.627 3.164h-.791v-.791A2.376 2.376 0 0 0 21.463 0a2.376 2.376 0 0 0-2.373 2.373v.791h-3.164v-.791A2.376 2.376 0 0 0 13.553 0a2.376 2.376 0 0 0-2.373 2.373v.791H7.91v-.791A2.376 2.376 0 0 0 5.537 0a2.376 2.376 0 0 0-2.373 2.373v.791h-.791A2.376 2.376 0 0 0 0 5.537v19.09A2.376 2.376 0 0 0 2.373 27h22.254A2.376 2.376 0 0 0 27 24.627V5.537a2.376 2.376 0 0 0-2.373-2.373zm-3.955-.791a.792.792 0 0 1 1.582 0v3.164a.792.792 0 0 1-1.582 0V2.373zm-7.91 0a.792.792 0 0 1 1.582 0v3.164a.792.792 0 0 1-1.582 0V2.373zm-8.016 0a.792.792 0 0 1 1.582 0v3.164a.792.792 0 0 1-1.582 0V2.373zm20.672 22.254a.792.792 0 0 1-.791.791H2.373a.792.792 0 0 1-.791-.791V11.18h23.836v13.447zm0-15.03H1.582v-4.06c0-.436.355-.79.791-.79h.791v.79A2.376 2.376 0 0 0 5.537 7.91 2.376 2.376 0 0 0 7.91 5.537v-.79h3.27v.79a2.376 2.376 0 0 0 2.373 2.373 2.376 2.376 0 0 0 2.373-2.373v-.79h3.164v.79a2.376 2.376 0 0 0 2.373 2.373 2.376 2.376 0 0 0 2.373-2.373v-.79h.791c.436 0 .791.354.791.79v4.06z"/>
        </g>
      </svg>
    )
  }
}

CalendarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

CalendarIcon.defaultProps = {
  width: 27,
  height: 27,
  color: theme.colors.primary
}
