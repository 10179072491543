import styled, { css } from 'styled-components'

import { flex, flexGrow } from './Utils'
import { StyledCheckbox } from './Inputs'

export const DefaultTextCss = css`
  ${props => props.centered ? 'align-self: center;' : ''}
  font-family: ${props => props.theme.fonts.fontFamily};
  font-size: 0.625rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline-block;
  cursor: default;
  margin: 0 0 3px;
  padding-right: 5px;
  align-items: center;
  ${props => props.minimumWidth ? 'min-width: 40px;' : ''}
  ${props => props.mediumWidth ? 'min-width: 6rem;' : ''}
  ${props => props.fixedWidth ? `
    width: 50%;
    white-space: pre-line;
    word-break: break-word;` : ''}
  ${props => props.wrap ? 'white-space: pre-line;' : ''}
  ${props => props.uppercase ? 'text-transform: uppercase;' : ''}
  ${props => props.block ? 'display: block;' : ''}
  ${props => props.spaced ? 'font-size: 0.75rem; letter-spacing: 1.07px;' : ''}
  ${props => props.highlight ? `color: ${props.theme.colors.default}; font-weight: bold;` : ''}
  ${props => props.bold ? 'font-weight: bold;' : ''}
  ${props => props.nospace ? 'margin: 0;' : ''}
`
export const DefaultText = styled.span`
  ${DefaultTextCss}
  color: ${props => props.error ? props.theme.colors.dangerAccent : props.theme.colors.secondary};
`
export const DefaultTextBold = styled.span`
  ${DefaultTextCss}
  font-weight: bold;
`
export const DefaultParagraph = styled.p`
  ${DefaultTextCss}
  padding: ${props => props.theme.spacing(1)} 0;
  font-size: 0.75rem;
`

export const NestedParagraph = styled.div`
  ${DefaultTextCss}
  padding: ${props => props.theme.spacing(1)} 0;
  font-size: 0.75rem;
`

export const SubParagraph = styled.p`
  margin: 0;
`

export const PrimaryText = styled(DefaultText)`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 1.07px;
`
export const Value = styled.div`
  ${DefaultTextCss}
  color: ${props => props.theme.inputColor};
  font-size: 1rem;
  border-bottom: 1px solid ${props => props.theme.inputBorderColor};
`
export const ErrorText = styled(DefaultText)`
  color: ${props => props.theme.colors.alert}
`

export const ContrastText = styled(DefaultText)`
  color: ${props => props.theme.contrastTextColor}
`

export const SingleLineText = styled(DefaultText)`
  font-size: 1rem;
  white-space: nowrap;
`

export const LoadingSequenceText = styled(DefaultText)`
  margin-left: -1rem;
`

export const HelperText = styled(SingleLineText)`
  font-size: 0.65rem;
  color: ${props => props.theme.colors.pass};
  top: 26px;
  left: 0;
  position: absolute;
`

export const MaterialLabel = styled.label`
  position: absolute;
  top: -4px;
  left: 0px;
  color: ${props => (props.isDisabled) ? props.theme.colors.disabled : props.theme.labelColor};
  pointer-events: none;
  font-weight: 600;
  font-size: 10px;
  transition: top 150ms ease-in-out;

  ${props => props.error && css`
    color: red;
  `}
`

export const Label = styled.label`
  ${DefaultTextCss}
  color: ${props => props.theme.labelColor};
  font-size: 0.75rem;
  font-weight: bold;
  ${props => props.flex ? flex : ''}
  ${props => props.flex ? 'align-self: center;' : ''}
  ${props => props.grow ? flexGrow : ''}
  ${props => props.highlight ? css`
    font-weight: bold;
    font-size: 0.75rem;
    color: ${props => props.theme.colors.default};
  ` : ''}

  ${StyledCheckbox} {
    padding: 0;
    margin-left: 1rem;
  }
`
export const LabelLeft = styled.span`
  margin-right: 5px;
  align-self: center;
  color: ${props => props.theme.colors.secondary};
`
export const HeaderTitle = styled(DefaultText)`
  font-size: 1rem;
`
export const ContrastHeaderTitle = styled(HeaderTitle)`
  color: ${props => props.theme.contrastTextColor}
`
export const Title = styled.h1`
  ${DefaultTextCss}
  font-size: 2rem;
  ${props => props.padded ? 'padding-bottom: 1.25rem;' : ''};
  ${props => props.smaller ? 'font-size: 1.25rem;' : ''}
  ${props => props.light ? 'font-weight: 300; margin-bottom: 0.75rem;' : ''}
`
export const ModalTitle = styled(Title)`
  margin: 0;
  padding: 22px 0 23px; // keep the same size as tabbed header
  font-size: 1rem;
  text-transform: uppercase;
`
export const ContentTitle = styled.h2`
  margin: 0 1rem 0 0;
`
export const SectionTitle = styled.h3`
  font-size: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.default};
`

export const SequenceTitle = styled.h3`
  font-size: 1rem;
  padding-bottom: 1rem;
  margin-top: -1rem;
  margin-bottom: 0rem;
  margin-left: -1rem;
  border-bottom: none;
`

export const Field = styled.div`
  ${props => props.mediumFontSize ? css`
    ${DefaultText}, ${DefaultTextBold} {
      font-size: 0.75rem;
    }
  ` : ''}
`

export const ListTitle = styled.h2`
  margin: 0;
`
