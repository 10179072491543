import React, { Component } from 'react'

import PropTypes from 'prop-types'

import {
  StyledNotification,
  StyledNotificationArea
} from '../styled/Notifications'
import { theme } from '../themes/taylor-farms'
import CloseButton from './CloseButton'
import WarningIcon from './icons/Warning'

class Notification extends Component {
  state = {
    closed: false
  }

  onClose = () => {
    this.setState({
      closed: true
    })
  }

  render () {
    const {
      children,
      type,
      closeable,
      isExpanded,
      action
    } = this.props

    const {
      closed
    } = this.state

    if (closed) {
      return null
    }

    const notification = (
      <StyledNotification type={type}>
        {type
          ? <WarningIcon color={theme.contrastTextColor}/>
          : null}
        <span className="notification-text">{children}</span>
        {action ? (<div className="action">{action}</div>) : ''}
        {closeable ? <CloseButton onClick={this.onClose} /> : ''}
      </StyledNotification>
    )

    if (closeable) {
      return (
        <StyledNotificationArea isExpanded={isExpanded}>
          {notification}
        </StyledNotificationArea>
      )
    }

    return notification
  }
}

Notification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  type: PropTypes.oneOf(['error', 'warning', 'info']),
  closeable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  action: PropTypes.any
}

Notification.defaultProps = {
  closeable: false
}

export default Notification
