import React, { Component } from 'react'
import styled from 'styled-components'

import headphoneIcon from '../../assets/images/headphone-green-icon.svg'
import mailIcon from '../../assets/images/mail-icon.svg'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    justify-content: center;
  }
`
const Title = styled.div`
  display: flex;
  color: ${props => props.theme.colors.primaryAccent};
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
`
const Icon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #898989;
  margin-bottom: 20px;
  text-decoration: none;
  padding: 1rem;
  transition: 500ms ease all;
  box-shadow: 0px 0px 0px 0px #000;
  
  &:hover {
    background-color: white;
    padding: 16px;
    box-shadow: 0px 1px 3px -2px #000;
  }
`
const HelpIcon = styled.img`
  width: 30px;
  margin: 10px 0;
`
class ContactUs extends Component {
  render () {
    return (
      <Container>
        <Title>CONTACT</Title>
        <Icon title="1-800-TAYLOR-FARMS click to call" href="tel:180012345"><HelpIcon src={headphoneIcon}></HelpIcon>1-800-TAYLOR-FARMS</Icon>
        <Icon title="Click to send an e-mail" href="mailto:support@companyx.com"><HelpIcon src={mailIcon}></HelpIcon>support@companyx.com</Icon>
      </Container>
    )
  }
}

export default ContactUs
