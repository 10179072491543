import { createSelector } from 'reselect'
import { List } from 'immutable'

import { getStateUI } from '../common/selectors'
import sortById from '../../utils/sortById'

export const getOrderDetailsModalAppointment = createSelector(getStateUI, ui =>
  ui.get('orderDetailsModalAppointment')
    ? ui.get('orderDetailsModalAppointment').merge({
      orders: ui
        .get('orderDetailsModalAppointment')
        .get('orders', new List())
        .sort(sortById)
    })
    : null
)

export const getIsOrderDetailsModalOpen = createSelector(getOrderDetailsModalAppointment, Boolean)
