import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

class Select extends Component {
  setEmptyStringValueIfNoValue = (options, value) => {
    if (!options || options.length === 0) return ''

    // simple options
    if (options.find && !options[0]?.options) return options.find(option => option.value === value) || ''

    // grouped options
    let optionResult
    options.every(option => {
      optionResult = option.options.find(option => option.value === value)
      return !optionResult
    })

    return optionResult || ''
  }

  render () {
    const {
      options,
      value,
      disableEmptyOptions,
      onInputChange,
      onChange,
      placeholder,
      className,
      isClearable,
      isDisabled,
      isSearchable
    } = this.props
    return (
      <ReactSelect
        noOptionsMessage={() => (disableEmptyOptions ? null : 'No options')}
        className={className}
        classNamePrefix="sdselect"
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={!!isClearable}
        value={this.setEmptyStringValueIfNoValue(options, value)}
        onInputChange={value => onInputChange && onInputChange(value)}
        onChange={option => onChange && onChange(option ? option.value : null)}
        options={options}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
      />
    )
  }
}

Select.propTypes = {
  disableEmptyOptions: PropTypes.bool,
  onInputChange: PropTypes.func,
  options: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool
}

export default Select
