export interface EditingApointment {
  guid: string;
}

export interface Building {
  id: number;
  timezone: string;
}

export interface Area {
  id: number;
  building: Building;
}

export interface Door {
  id: number;
  area: Area;
}

export interface Appointment {
  appointmentStatusId: number;
  date: string;
  guid: string;
  door: Door;
}

export interface AppointmentsState {
  // The problem with the current implementation is that states slices use immutablejs
  // This is necessary to convert immutablejs model instances to plain objects
  // This is optional because tests are based on plain objects
  toJS?(): AppointmentsState;
  appointments: Appointment[];
  editingAppointment: EditingApointment;
}

export enum AppointmentStatus {
  Draft = 10,
  Scheduled = 20,
  CheckedIn = 30,
  Loading = 40,
  Unloading = 45,
  CheckedOut = 50
}

export enum AppointmentStatusStyle {
  Draft = 'draft',
  Scheduled = 'scheduled',
  CheckedIn = 'checkedIn',
  Loading = 'loading',
  Unloading = 'unloading',
  CheckedOut = 'checkedOut',
  InProgress = 'inProgress',
  Reschedule = 'reschedule',
  Canceled = 'canceled',
  CarrierLate = 'carrierLate'
}

export const convertAppointmentStatusToStatusStyle = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.CheckedIn:
      return AppointmentStatusStyle.CheckedIn
    case AppointmentStatus.CheckedOut:
      return AppointmentStatusStyle.CheckedOut
    case AppointmentStatus.Draft:
      return AppointmentStatusStyle.Draft
    case AppointmentStatus.Loading:
      return AppointmentStatusStyle.Loading
    case AppointmentStatus.Unloading:
      return AppointmentStatusStyle.Unloading
    case AppointmentStatus.Scheduled:
    default:
      return AppointmentStatusStyle.Scheduled
  }
}
