import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'

import PropTypes from 'prop-types'

import { PageContainer } from '../../styled/Containers'
import { Tabs } from '../../styled/Tabs'
import { isLoggedIn } from '../../modules/users/selectors'
import Content from './layout/Content'
import FeedsActions from '../../modules/feeds/actions'
import { updateTimestamp } from '../../time/time-slice'
import HomeHeader from './layout/HomeHeader'
import ProgressLoader from '../../components/ProgressLoader'
import Menu from './layout/Menu'
import Modals from './layout/Modals'
import HandleError from '../../components/hocs/HandleError'
import withParams from '../../utils/WithParams'

class App extends Component {
  shouldComponentUpdate (nextProps, nextState, nextContext) {
    if (!nextProps.isLoggedIn) {
      return true
    }

    // guarantee App will not re-render to prevent breaking DnD stuff
    return false
  }

  componentDidMount = () => {
    const {
      getBootstrapData,
      startTimer
    } = this.props

    /* SYSTEM SETTINGS */
    getBootstrapData({})
    startTimer(Date.now())
  }

  render () {
    if (!this.props.isLoggedIn) {
      this.props.navigate('/')
    }

    return (
      <Fragment>
        <ProgressLoader />
        <PageContainer>
          <HomeHeader requestGetAppointmentsForDoors={this.requestGetAppointmentsForDoors} />

          <Tabs row="true">
            <Menu />
            <Content />
          </Tabs>
        </PageContainer>

        <Modals />
      </Fragment>
    )
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  getBootstrapData: PropTypes.func,
  startTimer: PropTypes.func,
  navigate: PropTypes.func
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state)
})

const mapDispatchToProps = dispatch => ({
  getBootstrapData: payload =>
    dispatch(FeedsActions.getBootstrapData(payload)),
  startTimer: (dateTime) =>
    dispatch(updateTimestamp(dateTime))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  // Last error resort, let's try to grab it
  HandleError(withParams(App))
)
