import styled, { css } from 'styled-components'
import Checkbox from '@mui/material/Checkbox'

import { input } from './Utils'
import TextArea from '../components/TextArea'
import TextInput from '../components/TextInput'

export const Form = styled.form`
  background-color: ${props => props.theme.contrastTextColor};
  padding: 40px 75px;
`

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${props => props.theme.spacing(1)};
`
export const StyledSimpleInput = styled.input`
  ${input}
  width: 100%;
  ${props => props.noBorderRight ? 'border-right: 0;border-top-right-radius: 0;border-bottom-right-radius: 0;' : ''}
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0px;
`

export const StyledActionBarCheckbox = styled(StyledCheckbox)`
  margin: 0 12px 0 -2px;
`

export const StyledTextArea = styled(TextArea)`
  font-size: 1rem;
  width: 100%;
  resize: none;
  border: 1px solid ${props => props.theme.darkGray};
  padding: ${props => props.theme.spacing(1)};
  outline: 0;
`
export const MaterialInput = styled.input`
  width: 100%;
  font-size: 0.75rem;
  margin-top: 1rem;
  border-style: none none solid none;
  border-width: 1px;
  border-color: ${props => props.theme.labelColor};
  background: ${props => props.theme.contrastTextColor};
  type: ${props => props.type || 'text'};
  padding-bottom: 0.25rem;
  margin-bottom: 2px;

  &:active {
    outline: none;
  }

  &::placeholder {
    opacity: 0;
    color: ${props => props.theme.lightGray};
    transition: opacity 150ms ease-in-out;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.brandDarker};
    &::placeholder {
      opacity: 1;
    }
  }

  ${props => props.fixedLabel && css`
    & + label {
      top: 0px;
    }
  `}

  ${props => !props.fixedLabel && css`
    &:focus + label {
      top: 0px;
    }
    ${props => props.value && css`
      & + label {
        top: 0px;
      }
    `}
  `}
`
