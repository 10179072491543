import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getBootstrapData: ['payload'],
  getBootstrapDataLoading: null,
  getBootstrapDataSuccess: ['bootstrap'],
  getBootstrapDataFailure: ['errorMessage']
})

export const FeedsTypes = Types
export default Creators
