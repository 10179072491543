import { Map, List } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  appointments: new List(),
  total: 0,
  pages: 0,
  appointment: null,
  appointmentOrders: null,
  appointmentStatuses: null,

  getAppointmentsIsLoading: false,
  getAppointmentsErrorMessage: null,

  getAppointmentIsLoading: false,
  getAppointmentErrorMessage: null,

  getAppointmentOrdersIsLoading: false,
  getAppointmentOrdersErrorMessage: null,

  createAppointmentIsLoading: false,
  createAppointmentErrorMessage: null,

  removeOrderFromAppointmentIsLoading: false,
  removeOrderFromAppointmentErrorMesage: null,

  deleteAppointmentIsLoading: false,
  deleteAppointmentErrorMesage: null,

  updateAppointmentIsLoading: false,
  updateAppointmentErrorMessage: null,

  getAllAppointmentStatusesIsLoading: false,
  getAllAppointmentStatusesErrorMessage: null,

  moveAppointmentIsLoading: false,
  moveAppointmentErrorMessage: null,

  getAppointmentsForWarehouseIsLoading: false,
  getAppointmentsForWarehouseErrorMessage: null,

  openEditAppointmentIsLoading: false,
  isUpsertAppointmentVisible: false,
  editingAppointmentTab: 0,
  editingAppointment: null,
  editingAppointmentIssues: new Map({
    hasPastDate: false,
    hasLowInventory: false,
    hasConflictingInventory: false
  }),
  editingAppointmentSuggestionsTimes: new List(),
  removingAppointment: null,

  clearRequestIsLoading: false,
  clearRequestErrorMessage: null,

  appointmentCounts: null,

  recalculateDurationFlag: false,

  searchAttributes: {
    id: '',
    searchText: '',
    customerPurchaseOrder: '',
    appointmentsStatusSelect: null,
    customerSelect: null,
    shippingDateSelect: null,
    destinationSelect: null,
    currentPage: 1
  }
})
