import {
  AnyAction,
  createAction,
  createSelector,
  createSlice,
  ThunkAction,
  ThunkDispatch
} from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import {
  selectAllBuildings,
  selectBuildingById,
  selectBuildingEntities
} from '../buildings/buildings-slice'
import AppointmentsActions from '../modules/appointments/actions'
import DoorsActions from '../modules/doors/actions'
import { getDefaultBuildingId } from '../modules/users/selectors'
import { RootState } from '../root-types'
import socket from '../utils/socket'
import { getDayEnd, getDayStart, getWorkingDayEnd, getWorkingDayStart } from '../utils/time'
import config from '../config'

export interface HomeViewState {
  startDate: string
  endDate: string
  startShift: string
  endShift: string
  startTime: string
  endTime: string
  siteId: number | null
  buildingId: number | null
  appointmentType: number
}

export interface ApplicationState {
  __EXPIRE_AT: string | null
  version: string
  HomeView: HomeViewState
  focusAppointment: any
}

const selectHomeView = (state: RootState) => state.app?.HomeView
const selectApp = (state: RootState) => state.app
export const selectAppointmentType = createSelector(
  selectHomeView,
  homeView => homeView?.appointmentType
)
export const isAppointmentTypeOutbound = (appointmentType: number) => appointmentType === 2
export const isAppointmentTypeInbound = (appointmentType: number) => appointmentType === 1
export const getAppointmentTypesOptions = () => [
  { label: 'ALL', value: 0 },
  { label: 'INBOUND', value: 1 },
  { label: 'OUTBOUND', value: 2 }
]
export const selectCurrentSiteId = createSelector(selectHomeView, homeView => homeView?.siteId)
export const selectStartDate = createSelector(selectHomeView, homeView =>
  moment(homeView?.startDate)
)
export const selectEndDate = createSelector(selectHomeView, homeView => moment(homeView?.endDate))
export const selectStartShiift = createSelector(selectHomeView, homeView =>
  moment(homeView?.startShift)
)
export const selectEndShift = createSelector(selectHomeView, homeView => moment(homeView?.endShift))
export const selectStartTime = createSelector(selectHomeView, homeView =>
  moment(homeView?.startTime)
)
export const selectEndTime = createSelector(selectHomeView, homeView => moment(homeView?.endTime))

export const selectFocusAppointment = createSelector(selectApp, app => app.focusAppointment)
export const selectCurrentBuildingId = createSelector(
  selectHomeView,
  homeView => homeView?.buildingId
)
export const selectBuildingsForCurrentSite = createSelector(
  selectAllBuildings,
  selectCurrentSiteId,
  (buildings, siteId) => buildings.filter(building => building.siteId === siteId)
)

export const selectCurrentBuildingTimezone = createSelector(
  selectBuildingEntities,
  selectCurrentBuildingId,
  (buildingEntities, buildingId) => {
    if (buildingId) {
      return buildingEntities[buildingId]?.timezone || 'UTC'
    }

    return 'UTC'
  }
)

export const selectTableTimeSpan = createSelector(selectStartDate, selectEndDate, selectStartTime, selectEndTime, selectCurrentBuildingTimezone, (startDate, endDate, startTime, endTime, timezone) => {
  return {
    startDate,
    endDate,
    startTime,
    endTime,
    timezone
  }
})
const setSiteAction = createAction<number>('app/homeView/setSiteId')
const setBuildingIdAction = createAction<number>('app/homeView/setBuildingId')
export const setAppointmentType = createAction<number>('app/homeView/setAppointmentType')
export const setStartConfiguration = createAction<{
  startDate: Moment
  endDate: Moment
  startShift: Moment
  endShift: Moment
}>('app/setHomeViewStartConfiguraion')
export const setFocusAppointment = createAction<any>('app/setFocusAppointment')

let appointmentsMap: any = {}
let appointmentsTimer: any = null
let warnings: any = null
let warningsTimer: any = null

// TODO: errors
// TODO: payload types
const turnSocketConnectionsOn = (
  buildingId: number,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
) => {
  socket.appointments.on(`building:${buildingId}`, (payload: any) => {
    if (payload.id) {
      appointmentsMap[payload.id] = payload
    } else {
      payload.forEach((a: any) => {
        appointmentsMap[a.id] = a
      })
    }

    clearTimeout(appointmentsTimer)
    appointmentsTimer = setTimeout(() => {
      dispatch(
        AppointmentsActions.updateAppointmentWithSocketAppointment(Object.values(appointmentsMap))
      )
      appointmentsMap = {}
    }, 500)
  })

  socket.reports.on(`updateWarnings:${buildingId}`, (payload: any) => {
    warnings = payload
    clearTimeout(warningsTimer)
    warningsTimer = setTimeout(() => {
      dispatch(AppointmentsActions.mergeAppointmentCounts(warnings))
      warnings = null
    }, 500)
  })
}

const turnSocketConnectionsOff = (
  buildingId: number,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>
) => {
  socket.appointments.off(`building:${buildingId}`, (payload: any) => {
    if (payload.id) {
      appointmentsMap[payload.id] = payload
    } else {
      payload.forEach((a: any) => {
        appointmentsMap[a.id] = a
      })
    }
    clearTimeout(appointmentsTimer)
    appointmentsTimer = setTimeout(() => {
      dispatch(
        AppointmentsActions.updateAppointmentWithSocketAppointment(Object.values(appointmentsMap))
      )
      appointmentsMap = {}
    }, 500)
  }
  )
  socket.reports.off(`updateWarnings:${buildingId}`, (payload: any) => {
    warnings = payload
    clearTimeout(warningsTimer)
    warningsTimer = setTimeout(() => {
      dispatch(AppointmentsActions.mergeAppointmentCounts(warnings))
      warnings = null
    }, 500)
  })
}

export const setSiteId =
  (siteId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      const buildingId = selectCurrentBuildingId(getState())
      if (buildingId) turnSocketConnectionsOff(buildingId, dispatch)
      dispatch(setSiteAction(siteId))
    }

export const setBuildingId =
  (buildingId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
      const previousBuildingIdId = selectCurrentBuildingId(getState())

      if (previousBuildingIdId) turnSocketConnectionsOff(buildingId, dispatch)
      turnSocketConnectionsOn(buildingId, dispatch)

      const building = selectBuildingById(getState(), buildingId)
      if (building && building.timezone) {
        console.log('Setting default timezone to ' + building.timezone)
        moment.tz.setDefault(building.timezone || 'America/Los_Angeles')
      }

      dispatch(setBuildingIdAction(buildingId))
      dispatch(DoorsActions.getAppointmentsForDoors({ selectedWarehouse: buildingId }))
    }

export const setDefaultBuilding =
  (): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    const defaultBuildingId = getDefaultBuildingId(getState() as never)
    const defaultSiteId = selectBuildingById(getState(), defaultBuildingId)?.siteId

    if (defaultBuildingId && defaultSiteId) {
      dispatch(setSiteId(defaultSiteId))
      dispatch(setBuildingId(defaultBuildingId))
    }
  }

// TODO moved from original initial state
// Set a default TimeZone for consistency
// TODO: This can cause unexpected behavior, but, can also unify the way different CT users see appointments
moment.tz.setDefault('America/Los_Angeles')

const initialState: ApplicationState = {
  __EXPIRE_AT: null,
  version: config.VERSION,
  focusAppointment: null,
  HomeView: {
    startDate: getDayStart().toISOString(),
    endDate: getDayEnd().toISOString(),
    startShift: getWorkingDayStart().toISOString(),
    endShift: getWorkingDayEnd().toISOString(),
    startTime: getWorkingDayStart().toISOString(),
    endTime: getWorkingDayEnd().toISOString(),
    siteId: null,
    buildingId: null,
    appointmentType: -1
  }
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setSiteAction, (state, action) => {
      state.HomeView.siteId = action.payload
      state.HomeView.buildingId = null
    })
    builder.addCase(setBuildingIdAction, (state, action) => {
      state.HomeView.buildingId = action.payload
    })
    builder.addCase(setAppointmentType, (state, action) => {
      state.HomeView.appointmentType = action.payload
    })
    builder.addCase(setStartConfiguration, (state, action) => {
      // TODO: add validation
      state.HomeView.startDate = action.payload.startDate.toISOString()
      state.HomeView.endDate = action.payload.endDate.toISOString()
      state.HomeView.startShift = action.payload.startShift.toISOString()
      state.HomeView.endShift = action.payload.endShift.toISOString()
    })
    builder.addCase(setFocusAppointment, (state, action) => {
      state.focusAppointment = action.payload
    })
  }
})

export default slice.reducer
