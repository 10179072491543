import React from 'react'

import PropTypes from 'prop-types'

import CloseButton from '../components/CloseButton'
import { Container } from '../styled/Containers'

const Tag = ({ label, onClose, className }) => (
  <Container className={className}>
    {label}
    <CloseButton onClick={onClose} />
  </Container>
)

Tag.propTypes = {
  label: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string
}

export default Tag
