import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CloseIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={width}
        height={height}
        viewBox="0 0 12 12"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M11.832 10.254L7.58 6l4.253-4.254c.194-.193.351-.877-.175-1.403s-1.21-.37-1.403-.175L6 4.42 1.746.168C1.553-.026.87-.183.343.343s-.369 1.21-.175 1.403L4.42 6 .168 10.254c-.194.193-.351.877.175 1.403s1.21.369 1.403.175L6 7.58l4.254 4.253c.193.194.877.351 1.403-.175s.369-1.21.175-1.403z"
        />
      </svg>
    )
  }
}

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

CloseIcon.defaultProps = {
  width: 12,
  height: 12,
  color: '#AAB0C0'
}
