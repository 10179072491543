import { EntityState, RequestStatus } from '../common-types'
import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../root-types'
import moment, { Moment } from 'moment'

export interface DoorSchedule {
  id: number
  scheduleId: number
  doorId: number
  holiday: boolean
  dayOfWeek: number
  open: string
  close: string
  active: boolean
}

export interface DoorScheduleState extends EntityState<DoorSchedule> {}

const adapter = createEntityAdapter<DoorSchedule>()

const initialState = {
  loading: RequestStatus.Idle,
  error: null
}

export const doorScheduleFulfilled = createAction<DoorSchedule[]>('doorSchedule/fulfilled')

const slice = createSlice({
  name: 'doorSchedule',
  initialState: adapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doorScheduleFulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = RequestStatus.Succeded
    })
  }
})

const globalizedSelectors = adapter.getSelectors((state: RootState) => state.doorSchedule)

export const selectAllDoorSchedules = globalizedSelectors.selectAll

export interface isDoorAvailableProps {
  doorId: number
  hour: Moment
}

export const createGetIsDoorAvailable = createSelector(
  selectAllDoorSchedules,
  doorSchedules => (props: isDoorAvailableProps) => {
    const { doorId, hour } = props

    if (!doorId || !hour) {
      return true
    }

    const dayOfWeek = hour.day()

    const doorScheduleByDay = doorSchedules.find(
      schedule => schedule.doorId === doorId && schedule.dayOfWeek === dayOfWeek
    )

    if (!doorScheduleByDay?.open || !doorScheduleByDay?.close) {
      return true
    }

    if (doorScheduleByDay?.holiday || !doorScheduleByDay?.active) {
      return false
    }

    const open = moment(doorScheduleByDay?.open, 'hh:mm:ss').set({
      date: hour.date(),
      month: hour.month(),
      year: hour.year()
    })
    const close = moment(doorScheduleByDay?.close, 'hh:mm:ss').set({
      date: hour.date(),
      month: hour.month(),
      year: hour.year()
    })

    return hour.isBetween(open, close) || hour.isSame(open)
  }
)

export default slice.reducer
