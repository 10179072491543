import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { OrdersTypes } from './actions'
import { AppointmentsTypes } from '../appointments/actions'
import { fromJS } from 'immutable'

// getOrders
export const getOrdersLoading = state =>
  state.merge({
    getOrdersIsLoading: true,
    getOrdersErrorMessage: ''
  })

export const getOrdersSuccess = (state, { orders, pages }) =>
  state.merge({
    orders,
    pages,
    getOrdersIsLoading: false,
    getOrdersErrorMessage: null
  })
export const updateOrdersFromAppointment = (state, { appointment }) => {
  if (!appointment.get) {
    appointment = fromJS(appointment)
  }
  const orders = appointment.get('orders')
  return state.merge({
    orders: state.get('orders').map(order => {
      const newOrder = orders.find(o => o.get('id') === order.get('id'))
      if (newOrder) {
        return order.merge({
          appointment
        })
      }
      return order
    })
  })
}
export const getOrdersFailure = (state, { errorMessage }) =>
  state.merge({
    getOrdersIsLoading: false,
    getOrdersErrorMessage: errorMessage
  })

export const setSelectedOrders = (state, { orders }) => {
  let sortedOrders = orders

  if (sortedOrders.toJS) {
    sortedOrders = sortedOrders.toJS()
  }

  sortedOrders = [...sortedOrders]

  sortedOrders.sort(function (a, b) {
    if (a.orderSequence < b.orderSequence) return -1
    if (a.orderSequence > b.orderSequence) return 1
    return 0
  })

  return state.merge({
    selectedOrders: sortedOrders
  })
}

export const setCheckedOrders = (state, { orders }) =>
  state.merge({
    checkedOrders: orders
  })

export const selectOrder = (state, { order }) =>
  state.merge({
    selectedOrders: [...state.get('selectedOrders'), order]
  })

export const deselectOrder = (state, { order }) =>
  state.merge({
    selectedOrders: state.get('selectedOrders').filter(o => o.get('id') !== order.get('id'))
  })

export const openOrderDetailsModal = state =>
  state.merge({
    isOrderDetailsModalVisible: true
  })

export const closeOrderDetailsModal = state =>
  state.merge({
    isOrderDetailsModalVisible: false
  })

export const setOrderDetails = (state, { orderDetails }) =>
  state.merge({
    orderDetails
  })

// Orders socket
export const updateOrdersWithSocketOrder = (state, { payload }) => {
  const { socketOrder } = payload
  return socketOrder.reduce((newState, updatingOrder) => {
    return newState.merge({
      orders: newState
        .get('orders', [])
        .map(order =>
          order.get
            ? order.get('id') !== updatingOrder.id
              ? order
              : order.merge(updatingOrder)
            : order.id !== updatingOrder.id
              ? order
              : { ...order, ...updatingOrder }
        ),
      selectedOrders: newState
        .get('selectedOrders', [])
        .map(order =>
          order.get
            ? order.get('id') !== updatingOrder.id
              ? order
              : order.merge(updatingOrder)
            : order.id !== updatingOrder.id
              ? order
              : { ...order, ...updatingOrder }
        )
    })
  }, state)
}

export const clearCheckedAndSelectedOrders = (state, { appointment }) => {
  return state.merge({
    checkedOrders: [],
    selectedOrders: []
  })
}

// Orders search
export const onSearchChange = (
  state,
  {
    payload: {
      destinationId,
      deliveryDate,
      requiredShipDate,
      customerId,
      page,
      orderStatusId,
      ...payload
    }
  }
) => {
  return state.merge({
    ...payload,
    customerSelect: customerId,
    ordersStatusSelect: orderStatusId,
    currentPage: page || 1,
    deliveryDateSelect: deliveryDate,
    requiredShipDateSelect: requiredShipDate,
    destinationSelect: destinationId
  })
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OrdersTypes.GET_ORDERS_LOADING]: getOrdersLoading,
  [OrdersTypes.GET_ORDERS_SUCCESS]: getOrdersSuccess,
  [OrdersTypes.GET_ORDERS_FAILURE]: getOrdersFailure,
  [OrdersTypes.UPDATE_ORDERS_FROM_APPOINTMENT]: updateOrdersFromAppointment,

  [OrdersTypes.SET_SELECTED_ORDERS]: setSelectedOrders,
  [OrdersTypes.SET_CHECKED_ORDERS]: setCheckedOrders,
  [OrdersTypes.SELECT_ORDER]: selectOrder,
  [OrdersTypes.DESELECT_ORDER]: deselectOrder,
  [OrdersTypes.OPEN_ORDER_DETAILS_MODAL]: openOrderDetailsModal,
  [OrdersTypes.CLOSE_ORDER_DETAILS_MODAL]: closeOrderDetailsModal,
  [OrdersTypes.SET_ORDER_DETAILS]: setOrderDetails,
  [OrdersTypes.GET_ORDERS]: onSearchChange,

  [OrdersTypes.UPDATE_ORDERS_WITH_SOCKET_ORDER]: updateOrdersWithSocketOrder,

  [AppointmentsTypes.CLOSE_UPSERT_APPOINTMENT]: clearCheckedAndSelectedOrders
})
