import styled from 'styled-components'
import CalendarIcon from '../components/icons/Calendar'
import ArrowTruckIcon from '../components/icons/ArrowTruck'
import { theme } from '../themes/taylor-farms'

export const StyledCalendarIcon = styled(CalendarIcon)`
  margin-right: 8px;
`
export const CartIcon = styled.img``
export const AppointmentIcon = styled.img``
export const UserIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: 2px 5px 2px 10px;
`

export { default as ArrowIcon } from '../components/icons/Arrow'

export const StyledTruckIcon = styled(ArrowTruckIcon).attrs(props => ({
  color: (props.scheduled) ? theme.orderStatuses.scheduled.text : theme.orderStatuses.open.text
}))`
  ${props => props.outbound ? 'transform: scaleX(-1);' : ''}
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
`

export const OrderCardTruckIcon = styled(StyledTruckIcon)`
  margin: 0 0 0 12px;
`
