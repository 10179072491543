import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SummonSMSForm from '../forms/SummonSMSForm'
import { APPOINTMENTS_TAB } from '../modals/AppointmentModal'
import SMSActions from '../../modules/sms/actions'
import { getSendSMSErrorMessage, getSendSMSIsLoading } from '../../modules/sms/selectors'
import AppointmentsActions from '../../modules/appointments/actions'

export const SUMMON_SMS_TEMPLATE_ID = 3

class SummonDriverTab extends Component {
  componentDidUpdate (prevProps, prevState) {
    const {
      sendSMSIsLoading,
      sendSMSErrorMessage,
      closeUpsertAppointment
    } = this.props

    if (!sendSMSIsLoading && prevProps.sendSMSIsLoading) {
      if (!sendSMSErrorMessage) {
        closeUpsertAppointment()
      } else {
        this.setState({ sendSMSError: sendSMSErrorMessage })
      }
    }
  }

  onSendSMS = ({ to, content }) => {
    const { sendSMS } = this.props
    sendSMS(to, content)
  }

  render () {
    const {
      appointmentData,
      switchToTab
    } = this.props

    return (
      <SummonSMSForm
        appointmentData={appointmentData || {}}
        onSubmit={this.onSendSMS}
        onCancel={switchToTab(APPOINTMENTS_TAB)}
      />
    )
  }
}

SummonDriverTab.propTypes = {
  // from parent
  appointmentData: PropTypes.object,
  switchToTab: PropTypes.func,
  // from store
  sendSMS: PropTypes.func,
  closeUpsertAppointment: PropTypes.func,
  sendSMSIsLoading: PropTypes.bool,
  sendSMSErrorMessage: PropTypes.string
}

const mapStateToProps = state => ({
  sendSMSIsLoading: getSendSMSIsLoading(state),
  sendSMSErrorMessage: getSendSMSErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
  sendSMS: (to, content) =>
    dispatch(SMSActions.sendSMS(to, SUMMON_SMS_TEMPLATE_ID, content)),
  closeUpsertAppointment: () =>
    dispatch(AppointmentsActions.closeUpsertAppointment())
})

export default connect(mapStateToProps, mapDispatchToProps)(SummonDriverTab)
