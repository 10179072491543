import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TimeState {
  timestamp: number
}

export const initialState : TimeState = {
  timestamp: 0
}

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    updateTimestamp: (state, action: PayloadAction<number>) => {
      state.timestamp = action.payload
    }
  }
})

export const { updateTimestamp } = timeSlice.actions

export default timeSlice.reducer
