import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getAllCarrierRequests
  getAllCarrierRequests: ['payload'],
  getAllCarrierRequestsLoading: null,
  getAllCarrierRequestsSuccess: ['carrierRequests'],
  getAllCarrierRequestsFailure: ['errorMessage'],

  // getNumberOfCarrierRequests
  getNumberOfCarrierRequests: null,
  getNumberOfCarrierRequestsLoading: null,
  getNumberOfCarrierRequestsSuccess: ['numberOfCarrierRequests'],
  getNumberOfCarrierRequestsFailure: ['errorMessage'],

  // getCarrierRequestPickUpTimes
  getCarrierRequestPickUpTimes: null,
  getCarrierRequestPickUpTimesLoading: null,
  getCarrierRequestPickUpTimesSuccess: ['carrierRequestsPickUpTimes'],
  getCarrierRequestPickUpTimesFailure: ['errorMessage'],

  // createCarrierRequest
  createCarrierRequest: ['payload'],
  createCarrierRequestLoading: null,
  createCarrierRequestSuccess: ['carrierRequest'],
  createCarrierRequestFailure: ['errorMessage'],

  // carrierRequests socket
  updateCarrierRequestsWithSocketCarrierRequest: ['payload'],
  updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide: ['payload'],
  updateCountCarrierRequestsWithSocketCountCarrierRequests: ['payload'],

  // updateCreatingCarrierRequest
  updateCreatingCarrierRequest: ['payload'],

  // resetCreatingCarrierRequest
  resetCreatingCarrierRequest: null,

  // Add PO to creating request
  addPo: ['payload'],
  addPoIsLoading: null,
  addPoSuccess: ['payload'],
  addPoFailure: ['errorMessage'],
  addPoReset: null,

  // Search
  searchRequests: ['payload'],
  onRequestSearchChange: ['payload'],

  // deleteCarrierRequest
  deleteCarrierRequest: ['id'],
  deleteCarrierRequestLoading: null,
  deleteCarrierRequestSuccess: null,
  deleteCarrierRequestFailure: ['errorMessage'],

  closeUpsertCarrierRequest: null,

  openDeleteCarrierRequest: ['request'],
  closeDeleteCarrierRequest: null,

  // updateCarrierRequest
  updateCarrierRequest: ['payload'],
  updateCarrierRequestLoading: null,
  updateCarrierRequestSuccess: ['updatedCarrierRequest'],
  updateCarrierRequestFailure: ['errorMessage']
})

export const CarrierRequests = Types
export default Creators
