import React from 'react'
import PropTypes from 'prop-types'

import { Container } from '../styled/Containers'
import { DefaultText, Label } from '../styled/Texts'

const TextArea = ({ label, fullwidth, isBox, error, ...props }) => (
  <Container>
    {label ? (
      <Label theme={props.theme} error={props.error}>
        {label}
      </Label>
    ) : ''}
    <textarea {...props} />
    {props.error ? (
      <DefaultText theme={props.theme} error={props.error}>
        {props.error}
      </DefaultText>
    ) : ''}
  </Container>
)

TextArea.propTypes = {
  label: PropTypes.string,
  fullwidth: PropTypes.string,
  error: PropTypes.any,
  theme: PropTypes.any,
  isBox: PropTypes.any
}

export default TextArea
