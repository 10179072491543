import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { reducer as usersReducer } from './users/reducers'
import usersSagas from './users/sagas'

import { reducer as ordersReducer } from './orders/reducers'
import ordersSagas from './orders/sagas'

import { reducer as appointmentsReducer } from './appointments/reducers'
import appointmentsSagas from './appointments/sagas'

import { reducer as buildingsReducer } from './buildings/reducers'
import buildingsSagas from './buildings/sagas'

import carriersSagas from './carriers/sagas'

import { reducer as customersReducer } from './customers/reducers'
import customersSagas from './customers/sagas'

import { reducer as doorsReducer } from './doors/reducers'
import doorsSagas from './doors/sagas'

import { reducer as orderItemsReducer } from './orderItems/reducers'
import orderItemsSagas from './orderItems/sagas'

import { reducer as itemsReducer } from './items/reducers'
import itemsSagas from './items/sagas'

import { reducer as emailsReducer } from './emails/reducers'
import emailsSagas from './emails/sagas'

import { reducer as carrierRequestsReducer } from './carrierRequests/reducers'
import carrierRequestsSagas from './carrierRequests/sagas'

import { reducer as feedsReducer } from './feeds/reducers'
import feedsSagas from './feeds/sagas'

import { reducer as smsReducer } from './sms/reducers'
import smsSagas from './sms/sagas'

import { reducer as reportsReducer } from './reports/reducers'
import reportsSagas from './reports/sagas'

import { reducer as inventoryItemsReducer } from './inventoryItems/reducers'
import inventoryItemsSagas from './inventoryItems/sagas'

import { reducer as settingsReducer } from './settings/reducers'
import settingsSagas from './settings/sagas'

import timeReducer from '../time/time-slice'
import timeSagas from '../time/time-sagas'

import siteReducer from '../sites/sites-slice'
import areaReducer from '../areas/areas-slice'
import buildingReducer from '../buildings/buildings-slice'
import doorReducer from '../doors/doors-slice'
import doorDurationReducer from '../doors/door-durations-slice'
import locationReducer from '../locations/locations-slice'
import appReducer from '../app/app-slice'
import driverReducer from '../drivers/drivers-slice'
import carrierReducer from '../carriers/carriers-slice'
import appointmentReducer from '../appointments/appointments-slice'
import orderReducer from '../orders/orders-slice'
import userReducer from '../users/users-slice'
import carrierAppReducer from '../carrier-app/carrier-app-slice'
import doorScheduleReducer from '../door-schedule/door-schedule-slice'

import { reducer as uiReducer } from './ui/reducers'

import { resettableReducer } from 'reduxsauce'

const resettable = resettableReducer('RESET')

/* Reducers */
export const rootReducer = combineReducers({
  /**
   * Register your reducers here.
   * @see https://redux.js.org/api-reference/combinereducers
   */
  users: usersReducer,
  orders: resettable(ordersReducer),
  appointments: resettable(appointmentsReducer),
  buildings: resettable(buildingsReducer),
  customers: resettable(customersReducer),
  doors: resettable(doorsReducer),
  orderItems: resettable(orderItemsReducer),
  items: resettable(itemsReducer),
  emails: resettable(emailsReducer),
  carrierRequests: resettable(carrierRequestsReducer),
  feeds: resettable(feedsReducer),
  sms: resettable(smsReducer),
  reports: resettable(reportsReducer),
  inventoryItems: resettable(inventoryItemsReducer),
  settings: resettable(settingsReducer),
  area: resettable(areaReducer),
  door: resettable(doorReducer),
  doorDuration: resettable(doorDurationReducer),
  site: resettable(siteReducer),
  time: resettable(timeReducer),
  building: resettable(buildingReducer),
  location: resettable(locationReducer),
  doorSchedule: resettable(doorScheduleReducer),
  app: resettable(appReducer),
  carrier: resettable(carrierReducer),
  driver: resettable(driverReducer),
  appointment: resettable(appointmentReducer),
  order: resettable(orderReducer),
  user: resettable(userReducer),
  carrierApp: resettable(carrierAppReducer),
  ui: resettable(uiReducer)
})

/* Sagas */
export function * rootSaga () {
  yield all([
    fork(usersSagas),
    fork(carriersSagas),
    fork(ordersSagas),
    fork(appointmentsSagas),
    fork(buildingsSagas),
    fork(customersSagas),
    fork(doorsSagas),
    fork(orderItemsSagas),
    fork(itemsSagas),
    fork(emailsSagas),
    fork(carrierRequestsSagas),
    fork(feedsSagas),
    fork(smsSagas),
    fork(reportsSagas),
    fork(inventoryItemsSagas),
    fork(settingsSagas),
    fork(timeSagas)
  ])
}
