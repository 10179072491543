import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Container } from '../styled/Containers'
import { ErrorText, MaterialLabel } from '../styled/Texts'
import { MaterialCreatableSelect } from '../styled/Selects'
import { theme } from '../themes/taylor-farms'

const selectStyles = error => ({
  container: () => ({}),
  control: (base, { isFocused }) => ({
    ...base,
    marginTop: '0.35rem',
    borderRadius: 2,
    borderWidth: '0px 0px 1px 0px',
    borderColor: error ? theme.colors.alert : (isFocused ? theme.colors.default : theme.darkGray),
    boxShadow: 'none',
    outline: 'none',
    width: '100%'
  }),
  valueContainer: () => ({}),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? theme.colors.important : null,
    color: isSelected || isFocused ? theme.contrastTextColor : null
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: base => ({
    ...base,
    color: theme.darkGray,
    marginRight: '0.25rem'
  })
})

class AutoComplete extends Component {
  onChangeAutoComplete = (selection, input) => {
    const { onChange } = this.props
    if (!selection) {
      // nothing selected
      onChange({ label: null, value: null })
    } else if (selection.value === selection.label) {
      // creating a new one
      onChange({ ...selection, value: null })
    } else {
      // updating existing
      onChange({ ...selection })
    }
  }

  render () {
    const { label, error, options, value } = this.props
    const castedOptions = options.map(option => ({ ...option, value: String(option.value) }))
    const selectedValue =
      value && castedOptions.find(option => option.value === String(value))
    return (
      <Container>
        <MaterialLabel error={error}>{label}</MaterialLabel>
        {error && <ErrorText error={error}>{error}</ErrorText>}
        <MaterialCreatableSelect
          {...this.props}
          options={castedOptions}
          value={selectedValue}
          styles={selectStyles(error)}
          onChange={this.onChangeAutoComplete}
        />
      </Container>
    )
  }
}

AutoComplete.propTypes = {
  label: PropTypes.string,
  error: PropTypes.any,
  options: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default AutoComplete
