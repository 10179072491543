export const getUniqueItems = items => {
  return Object.values(items.reduce((newItems, item) => {
    if (!newItems[item.sku]) {
      newItems[item.sku] = item
    } else {
      const orderItem = item.orderItem
      newItems[item.sku].orderItem.orderedQty += orderItem.orderedQty
      newItems[item.sku].orderItem.orderedWeight += orderItem.orderedWeight
      newItems[item.sku].orderItem.remainderQty += orderItem.remainderQty
    }
    return newItems
  }, {})) || []
}
export const getRate = totalsObj => {
  const rate = 100 - (totalsObj.remainderQty * 100 / totalsObj.orderedQty)
  return Math.round(rate * 100) / 100
}

export const getAppointamentPickingRate = orders => {
  const appointamentItemTotals = {
    orderedQty: 0,
    remainderQty: 0
  }

  orders.forEach(order => {
    order.items.forEach(item => {
      appointamentItemTotals.orderedQty += item.orderItem.orderedQty
      appointamentItemTotals.remainderQty += item.orderItem.remainderQty
    })
  })

  return getRate(appointamentItemTotals)
}

export const getOrderPickingRate = items => {
  const orderItemTotals = {
    orderedQty: 0,
    remainderQty: 0
  }

  items.forEach(item => {
    orderItemTotals.orderedQty += item.orderItem.orderedQty
    orderItemTotals.remainderQty += item.orderItem.remainderQty
  })

  return getRate(orderItemTotals)
}

export const getTotalWeight = uniqueItems => uniqueItems.reduce((totalWeight, item) => totalWeight + item.orderItem.orderedWeight, 0)

export const getOrderSummary = items => {
  return items.reduce((orderSummary, item) => {
    const itemDetails = item.orderItem
    const orderedQtyUOM = itemDetails.orderedQtyUOM
    const skus = Array.from(new Set([
      ...orderSummary.totalSkus,
      item.sku
    ]))
    const orderedWeightUOM = itemDetails.orderedWeightUOM

    return {
      totalOrderedQuantities: {
        ...orderSummary.totalOrderedQuantities,
        [orderedQtyUOM]: orderSummary.totalOrderedQuantities[orderedQtyUOM]
          ? orderSummary.totalOrderedQuantities[orderedQtyUOM] + itemDetails.orderedQty
          : itemDetails.orderedQty
      },
      totalSkus: skus,
      totalWeight: {
        ...orderSummary.totalWeight,
        [orderedWeightUOM]: orderSummary.totalWeight[orderedWeightUOM]
          ? orderSummary[orderedWeightUOM] + itemDetails.orderedWeight
          : itemDetails.orderedWeight
      }
    }
  }, {
    totalOrderedQuantities: {},
    totalSkus: [],
    totalWeight: {}
  })
}
