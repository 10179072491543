import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment-timezone'
import { List } from 'immutable'

import PropTypes from 'prop-types'

import { Container, ExpandableContainer, Scrollable } from '../../../styled/Containers'
import { GridCol, GridRow } from '../../../styled/Grids'
import { convertToCamelCase } from '../../../utils/common'
import {
  getAllAppointmentStatuses,
  getAppointmentStatusesAsOptions,
  getAppointments,
  getAppointmentsIsLoading,
  getPages,
  getSearchAttributes,
  getSearchAttributesCount,
  getUpdateAppointmentIsLoading
} from '../../../modules/appointments/selectors'
import {
  appointmentStatusesMap,
  getDayStart,
  getWorkingDayEnd,
  isRequestLate,
  requestStatusesMap
} from '../../../utils/time'
import AppointmentsActions from '../../../modules/appointments/actions'
import DoorsActions from '../../../modules/doors/actions'
import OrdersActions from '../../../modules/orders/actions'
import { INVENTORY_TAB } from '../../../components/modals/AppointmentModal'
import { StyledAppointmentCard } from '../../../styled/Cards'
import Filters from '../layout/Filters'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import { createGetDoorById } from '../../../doors/doors-slice'
import HandleError from '../../../components/hocs/HandleError'
import { SimpleSpinner } from '../../../styled/Loading'
import { isAppointmentTypeInbound, isAppointmentTypeOutbound, selectAppointmentType, selectCurrentBuildingId, selectStartDate, setBuildingId, setFocusAppointment, setSiteId } from '../../../app/app-slice'

const DEFAULT_STATUS = appointmentStatusesMap.draft
const DEFAULT_REQUEST_STATUS = requestStatusesMap.pending

class Appointments extends Component {
  constructor (props) {
    super(props)

    this.state = {
      warehouse: props.warehouse,
      startDate: props.startDate,
      showFilters: false
    }
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return nextState !== this.state ||
      nextProps.loading !== this.props.loading ||
      nextProps.warehouse !== this.props.warehouse ||
      nextProps.startDate !== this.props.startDate ||
      nextProps.appointments !== this.props.appointments ||
      nextProps.isUpdateAppointmentLoading !== this.props.isUpdateAppointmentLoading ||
      nextProps.appointmentStatuses !== this.props.appointmentStatuses ||
      nextProps.appointmentType !== this.props.appointmentType
  }

  toggleShowFilters = () => {
    return this.setState({
      showFilters: !this.state.showFilters
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { warehouse, appointmentType, searchAppointments } = this.props

    if (prevProps.appointmentType !== appointmentType) {
      searchAppointments({ currentPage: 1 })
      this.clearFilters()

      return
    }

    if (
      prevProps.warehouse !== warehouse ||
      !prevProps.startDate.isSame(this.props.startDate, 'day')
    ) {
      searchAppointments({ currentPage: 1 })
    }
  }

  componentDidMount () {
    const { searchAppointments, appointmentType } = this.props
    if (appointmentType !== -1) {
      searchAppointments({ currentPage: 1 })
    }
  }

  // TODO move to saga for reusability
  onAppointmentClick (appointment) {
    if (appointment.get('door', null)) {
      const {
        setFocusAppointment,
        setSite,
        setWarehouse,
        getAppointmentsForDoors
      } = this.props

      const timezone = appointment.get('door')?.area?.building?.timezone || 'UTC'

      const date = moment(appointment.get('date')).tz(timezone)

      const site = appointment.get('door')?.area?.building?.site?.id
      const warehouse = appointment.get('door').area?.building?.id
      const tzStart = getDayStart(date)
      if (date.hour() < 6) {
        tzStart.subtract(1, 'day')
      }
      const tzEnd = getWorkingDayEnd(tzStart)

      setSite(site)
      setWarehouse(warehouse)
      setFocusAppointment(appointment)

      this.clearFilters()
      this.setState({ showFilters: false })

      getAppointmentsForDoors({
        buildingId: warehouse,
        selectedStartDate: tzStart,
        selectedEndDate: tzEnd,
        selectedEndShift: tzEnd
      })
    }
  }

  onAppointmentEdit (appointment, tab) {
    this.props.closeUpsertAppointment()
    this.props.setSelectedOrders(appointment.orders)

    const doorId = appointment.door ? appointment.door.id : null

    // TODO we need to normalize the appointment instance
    this.props.openEditAppointment({
      ...appointment,
      doorId
    })
    if (tab && tab === INVENTORY_TAB) {
      this.props.changeEditAppointmentTab(tab)
    }
  }

  onAppointmentDelete (appointment) {
    this.props.openDeleteAppointment(appointment)
  }

  getRequestStatus = (carrierRequest, appointmentStatus) => {
    const carrierRequestStatus = carrierRequest
      ? carrierRequest.get('status')
      : null
    if (
      carrierRequestStatus === requestStatusesMap.canceled &&
      appointmentStatus &&
      (appointmentStatus.get('name') === appointmentStatusesMap.draft ||
        appointmentStatus.get('name') === appointmentStatusesMap.scheduled)
    ) {
      return requestStatusesMap.canceled
    } else if (
      carrierRequestStatus === requestStatusesMap.reschedule &&
      appointmentStatus &&
      (appointmentStatus.get('name') === appointmentStatusesMap.draft ||
        appointmentStatus.get('name') === appointmentStatusesMap.scheduled)
    ) {
      return requestStatusesMap.reschedule
    } else {
      return carrierRequestStatus || DEFAULT_REQUEST_STATUS
    }
  }

  clearFilters = () => {
    this.props.searchAppointments({
      id: '',
      searchText: '',
      customerPurchaseOrder: '',
      appointmentsStatusSelect: null,
      customerSelect: null,
      shippingDateSelect: null,
      destinationSelect: null,
      buildingId: null,
      currentPage: 1
    })
  }

  debouncedShowFilters = debounce(this.toggleShowFilters, 300)

  onScroll = throttle(() => {
    const { pages, loading, searchAppointments, searchAttributes } = this.props

    if (this.appointmentList && searchAttributes.currentPage < pages && !loading) {
      const scrollPos = this.appointmentList.scrollTop + this.appointmentList.clientHeight
      if (scrollPos + 500 > this.appointmentList.scrollHeight) {
        searchAppointments({ currentPage: searchAttributes.currentPage + 1 })
      }
    }
    if (this.state.showFilters) {
      this.debouncedShowFilters()
    }
  }, 1000)

  onSearch = filters => {
    const { searchAppointments } = this.props
    searchAppointments(filters)
  }

  calculateLateness = appointment => {
    const { appointmentStatuses } = this.props
    const appointmentStatus = appointmentStatuses
      ? appointmentStatuses.find(
        as => as.get('id') === appointment.appointmentStatusId
      )
      : null
    const carrierRequest = appointment.carrierRequests ? appointment.carrierRequests[0] : null
    if (appointmentStatus && appointment) {
      return isRequestLate(
        carrierRequest,
        appointment,
        appointmentStatus
      )
    }
  }

  getFilterDateLabel = (appointmentType) => {
    if (isAppointmentTypeInbound(appointmentType)) return 'Arrival date'
    if (isAppointmentTypeOutbound(appointmentType)) return 'Shipping date'

    return 'Arrival/Shipping date'
  }

  render () {
    const {
      appointments,
      getDoorById,
      appointmentStatuses = [],
      appointmentStatusesOptions = [],
      searchAttributes,
      searchAttributesCount,
      isUpdateAppointmentLoading,
      loading,
      appointmentType
    } = this.props

    return (
      <GridCol flex={1}>
        <Filters
          showId
          idPlaceholder='Appointment number'
          open={this.state.showFilters}
          onToggle={this.toggleShowFilters}
          searchAttributes={searchAttributes}
          searchAttributesCount={searchAttributesCount}
          dateField={{
            label: this.getFilterDateLabel(appointmentType),
            key: 'shippingDateSelect'
          }}
          statuses={{
            options: appointmentStatusesOptions,
            label: 'Appointment Status',
            key: 'appointmentsStatusSelect'
          }}
          showLocations
          showCustomers
          onClear={this.clearFilters}
          onSearch={this.onSearch}
          appointmentType={appointmentType}
        />

        <Scrollable
          ref={ref => {
            this.appointmentList = ref
          }}
          onScroll={this.onScroll}
        >
          {appointments
            ? appointments.map(appointment => {
              const appointmentStatus = appointmentStatuses
                ? appointmentStatuses.find(
                  as =>
                    as.get('id') ===
                            appointment.get('appointmentStatusId')
                )
                : null
              const appointmentStatusParsed = appointmentStatus
                ? appointmentStatus.get('name')
                : DEFAULT_STATUS
              const carrierRequest = appointment
                .get('carrierRequests', new List())
                .valueSeq()
                .first()
              const isCarrierLate = this.calculateLateness(appointment)
              const status = convertToCamelCase(
                isCarrierLate ? requestStatusesMap.carrierLate : appointmentStatusParsed
              )
              const requestStatus = this.getRequestStatus(
                carrierRequest,
                appointmentStatus
              )
              return (
                <StyledAppointmentCard
                  appointmentStatuses={appointmentStatuses}
                  getDoorById={getDoorById}
                  key={appointment.get('id')}
                  appointment={appointment.toJS()}
                  calculateLateness={() => this.calculateLateness(appointment)}
                  onClick={() => this.onAppointmentClick(appointment)}
                  onEdit={() => this.onAppointmentEdit(appointment.toJS())}
                  onIssue={() => this.onAppointmentEdit(appointment.toJS(), INVENTORY_TAB)}
                  onDelete={() => this.onAppointmentDelete(appointment)}
                  appointmentStatus={appointmentStatusParsed}
                  isLoading={appointment.get('id') === isUpdateAppointmentLoading}
                  status={status}
                  isRequestLate={isCarrierLate}
                  requestStatus={requestStatus}
                />
              )
            }) : ''}
          {
            (!appointments || !appointments.size)
              ? <Container>No Results</Container>
              : null
          }
        </Scrollable>
        <ExpandableContainer isExpanded={loading} vertical height="50">
          <GridRow centered padded="right">
            <SimpleSpinner />
          </GridRow>
        </ExpandableContainer>
      </GridCol>
    )
  }
}

Appointments.propTypes = {
  warehouse: PropTypes.any,
  setSelectedOrders: PropTypes.func,
  openEditAppointment: PropTypes.func,
  openDeleteAppointment: PropTypes.func,
  closeUpsertAppointment: PropTypes.func,
  changeEditAppointmentTab: PropTypes.func,
  searchAppointments: PropTypes.func,
  pages: PropTypes.number,
  loading: PropTypes.bool,
  isUpdateAppointmentLoading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  appointments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  appointmentStatuses: PropTypes.object,
  appointmentStatusesOptions: PropTypes.array,
  searchAttributes: PropTypes.object,
  appointmentType: PropTypes.number,
  searchAttributesCount: PropTypes.number,

  // prevent warning
  startDate: PropTypes.any,
  getAppointmentsForDoors: PropTypes.func,
  setFocusAppointment: PropTypes.func,
  setSite: PropTypes.func,
  setWarehouse: PropTypes.func,
  getDoorById: PropTypes.func
}

const mapStateToProps = state => ({
  pages: getPages(state),
  loading: getAppointmentsIsLoading(state),
  warehouse: selectCurrentBuildingId(state),
  startDate: selectStartDate(state),
  searchAttributes: getSearchAttributes(state),
  appointmentType: selectAppointmentType(state),
  searchAttributesCount: getSearchAttributesCount(state),
  appointments: getAppointments(state),
  appointmentStatuses: getAllAppointmentStatuses(state),
  appointmentStatusesOptions: getAppointmentStatusesAsOptions(state),
  isUpdateAppointmentLoading: getUpdateAppointmentIsLoading(state),
  getDoorById: createGetDoorById(state)
})

const mapDispatchToProps = dispatch => ({
  setFocusAppointment: payload =>
    dispatch(setFocusAppointment(payload)),
  setWarehouse: payload => dispatch(setBuildingId(payload)),
  updateAppointmentWithSocketAppointment: payload =>
    dispatch(AppointmentsActions.updateAppointmentWithSocketAppointment(payload)),
  setSite: payload => dispatch(setSiteId(payload)),
  getAppointmentsForDoors: payload =>
    dispatch(DoorsActions.getAppointmentsForDoors(payload)),
  deleteAppointment: appointment =>
    dispatch(AppointmentsActions.deleteAppointment(appointment.get('id'))),
  removeOrderFromAppointment: (appointmentId, orderId) =>
    dispatch(
      AppointmentsActions.removeOrderFromAppointment(appointmentId, orderId)
    ),
  searchAppointments: payload => dispatch(AppointmentsActions.searchAppointments(payload)),
  setSelectedOrders: orders =>
    dispatch(OrdersActions.setSelectedOrders(orders)),
  openEditAppointment: appointment =>
    dispatch(AppointmentsActions.openEditAppointment(appointment)),
  changeEditAppointmentTab: tab =>
    dispatch(AppointmentsActions.changeEditAppointmentTab(tab)),
  openDeleteAppointment: appointment =>
    dispatch(AppointmentsActions.openDeleteAppointment(appointment)),
  closeUpsertAppointment: appointment =>
    dispatch(AppointmentsActions.closeUpsertAppointment(appointment))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandleError(Appointments))
