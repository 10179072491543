import { put, takeLatest, fork } from 'redux-saga/effects'
import { schema } from 'normalizr'

import CarrierRequestsActions from '../carrierRequests/actions'
import { carrierLogoutFulfilled } from '../../carrier-app/carrier-app-slice'

export const CarrierSchema = new schema.Entity('carriers')

// TODO: remove when refactoring carrier requests
function * logout () {
  yield put(CarrierRequestsActions.getAllCarrierRequestsSuccess([]))
}

function * logoutWatcher () {
  yield takeLatest(carrierLogoutFulfilled, logout)
}

export default function * root () {
  yield fork(logoutWatcher)
}
