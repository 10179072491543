import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class WarningIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    const viewBox = '0 0 17 18'
    return (
      <svg xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
      >
        <g fill={color} fillRule="evenodd">
          <path style={{
            transform: 'scale(1.4) translate(-10px, 2px)'
          }} fill={color} d="M21.143 8.864L16.327.403A.804.804 0 0 0 15.625 0a.803.803 0 0 0-.703.403l-4.815 8.461a.692.692 0 0 0 .012.757.809.809 0 0 0 .69.379h9.632a.809.809 0 0 0 .69-.379.692.692 0 0 0 .012-.757zm-4.715-.601c0 .056-.02.103-.06.141a.196.196 0 0 1-.14.057h-1.205a.195.195 0 0 1-.14-.057.187.187 0 0 1-.06-.141V7.121c0-.056.02-.103.06-.14a.196.196 0 0 1 .14-.058h1.204c.054 0 .101.019.141.057.04.038.06.085.06.141v1.142zm-.013-2.247a.133.133 0 0 1-.066.099.264.264 0 0 1-.147.039h-1.16a.279.279 0 0 1-.15-.04c-.043-.026-.063-.058-.063-.099l-.107-2.746c0-.056.021-.098.063-.126.054-.044.104-.066.15-.066h1.38c.046 0 .096.022.15.066a.13.13 0 0 1 .063.114l-.113 2.759z"/>
        </g>
      </svg>
    )
  }
}

WarningIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

WarningIcon.defaultProps = {
  width: 20,
  height: 21,
  color: '#FF8100'
}
