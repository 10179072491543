// findsecrets-ignore-file
import { fromJS, List } from 'immutable'
import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { CarrierRequests } from './actions'
import { carrierLoginFullfiled } from '../../carrier-app/carrier-app-slice'

// getAllCarrierRequests
const getAllCarrierRequestsLoading = state =>
  state.merge({
    getAllCarrierRequestsIsLoading: true,
    getAllCarrierRequestsErrorMessage: ''
  })

const getAllCarrierRequestsSuccess = (state, { carrierRequests }) =>
  state.merge({
    carrierRequests,
    getAllCarrierRequestsIsLoading: false,
    getAllCarrierRequestsErrorMessage: null
  })

const getAllCarrierRequestsFailure = (state, { errorMessage }) =>
  state.merge({
    carrierRequests: null,
    getAllCarrierRequestsIsLoading: false,
    getAllCarrierRequestsErrorMessage: errorMessage
  })

// getNumberOfCarrierRequests
const getNumberOfCarrierRequestsLoading = state =>
  state.merge({
    getNumberOfCarrierRequestsIsLoading: true,
    getNumberOfCarrierRequestsErrorMessage: ''
  })

const getNumberOfCarrierRequestsSuccess = (state, { numberOfCarrierRequests }) =>
  state.merge({
    numberOfCarrierRequests: numberOfCarrierRequests,
    getNumberOfCarrierRequestsIsLoading: false,
    getNumberOfCarrierRequestsErrorMessage: null
  })

const getNumberOfCarrierRequestsFailure = (state, { errorMessage }) =>
  state.merge({
    numberOfCarrierRequests: null,
    getNumberOfCarrierRequestsIsLoading: false,
    getNumberOfCarrierRequestsErrorMessage: errorMessage
  })

// getCarrierRequestPickUpTimes
const getCarrierRequestPickUpTimesLoading = state =>
  state.merge({
    getCarrierRequestPickUpTimesIsLoading: true,
    getCarrierRequestPickUpTimesErrorMessage: ''
  })

const getCarrierRequestPickUpTimesSuccess = (state, { carrierRequestsPickUpTimes }) =>
  state.merge({
    carrierRequestsPickUpTimes,
    getCarrierRequestPickUpTimesIsLoading: false,
    getCarrierRequestPickUpTimesErrorMessage: null
  })

const getCarrierRequestPickUpTimesFailure = (state, { errorMessage }) =>
  state.merge({
    carrierRequestsPickUpTimes: null,
    getCarrierRequestPickUpTimesIsLoading: false,
    getCarrierRequestPickUpTimesErrorMessage: errorMessage
  })

const openDeleteCarrierRequest = (state, { request }) =>
  state.merge({
    removingRequest: request
  })

const closeDeleteCarrierRequest = state =>
  state.merge({
    removingRequest: null
  })

// createCarrierRequest

const createCarrierRequestLoading = state =>
  state.merge({
    createCarrierRequestIsLoading: true,
    createCarrierRequestErrorMessage: null
  })

const createCarrierRequestSuccess = (state, { carrierRequest }) =>
  state.merge({
    creatingCarrierRequest: null,
    createCarrierRequestIsLoading: false,
    createCarrierRequestErrorMessage: null
  })

const createCarrierRequestFailure = (state, { errorMessage }) =>
  state.merge({
    creatingCarrierRequest: null,
    createCarrierRequestIsLoading: false,
    createCarrierRequestErrorMessage: errorMessage
  })

// deleteCarrierRequest
const deleteCarrierRequestLoading = state =>
  state.merge({
    deleteCarrierRequestIsLoading: true,
    deleteCarrierRequestErrorMesage: null
  })

const deleteCarrierRequestSuccess = state =>
  state.merge({
    deleteCarrierRequestIsLoading: false,
    deleteCarrierRequestErrorMesage: null
  })

const deleteCarrierRequestFailure = (state, { errorMessage }) =>
  state.merge({
    deleteCarrierRequestIsLoading: false,
    deleteCarrierRequestErrorMesage: errorMessage
  })

// carrierRequests socket
const updateCarrierRequestsWithSocketCarrierRequest = (state, { payload }) => {
  const { socketCarrierRequest } = payload
  return socketCarrierRequest.reduce((newState, updatingCarrierRequest) => {
    if (updatingCarrierRequest.deletedAt) {
      return newState.merge({
        carrierRequests: (newState.get('carrierRequests') || new List()).filter(carrierRequest => carrierRequest.get('id') !== updatingCarrierRequest.id)
      })
    }

    if (updatingCarrierRequest.status && updatingCarrierRequest.status.toUpperCase() === 'SCHEDULED') {
      return newState.merge({
        carrierRequests: (newState.get('carrierRequests') || new List()).filter(carrierRequest => carrierRequest.get('id') !== updatingCarrierRequest.id)
      })
    }

    const carrierRequestExists = (newState.get('carrierRequests') || new List()).find(carrierRequest => carrierRequest.get('id') === updatingCarrierRequest.id)
    if (!carrierRequestExists) {
      return newState.merge({
        carrierRequests: (newState.get('carrierRequests') || new List()).push(fromJS(updatingCarrierRequest))
      })
    } else {
      return newState.merge({
        carrierRequests: (newState.get('carrierRequests') || new List()).map(
          carrierRequest => carrierRequest.get('id') !== updatingCarrierRequest.id ? carrierRequest : carrierRequest.merge(updatingCarrierRequest)
        )
      })
    }
  }, state)
}

const updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide = (state, { payload }) => {
  const { socketCarrierRequest } = payload

  return socketCarrierRequest.reduce((newState, updatingCarrierRequest) => {
    const carrierRequestExists = (newState.get('carrierRequests') || new List()).find(carrierRequest => carrierRequest.get('id') === updatingCarrierRequest.id)
    if (carrierRequestExists) {
      return newState.merge({
        carrierRequests: (newState.get('carrierRequests') || new List()).map(
          carrierRequest => carrierRequest.get('id') !== updatingCarrierRequest.id ? carrierRequest : carrierRequest.merge(updatingCarrierRequest)
        )
      })
    }

    return newState.merge({
      carrierRequests: [updatingCarrierRequest, ...newState.get('carrierRequests').toJS()]
    })
  }, state)
}

const updateCountCarrierRequestsWithSocketCountCarrierRequests = (state, { payload: { numberOfCarrierRequests } }) => {
  return state.merge({
    numberOfCarrierRequests: numberOfCarrierRequests
  })
}

const updateCreatingCarrierRequest = (state, { payload }) => {
  return state.merge({
    creatingCarrierRequest: state.get('creatingCarrierRequest')
      ? state.get('creatingCarrierRequest').merge({
        ...payload
      }) : payload
  })
}

const closeUpsertCarrierRequest = state =>
  state.merge({
    isUpsertCarrierRequestVisible: false,
    openEditCarrierRequestIsLoading: false,
    editingCarrierRequestTab: 0,
    editingCarrierRequest: null
  })

const resetCreatingCarrierRequest = state => {
  return state.merge({
    creatingCarrierRequest: null
  })
}

const loginSuccess = (state, { user }) => {
  let creatingCarrierRequest = state.get('creatingCarrierRequest')
  if (creatingCarrierRequest) {
    creatingCarrierRequest = creatingCarrierRequest.merge({
      carrierId: user.carrierId,
      driverId: user.driverId,
      phone: user.phone,
      email: user.email,
      prefContactMethod: user.prefContactMethod
    })
  }

  return state.merge({ creatingCarrierRequest })
}

// update carrierRequest
const updateCarrierRequestLoading = state =>
  state.merge({
    updateCarrierRequestIsLoading: true,
    updateCarrierRequestErrorMessage: null
  })

const updateCarrierRequestSuccess = (state, { updatedCarrierRequest }) => {
  if (updatedCarrierRequest.status.toUpperCase() === 'SCHEDULED') {
    return state.merge({
      carrierRequests: (state.get('carrierRequests') || new List()).filter(carrierRequest => carrierRequest.get('id') !== updatedCarrierRequest.id),
      updateCarrierRequestIsLoading: false,
      updateCarrierRequestErrorMessage: null
    })
  }

  return state.merge({
    carrierRequests: (state.get('carrierRequests') || new List()).map(
      carrierRequest => carrierRequest.get('id') !== updatedCarrierRequest.id ? carrierRequest : carrierRequest.merge(updatedCarrierRequest)
    ),
    updateCarrierRequestIsLoading: false,
    updateCarrierRequestErrorMessage: null
  })
}

const updateCarrierRequestFailure = (state, { errorMessage }) =>
  state.merge({
    updateCarrierRequestIsLoading: false,
    updateCarrierRequestErrorMessage: errorMessage
  })

// Search
const onSearchChange = (state, { payload }) => {
  return state.merge({
    ...payload
  })
}

// Add PO
const addPoIsLoading = state => {
  return state.merge({
    addPoIsLoading: true
  })
}
const addPoSuccess = (state, { payload }) => {
  return state.merge({
    addPoIsLoading: false,
    addPoSuccess: payload
  })
}
const addPoFailure = (state, { errorMessage }) => {
  return state.merge({
    addPoIsLoading: false,
    addPoFailure: errorMessage
  })
}
const addPoReset = state => {
  return state.merge({
    addPoIsLoading: false,
    addPoFailure: null,
    addPoSuccess: null
  })
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CarrierRequests.ON_REQUEST_SEARCH_CHANGE]: onSearchChange,
  [CarrierRequests.GET_ALL_CARRIER_REQUESTS_LOADING]: getAllCarrierRequestsLoading,
  [CarrierRequests.GET_ALL_CARRIER_REQUESTS_SUCCESS]: getAllCarrierRequestsSuccess,
  [CarrierRequests.GET_ALL_CARRIER_REQUESTS_FAILURE]: getAllCarrierRequestsFailure,
  [CarrierRequests.GET_NUMBER_OF_CARRIER_REQUESTS_LOADING]: getNumberOfCarrierRequestsLoading,
  [CarrierRequests.GET_NUMBER_OF_CARRIER_REQUESTS_SUCCESS]: getNumberOfCarrierRequestsSuccess,
  [CarrierRequests.GET_NUMBER_OF_CARRIER_REQUESTS_FAILURE]: getNumberOfCarrierRequestsFailure,
  [CarrierRequests.GET_CARRIER_REQUEST_PICK_UP_TIMES_LOADING]: getCarrierRequestPickUpTimesLoading,
  [CarrierRequests.GET_CARRIER_REQUEST_PICK_UP_TIMES_SUCCESS]: getCarrierRequestPickUpTimesSuccess,
  [CarrierRequests.GET_CARRIER_REQUEST_PICK_UP_TIMES_FAILURE]: getCarrierRequestPickUpTimesFailure,
  [CarrierRequests.CREATE_CARRIER_REQUEST_LOADING]: createCarrierRequestLoading,
  [CarrierRequests.CREATE_CARRIER_REQUEST_SUCCESS]: createCarrierRequestSuccess,
  [CarrierRequests.CREATE_CARRIER_REQUEST_FAILURE]: createCarrierRequestFailure,
  [CarrierRequests.OPEN_DELETE_CARRIER_REQUEST]: openDeleteCarrierRequest,
  [CarrierRequests.CLOSE_DELETE_CARRIER_REQUEST]: closeDeleteCarrierRequest,
  [CarrierRequests.DELETE_CARRIER_REQUEST_LOADING]: deleteCarrierRequestLoading,
  [CarrierRequests.DELETE_CARRIER_REQUEST_SUCCESS]: deleteCarrierRequestSuccess,
  [CarrierRequests.DELETE_CARRIER_REQUEST_FAILURE]: deleteCarrierRequestFailure,
  [CarrierRequests.UPDATE_CARRIER_REQUESTS_WITH_SOCKET_CARRIER_REQUEST]: updateCarrierRequestsWithSocketCarrierRequest,
  [CarrierRequests.UPDATE_CARRIER_REQUESTS_WITH_SOCKET_CARRIER_REQUEST_ON_CARRIER_SIDE]: updateCarrierRequestsWithSocketCarrierRequestOnCarrierSide,
  [CarrierRequests.UPDATE_COUNT_CARRIER_REQUESTS_WITH_SOCKET_COUNT_CARRIER_REQUESTS]: updateCountCarrierRequestsWithSocketCountCarrierRequests,
  [CarrierRequests.UPDATE_CREATING_CARRIER_REQUEST]: updateCreatingCarrierRequest,
  [CarrierRequests.RESET_CREATING_CARRIER_REQUEST]: resetCreatingCarrierRequest,
  [carrierLoginFullfiled]: loginSuccess,
  [CarrierRequests.UPDATE_CARRIER_REQUEST_LOADING]: updateCarrierRequestLoading,
  [CarrierRequests.UPDATE_CARRIER_REQUEST_SUCCESS]: updateCarrierRequestSuccess,
  [CarrierRequests.UPDATE_CARRIER_REQUEST_FAILURE]: updateCarrierRequestFailure,
  [CarrierRequests.ADD_PO_IS_LOADING]: addPoIsLoading,
  [CarrierRequests.ADD_PO_SUCCESS]: addPoSuccess,
  [CarrierRequests.ADD_PO_FAILURE]: addPoFailure,
  [CarrierRequests.ADD_PO_RESET]: addPoReset,
  [CarrierRequests.CLOSE_UPSERT_CARRIER_REQUEST]: closeUpsertCarrierRequest
})
