import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { GridCol, GridRow } from '../../../styled/Grids'
import { DefaultText, PrimaryText, Value } from '../../../styled/Texts'
import { Container } from '../../../styled/Containers'
import { getOriginDestinationLabel, getVendorCustomerLabel } from '../../../utils/common'

class CustomerInformation extends Component {
  render () {
    const {
      customer,
      destination,
      references,
      noReference,
      isOutbound
    } = this.props

    return (
      <Container>
        <GridRow>
          <GridCol>
            <PrimaryText uppercase>{getVendorCustomerLabel(isOutbound)}</PrimaryText>
            <p>
              <DefaultText block spaced>
                {!customer ? 'N/A' : customer.name}
              </DefaultText>
            </p>
          </GridCol>
          <GridCol padded="left">
            <PrimaryText uppercase>{getOriginDestinationLabel(isOutbound)}</PrimaryText>
            <p>
              {
                destination ? (
                  <Fragment>
                    <DefaultText block spaced bold>
                      {destination.name}
                    </DefaultText>
                    <DefaultText block>
                      {destination.address1}
                    </DefaultText>
                    <DefaultText>
                      {destination.city}, {destination.state}
                    </DefaultText>
                  </Fragment>
                ) : (
                  <DefaultText spaced>N/A</DefaultText>
                )
              }
            </p>
          </GridCol>
          {
            !noReference ? (
              <GridCol padded="left">
                <PrimaryText uppercase>References</PrimaryText>
                {
                  references ? references.map((ref, index) => (
                    <p key={index} className={'content gray'}>
                      <DefaultText spaced bold>{ref.type}</DefaultText>: <DefaultText spaced bold>{ref.val}</DefaultText>
                    </p>
                  )) : (
                    <Value>N/A</Value>
                  )
                }
              </GridCol>
            ) : null
          }
        </GridRow>
      </Container>
    )
  }
}

CustomerInformation.propTypes = {
  customer: PropTypes.object,
  destination: PropTypes.object,
  references: PropTypes.array,
  noReference: PropTypes.bool,
  isOutbound: PropTypes.bool
}

export default CustomerInformation
