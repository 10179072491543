import 'react-notifications/lib/notifications.css'

import { Routes, Route, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { ButtonPrimary } from '../../styled/Buttons'
import AppointmentRequests from './AppointmentRequests'
import CarrierLogin from './CarrierLogin'
import ContactUs from './ContactUs'
import RequestWizard from './RequestWizard'
import brandImage from '../../assets/images/taylor-farms-logo.svg'
import headphoneIcon from '../../assets/images/headphone-icon.png'
import { DefaultTextCss } from '../../styled/Texts'
import { carrierLoginOpened, carrierLogoutFulfilled, selectIsCarrierUserLoggedIn } from '../../carrier-app/carrier-app-slice'
import CreateCarrierUser from './CreateCarrierUser'
import UpdateCarrierUser from './UpdateCarrierUser'

const CarrierContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const Header = styled.div`
  border-top: 5px solid ${props => props.theme.colors.primaryAccent};
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.07);
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
`

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
`

const NavBrandImage = styled.img`
  height: 60px;
  margin-left: 40px;
  margin-top: 7px;
`

const NavbarNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const NavLinks = styled(NavLink)`
  margin: 0 40px;
  color: ${props => props.theme.labelColor};
  text-decoration: none;
  font-size: 12px;
  &.active {
    color: ${props => props.theme.colors.primaryAccent};
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  background-color: ${props => props.theme.background};
  margin-top: 80px;
  min-height: calc(100% - 150px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`

const Footer = styled.div`
  display: flex;
  background-color: #fff;
  padding: 8px 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 70px;
`

const Copyright = styled.div`
  font-size: 12px;
  text-align: center;
  font-style: italic;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.default};
`

const TermsAndConditions = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.colors.default};
`

const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const CopyrightContainer = styled(HelpContainer)`
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`

const HelpIcon = styled.img`
  width: 30px;
  margin-right: 18px;
`

const HelpText = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.default};
  text-transform: uppercase;
`

const PhoneLink = styled.a`
  ${DefaultTextCss}
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
  }
`

const TermsLink = styled(TermsAndConditions)`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
  }
`

const year = (new Date()).getFullYear()

const getActiveClassName = (navData) => navData.isActive ? 'active' : ''

const App = ({
  logout,
  showCarrierLogin,
  loggedIn
}) => (
  <CarrierContainer>
    <Header>
      <Navbar>
        <NavBrandImage src={brandImage}/>
        <NavbarNav>
          <NavLinks to={'/carrier/contactUs'} className={getActiveClassName}>Contact Us</NavLinks>
          <NavLinks to={'/carrier'} className={getActiveClassName} end>New Request</NavLinks>
          {loggedIn &&
            <NavLinks to={'/carrier/appointmentRequests'} className={getActiveClassName}>Appointments Requested</NavLinks>}
          <NavLinks className={getActiveClassName}
                    to={loggedIn ? '/carrier/account' : '/carrier/account/create'}>{loggedIn ? 'Update Account' : 'Create Account'}</NavLinks>
          <ButtonPrimary onClick={loggedIn ? logout : showCarrierLogin}>{loggedIn ? 'Logout' : 'Login'}</ButtonPrimary>
        </NavbarNav>
      </Navbar>
    </Header>
    <Content>
      <Routes>
        <Route path="/" element={<RequestWizard/>}/>
        <Route path="/account/create" element={<CreateCarrierUser />}/>
        <Route path="/account" element={<UpdateCarrierUser/>}/>
        <Route path="/contactUs" element={<ContactUs/>}/>
        <Route path="/appointmentRequests" element={<AppointmentRequests/>}/>
        <Route path="/appointmentRequests/:guid" element={<AppointmentRequests/>}/>
      </Routes>
    </Content>
    <Footer>
      <HelpContainer>
        <HelpIcon src={headphoneIcon}/>
        <HelpText>
          Need help?<br/>
          Call us for assistance: <PhoneLink href="tel:1-800-555555-5" title="Call Us">1-800-TAYLOR-FARMS</PhoneLink>
        </HelpText>
      </HelpContainer>

      <CopyrightContainer>
        <Copyright>&copy; Copyright Information, {year}</Copyright>
        <TermsLink as="a" href="about:blank" target="_blank">Terms and Conditions</TermsLink>
      </CopyrightContainer>
    </Footer>
    <CarrierLogin/>
  </CarrierContainer>
)
App.propTypes = {
  logout: PropTypes.func,
  showCarrierLogin: PropTypes.func,
  loggedIn: PropTypes.bool
}
const mapStateToProps = state => ({
  loggedIn: selectIsCarrierUserLoggedIn(state)
})

const mapDispatchToProps = dispatch => ({
  showCarrierLogin: () => dispatch(carrierLoginOpened()),
  logout: () => dispatch(carrierLogoutFulfilled())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
