import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getAppointments
  getAppointments: ['payload'],
  // The operation has started and is loading
  getAppointmentsLoading: null,
  // The appointments was successfully fetched
  getAppointmentsSuccess: ['appointments'],
  // An error occurred
  getAppointmentsFailure: ['errorMessage'],
  // update appointments
  updateAppointments: ['appointments'],

  // getAppointment
  getAppointment: ['id'],
  getAppointmentLoading: null,
  getAppointmentSuccess: ['appointment'],
  getAppointmentFailure: ['errorMessage'],

  // getAppointmentWithOrders previously called getAppointmentOrders
  getAppointmentWithOrders: ['payload'],
  getAppointmentWithOrdersLoading: null,
  getAppointmentWithOrdersSuccess: ['appointmentOrders'],
  getAppointmentWithOrdersFailure: ['errorMessage'],

  // createAppointment
  createAppointment: ['payload'],
  createAppointmentLoading: null,
  createAppointmentSuccess: ['appointment'],
  createAppointmentFailure: ['errorMessage'],

  // removeOrderFromAppointment
  removeOrderFromAppointment: ['appointmentId', 'orderId'],
  removeOrderFromAppointmentLoading: null,
  removeOrderFromAppointmentSuccess: null,
  removeOrderFromAppointmentFailure: ['errorMessage'],

  // deleteAppointment
  deleteAppointment: ['id'],
  deleteAppointmentLoading: null,
  deleteAppointmentSuccess: null,
  deleteAppointmentFailure: ['errorMessage'],

  // updateAppointment
  updateAppointment: ['payload'],
  updateAppointmentLoading: ['appointmentId'],
  updateAppointmentSuccess: ['appointment'],
  updateAppointmentFailure: ['errorMessage'],

  // getAllAppointmentStatuses
  getAllAppointmentStatuses: null,
  getAllAppointmentStatusesLoading: null,
  getAllAppointmentStatusesSuccess: ['appointmentStatuses'],
  getAllAppointmentStatusesFailure: ['errorMessage'],

  // moveAppointment
  moveAppointment: ['payload'],
  moveAppointmentLoading: null,
  moveAppointmentSuccess: ['appointment'],
  moveAppointmentFailure: ['errorMessage'],

  openCreateAppointment: null,
  openEditAppointment: ['appointment'],
  openEditAppointmentIsLoading: ['appointmentId'],
  openEditAppointmentSuccess: ['appointment'],
  openEditAppointmentFailure: ['errorMessage'],
  createFromRequest: ['request', 'apptData'],
  closeUpsertAppointment: null,
  updateAppointmentWithSocketAppointment: ['data'],
  changeEditAppointmentTab: ['tab'],
  openDeleteAppointment: ['appointment'],
  closeDeleteAppointment: null,

  getAppointmentsForWarehouse: null,
  getAppointmentsForWarehouseLoading: null,
  getAppointmentsForWarehouseSuccess: null,
  getAppointmentsForWarehouseFailure: ['errorMessage'],
  setAppointments: ['appointments'],

  showAppointment: ['appointment'],

  // clearRequest
  clearRequest: ['id'],
  clearRequestLoading: null,
  clearRequestSuccess: ['appointment'],
  clearRequestFailure: ['errorMessage'],

  // counts
  mergeAppointmentCounts: ['payload'],

  // flag recalculate duration
  setRecalculateDurationFlag: ['flag'],

  // Search
  searchAppointments: ['payload'],
  onAppointmentSearchChange: ['payload'],

  setEditingAppointmentIssues: ['payload'],
  editingAppointmentIssuesReset: null,
  editingAppointmentSuggestionsReset: null
})

export const AppointmentsTypes = Types
export default Creators
