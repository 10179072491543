import { Map } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  users: [],
  user: null,
  allowIssuesUser: null,
  token: null,
  roles: null,
  accounts: null,
  getUsersIsLoading: false,
  getUsersErrorMessage: null,
  createUserIsLoading: false,
  createUserErrorMessage: null,
  updateUserIsLoading: false,
  updateUserErrorMessage: null,
  loginIsLoading: false,
  loginErrorMessage: null,
  changeUserActivationIsLoading: null,
  changeUserActivationErrorMessage: null,
  deleteUserIsLoading: null,
  deleteUserErrorMessage: null,
  isSystemSettingsOpen: false
})
