import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { ReportsTypes } from './actions'

// getReport
export const getReportLoading = state =>
  state.merge({
    isLoading: true,
    errorMessage: null
  })

export const getReportSuccess = (state, { data }) =>
  state.merge({
    data,
    isLoading: false,
    errorMessage: null
  })

export const getReportFailure = (state, { errorMessage }) =>
  state.merge({
    data: null,
    isLoading: false,
    errorMessage
  })

export const reducer = createReducer(INITIAL_STATE, {
  [ReportsTypes.GET_REPORT_LOADING]: getReportLoading,
  [ReportsTypes.GET_REPORT_SUCCESS]: getReportSuccess,
  [ReportsTypes.GET_REPORT_FAILURE]: getReportFailure
})
