import { Map, List } from 'immutable'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = Map({
  orders: List([]),
  pages: 0,
  order: null,

  getOrdersIsLoading: false,
  getOrdersErrorMessage: null,

  isOrderDetailsModalVisible: false,
  orderDetails: null,

  searchText: '',
  customerPurchaseOrder: '',
  isScheduledSelect: null,
  ordersStatusSelect: null,
  customerSelect: null,
  deliveryDateSelect: null,
  destinationSelect: null,
  currentPage: 1
})
