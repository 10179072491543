import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { StyledOrderCard } from '../styled/Cards'
import { TYPES } from './TimeTable/DropOnTableHoc'

function DraggableOrderCard (props) {
  const [{ isDragging, canDrag }, drag, preview] = useDrag({
    type: TYPES.ORDER,
    item: { ...props },
    canDrag: () => {
      const { isScheduled, isDisabled } = props
      return !(isScheduled || isDisabled)
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <StyledOrderCard drag={drag} {...props} isDragging={isDragging} canDrag={canDrag} />
  )
}

DraggableOrderCard.propTypes = {
  order: PropTypes.object,
  isScheduled: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default DraggableOrderCard
