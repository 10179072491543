import {
  call,
  put,
  takeLatest,
  fork,
  select
} from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'

import axios from '../../utils/axios'
import OrdersActions, {
  OrdersTypes
} from './actions'
import {
  token as getToken,
  getUserAccounts
} from '../users/selectors'
import config from '../../config'
import { selectAppointmentType } from '../../app/app-slice'
import { setManyOrders } from '../../orders/orders-slice'

const baseUrl = '/orders'

export const OrderSchema = new schema.Entity('orders')

/* Sagas */

function * getOrders ({
  payload
}) {
  yield put(OrdersActions.getOrdersLoading())
  try {
    const token = yield select(getToken)
    const accounts = yield select(getUserAccounts)
    const appointmentType = yield select(selectAppointmentType)

    const {
      searchText,
      customerPurchaseOrder,
      isOpen,
      requiredShipDate,
      deliveryDate,
      deliveryDateFrom,
      deliveryDateTo,
      customerId,
      orderStatusId,
      destinationId,
      page,
      include
    } = payload

    const urlPrefix = isOpen ? `/open${baseUrl}` : baseUrl
    let url = `${urlPrefix}?`

    if (searchText) {
      if (searchText.indexOf(',') > -1) {
        const parts = searchText.split(',').map(part => part.trim()).filter(part => part.length > 0)
        url += `&primaryRefValue=${parts.join('&primaryRefValue=')}`
      } else {
        url += `&primaryRefValue=${searchText}`
      }
    }
    if (customerPurchaseOrder) {
      if (customerPurchaseOrder.indexOf(',') > -1) {
        const parts = customerPurchaseOrder.split(',').map(part => part.trim()).filter(part => part.length > 0)
        url += `&otherRefs=${parts.join('&otherRefs=')}`
      } else {
        url += `&otherRefs=${customerPurchaseOrder}`
      }
    }
    if (appointmentType && appointmentType === 1) {
      url += '&primaryRefName=Purchase Order'
    }
    if (appointmentType && appointmentType === 2) {
      url += '&primaryRefName=Sales Order'
    }
    if (deliveryDate) {
      url += `&deliveryDate=${deliveryDate}`
    }
    if (requiredShipDate) {
      url += `&requiredShipDate=${requiredShipDate}`
    }
    if (deliveryDateFrom && deliveryDateTo) {
      url += `&deliveryDateFrom=${deliveryDateFrom}&deliveryDateTo=${deliveryDateTo}`
    }
    if (customerId) {
      url += `&customerId=${customerId}`
    }
    if (orderStatusId) {
      url += `&orderStatusId=${orderStatusId}`
    }
    if (destinationId) {
      url += `&destinationId=${destinationId}`
    }
    if (page) {
      url += `&page=${page}`
    }
    if (!isOpen && accounts && accounts.size) {
      accounts.forEach(account => {
        url += `&accountId=${account.get('id')}`
      })
    }
    if (include) {
      url += `&_include=${include}`
    }

    const {
      data
    } = yield call(axios.get, url, {
      headers: {
        Authorization: isOpen ? `Key ${config.API_KEY}` : `Bearer ${token}`
      }
    })

    const normalizedData = normalize(data.orders, [OrderSchema])
    if (normalizedData.entities.orders) {
      yield put(setManyOrders(Object.values(normalizedData.entities.orders)))
    }

    if (page && page > 1) {
      const currentOrders = yield select(state => state.orders.get('orders'))
      if (currentOrders) {
        yield put(
          OrdersActions.getOrdersSuccess(
            [...currentOrders.toArray(), ...data.orders],
            data.pages
          )
        )
      } else {
        yield put(OrdersActions.getOrdersSuccess(data.orders, data.pages))
      }
    } else {
      yield put(OrdersActions.getOrdersSuccess(data.orders, data.pages))
    }
  } catch (e) {
    yield put(OrdersActions.getOrdersFailure(e))
  }
}

function * revertOrder ({ id }) {
  try {
    const token = yield select(getToken)
    const {
      data
    } = yield call(axios.put, `${baseUrl}/${id}/revert`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const orderDetails = {
      order: data
    }

    yield put(OrdersActions.setOrderDetails(orderDetails))
  } catch (e) {
    yield put(OrdersActions.revertOrderFailure(e.errorMessage))
  }
}

// Watchers
function * getOrdersWatcher () {
  yield takeLatest(OrdersTypes.GET_ORDERS, getOrders)
}

function * revertOrderWatcher () {
  yield takeLatest(OrdersTypes.REVERT_ORDER, revertOrder)
}

export default function * root () {
  yield fork(getOrdersWatcher)
  yield fork(revertOrderWatcher)
}
