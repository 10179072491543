import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { ButtonIcon } from '../styled/Buttons'
import { GridCell, GridRow } from '../styled/Grids'
import { SingleLineText } from '../styled/Texts'
import PlusIcon from './icons/Plus'
import MinusIcon from './icons/Minus'

class ZoomSelector extends Component {
  onMinusClick = () => {
    const { onChange, level, step } = this.props
    if (onChange) {
      onChange(level - step)
    }
  }

  onPlusClick = () => {
    const { onChange, level, step } = this.props
    if (onChange) {
      onChange(level + step)
    }
  }

  render () {
    const { level, minLevel, maxLevel, step } = this.props

    return (
      <GridRow>
        <GridCell centered>
          <SingleLineText>ZOOM: {level}%</SingleLineText>
        </GridCell>
        <GridCell>
          <ButtonIcon spaced="left" onClick={this.onMinusClick} disabled={level - step < minLevel}>
            <MinusIcon />
          </ButtonIcon>
        </GridCell>
        <GridCell>
          <ButtonIcon spaced="left" onClick={this.onPlusClick} disabled={level + step > maxLevel}>
            <PlusIcon />
          </ButtonIcon>
        </GridCell>
      </GridRow>
    )
  }
}

ZoomSelector.propTypes = {
  onChange: PropTypes.func,
  minLevel: PropTypes.number,
  maxLevel: PropTypes.number,
  level: PropTypes.number,
  step: PropTypes.number
}

export default ZoomSelector
