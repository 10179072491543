import React, { Component } from 'react'
import ReactSelect from 'react-select'

import PropTypes from 'prop-types'

import { theme } from '../themes'
import OptionSelect from './OptionSelect'
import SelectValueContainer from './SelectValueContainer'

const customStyles = error => ({
  container: base => ({ ...base, border: 'none', boxShadow: 'none', outline: 'none' }),
  control: base => ({
    ...base,
    borderRadius: 2,
    borderColor: error ? '#d9534f' : theme.darkGray,
    boxShadow: 'none',
    outline: 'none',
    width: '220px'
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? theme.colors.important : null,
    color: isSelected || isFocused ? theme.contrastTextColor : null
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: base => ({ ...base, color: theme.darkGray })
})

class AccountSelect extends Component {
  onChangeAccount = selections => {
    const { options, onChange } = this.props

    // TODO unify data structure on both list and form so we avoid using both id and value
    const selected = options
      .filter(option => selections.some(selection => option.value === selection.value))
      .map(option => ({ ...option, id: option.value }))

    onChange(selected)
  }

  render () {
    const { options, value, className, error, styles, isClearable } = this.props
    const selectedValue = value && options.filter(option => value.some(val => (val.id || val.value) === option.value))

    return (
      <ReactSelect
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        className={className}
        isMulti
        components={{ Option: OptionSelect, ValueContainer: SelectValueContainer }}
        isClearable={!!isClearable}
        value={selectedValue}
        placeholder={'Assigned Locations'}
        styles={styles ? styles(error) : customStyles(error)}
        onChange={this.onChangeAccount}
        options={options}
      />
    )
  }
}

AccountSelect.propTypes = {
  options: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.any,
  styles: PropTypes.any,
  isClearable: PropTypes.bool
}

export default AccountSelect
