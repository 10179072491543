import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Loading } from '../styled/Loading'
import { AnimatedLoadingIcon } from '../components/icons/Loading'
import { Title } from '../styled/Texts'
import { getAppointmentsForDoorsIsLoading } from '../modules/doors/selectors'
import { getBootstrapDataIsLoading } from '../modules/feeds/selectors'
import { getUsersIsLoading } from '../modules/users/selectors'

class ProgressLoader extends Component {
  render () {
    const { isOpen } = this.props
    return (
      <Loading isOpen={isOpen}>
        <AnimatedLoadingIcon />
        <Title>LOADING...</Title>
      </Loading>
    )
  }
}

ProgressLoader.propTypes = {
  isOpen: PropTypes.bool

}

const mapStateToProps = state => ({
  isOpen: getAppointmentsForDoorsIsLoading(state) ||
    getBootstrapDataIsLoading(state) ||
    getUsersIsLoading(state)
})

export default connect(mapStateToProps)(ProgressLoader)
