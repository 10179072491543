import { createSelector } from 'reselect'
import { createGetSettingsByBuildingId } from '../buildings/selectors'
import { getStateSettings } from '../common/selectors'

export const getAllSettings = createSelector(
  getStateSettings,
  settings => settings.get('settings') ? settings.get('settings').toJS() : null
)

export const createGetInventoryCalculationSetting = createSelector(
  createGetSettingsByBuildingId,
  getSettingsByBuildingId => buildingId => {
    const inventoryCalculationSetting = getSettingsByBuildingId(buildingId)
      .filter(setting => setting.getIn(['setting', 'name']) === 'inventoryCalculation')
    return inventoryCalculationSetting.size ? inventoryCalculationSetting.get(0).get('value') === '1' : false
  }
)
