import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SecondaryContainer } from '../../../styled/Containers'
import { TabLink, TabList } from '../../../styled/Tabs'
import { GridCol } from '../../../styled/Grids'
import OrdersIcon from '../../../components/icons/Orders'
import { ContrastText } from '../../../styled/Texts'
import AppointmentsIcon from '../../../components/icons/Appointments'
import RequestsIcon from '../../../components/icons/Requests'
import ReportsIcon from '../../../components/icons/Reports'
import { currentTheme } from '../../../themes'
import { numberOfCarrierRequests } from '../../../modules/carrierRequests/selectors'
import withParams from '../../../utils/WithParams'

class Menu extends Component {
  render () {
    const { numberOfRequests } = this.props
    const { pathname } = this.props.location
    const requestCount =
      numberOfRequests &&
      numberOfRequests.get &&
      (numberOfRequests.get('allCarrierRequests') || 0)
    const isOrderTab = pathname.indexOf('/orders') > -1
    const isAppointmentTab = pathname.indexOf('/appointments') > -1
    const isRequestsTab = pathname.indexOf('/requests') > -1
    const isReportsTab = pathname.indexOf('/reports') > -1
    const defaultColor = currentTheme.colors.primary
    const selectedColor = currentTheme.colors.secondary

    return (
      <SecondaryContainer>
        <TabList vertical="true" padded="true" flex="true" grow="true" centered="true">
          <TabLink to="/scheduler/orders" className={(navData) => navData.isActive ? 'selected' : '' }>
            <GridCol noMargin spaced centered>
              <OrdersIcon color={isOrderTab ? selectedColor : defaultColor}/>
              <ContrastText bold>Orders</ContrastText>
            </GridCol>
          </TabLink>
          <TabLink to="/scheduler/appointments">
            <GridCol noMargin spaced centered>
              <AppointmentsIcon color={isAppointmentTab ? selectedColor : defaultColor}/>
              <ContrastText bold>Appointments</ContrastText>
            </GridCol>
          </TabLink>
          <TabLink to="/scheduler/requests">
            <GridCol noMargin spaced centered>
              <RequestsIcon color={isRequestsTab ? selectedColor : defaultColor}/>
              <ContrastText bold>
                Requests{' '}
                <span className={requestCount ? 'counter' : ''}>
                  ({requestCount || 0})
                </span>
              </ContrastText>
            </GridCol>
          </TabLink>
          <TabLink to="/scheduler/reports">
            <GridCol noMargin spaced centered>
              <ReportsIcon color={isReportsTab ? selectedColor : defaultColor}/>
              <ContrastText bold>Reports</ContrastText>
            </GridCol>
          </TabLink>
        </TabList>
      </SecondaryContainer>
    )
  }
}

Menu.propTypes = {
  numberOfRequests: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  numberOfRequests: numberOfCarrierRequests(state)
})

export default connect(mapStateToProps)(withParams(Menu))
