import React from 'react'
import moment from 'moment'

import PropTypes from 'prop-types'

import { ButtonIcon } from '../styled/Buttons'
import { Container } from '../styled/Containers'
import { GridRow } from '../styled/Grids'
import { Header } from '../styled/Headers'
import { Section } from '../styled/Boxes'
import { Title, DefaultText } from '../styled/Texts'
import { theme } from '../themes/taylor-farms'
import ScheduleIcon from './icons/Schedule'
import WarningIcon from './icons/Warning'
import DeleteIcon from './icons/Delete'
import { requestStatusesMap } from '../utils/time'

const DEFAULT_STATUS = 'pending'

const getDate = appointment => {
  const date = appointment.get('date')
  if (date) {
    return moment(date).format('dddd, MMMM D')
  }
}

const getIconForRequestStatus = name => {
  const icons = {
    AppointmentIcon: <ScheduleIcon color={theme.colors.secondary} />,
    ModificationIcon: '',
    CancellationIcon: '',
    DeleteIcon: <DeleteIcon color={theme.colors.secondary} />
  }

  switch (name) {
    case 'canceled':
    case 'cancelled':
      icons.CancellationIcon = <WarningIcon color={theme.colors.danger}/>
      break
    case 'reschedule':
      icons.ModificationIcon = <WarningIcon />
      break
    case 'pending':
    case 'draft':
    default:
    // default
  }

  return icons
}

const getHeader = carrierRequest => {
  if (carrierRequest.get('status') === requestStatusesMap.canceled) {
    return 'Cancellation request'
  } else if (carrierRequest.get('status') === requestStatusesMap.reschedule) {
    return 'Reschedule requested '
  } else {
    return getDate(carrierRequest)
  }
}

const getRescheduleTime = (carrierRequest, timezone) => {
  if (
    carrierRequest.get('rescheduleTimeSuggestion') &&
    carrierRequest.get('status') === requestStatusesMap.reschedule
  ) {
    return (
      carrierRequest &&
      moment(carrierRequest.get('rescheduleTimeSuggestion'))
        .tz(timezone)
        .format('MM.DD.YYYY - LT')
    )
  }
  if (!carrierRequest.get('rescheduleTimeSuggestion')) {
    return <div />
  }
}

const getRequestStatus = request => {
  const validRequestStatuses = [
    requestStatusesMap.pending,
    requestStatusesMap.scheduled,
    requestStatusesMap.reschedule,
    requestStatusesMap.canceled
  ]
  return (
    validRequestStatuses.find(as => as === request.get('status')) ||
    DEFAULT_STATUS
  )
}
const RequestCard = ({
  carrierRequest,
  onClick,
  onCreate,
  onDelete,
  className,
  hide,
  timezone,
  drag
}) => {
  const carrierRequestOrders = carrierRequest.getIn(
    ['carrierRequestOrders'],
    null
  )
  const carrierRequestOrder = carrierRequestOrders
    ? carrierRequestOrders.first()
    : null
  const { AppointmentIcon, CancellationIcon, ModificationIcon, DeleteIcon } = getIconForRequestStatus(getRequestStatus(carrierRequest))
  return (
    <Container ref={drag} className={className}>
      <Header flex grow centered hide={hide}>
        <Container flex grow>
          <Title nomargin status={getRequestStatus(carrierRequest)}>
            {getHeader(carrierRequest)}
            {getRescheduleTime(carrierRequest, timezone)}
          </Title>
        </Container>
        <ButtonIcon title="Change appointment information" onClick={onCreate}>
          {AppointmentIcon}
        </ButtonIcon>
        <ButtonIcon title="Delete this carrier request" onClick={onDelete}>
          {DeleteIcon}
        </ButtonIcon>
        {
          CancellationIcon ? (
            <ButtonIcon title="A cancellation has been requested" onClick={onCreate}>
              {CancellationIcon}
            </ButtonIcon>
          ) : ''
        }
        {
          ModificationIcon ? (
            <ButtonIcon title="A modification has been requested" onClick={onCreate}>
              {ModificationIcon}
            </ButtonIcon>
          ) : ''
        }
      </Header>
      <Container padded="small" onClick={onClick} hide={hide}>
        <Section>
          <GridRow flex={1}>
            <DefaultText mediumWidth bold>Appointment: </DefaultText>
            <DefaultText>
              {carrierRequest.get('appointmentId') ? `#${carrierRequest.get('appointmentId')}` : 'None'}
            </DefaultText>
          </GridRow>
        </Section>
        <Section>
          <GridRow flex={1}>
            <DefaultText mediumWidth bold>Pickup Date: </DefaultText>
            <DefaultText>
              {moment(carrierRequest.get('date')).format('MM-DD-YYYY')}
            </DefaultText>
          </GridRow>
        </Section>
        <Section>
          <GridRow flex={1}>
            <DefaultText mediumWidth bold>Pickup Time: </DefaultText>
            <DefaultText>
              {moment(carrierRequest.get('timeStart'))
                .utc()
                .format('HH:mm') +
                '-' +
                moment(carrierRequest.get('timeEnd'))
                  .utc()
                  .format('HH:mm')}
            </DefaultText>
          </GridRow>
        </Section>
        <Section>
          <GridRow flex={1}>
            <DefaultText mediumWidth bold>Carrier Name: </DefaultText>
            <DefaultText fixedWidth>
              {carrierRequest.get('carrier')
                ? carrierRequest.get('carrier').name
                : ''}
            </DefaultText>
          </GridRow>
        </Section>
        <Section>
          <GridRow flex={1}>
            <DefaultText mediumWidth bold>PO: </DefaultText>
            <DefaultText>
              {carrierRequestOrder
                ? carrierRequestOrder.get('poNumber')
                : 'N/A'}
            </DefaultText>
          </GridRow>
        </Section>
      </Container>
    </Container>
  )
}

RequestCard.propTypes = {
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  timezone: PropTypes.string,
  carrierRequest: PropTypes.object,
  hide: PropTypes.bool,
  onClick: PropTypes.func,
  drag: PropTypes.any
}

export default RequestCard
