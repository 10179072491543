import { EntityState as ToolkitEntityState, SerializedError } from '@reduxjs/toolkit'

export interface EntityState<T> extends ToolkitEntityState<T>{
  loading: RequestStatus
  error: SerializedError | null
}

export enum RequestStatus {
  Idle,
  Pending,
  Succeded,
  Failed
}
