import moment from 'moment-timezone'
const format = 'YYYY-MM-DD HH:mm'

const sortBySku = (previousItem, nextItem) => {
  if (Number(previousItem.get('sku')) < Number(nextItem.get('sku'))) {
    return -1
  } else if (Number(previousItem.get('sku')) > Number(nextItem.get('sku'))) {
    return 1
  } else {
    return 0
  }
}

export const sortInventoryItems = (previousItem, nextItem) => {
  if (previousItem.get('orderItem').get('remainderQty') === 0) {
    return 1
  } else if (nextItem.get('orderItem').get('remainderQty') === 0) {
    return -1
  }

  const previousItemAvailability = previousItem
    .get('inventoryIssues')
    .get('availabilityReadyTime')
  const nextItemAvailability = nextItem
    .get('inventoryIssues')
    .get('availabilityReadyTime')

  if (
    Boolean(previousItemAvailability) === false ||
    Boolean(nextItemAvailability) === false
  ) {
    if (
      Boolean(previousItemAvailability) === false &&
      Boolean(nextItemAvailability) !== false
    ) {
      return -1
    } else if (
      Boolean(previousItemAvailability) !== false &&
      Boolean(nextItemAvailability) === false
    ) {
      return 1
    } else {
      return sortBySku(previousItem, nextItem)
    }
  } else if (moment(previousItemAvailability, format).isValid()) {
    if (moment(nextItemAvailability, format).isValid()) {
      const diff = moment(nextItemAvailability, format).diff(
        moment(previousItemAvailability, format)
      )

      if (diff === 0) return sortBySku(previousItem, nextItem)

      return diff
    } else {
      return nextItemAvailability ? -1 : 1
    }
  } else {
    return nextItemAvailability === true ? sortBySku(previousItem, nextItem) : 1
  }
}
