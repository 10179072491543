import styled from 'styled-components'

export const ThSeparator = styled.th`
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  vertical-align: middle;
  background: ${props => props.theme.timeTable.daySeparator};
  text-align: left;
  padding: 8px;
`

export const ThSeparatorSticky = styled.th`
  border-bottom: 1px solid ${props => props.theme.timeTable.lines};
  vertical-align: middle;
  background: ${props => props.theme.timeTable.daySeparator};
  text-align: left;
  position: sticky;
  cursor: default;
  padding: 8px;
  left: 0;
  top: 0;
  z-index: 100;
`
