import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr'

import { getDefaultAppointmentType, getUserToken, isAdmin } from '../users/selectors'
import axios from '../../utils/axios'
import AppointmentActions from '../appointments/actions'
import BuildingsActions from '../buildings/actions'
import CarrierRequestsActions from '../carrierRequests/actions'
import FeedsActions, { FeedsTypes } from './actions'
import ReportsActions from '../reports/actions'
import SettingsActions from '../settings/actions'
import UsersActions from '../users/actions'

import { getAreasFulfilled } from '../../areas/areas-slice'
import { getDoorsFulfilled } from '../../doors/doors-slice'
import { getBuildingsFulfilled } from '../../buildings/buildings-slice'
import { getSitesFulfilled } from '../../sites/sites-slice'
import { getLocationsFulfilled } from '../../locations/locations-slice'
import { selectAppointmentType, setAppointmentType, setDefaultBuilding } from '../../app/app-slice'
import { getDoorDurationsFulfilled } from '../../doors/door-durations-slice'
import {
  getAllCarriersFulfilled,
  initCarriersUpdateSocketListener
} from '../../carriers/carriers-slice'
import {
  getAllDriversFulfilled,
  initDriversUpdateSocketListener
} from '../../drivers/drivers-slice'
import { setManyOrderStatuses } from '../../orders/order-statuses-slice'
import { doorScheduleFulfilled } from '../../door-schedule/door-schedule-slice'

const CarrierSchema = new schema.Entity('carriers')

const DoorDurationSchema = new schema.Entity('doorDurations')

const DriverSchema = new schema.Entity('drivers')

const DoorSchema = new schema.Entity('doors', {
  doorDurations: [DoorDurationSchema]
})

const AreaSchema = new schema.Entity('areas', {
  doors: [DoorSchema]
})

const BuildingSchema = new schema.Entity('buildings', {
  areas: [AreaSchema]
})

const SiteSchema = new schema.Entity('sites', {
  buildings: [BuildingSchema]
})

// Sagas
function * getBootstrapData ({ payload }) {
  yield put(CarrierRequestsActions.getNumberOfCarrierRequests())
  yield put(FeedsActions.getBootstrapDataLoading())
  try {
    const { carrierPortal, isLoggedIn } = payload

    let url = '/feeds/bootstrap'
    const token = yield select(getUserToken)
    if (carrierPortal) {
      url += `?carrierPortal=${carrierPortal}`
    }

    const { data } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (carrierPortal) {
      const normalizedSites = normalize(data.sites, [SiteSchema])
      yield put(getSitesFulfilled(Object.values(normalizedSites.entities.sites)))
      if (isLoggedIn) {
        yield put(AppointmentActions.getAllAppointmentStatuses())
        yield put(CarrierRequestsActions.getAllCarrierRequests({ carrierPortal: true }))
      }
    } else {
      const userIsAdmin = yield select(isAdmin)
      if (userIsAdmin) {
        yield put(UsersActions.getUsers())
      }
      yield put(SettingsActions.getSettings())
      yield put(BuildingsActions.getSettingsForAllBuildings())

      const normalizedSites = normalize(data.sites, [SiteSchema])
      const normalizedCarriers = normalize(data.carriers, [CarrierSchema])
      const normalizedDrivers = normalize(data.drivers, [DriverSchema])

      yield put(AppointmentActions.getAllAppointmentStatusesSuccess(data.appointmentStatuses))
      yield put(UsersActions.getRolesSuccess(data.roles))
      yield put(FeedsActions.getBootstrapDataSuccess(data))

      const currentAppointmentType = yield select(selectAppointmentType)
      if (currentAppointmentType === -1) {
        const defaultAppointmentType = yield select(getDefaultAppointmentType)
        yield put(setAppointmentType(defaultAppointmentType))
      }

      yield put(doorScheduleFulfilled(data.doorSchedules))
      yield put(setManyOrderStatuses(data.orderStatuses))
      yield put(getAllCarriersFulfilled(Object.values(normalizedCarriers.entities.carriers)))
      yield put(getAllDriversFulfilled(Object.values(normalizedDrivers.entities.drivers)))
      yield put(getSitesFulfilled(Object.values(normalizedSites.entities.sites)))
      yield put(getBuildingsFulfilled(Object.values(normalizedSites.entities.buildings)))
      yield put(getAreasFulfilled(Object.values(normalizedSites.entities.areas)))
      yield put(getDoorsFulfilled(Object.values(normalizedSites.entities.doors)))
      yield put(getDoorDurationsFulfilled(Object.values(normalizedSites.entities.doorDurations)))
      const locations = [
        ...new Set(
          Object.values(normalizedSites.entities.buildings).map(building => building.location)
        )
      ]
      yield put(getLocationsFulfilled(locations))
      yield put(initDriversUpdateSocketListener())
      yield put(initCarriersUpdateSocketListener())
      yield put(setDefaultBuilding())

      // let's update the bubbles count with the new info
      yield put(ReportsActions.updateWarnings())
    }
  } catch (e) {
    yield put(FeedsActions.getBootstrapDataFailure(e))
  }
}

// Watchers
function * getBootstrapDataWatcher () {
  yield takeLatest(FeedsTypes.GET_BOOTSTRAP_DATA, getBootstrapData)
}

export default function * root () {
  yield fork(getBootstrapDataWatcher)
}
