import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { GridCell, GridRow } from '../../styled/Grids'
import DatePicker from '../DatePicker'
import moment from 'moment-timezone'
// TODO: Remove ts-ignores after configuring external resources in typescript
// @ts-ignore: Cannot find module.
import calendarRightArrowIcon from '../../assets/images/right-date-arrow-icon.svg'
import DayTabs from '../DayTabs'
import HourFormatSwitch from '../HourFormatSwitch'
import ZoomSelector from '../ZoomSelector'
import { getAppointmentCounts } from '../../modules/appointments/selectors'
import { selectTableTimeSpan } from '../../app/app-slice'
import { createDays } from './utils'
import { Moment } from 'moment'

export interface TableHeaderProps {
  set24Format: (is24Format: boolean) => void
  setZoomLevel: (zoomLevel: number) => void
  is24Format: boolean
  isInventoryCalculationEnabled?: boolean
  zoomLevel?: number
  onDateChange: (date: Moment) => void
}

const TableHeader = (props: TableHeaderProps) => {
  const { startDate, endDate, startTime, endTime, timezone } = useSelector(selectTableTimeSpan)
  const appointmentCounts = useSelector(getAppointmentCounts)
  const {
    set24Format,
    setZoomLevel,
    is24Format,
    isInventoryCalculationEnabled,
    zoomLevel,
    onDateChange
  } = props

  const [date, setDate] = useState<Moment>()

  const days = useMemo(() => {
    return createDays(timezone).map(day => {
      let status = day.id === 0 ? 'past' : 'active'

      status = day.date.isSame(date || startDate, 'day') ? 'selected' : status

      return {
        ...day,
        status
      }
    })
  }, [startDate, timezone])

  const handleDateChange = (date: Moment) => {
    if (onDateChange) {
      onDateChange(date)
    }

    setDate(date.clone().startOf('day'))
  }

  const handleDayTabChange = (id: number) => {
    const selectedDay = days[id]
    handleDateChange(selectedDay.date)
  }

  return (
    <GridRow>
      <GridCell flex centered noMaxHeight>
        <DatePicker
          HeaderGridDatePicker
          dateFormat='dddd, MMMM Do'
          onChangeStartDate={handleDateChange}
          onChangeEndDate={handleDateChange}
          startDate={startDate ? moment(startDate).startOf('day') : null}
          endDate={endDate ? moment(endDate) : null}
          onChangeStartTime={handleDateChange}
          onChangeEndTime={handleDateChange}
          startTime={moment(startTime)}
          endTime={moment(endTime)}
          rightArrowIcon={calendarRightArrowIcon}
          showIcon={true}
        />
      </GridCell>
      <GridCell flex centered noMaxHeight>
        <DayTabs
          days={days}
          handleClick={handleDayTabChange}
          appointmentCounts={appointmentCounts}
          isInventoryCalculationEnabled={isInventoryCalculationEnabled}
        />
      </GridCell>
      <GridCell flex centered noMaxHeight>
        <HourFormatSwitch is24Format={is24Format} onChange={set24Format} />
      </GridCell>
      <GridCell flex centered noMaxHeight>
        <ZoomSelector
          level={zoomLevel}
          minLevel={25}
          maxLevel={100}
          step={25}
          onChange={setZoomLevel}
        />
      </GridCell>
    </GridRow>
  )
}

export default TableHeader
