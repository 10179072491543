import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { theme } from '../../themes/taylor-farms'

export default class ReportsIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 26 25"
        className={className}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M25.332 2.05a.67.67 0 0 0-.674.663v17.596c0 1.862-1.538 3.37-3.425 3.37H4.773c-1.893 0-3.426-1.513-3.426-3.37V1.326H21.07v17.072a.67.67 0 0 0 .674.663.67.67 0 0 0 .674-.663V.663A.67.67 0 0 0 21.744 0H.674A.67.67 0 0 0 0 .663v19.64C0 22.896 2.14 25 4.772 25h16.456C23.86 25 26 22.895 26 20.304V2.713a.662.662 0 0 0-.668-.663z"
          />
          <path
            fill="#FFF"
            d="M18.328 6c.37 0 .672-.225.672-.5s-.303-.5-.672-.5H3.672C3.302 5 3 5.225 3 5.5s.303.5.672.5h14.656zM18.333 17H3.673c-.37 0-.673.225-.673.5s.303.5.673.5h14.654c.37 0 .673-.225.673-.5s-.297-.5-.667-.5zM18.312 20H5.688c-.379 0-.688.225-.688.5s.31.5.688.5h12.624c.378 0 .688-.225.688-.5s-.31-.5-.688-.5z"
          />
          <path
            fill={color}
            d="M3 7.702v7.596c0 .386.315.702.7.702h7.6c.385 0 .7-.316.7-.702V7.702A.703.703 0 0 0 11.3 7H3.7c-.385 0-.7.316-.7.702zm1.4 6.894h6.2V8.404H4.4v6.192z"
          />
          <path
            fill="#FFF"
            d="M18.347 8h-4.694c-.36 0-.653.225-.653.5s.293.5.653.5h4.694c.36 0 .653-.225.653-.5s-.288-.5-.653-.5zM18.347 11h-4.694c-.36 0-.653.225-.653.5s.293.5.653.5h4.694c.36 0 .653-.225.653-.5s-.288-.5-.653-.5zM19 14.5c0-.275-.294-.5-.653-.5h-4.694c-.36 0-.653.225-.653.5s.293.5.653.5h4.694c.365 0 .653-.225.653-.5z"
          />
        </g>
      </svg>
    )
  }
}

ReportsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

ReportsIcon.defaultProps = {
  width: 26,
  height: 25,
  color: theme.colors.primary
}
