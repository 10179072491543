import { call, put, take, takeLatest, fork, select } from 'redux-saga/effects'

import axios from '../../utils/axios'
import CarrierRequestsActions, { CarrierRequests } from './actions'
import OrdersActions, { OrdersTypes } from '../orders/actions'
import { normalize } from 'normalizr'
import { AppointmentSchema } from '../appointments/sagas'
import { getUserToken } from '../users/selectors'
import config from '../../config'
import { getSearchAttributes, getAllCarrierRequests as selectAllCarrierRequests } from './selectors'
import { getOrders } from '../orders/selectors'
import { setManyAppointments } from '../../appointments/appointments-slice'

const baseUrl = '/carrierRequests'
const openBaseUrl = '/open/carrierRequests'

// Sagas
function * getAllCarrierRequests ({ payload }) {
  yield put(CarrierRequestsActions.getAllCarrierRequestsLoading())
  try {
    const {
      searchText,
      customerPurchaseOrder,
      carrierRequestStatus,
      carrierPortal,
      carrierSelect,
      sortByDate,
      includes,
      guid
    } = payload

    const token = yield select(getUserToken)
    const useBaseUrl = token ? baseUrl : openBaseUrl

    let url = `${useBaseUrl}?`

    if (searchText) {
      url = `${url}primaryRefValue=${searchText}`
    }

    if (customerPurchaseOrder) {
      url += `&otherRefs=${customerPurchaseOrder}`
    }

    if (sortByDate) {
      url += `&sort=updatedAt:${sortByDate}`
    }

    if (carrierRequestStatus) {
      carrierRequestStatus.split(',').forEach(status => {
        url += `&status=${status}`
      })
    }

    if (carrierSelect) {
      url += `&carrierId=${carrierSelect}`
    }

    if (carrierPortal) {
      url += `&carrierPortal=${carrierPortal}`
    }

    if (includes && includes.length > 0) {
      includes.forEach(include => {
        url += `&_include=${include}`
      })
    }

    if (guid) {
      url += `&guid=${guid}`
    }

    const {
      data
    } = yield call(axios.get, url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : `Key ${config.API_KEY}`
      }
    })

    if (carrierPortal) {
      const appointments = data.map(cr => cr.appointment)
      const normalizedData = normalize(appointments, [AppointmentSchema])
      yield put(setManyAppointments(normalizedData.entities.appointments))
    }

    yield put(CarrierRequestsActions.getAllCarrierRequestsSuccess(data))
  } catch (e) {
    yield put(CarrierRequestsActions.getAllCarrierRequestsFailure(e))
  }
}

function * getNumberOfCarrierRequests ({ payload }) {
  yield put(CarrierRequestsActions.getNumberOfCarrierRequestsLoading()) // findsecrets-ignore-line
  try {
    const token = yield select(getUserToken)
    const { data } = yield call(axios.get, `${baseUrl}/count`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(CarrierRequestsActions.getNumberOfCarrierRequestsSuccess(data.numberOfCarrierRequests))
  } catch (e) {
    yield put(CarrierRequestsActions.getNumberOfCarrierRequestsFailure(e))
  }
}

function * getCarrierRequestPickUpTimes ({ payload }) {
  yield put(CarrierRequestsActions.getCarrierRequestPickUpTimesLoading()) // findsecrets-ignore-line
  try {
    const { data } = yield call(axios.get, 'open/carrierRequestTimeRanges', {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    yield put(CarrierRequestsActions.getCarrierRequestPickUpTimesSuccess(data))
  } catch (e) {
    yield put(CarrierRequestsActions.getCarrierRequestPickUpTimesFailure(e)) // findsecrets-ignore-line
  }
}

function * createCarrierRequest ({ payload }) {
  yield put(CarrierRequestsActions.createCarrierRequestLoading())
  try {
    const {
      data
    } = yield call(axios.post, openBaseUrl, payload, {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    yield put(CarrierRequestsActions.createCarrierRequestSuccess(data))
    yield put(CarrierRequestsActions.getAllCarrierRequests({}))
  } catch (e) {
    yield put(CarrierRequestsActions.createCarrierRequestFailure(e))
  }
}

function * updateCarrierRequest ({ payload }) {
  yield put(CarrierRequestsActions.updateCarrierRequestLoading())
  const { id } = payload
  try {
    const {
      data
    } = yield call(axios.put, `${openBaseUrl}/${id}`, payload, {
      headers: {
        Authorization: `Key ${config.API_KEY}`
      }
    })
    yield put(CarrierRequestsActions.updateCarrierRequestSuccess(data))
  } catch (e) {
    yield put(CarrierRequestsActions.updateCarrierRequestFailure(e))
  }
}

function * deleteCarrierRequest ({
  id
}) {
  yield put(CarrierRequestsActions.deleteCarrierRequestLoading())
  try {
    const token = yield select(getUserToken)
    yield call(axios.delete, `${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    yield put(CarrierRequestsActions.deleteCarrierRequestSuccess())
  } catch (e) {
    yield put(CarrierRequestsActions.deleteCarrierRequestFailure(e))
  }
}

function * searchRequests ({ payload }) {
  yield put(CarrierRequestsActions.onRequestSearchChange(payload))

  const attributes = yield select(getSearchAttributes)

  yield put(CarrierRequestsActions.getAllCarrierRequests({
    ...attributes,
    includes: ['carrier', 'carrierRequestOrder']
  }))
}

function * addPo ({ payload }) {
  yield put(CarrierRequestsActions.addPoIsLoading())

  // fetch if there's carrier requests
  yield put(CarrierRequestsActions.getAllCarrierRequests({
    customerPurchaseOrder: payload
  }))

  // fetch order open
  yield put(OrdersActions.getOrders({
    customerPurchaseOrder: payload,
    isOpen: true,
    include: 'appointments'
  }))

  yield take(CarrierRequests.GET_ALL_CARRIER_REQUESTS_SUCCESS)
  yield take(OrdersTypes.GET_ORDERS_SUCCESS)

  const orders = yield select(getOrders)
  const carrierRequests = yield select(selectAllCarrierRequests)

  if (!orders.size) {
    return yield put(CarrierRequestsActions.addPoFailure({ type: 'NOT_FOUND', message: `No results found for PO: "${payload}". Try Again` }))
  }

  const deletedStatus = carrierRequests.every(c => c.get('status') === 'deleted')
  if (carrierRequests.size > 0 && !deletedStatus) {
    return yield put(CarrierRequestsActions.addPoFailure({ type: 'EXISTS', message: `The PO "${payload}" has already been requested.` }))
  }
  return yield put(CarrierRequestsActions.addPoSuccess({ PO: payload, order: orders.first() }))
}

// Watchers
function * getAllCarrierRequestsWatcher () {
  yield takeLatest(CarrierRequests.GET_ALL_CARRIER_REQUESTS, getAllCarrierRequests)
}

function * getNumberOfCarrierRequestsWatcher () {
  yield takeLatest(CarrierRequests.GET_NUMBER_OF_CARRIER_REQUESTS, getNumberOfCarrierRequests)
}

function * getCarrierRequestPickUpTimesWatcher () { // findsecrets-ignore-line
  yield takeLatest(CarrierRequests.GET_CARRIER_REQUEST_PICK_UP_TIMES, getCarrierRequestPickUpTimes)
}

function * createCarrierRequestWatcher () {
  yield takeLatest(CarrierRequests.CREATE_CARRIER_REQUEST, createCarrierRequest)
}

function * updateCarrierRequestWatcher () {
  yield takeLatest(CarrierRequests.UPDATE_CARRIER_REQUEST, updateCarrierRequest)
}

function * searchRequestsWatcher () {
  yield takeLatest(CarrierRequests.SEARCH_REQUESTS, searchRequests)
}

function * addPoWatcher () {
  yield takeLatest(CarrierRequests.ADD_PO, addPo)
}

function * deleteCarrierRequestWatcher () {
  yield takeLatest(CarrierRequests.DELETE_CARRIER_REQUEST, deleteCarrierRequest)
}

export default function * root () {
  yield fork(getAllCarrierRequestsWatcher)
  yield fork(getNumberOfCarrierRequestsWatcher)
  yield fork(getCarrierRequestPickUpTimesWatcher) // findsecrets-ignore-line
  yield fork(createCarrierRequestWatcher)
  yield fork(updateCarrierRequestWatcher)
  yield fork(searchRequestsWatcher)
  yield fork(addPoWatcher)
  yield fork(deleteCarrierRequestWatcher)
}
