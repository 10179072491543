import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { theme } from '../../themes/taylor-farms'

export default class AppointmentsIcon extends Component {
  render () {
    const { width, height, color, className } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={className}
        viewBox="0 0 33 23"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M19.159 21.21v-6.369h-6.37v6.37h6.37zM11.5 14.196v7.66c0 .357.287.644.645.644h7.659a.642.642 0 0 0 .644-.645v-7.659a.642.642 0 0 0-.644-.645h-7.66a.642.642 0 0 0-.644.645z"
          />
          <path
            fill="#FFF"
            d="M30.874 21.21v-6.369h-6.37v6.37h6.37zm1.29.645v-7.659a.642.642 0 0 0-.645-.645h-3.184V.663a.642.642 0 0 0-.645-.645H4.576a.642.642 0 0 0-.645.645v8.154L2.602 7.488a.64.64 0 0 0-.911 0 .64.64 0 0 0 0 .912l2.429 2.43a.64.64 0 0 0 .912 0L7.46 8.4a.653.653 0 0 0 .189-.456.652.652 0 0 0-.19-.456.64.64 0 0 0-.91 0L5.22 8.817v-7.51h21.825v12.244H23.86a.643.643 0 0 0-.645.645v7.66c0 .357.287.644.645.644h7.66a.65.65 0 0 0 .644-.645z"
          />
          <path
            fill={color}
            d="M8.159 21.21v-6.369h-6.37v6.37h6.37zm-7.014-7.659a.642.642 0 0 0-.645.645v7.66c0 .357.287.644.645.644h7.659a.642.642 0 0 0 .645-.645v-7.659a.643.643 0 0 0-.645-.645h-7.66z"
          />
        </g>
      </svg>

    )
  }
}

AppointmentsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

AppointmentsIcon.defaultProps = {
  width: 33,
  height: 33,
  color: theme.colors.primary
}
