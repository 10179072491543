import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getOrders
  getOrders: ['payload'],
  // The operation has started and is loading
  getOrdersLoading: null,
  // The users was successfully fetched
  getOrdersSuccess: ['orders', 'pages'],
  // An error occurred
  getOrdersFailure: ['errorMessage'],

  setSelectedOrders: ['orders'],
  setCheckedOrders: ['orders'],
  selectOrder: ['order'],
  deselectOrder: ['order'],

  openOrderDetailsModal: null,
  setOrderDetails: ['orderDetails'],
  closeOrderDetailsModal: null,

  revertOrder: ['id'],
  revertOrderSuccess: ['payload'],
  revertOrderFailure: ['errorMessage'],
  updateOrdersFromAppointment: ['appointment'],

  // orders socket
  updateOrdersWithSocketOrder: ['payload'],
  onSearchChange: ['payload']
})

export const OrdersTypes = Types
export default Creators
