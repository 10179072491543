import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DeleteIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height} viewBox="0 0 11 15"
      >
        <path fill={color} fillRule="evenodd" d="M1.527 13.982S1.75 15 2.9 15h5.2c1.15 0 1.373-1.018 1.373-1.018L10.5 4H.5l1.027 9.982zM7.5 5.5a.5.5 0 0 1 1 0L8 13a.5.5 0 0 1-1 0l.5-7.5zM5 5.5a.5.5 0 0 1 1 0V13a.5.5 0 0 1-1 0V5.5zM3 5a.5.5 0 0 1 .5.5L4 13a.5.5 0 0 1-1 0l-.5-7.5A.5.5 0 0 1 3 5zm7.2-3H8V1c0-.759-.245-1-1-1H4c-.692 0-1 .335-1 1v1H.8c-.442 0-.8.337-.8.751 0 .415.358.75.8.75h9.4c.442 0 .8-.335.8-.75 0-.414-.358-.75-.8-.75zM7 2H4V1h3v1z"/>
      </svg>
    )
  }
}

DeleteIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
}

DeleteIcon.defaultProps = {
  width: 11,
  height: 15,
  color: '#ffffff'
}
