import moment from 'moment-timezone'
import { getDayStart } from '../../utils/time'
import { Moment } from 'moment'
import { List } from 'immutable'
import { Appointment } from '../../types/Appointment'

export const createDays = (timezone: string) => {
  const today = moment.tz(timezone)
  return [
    {
      id: 0,
      status: 'past',
      date: getDayStart(today).subtract(1, 'days'),
      countKey: 'appointmentYesterdayCount'
    },
    {
      id: 1,
      status: 'active',
      date: getDayStart(today),
      countKey: 'appointmentTodayCount'
    },
    {
      id: 2,
      status: 'active',
      date: getDayStart(today).add(1, 'days'),
      countKey: 'appointmentNext1Count'
    },
    {
      id: 3,
      status: 'active',
      date: getDayStart(today).add(2, 'days'),
      countKey: 'appointmentNext2Count'
    },
    {
      id: 4,
      status: 'active',
      date: getDayStart(today).add(3, 'days'),
      countKey: 'appointmentNext3Count'
    }
  ]
}

export const parseHour = (time: Moment) => {
  return time.hours().toString(10).padStart(2, '0')
}

export const getUniqueSkusByHour = (
  appointmentsList: List<Appointment>,
  timezone: string,
  startShift: Moment,
  endShift: Moment
) => {
  const appointments = appointmentsList.toJS()
  if (appointments.length <= 0) {
    return new Map<string, string[]>()
  }

  return appointments.reduce((map: Map<string, string[]>, appointment: Appointment) => {
    const appointmentStart = moment(appointment.date).tz(timezone)
    const appointmentEnd = moment(appointment.date)
      .tz(timezone)
      .add(appointment.duration, 'minutes')

    if (appointmentStart.isAfter(endShift) || appointmentEnd.isBefore(startShift)) {
      return map
    }

    const startHour = appointmentStart.startOf('hour')

    const appointmentSkus =
      appointment?.orders?.flatMap(order => order?.items?.map(item => item.sku) ?? []) ?? []

    const numSkus = appointmentSkus.length
    const avgSkusPerHour = Math.ceil(numSkus / (appointment.duration / 60))

    const durationHours = Math.ceil(appointment.duration / 60)
    for (let i = 0; i < durationHours; i++) {
      const hour = startHour.clone().add(i, 'hours')
      if (hour.isBefore(startShift) || hour.isAfter(endShift)) {
        continue
      }

      const key = parseHour(hour)
      if (!map.has(key)) {
        map.set(key, [])
      }

      for (let j = 0; j < avgSkusPerHour; j++) {
        if (!appointmentSkus?.length) {
          continue
        }
        const sku = appointmentSkus.shift()
        const skuList = map.get(key) ?? []
        if (sku && !skuList.includes(sku)) {
          skuList.push(sku)
          map.set(key, skuList)
        }
      }
    }
    return map
  }, new Map<string, string[]>())
}
