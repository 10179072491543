import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { AppointmentSchema } from '../appointments/sagas'

import axios from '../../utils/axios'
import DoorsActions, { DoorsTypes } from './actions'
import { token as getToken } from '../users/selectors'
import { selectAllAreas } from '../../areas/areas-slice'
import { OrderSchema } from '../orders/sagas'
import { getWorkingDayEnd, getWorkingDayStart } from '../../utils/time'
import {
  selectCurrentBuildingId,
  selectCurrentBuildingTimezone,
  selectEndDate,
  selectStartDate,
  setStartConfiguration
} from '../../app/app-slice'
import { selectAllDoors } from '../../doors/doors-slice'
import { setManyAppointments } from '../../appointments/appointments-slice'
import { setManyOrders } from '../../orders/orders-slice'
import merge from 'lodash.merge'

// Sagas
function * getAppointmentsForDoors ({
  payload: { selectedWarehouse, selectedStartDate, selectedEndDate, selectedEndShift }
}) {
  const warehouse = yield select(selectCurrentBuildingId)
  let startDate = yield select(selectStartDate)
  let endDate = yield select(selectEndDate)

  const buildingId = selectedWarehouse || warehouse
  const timezone = yield select(selectCurrentBuildingTimezone)

  const tzStart = (selectedStartDate || startDate.tz(timezone)).clone()
  const tzEnd = (selectedEndDate || endDate.tz(timezone)).clone()

  const startShift = getWorkingDayStart(tzStart.clone())
  const endShift = (selectedEndShift || getWorkingDayEnd(tzEnd.clone())).clone()

  // make sure we only show a single shift
  if (endShift.diff(startShift, 'hours') > 24) {
    endShift.subtract(endShift.diff(startShift, 'hours') - 23, 'hours')
  }

  // Set date being fetched
  yield put(
    setStartConfiguration({
      startDate: tzStart.clone(),
      endDate: tzEnd.clone(),
      /**
       * Adjust shift dates for the table
       * For now we don't need to set startShift anywhere else so instead of dispatching multiple
       * actions where it needs to change we centralize this here
       */
      startShift,
      endShift
    })
  )

  const startTime = tzStart.utc().format('HH:mm:ss')
  const endTime = tzEnd.utc().format('HH:mm:ss')
  startDate = tzStart.format('L')
  endDate = tzEnd.format('L')

  yield put(DoorsActions.getAppointmentsForDoorsLoading())

  try {
    if (buildingId) {
      const token = yield select(getToken)
      const areas = yield select(selectAllAreas)
      const doors = yield select(selectAllDoors)

      const areaBuilding = areas.find(a => a.buildingId === buildingId)
      const areaDoors = doors.filter(d => d.areaId === areaBuilding.id)

      let attributes = ''
      areaDoors.forEach((door, index) => {
        index > 0 ? (attributes += `&doorId=${door.id}`) : (attributes += `doorId=${door.id}`)
      })
      const { data } = yield call(
        axios.get,
        `/appointments?${attributes}&timeFrom=${startTime}&timeTo=${endTime}&dateFrom=${startDate}&dateTo=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      // update normalized entities appointments
      const normalizedData = normalize(data, [AppointmentSchema])
      yield put(setManyAppointments(normalizedData.entities.appointments))

      const orders = {}

      for (const appt of data) {
        if (appt.orders) {
          const normalizedOrdersData = normalize(appt.orders, [OrderSchema])
          merge(orders, normalizedOrdersData.entities.orders)
        }
      }

      yield put(setManyOrders(Object.values(orders)))
      yield put(DoorsActions.setAppointments(data))
    }

    yield put(DoorsActions.getAppointmentsForDoorsSuccess())
  } catch (e) {
    yield put(DoorsActions.getAppointmentsForDoorsFailure(e))
  }
}

// Watchers

function * getAppointmentsForDoorsWatcher () {
  yield takeLatest(DoorsTypes.GET_APPOINTMENTS_FOR_DOORS, getAppointmentsForDoors)
}

export default function * root () {
  yield fork(getAppointmentsForDoorsWatcher)
}
