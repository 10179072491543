import { Link } from 'react-router-dom'
import React, { Component } from 'react'

class NotFound extends Component {
  render () {
    return (
      <div>
        <h1>
          Page not found.
        </h1>
        <span>Click <Link to="/scheduler">here</Link> to go home</span>
      </div>
    )
  }
}

NotFound.propTypes = {}

export default NotFound
