import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EntityState, RequestStatus } from '../common-types'

export interface OrderStatus {
  id: number
  name: string
}

export interface OrderStatusesState extends EntityState<OrderStatus> {}

const adapter = createEntityAdapter<OrderStatus>()

export const initialState: OrderStatusesState = adapter.getInitialState({
  loading: RequestStatus.Idle,
  error: null
})

const slice = createSlice({
  name: 'orderStatus',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setManyOrderStatuses: (state: OrderStatusesState, action: PayloadAction<OrderStatus[]>) => {
      adapter.setMany(state, action.payload)
    }
  }
})

export default slice.reducer
export const { setManyOrderStatuses } = slice.actions

const globalizedSelectors = adapter.getSelectors((state: {orderStatus: OrderStatusesState}) => {
  return state.orderStatus
})

export const _selectAllOrderStatuses = globalizedSelectors.selectAll
