import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DownloadIcon extends Component {
  render () {
    const { width, height, color, className } = this.props
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2.6458333 3.4395833"
        height={height}
        width={width}>
        <g transform="translate(-90.147322,-53.375446)">
          <g
            style={{ fill: color, fillRule: 'nonzero' }}
            transform="matrix(0.26458333,0,0,0.26458333,58.397322,49.935863)">
            <path d="m 127.966,19.078 a 0.374,0.374 0 0 0 -0.34,-0.245 h -1.5 v -5.416 c 0,-0.23 -0.169,-0.417 -0.376,-0.417 h -1.5 c -0.207,0 -0.375,0.187 -0.375,0.417 v 5.416 h -1.5 a 0.375,0.375 0 0 0 -0.341,0.244 0.452,0.452 0 0 0 0.059,0.447 l 2.625,3.333 C 124.789,22.948 124.892,23 125,23 c 0.108,0 0.21,-0.053 0.282,-0.143 l 2.625,-3.333 a 0.452,0.452 0 0 0 0.06,-0.447 z" />
            <path d="m 128.667,22 v 2.4 h -7.334 V 22 H 120 v 3.2 c 0,0.442 0.299,0.8 0.667,0.8 h 8.666 C 129.702,26 130,25.642 130,25.2 V 22 Z" />
          </g>
        </g>
      </svg>
    )
  }
}

DownloadIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  className: PropTypes.string
}

DownloadIcon.defaultProps = {
  width: '2.6458333mm',
  height: '3.4395833mm',
  color: '#ffffff'
}
