import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  clearAppointments: null,

  setAppointments: ['appointments'],

  editAppointment: ['appointment'],

  updateAppointmentForDoorWithSocketAppointment: ['appointment'],

  // tmp hack
  getAppointmentsForDoor: ['doorId'],
  getAppointmentsForDoorLoading: null,
  getAppointmentsForDoorSuccess: ['appointments'],
  getAppointmentsForDoorFailure: ['errorMessage'],

  getAppointmentsForDoors: ['payload'],
  getAppointmentsForDoorsLoading: null,
  getAppointmentsForDoorsSuccess: null,
  getAppointmentsForDoorsFailure: ['errorMessage']
})

export const DoorsTypes = Types
export default Creators
