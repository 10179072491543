import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCarrierUser } from '../../carrier-app/carrier-app-slice'
import {
  updateCarrierUser,
  updateCarrierUserCleared,
  selectUpdateCarrierUserError,
  selectUpdateCarrierUserStatus
} from '../../users/users-slice'
import UpsertCarrierUser from './UpsertCarrierUser'

function UpdateCarrierUser () {
  const user = useSelector(selectCarrierUser)
  const updateStatus = useSelector(selectUpdateCarrierUserStatus)
  const updateError = useSelector(selectUpdateCarrierUserError)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) navigate('/carrier/account/create')
  })

  if (!user || !updateError) return null

  return (
    <UpsertCarrierUser
      user={user}
      upsertStatus={updateStatus}
      upsertError={updateError}
      upsertCleared={updateCarrierUserCleared}
      upsert={updateCarrierUser}
    ></UpsertCarrierUser>
  )
}

export default UpdateCarrierUser
