import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ScheduleIcon extends Component {
  render () {
    const { width, height, color, className, warning, warningColor, issue, issueColor } = this.props
    let viewBox = '0 0 17 18'
    const styles = {}
    if (warning || issue) {
      viewBox = '0 0 22 21'
      styles.transform = 'translate(0px, 4px)'
    }

    return (
      <svg xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
      >
        <g fill={color} fillRule="evenodd">
          <path style={styles} d="M1.308 16.714h14.384V6.43H1.308v10.285zM3.923 1.607a.31.31 0 0 1 .327-.321h.654a.31.31 0 0 1 .327.321V4.5a.31.31 0 0 1-.092.231.322.322 0 0 1-.235.09H4.25a.31.31 0 0 1-.327-.321V1.607zm7.847 0a.31.31 0 0 1 .326-.321h.654a.31.31 0 0 1 .327.321V4.5a.31.31 0 0 1-.327.321h-.654a.31.31 0 0 1-.327-.321V1.607zm4.842 1.346a1.268 1.268 0 0 0-.92-.382h-1.307v-.964c0-.442-.16-.82-.48-1.135A1.587 1.587 0 0 0 12.75 0h-.654c-.45 0-.834.157-1.154.472-.32.315-.48.693-.48 1.135v.964H6.539v-.964c0-.442-.16-.82-.48-1.135A1.587 1.587 0 0 0 4.903 0H4.25c-.45 0-.834.157-1.154.472-.32.315-.48.693-.48 1.135v.964H1.307c-.355 0-.661.128-.92.382A1.226 1.226 0 0 0 0 3.857v12.857c0 .348.13.65.388.904.259.255.565.382.92.382h14.384c.354 0 .661-.127.92-.382.259-.254.388-.556.388-.904V3.857c0-.348-.13-.65-.388-.904z"/>
          <path style={styles} d="M5.38 11.988h2.632v2.632c0 .12.162.38.488.38.325 0 .488-.26.488-.38v-2.632h2.632c.12 0 .38-.162.38-.488 0-.326-.26-.488-.38-.488H8.988V8.38c0-.12-.163-.38-.488-.38-.326 0-.488.26-.488.38v2.632H5.38c-.12 0-.38.162-.38.488 0 .325.26.488.38.488"/>
          {
            warning
              ? <path fill={warningColor} d="M21.143 8.864L16.327.403A.804.804 0 0 0 15.625 0a.803.803 0 0 0-.703.403l-4.815 8.461a.692.692 0 0 0 .012.757.809.809 0 0 0 .69.379h9.632a.809.809 0 0 0 .69-.379.692.692 0 0 0 .012-.757zm-4.715-.601c0 .056-.02.103-.06.141a.196.196 0 0 1-.14.057h-1.205a.195.195 0 0 1-.14-.057.187.187 0 0 1-.06-.141V7.121c0-.056.02-.103.06-.14a.196.196 0 0 1 .14-.058h1.204c.054 0 .101.019.141.057.04.038.06.085.06.141v1.142zm-.013-2.247a.133.133 0 0 1-.066.099.264.264 0 0 1-.147.039h-1.16a.279.279 0 0 1-.15-.04c-.043-.026-.063-.058-.063-.099l-.107-2.746c0-.056.021-.098.063-.126.054-.044.104-.066.15-.066h1.38c.046 0 .096.022.15.066a.13.13 0 0 1 .063.114l-.113 2.759z"/>
              : ''
          }
          {
            issue
              ? <path fill={issueColor} d="M14.5 0A10.67 10.67 0 0 0 8 2.2l6.5 8.8L21 2.2A10.67 10.67 0 0 0 14.5 0zm0 5.604c-.593 0-1.187.148-1.73.346L10 2.238A8.776 8.776 0 0 1 14.5 1c1.632 0 3.165.446 4.5 1.238L16.181 6a3.821 3.821 0 0 0-1.681-.396z"/>
              : ''
          }
        </g>
      </svg>
    )
  }
}

ScheduleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  warning: PropTypes.bool,
  warningColor: PropTypes.string,
  issue: PropTypes.bool,
  issueColor: PropTypes.string
}

ScheduleIcon.defaultProps = {
  width: 17,
  height: 18,
  color: '#ffffff',
  warning: false,
  warningColor: '#ffffff', // FF8100
  issue: false,
  issueColor: '#ffffff' // FF1E3A
}
