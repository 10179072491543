import { createSelector } from 'reselect'

import { getStateBuildings } from '../common/selectors'
import { selectAllBuildings } from '../../buildings/buildings-slice'

const createGenericBuildingsSelector = field => createSelector(
  getStateBuildings,
  buildings => buildings.get(field)
)

export const getSettingsForAllBuildings = createGenericBuildingsSelector('settings')

export const createGetSettingsForSiteBuildings = createSelector(
  getSettingsForAllBuildings,
  selectAllBuildings,
  (settingsForAllBuildings, allBuildings) => (siteId) => {
    if (!settingsForAllBuildings || !siteId) return []

    return settingsForAllBuildings.filter(buildingSettings => {
      return allBuildings.find(building =>
        building.siteId === siteId && building.id === buildingSettings.get('building').get('id')
      )
    })
  }
)

export const createGetSettingsByBuildingId = createSelector(
  getSettingsForAllBuildings,
  settingsForAllBuildings => buildingId => {
    if (!settingsForAllBuildings) return []

    return settingsForAllBuildings.filter(buildingSettings => {
      return buildingSettings.get('building').get('id') === buildingId
    })
  }
)
