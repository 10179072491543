import { fromJS } from 'immutable'

export const maskPhoneNumber = phoneNumber => {
  const numbers = phoneNumber
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  return !numbers[2]
    ? numbers[1]
    : '' + numbers[1] + '-' + numbers[2] + (numbers[3] ? '-' + numbers[3] : '')
}

// this function return phone number in format (xxx) xxx-xxxx
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export function convertToCamelCase (name) {
  if (name == null) return name

  const status = name.charAt(0).toLowerCase() + name.slice(1)
  return status.replace(/ /g, '')
}

export function isNumeric (number) {
  return !Number.isNaN(parseFloat(number)) && Number.isFinite(number)
}

export const isNotNullOrUndef = value => value !== null && value !== undefined

export const pick = (object, keys) => {
  if (!object) return new Map()

  const immutableObject = fromJS(object)

  return fromJS(
    keys.reduce((result, key) => {
      return immutableObject.has(String(key))
        ? {
            ...result,
            [key]: immutableObject.get(String(key))
          }
        : result
    }, {})
  )
}

export const ignore = (object, keys) => {
  if (!object) return new Map()

  const immutableObject = fromJS(object)

  return fromJS(
    immutableObject.keySeq().reduce((result, objectKey) => {
      return keys.find(key => String(key) === String(objectKey))
        ? result
        : {
            ...result,
            [objectKey]: immutableObject.get(objectKey)
          }
    }, {})
  )
}

export const getInboundOutboundLabel = (isOutbound) => isOutbound ? 'OUT' : 'IN'
export const getOriginDestinationLabel = (isOutbound) => isOutbound ? 'Destination' : 'Origin'
export const getVendorCustomerLabel = (isOutbound) => isOutbound ? 'Customer Name' : 'Vendor Name'
export const getPurchaseSalesLabel = (isOutbound) => isOutbound ? 'Sales order' : 'Purchase order'
