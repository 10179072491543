import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { UITypes } from './actions'

export const openDestinationDetailsModal = (state, { payload }) =>
  state.merge({
    orderDetailsModalAppointment: payload
  })

export const closeDestinationDetailsModal = state =>
  state.merge({
    orderDetailsModalAppointment: null
  })

export const reducer = createReducer(INITIAL_STATE, {
  [UITypes.OPEN_DESTINATION_DETAILS_MODAL]: openDestinationDetailsModal,
  [UITypes.CLOSE_DESTINATION_DETAILS_MODAL]: closeDestinationDetailsModal
})
