import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { Button, ButtonPrimary } from '../../styled/Buttons'
import { DefaultParagraph, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent, StyledModalFooter
} from '../../styled/Modals'
import CloseButton from '../CloseButton'

class DeletePOConfirmationModal extends Component {
  render () {
    const { isOpen = false, onClose, onConfirmDelete } = this.props
    return (
      <StyledModal
        isOpen={isOpen}
        size="small"
      >
        <StyledModalAlertHeader>
          <Title>DELETE POs</Title>
          <CloseButton onClick={onClose} />
        </StyledModalAlertHeader>
        <StyledModalContent>
          <DefaultParagraph>Are you sure you want to delete all POs?</DefaultParagraph>
        </StyledModalContent>
        <StyledModalFooter>
          <Button type="submit" onClick={onClose}>
            No
          </Button>
          <ButtonPrimary type="submit" onClick={onConfirmDelete}>
            Yes, Delete
          </ButtonPrimary>
        </StyledModalFooter>
      </StyledModal>
    )
  }
}
DeletePOConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirmDelete: PropTypes.func
}
export default DeletePOConfirmationModal
