import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  position: relative;

  ${props => props.fullwidth && css`
    width: 100%;
  `}
`

const Label = styled.label`
  position: absolute;
  top: 13px;
  left: 0px;
  color: ${props => props.theme.labelColor};
  pointer-events: none;
  font-weight: 600;
  font-size: 10px;
  transition: top 150ms ease-in-out;

  ${props => props.error && css`
    color: red;
  `}
`

const HelpText = styled.label`
  color: ${props => props.theme.labelColor};
  pointer-events: none;
  font-weight: 300;
  font-size: 12px;

  ${props => props.error && css`
    color: red;
  `}
`

const Input = styled.input`
  width: 100%;
  font-size: 18px;
  margin-top: 18px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: ${props => props.theme.colors.default};
  type: ${props => props.type || 'text'};
  padding-bottom: 1rem;
  margin-bottom: 2px;

  &:active {
    outline: none;
  }

  &::placeholder {
    opacity: 0;
    color: ${props => props.theme.colors.default};
    transition: opacity 150ms ease-in-out;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primaryAccent};
    &::placeholder {
      opacity: 1;
    }
  }

  ${props => props.fixedLabel && css`
    & + label {
      top: 0px;
    }
  `}

  ${props => !props.fixedLabel && css`
    &:focus + label {
      top: 0px;
    }
    ${props => props.value && css`
      & + label {
        top: 0px;
      }
    `}
  `}
`

const CarrierTextInput = ({ label, fullwidth, ...props }) => (
  <Container fullwidth={fullwidth}>
    <Input {...props} />
    {label &&
      <Label theme={props.theme} error={props.error}>{label}</Label>
    }
    {props.error &&
      <HelpText theme={props.theme} error={props.error}>{props.error}</HelpText>
    }
  </Container>
)

CarrierTextInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.any,
  theme: PropTypes.any,
  fullwidth: PropTypes.any
}

export default CarrierTextInput
