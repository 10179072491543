import { call, put, takeLatest, fork, select } from 'redux-saga/effects'

import axios from '../../utils/axios'
import SettingsActions, { SettingsTypes } from './actions'
import { getUserToken } from '../users/selectors'

function * getSettings ({ payload }) {
  yield put(SettingsActions.getSettingsLoading())
  try {
    const token = yield select(getUserToken)
    const url = '/settings'
    const { data } = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(SettingsActions.getSettingsSuccess(data))
  } catch (e) {
    yield put(SettingsActions.getSettingsFailure(e))
  }
}

function * getSettingsWatcher () {
  yield takeLatest(SettingsTypes.GET_SETTINGS, getSettings)
}

export default function * root () {
  yield fork(getSettingsWatcher)
}
