import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { SettingsTypes } from './actions'

export const getSettingsLoading = state =>
  state.merge({
    getSettingsIsLoading: true,
    getAreasErrorMessage: ''
  })

export const getSettingsSuccess = (state, { settings }) =>
  state.merge({
    settings,
    getSettingsIsLoading: false,
    getAreasErrorMessage: null
  })

export const getSettingsFailure = (state, { errorMessage }) =>
  state.merge({
    areas: null,
    getAreasIsLoading: false,
    getAreasErrorMessage: errorMessage
  })

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SettingsTypes.GET_SETTINGS_LOADING]: getSettingsLoading,
  [SettingsTypes.GET_SETTINGS_SUCCESS]: getSettingsSuccess,
  [SettingsTypes.GET_SETTINGS_FAILURE]: getSettingsFailure
})
