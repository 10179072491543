import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { Button } from '../../styled/Buttons'
import { ContentTitle, Title } from '../../styled/Texts'
import {
  StyledModal,
  StyledModalAlertHeader,
  StyledModalContent, StyledModalFooter
} from '../../styled/Modals'
import {
  getEditingAppointmentSuggestions
} from '../../modules/appointments/selectors'
import CloseButton from '../CloseButton'
import { selectAllDoors } from '../../doors/doors-slice'

const customStyles = {
  content: {}
}

const Options = styled.div`
  margin-top: 1rem;
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow: auto;
  max-height: 300px;

  ${Button} {
    margin: 0 0 0.5rem;
  }
`

class SuggestAppointmentTimeModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectingDoor: false,
      selectedTime: null,
      selectedDoor: null
    }
  }

  renderSelectTime () {
    const { selectedTime } = this.state
    const { times, onClose, timezone } = this.props
    return (
      <Fragment>
        <StyledModalContent>
          <ContentTitle>Select a date from the list bellow</ContentTitle>
          <Options>
            {times &&
              times.map((time, i) => {
                const newTime = moment.utc(time.get('time')).tz(timezone)
                return (
                  <Button
                    key={i}
                    selected={time === selectedTime}
                    onClick={() => this.setState({ selectedTime: time })}
                  >
                    {newTime.format('MM . DD . YYYY')}
                    {' | '}
                    {newTime.format('HH:mm')}
                  </Button>
                )
              })}
          </Options>
        </StyledModalContent>
        <StyledModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={!selectedTime}
            onClick={() => this.setState({ selectingDoor: true })}
          >
            Select a door
          </Button>
        </StyledModalFooter>
      </Fragment>
    )
  }

  renderSelectDoor () {
    const { selectedTime, selectedDoor } = this.state
    const { onClose, onApplySuggestions, doors, timezone } = this.props

    return (
      <Fragment>
        <StyledModalContent>
          <ContentTitle>Select a door</ContentTitle>
          <Options>
            {selectedTime &&
              selectedTime.get('doors').map(doorId => {
                const door = doors && doors.find(d => d.id === doorId)
                return (
                  <Button
                    key={doorId}
                    selected={doorId === selectedDoor}
                    onClick={() => this.setState({ selectedDoor: doorId })}
                  >
                    {door && door.name}
                  </Button>
                )
              })}
          </Options>
        </StyledModalContent>
        <StyledModalFooter>
          <Button
            onClick={() =>
              this.setState({ selectingDoor: false, selectedDoor: null })
            }
          >
            Back
          </Button>
          <Button
            disabled={!selectedDoor}
            onClick={() => {
              const newTime = moment.utc(selectedTime.get('time')).tz(timezone)
              onClose()
              onApplySuggestions({
                time: newTime,
                doorId: selectedDoor
              })
            }}
          >
            Update appointment
          </Button>
        </StyledModalFooter>
      </Fragment>
    )
  }

  renderNoTimes () {
    const { onClose } = this.props

    return (
      <Fragment>
        <StyledModalContent>
          <p>
            Sorry, there are no dates/times available. Please contact production.
          </p>
        </StyledModalContent>
        <StyledModalFooter>
          <Button onClick={onClose}>Ok</Button>
        </StyledModalFooter>
      </Fragment>
    )
  }

  render () {
    const { selectingDoor } = this.state
    const { isOpen, onClose, loading, times } = this.props

    let content
    if (loading) {
      content = <StyledModalContent><p>Loading...</p></StyledModalContent>
    } else {
      if (times && times.size) {
        if (selectingDoor) {
          content = this.renderSelectDoor()
        } else {
          content = this.renderSelectTime()
        }
      } else {
        content = this.renderNoTimes()
      }
    }

    let title = 'Next available loading date(s)'
    if (!loading && (!times || !times.size)) {
      title = 'No dates available'
    }

    return (
      <StyledModal
        isOpen={isOpen}
        styles={customStyles}
        size="small"
      >
        <StyledModalAlertHeader>
          <Title>{title}</Title>
          <CloseButton onClick={onClose} />
        </StyledModalAlertHeader>
        {content}
      </StyledModal>
    )
  }
}

SuggestAppointmentTimeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  times: PropTypes.object,
  loading: PropTypes.bool,
  doors: PropTypes.array,
  timezone: PropTypes.string,
  onApplySuggestions: PropTypes.func
}

const mapStateToProps = state => ({
  times: getEditingAppointmentSuggestions(state),
  doors: selectAllDoors(state)
})

export default connect(
  mapStateToProps
)(SuggestAppointmentTimeModal)
