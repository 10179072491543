import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Navbar = styled.div``
export const NavBrandImage = styled.img``
export const NavbarNav = styled.div``
export const NavLinks = styled(NavLink)``
export const NavLinkLogin = styled.a``
export const Back = styled.div``
export const BackText = styled.div``
export const TopNavigation = styled.div``
