import { List } from 'immutable'
import { createSelector } from 'reselect'
import { getStateDoors } from '../common/selectors'

export const getAppointmentsForDoors = createSelector(
  getStateDoors,
  doors => {
    return doors.get('appointments') || new List()
  }
)

export const getAppointmentsForDoorsIsLoading = createSelector(
  getStateDoors,
  doors => doors.get('getAppointmentsForDoorsIsLoading')
)
