import { List } from 'immutable'
import { createSelector } from 'reselect'
import { getStateCustomers } from '../common/selectors'
import { entityToOptions } from '../../utils/dropdownOptions'

export const getAllCustomers = createSelector(
  getStateCustomers,
  customers => customers ? customers.get('customers') : List([])
)

export const getAllCustomersAsOptions = createSelector(
  getAllCustomers,
  customers => entityToOptions(customers)
)
