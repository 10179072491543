import React from 'react'
import * as PropTypes from 'prop-types'

import { Placeholder } from '../styled/Boxes'
import ScheduleIcon from '../components/icons/Schedule'
import DropOnTableHoc from '../components/TimeTable/DropOnTableHoc'
import { theme } from '../themes/taylor-farms'

export interface YardsDropProps {
  drop: any
  canDrop: boolean
  isOver: boolean
}

const YardsDrop = ({ drop, canDrop, isOver }: YardsDropProps) => {
  return (
    <Placeholder
      ref={drop}
      // @ts-ignore:next-line
      inverted={canDrop}
      isOver={canDrop && isOver}
      width={'100%'}
      height={'100%'}
    >
      <ScheduleIcon
        color={
          canDrop ? theme.colors.contrast : theme.colors.default
        }
        height={24}
        width={24} />
    </Placeholder>
  )
}

export default DropOnTableHoc(YardsDrop)

YardsDrop.propTypes = {
  drop: PropTypes.func,
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool
}
