import React from 'react'
import { ThSeparator, ThSeparatorSticky } from './DaySeparator.styles'
import { Moment } from 'moment'

export interface DaySeparatorProps {
  hour: Moment,
  doorsCount: number
}

const DaySeparator = (props: DaySeparatorProps) => {
  const { doorsCount, hour } = props

  return (
    <tr>
      <ThSeparatorSticky>
        {hour.format('MM/DD/YYYY')}
      </ThSeparatorSticky>
      <ThSeparator colSpan={(doorsCount || 0) + 1}>
        &nbsp;
      </ThSeparator>
    </tr>
  )
}

export default DaySeparator
